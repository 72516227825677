import styles from './TradeStatus.module.css'

import { Ellipse } from '../../../../ui/Ellipse'

type TradeStatusProps = {
  text: string
  isDisable?: boolean
  containerStyle?: string
}

const TradeStatus = ({ text, isDisable, containerStyle }: TradeStatusProps) => {
  const bgColor = isDisable ? '#3b4266' : '#344551'
  const textColor = isDisable ? '#7E88BB' : '#8EF84F'

  return (
    <div className={`${styles.status} ${containerStyle}`} style={{ backgroundColor: bgColor }}>
      <Ellipse bgColor={textColor} />
      <p style={{ color: textColor, fontSize: 14 }}>{text}</p>
    </div>
  )
}

export { TradeStatus }
