import api from 'api/axios/axiosInstance'

export type CatalogItemColorsType = {
  id: string
  name: string
  isSelectedColor: boolean
}

const getCatalogItemColors = async (imageId: string) => {
  const response: CatalogItemColorsType[] = await api.get(`/api/catalog/color/${imageId}/list`)
  try {
    return response
  } catch (error) {
    console.log(error)
  }
}

const changeCatalogItemColor = async (imageId: string, colorId: string) => {
  await api.post(`/api/catalog/color/${imageId}/${colorId}/create`)
  try {
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

const deleteCatalogItemColor = async (imageId: string, colorId: string) => {
  await api.delete(`/api/catalog/color/${imageId}/${colorId}/delete`)
  try {
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export { getCatalogItemColors, changeCatalogItemColor, deleteCatalogItemColor }
