import styles from './ProfileAndSubscriptionBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ProfileSettingsPageAvatar } from '../../ProfileSettingsPageAvatar'
import {
  Text12Green,
  Text12White,
  Text14Green,
  Text14Grey,
  Text14White,
  Text16White,
  Text18White
} from 'components/styledComponents/text/textStyles'
import VerticalDivider from 'components/ui/dividers/VerticalDivider/VerticalDivider'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'

import ButtonIcon from '../../../../icons/profileSettings/rounded_white.svg'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'
import VipIcon from '../../../../icons/userStatus/pro_badge.svg'
import StarIcon from '../../../../icons/star_yellow.svg'
import UserCheckIcon from '../../../../icons/profileSettings/user-check.svg'
import MailIcon from '../../../../icons/profileSettings/mail.svg'
import { formatUserVipDate } from 'utils/forms/helpers'
import { useState } from 'react'
import { SubscriptionPaymentModalsContainer } from 'components/ui/modals/SubscriptionPaymentModalsContainer'

const firstString = 'Файлы *.png, *.jpg, *.webp '
const secondString = 'или *.gif-анимации'
const fileSizeText = '(макс. 5 мб)'
const list = [
  'Объявление активно 36 часов',
  'Доступ к разделу “Хочу купить”',
  'Можно создать до 1000 объявлений',
  'Уникальная роль в ДС',
  'Получение значка на профиль',
  'Доступен игровой номер',
  'Уведомления в Discord/Telegram'
]

const ProfileAndSubscriptionBlock = observer(() => {
  const {
    isVip,
    userData: { nickname, email, subscriptionExpirationDate }
  } = userInfoStore

  const dateFormat = formatUserVipDate(subscriptionExpirationDate)

  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <DivFlexRow style={styles.container}>
      <DivFlexColumn style={styles.userBlock}>
        <DivFlexRow style={styles.avatarBlock}>
          <ProfileSettingsPageAvatar />

          {isVip ? (
            <DivFlexColumn style={styles.avatarText}>
              <Text14Grey>{firstString}</Text14Grey>
              <Text14Grey>
                {secondString} {fileSizeText}
              </Text14Grey>
            </DivFlexColumn>
          ) : (
            <DivFlexColumn style={styles.inActiveVipAvatarText}>
              <Text14Grey>{firstString}</Text14Grey>
              <Text14Grey>{fileSizeText}</Text14Grey>

              <DivFlexRow style={styles.buyGifButton} onClick={setOpen.bind(this, true)}>
                <img src={VipIcon} alt="vip" className={styles.buyGifIcon} />
                <Text12White>GIF-анимация в подписке</Text12White>
              </DivFlexRow>
            </DivFlexColumn>
          )}
        </DivFlexRow>

        <DivFlexRow style={styles.userInfo}>
          <img src={UserCheckIcon} alt="nickname" className={styles.userInfoIcon} />
          <Text14White>{nickname}</Text14White>
        </DivFlexRow>

        <DivFlexRow style={styles.userInfo}>
          <img src={MailIcon} alt="mail" className={styles.userInfoIcon} />
          <Text14White>{email}</Text14White>
        </DivFlexRow>
      </DivFlexColumn>

      <VerticalDivider cssStyle={styles.divider} />

      {isVip ? (
        <></>
      ) : (
        /*
        RPMARKET-192: [BE/FE] Выдавать всем подписку автоматически

        <DivFlexColumn style={styles.activeSubBlock}>
          <Text16White>Статус подписки</Text16White>

          <DivFlexRow style={styles.statusBlock}>
            <DivFlexRow style={styles.status}>
              <div className={styles.statusIcon}></div>
              <Text14Green>Активна</Text14Green>
            </DivFlexRow>
            <Text12Green style={styles.statusText}>до {dateFormat}</Text12Green>
          </DivFlexRow>

          <OvalButton
            text="Продлить"
            icon={ButtonIcon}
            cssContainerStyle={styles.button}
            fontSize={12}
            onClick={setOpen.bind(this, true)}
          />
        </DivFlexColumn>*/
        <DivFlexColumn style={styles.inActiveSubBlock}>
          <Text18White style={styles.inActiveSubText}>Подключите RP PRO!</Text18White>

          <DivFlexColumn style={styles.list}>
            {list.map((text, index) => {
              return (
                <DivFlexRow key={index} style={styles.listItem}>
                  <img src={StarIcon} alt="star" className={styles.iconListItem} />
                  <Text14White>{text}</Text14White>
                </DivFlexRow>
              )
            })}
          </DivFlexColumn>

          <DivFlexRow style={styles.buyVipButton} onClick={setOpen.bind(this, true)}>
            <img src={VipIcon} alt="vip" className={styles.vipBadge} />
            <Text14White>Оформить подписку</Text14White>
            <Text14White style={styles.bold}>от 249 рублей</Text14White>
          </DivFlexRow>
        </DivFlexColumn>
      )}

      {isOpen && <SubscriptionPaymentModalsContainer onClose={setOpen.bind(this, false)} />}
    </DivFlexRow>
  )
})

export { ProfileAndSubscriptionBlock }
