import styles from './ShopBarCardFooter.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { ButtonCountArrowLeft } from 'components/ui/buttons/ButtonCountArrowLeft'
import { useState } from 'react'
import { SubscriptionPaymentModalsContainer } from 'components/ui/modals/SubscriptionPaymentModalsContainer'
import { createLinkAdvertisementBuy, createLinkAdvertisementSale } from '../../../../../utils/link'

type ShopBarCardFooterProps = {
  countAdvertisementBuy: number
  countAdvertisementSale: number
  nameButtonSale: string
  nameButtonBuy: string
  isVip: boolean
  catalogId: string
}

const ShopBarCardFooter = ({
  countAdvertisementBuy,
  countAdvertisementSale,
  nameButtonSale,
  nameButtonBuy,
  isVip,
  catalogId
}: ShopBarCardFooterProps) => {
  const [isSubModal, setSubModal] = useState<boolean>(false)

  const handleClickBuyButton = () => {
    if (!isVip) {
      setSubModal(true)
    } else {
      window.open(createLinkAdvertisementBuy(catalogId), '_blank')
    }
  }

  const handleClickSaleButton = () => {
    window.open(createLinkAdvertisementSale(catalogId), '_blank')
  }

  return (
    <>
      <DivFlexRow style={`${styles.buttonsContainer}`}>
        <DivFlexRow style={`${styles.catalogButtonsBox} ${!isVip && styles.vipDisable}`}>
          <ButtonCountArrowLeft
            text={nameButtonBuy ?? "Покупают"}
            count={countAdvertisementBuy}
            color="green"
            fontSize={14}
            containerStyle={styles.button}
            isVip={isVip}
            onClick={handleClickBuyButton}
          />
          <ButtonCountArrowLeft
            text={nameButtonSale ?? "Продают"}
            count={countAdvertisementSale}
            color="azure"
            fontSize={14}
            containerStyle={styles.button}
            onClick={handleClickSaleButton}
          />
        </DivFlexRow>
      </DivFlexRow>

      {isSubModal && <SubscriptionPaymentModalsContainer onClose={setSubModal.bind(this, false)} />}
    </>
  )
}

export { ShopBarCardFooter }
