import styles from './AuthModal.module.css'

import { useState } from 'react'
import logo from '../../../icons/rp_market_logo_dark.svg'
import CrossButton from '../../buttons/CrossButton/CrossButton'
import AuthForm from '../../forms/AuthForm/AuthForm'
import RegistrationForm from 'components/ui/forms/RegistrationForm/RegistrationForm'
import { ModalWrapper } from 'components/ui/wrappers/ModalWrapper'

type AuthModalProps = {
  isOpen: boolean
  onClose: () => void
  isPortal?: boolean
  inModal?: boolean
}

const AuthModal = ({ isOpen, onClose, isPortal, inModal = false }: AuthModalProps) => {
  const [isLoginPage, setLoginPage] = useState<boolean>(false)

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} isPortal={isPortal} inModal={inModal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <img className={styles.logo} src={logo} alt="logo" />
          <CrossButton onClick={onClose} anyStyles={{ position: 'absolute', right: 0, top: 0 }} />
        </div>

        <div className={styles.formContainer}>
          <div className={styles.formHeader}>
            <div className={styles.textContainer}>
              <p
                className={`${styles.formHeaderText} ${isLoginPage && styles.inActive}`}
                onClick={setLoginPage.bind(this, false)}>
                Авторизация
              </p>
              <p
                className={`${styles.formHeaderText} ${!isLoginPage && styles.inActive}`}
                onClick={setLoginPage.bind(this, true)}>
                Регистрация
              </p>
            </div>

            <div className={styles.lineContainer}>
              <div className={`${styles.line} ${isLoginPage ? styles.inActive : styles.active}`}></div>
              <div className={`${styles.line} ${isLoginPage ? styles.active : styles.inActive}`}></div>
            </div>

            <div className={styles.formContainer}>
              {isLoginPage ? <RegistrationForm onClose={onClose} /> : <AuthForm onClose={onClose} />}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AuthModal
