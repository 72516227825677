import { Text16Grey } from 'components/styledComponents/text/textStyles'
import styles from './PhotoLoader.module.css'

const loadingText = 'Изображение загружается'

const PhotoLoader = () => {
  return (
    <div className={styles.container}>
      <Text16Grey>{loadingText}</Text16Grey>
    </div>
  )
}

export { PhotoLoader }
