import styles from './FormTextBackButton.module.css'

type FormTextBackButtonProps = {
  text: string
  onClick: () => void
}

const FormTextBackButton = ({ text, onClick }: FormTextBackButtonProps) => {
  return (
    <div className={styles.underlinedText} style={{ marginTop: '12px' }} onClick={onClick}>
      <span className={styles.backButtonText}>{text}</span>
    </div>
  )
}

export default FormTextBackButton
