import styles from './CatalogCardPagePropertyBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14White, Text16Grey } from 'components/styledComponents/text/textStyles'
import { SearchItemProperty } from 'store/platformConfigStore/types'

type CatalogCardPagePropertyBlockProps = {
  property: SearchItemProperty[]
}

const CatalogCardPagePropertyBlock = ({ property = [] }: CatalogCardPagePropertyBlockProps) => {
  return (
    <DivFlexColumn style={styles.container}>
      {!!property.length &&
        property.map((item, index) => {
          const { name, value } = item
          return (
            <DivFlexRow key={index} style={styles.property}>
              <Text16Grey>{name}:</Text16Grey>
              <Text14White>{value}</Text14White>
            </DivFlexRow>
          )
        })}
    </DivFlexColumn>
  )
}

export { CatalogCardPagePropertyBlock }
