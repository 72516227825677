import styles from './CatalogCard.module.css'

import { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getCardImage, getCatalogItem } from 'api/catalog/catalog.method'
import { observer } from 'mobx-react-lite'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { LoaderPage } from 'components/ui/loaders/LoaderPage'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ShopBarBackButtonBlock } from '../../ui/ShopBar/ShopBarBackButtonBlock'
import { CatalogCardPageNameBlock } from './CatalogCardPageNameBlock'
import { CatalogCardPageParamsBlock } from './CatalogCardPageParamsBlock'
import { ShopBarChartBlock } from 'components/ui/ShopBar/ShopBarChartBlock'
import { ShopBarGallery } from 'components/ui/modals/ShopBarModal/ShopBarGallery'
import { ShopBarProductDescription } from 'components/ui/modals/ShopBarModal/ShopBarProductDescription'
import { NavigateLinks } from '../../../PageRouters'

type Image = {
  id: string
  name: string
  image?: string
}

const CatalogCard = observer(() => {
  const { cardId } = useParams()

  const {
    id,
    name,
    sourceCategory,
    images,
    price,
    currency,
    advertisementCurrency,
    propertyPage,
    countAdvertisementBuy,
    countAdvertisementSale,
    description,
  } = userPlatformConfig.catalogItem

  const [isLoading, setLoading] = useState<boolean>(true)
  const [isFirstImageLoading, setFirstImageLoading] = useState<boolean>(false)

  const [loadedImages, setLoadedImages] = useState<Image[]>([])
  const [activeImage, setActiveImage] = useState<Image | null>(null)

  useLayoutEffect(() => {
    // подгружаем данные карточки
    ;(async () => {
      if (cardId) {
        await getCatalogItem(cardId)
        setLoading(false)
      }
    })()
  }, [cardId])

  useEffect(() => {
    //  подгружаем главную картинку
    ;(async () => {
      if (images[0]) {
        const image = images[0]
        const firstImage = await getCardImage(image.id)
        const newImage = { id: image.id, name: image.name, image: firstImage }
        const fakeImages = Array.from(images.slice(1))
        setActiveImage(newImage)
        setLoadedImages([newImage, ...fakeImages])
        setFirstImageLoading(true)
      }
    })()
  }, [images])

  useEffect(() => {
    // подгружаем остальные фото
    if (isFirstImageLoading) {
      const loadImages = async () => {
        const loadedImagePromises = images.slice(1).map(async (image) => {
          const getImage = await getCardImage(image.id)
          return { id: image.id, name: image.name, image: getImage }
        })

        const loadedAllImages = await Promise.all(loadedImagePromises)

        // Обновляем массив loadedImages с учетом совпадающих id
        // это потому что мы хотим на каждой картинке крутилку пока она грузится...
        setLoadedImages((prev) => {
          // Создаем копию массива loadedImages
          const updatedLoadedImages = [...prev]
          loadedAllImages.forEach((loadedImage) => {
            // Проверяем, есть ли объект с таким же id в текущем массиве
            const index = updatedLoadedImages.findIndex((image) => image.id === loadedImage.id)

            if (index !== -1) {
              // Обновляем объект, добавляя ключ image
              updatedLoadedImages[index].image = loadedImage.image
            } else {
              // Добавляем новый объект с ключом image
              updatedLoadedImages.push(loadedImage)
            }
          })
          return updatedLoadedImages
        })
      }

      loadImages()
    }
  }, [isFirstImageLoading, images])

  if (isLoading) {
    return (
      <PageWrapper>
        <LoaderPage />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <DivFlexColumn style={styles.section}>
        <ShopBarBackButtonBlock link={NavigateLinks.Catalog} />

        <DivFlexRow style={styles.main}>
          <CatalogCardPageNameBlock name={name} description={sourceCategory} image={activeImage?.image} />

          <CatalogCardPageParamsBlock
            price={price}
            currency={currency}
            advertisementCurrency={advertisementCurrency}
            property={propertyPage}
            countAdvertisementBuy={countAdvertisementBuy}
            countAdvertisementSale={countAdvertisementSale}
            catalogId={id}
          />
        </DivFlexRow>

        <ShopBarGallery
          cssContainer={styles.gallery}
          loadedImages={loadedImages}
          activeImage={activeImage}
          setActiveImage={setActiveImage}
        />

        {!!description && <ShopBarProductDescription description={description} mt={32} />}

        <ShopBarChartBlock cssContainer={styles.chartBlock} headerText="График изменения цены" />
      </DivFlexColumn>
    </PageWrapper>
  )
})

export { CatalogCard }
