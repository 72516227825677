import styles from './img.module.css'

type ImgProps = {
  img: string
  alt?: string
  style?: string
}

export const ImgBase = ({ img, alt = '', style }: ImgProps) => {
  return <img className={`${styles.ImgBase} ${style}`} src={img} alt={alt} />
}

export const ImgRounded = ({ img, alt, style }: ImgProps) => {
  return <img className={`${styles.ImgRounded} ${style}`} src={img} alt={alt} />
}
