import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'

export const Agreement = () => {
    return (
        <PageWrapper>
            <div>
                Пользовательское Соглашение<br/>
                <br/>
                Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ИП Ковальчук Артур Вячеславович (ИНН 366324411128 ОГРНИП: 322619600009200) (далее RP.Market или Администрация) с одной стороны и пользователем сайта с другой.<br/>
                Используя сайт, Вы соглашаетесь с условиями данного соглашения.Если Вы не согласны с условиями данного соглашения, не используйте сайт RP.Market!<br/>
                <br/>
                Предмет соглашения<br/>
                <br/>
                Администрация предоставляет пользователю право на размещение на сайте следующей информации:<br/>
                - Текстовой информации<br/>
                - Фотоматериалов<br/>
                Права и обязанности сторон<br/>
                Пользователь имеет право:<br/>
                - осуществлять поиск информации на сайте<br/>
                - получать информацию на сайте<br/>
                - создавать информацию для сайта<br/>
                - копировать информацию на другие сайты с указанием источника<br/>
                - использовать информацию сайта в личных некоммерческих целях<br/>
                Администрация имеет право:<br/>
                - по своему усмотрению и необходимости создавать, изменять, отменять правила<br/>
                - ограничивать доступ к любой информации на сайте<br/>
                - блокировать учетные записи пользователя без объяснения причины<br/>
                - блокировать/удалять/редактировать объявления по своему усмотрению<br/>
                Пользователь обязуется:<br/>
                - обеспечить достоверность предоставляемой информации<br/>
                - не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или административная ответственность<br/>
                - не нарушать работоспособность сайта<br/>
                - не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу<br/>
                - не совершать действия, направленные на введение других Пользователей в заблуждение<br/>
                - не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам<br/>
                - не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ<br/>
                - не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами<br/>
                - не использовать сайт в качестве площадки для торговли внутри-игровыми предметами за реальную валюту
                Администрация обязуется:<br/>
                - поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.<br/>
                Ответственность сторон<br/>
                - пользователь лично несет полную ответственность за распространяемую им информацию<br/>
                - администрация не несет никакой ответственности за достоверность информации, скопированной из других источников<br/>
                - администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг<br/>
                - администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами<br/>
                - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса<br/>
                Условия действия Соглашения<br/>
                - данное Соглашение вступает в силу при любом использовании данного сайта.<br/>
                - соглашение перестает действовать при появлении его новой версии.<br/>
                - администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.<br/>
                - администрация не оповещает пользователей об изменении в Соглашении.<br/>
                <br/>
                <br/>
                Контакты для связи:<br/>
                - Техническая поддержка: support@rp.market<br/>
                - Чат в правом нижнем углу сайт<br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </PageWrapper>
    )
}
