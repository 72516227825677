import styles from './SubscriptionPaymentStatusModal.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import Loader from 'components/ui/loaders/Loader/Loader'
import { Text14Grey, Text32White } from 'components/styledComponents/text/textStyles'
import { SubscriptionPaymentModalHeader } from '../SubscriptionPaymentModalHeader'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { ModalWrapper } from 'components/ui/wrappers/ModalWrapper'
import { ReferralTransactionDataType, ReferralTransactionStatus } from 'api/payment/payment'

import CrossIcon from '../../../../icons/cross_purple.svg'
import ErrorIcon from '../icons/error.svg'
import SuccessIcon from '..//icons/success.svg'
import RefreshIcon from '../../../../icons/refresh.svg'

enum HeaderStatusText {
  Success = 'Оплата прошла успешно!',
  Error = 'Платёж не прошёл',
  Loading = 'Обработка платежа'
}

enum StatusText {
  Success = 'Поздравляем!Теперь Вы в клубе RP Market, и Вам доступны все преимущества PRO Аккаунта',
  Error = 'Причина отмены:',
  Loading = 'Пожалуйста, не закрывайте это окно, пока не завершится процесс оплаты'
}

type SubscriptionPaymentStatusModalProps = {
  isOpen: boolean
  onClose: () => void
  transaction: ReferralTransactionDataType | null | undefined
  subscriptionName: string
  returnToPaymenModal: () => void
}

const SubscriptionPaymentStatusModal = ({
  isOpen,
  onClose,
  transaction,
  subscriptionName,
  returnToPaymenModal
}: SubscriptionPaymentStatusModalProps) => {
  const getHeaderStatusText = (): string => {
    if (!transaction || transaction.status === ReferralTransactionStatus.Pending) {
      return HeaderStatusText.Loading
    } else {
      if (transaction.status === ReferralTransactionStatus.Succeeded) {
        return HeaderStatusText.Success
      } else {
        return HeaderStatusText.Error
      }
    }
  }

  const getStatusText = (): string => {
    if (!transaction || transaction.status === ReferralTransactionStatus.Pending) {
      return StatusText.Loading
    } else {
      if (transaction.status === ReferralTransactionStatus.Succeeded) {
        return StatusText.Success
      } else return `${StatusText.Error} ${transaction.reasonCanceled ?? 'Неизвестно'}`
    }
  }

  const getImage = () => {
    if (!transaction || transaction.status === ReferralTransactionStatus.Pending) {
      return <Loader size={27} bold={3} anyStyles={{ margin: '4px 0 auto 4px' }} />
    } else {
      if (transaction.status === ReferralTransactionStatus.Succeeded) {
        return <img src={SuccessIcon} alt="success" className={styles.statusIcon} />
      } else {
        return <img src={ErrorIcon} alt="error" className={styles.statusIcon} />
      }
    }
  }

  const imageStatus = getImage()
  const headerStatusText = getHeaderStatusText()
  const statusText = getStatusText()

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <DivFlexColumn style={styles.modal}>
        <DivFlexRow style={styles.header}>
          {imageStatus}
          <Text32White style={styles.headerText}>{headerStatusText}</Text32White>
          <img className={styles.headerCloseIcon} src={CrossIcon} alt="close" onClick={onClose} />
        </DivFlexRow>

        <Text14Grey style={styles.subText}>{statusText}</Text14Grey>

        <SubscriptionPaymentModalHeader
          subscriptionName={subscriptionName}
          isActive={!!transaction && transaction.status === ReferralTransactionStatus.Succeeded}
        />

        {!!transaction && transaction.status === ReferralTransactionStatus.Canceled && (
          <OvalButton
            text="Повторить попытку"
            icon={RefreshIcon}
            cssContainerStyle={styles.refreshButton}
            onClick={returnToPaymenModal}
          />
        )}
      </DivFlexColumn>
    </ModalWrapper>
  )
}

export { SubscriptionPaymentStatusModal }
