import styles from './Home.module.css'

import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { HomeWelcomeSection } from './sections/HomeWelcomeSection/HomeWelcomeSection'
import { HomeSubscriptionSection } from './sections/HomeSubscriptionSection/HomeSubscriptionSection'
import { HomeCatalogSection } from './sections/HomeCatalogSection/HomeCatalogHomeCatalogSection'
import { HomeAdsSection } from './sections/HomeAbsSection/HomeAdsSection'
import { HomeFiltersSection } from './sections/HomeFiltersSection'
import { HomeNotificationsSection } from './sections/HomeNotificationsSection'
import { HomePriceChartSection } from './sections/HomePriceChartSection'
import { HomeActivitySection } from './sections/HomeActivitySection'
import { HomePhoneSection } from './sections/HomePhoneSection'
import { HomeBackgroundImages } from './sections/HomeBackgroundImages'
import { HeaderHome } from 'components/ui/headers/HeaderHome'

const Home = () => {
  return (
    <>
      <HeaderHome />

      <HomeBackgroundImages />

      <PageWrapper style={styles.wrapper}>
        <HomeWelcomeSection />
        <HomeSubscriptionSection />
        <HomeCatalogSection />
        <HomeAdsSection />
        <HomeFiltersSection />
        <HomeNotificationsSection />
        <HomePriceChartSection />
        <HomeActivitySection />
        <HomePhoneSection />
      </PageWrapper>
    </>
  )
}

export default Home
