import styles from './UserHeaderMenu.module.css'

import { ButtonTexts } from 'constants/buttonTexts'
import StringWithIconButton from 'components/ui/buttons/StringWithIconButton/StringWithIconButton'
import ToPlugProButton from 'components/ui/buttons/ToPlugProButton/ToPlugProButton'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { BrowserRouters } from 'PageRouters'
import { userAuthLogOut } from 'api/auth/auth'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'
import { UserDataContainer } from 'components/ui/dropdowns/UserHeaderMenu/UserDataContainer'
import { UserStatusContainer } from 'components/ui/dropdowns/UserHeaderMenu/UserStatusContainer'
import { useState } from 'react'
import { SubscriptionPaymentModalsContainer } from 'components/ui/modals/SubscriptionPaymentModalsContainer'

import Setting from '../../../icons/setting_icon.svg'
import Subscription from '../../../icons/subscriptions_icon.svg'
import Base from '../../../icons/base_icon.svg'
import Referral from '../../../icons/referral_icon.svg'
import LogOut from '../../../icons/log-out_icon.svg'
import { useNavigate } from 'react-router-dom'

type UserHeaderMenuProps = {
  isOpen: boolean
  onCloseMenu: () => void
}

const UserHeaderMenu = observer(({ onCloseMenu, isOpen }: UserHeaderMenuProps) => {
  const { isVip, isMedia } = userInfoStore
  const navigate = useNavigate()

  const [isSubModalOpen, setSubModalOpen] = useState<boolean>(false)

  const userHandleLogOut = () => {
    userAuthLogOut()
    onCloseMenu()
    navigate('/')
  }

  return (
    <>
      <div className={`${styles.container} ${isOpen && styles.visible}`}>
        <UserDataContainer />

        <UserStatusContainer onCloseMenu={onCloseMenu} />

        <HorizontalDivider style={{ width: '228px', marginLeft: '8px' }} />

        <StringWithIconButton
          text={ButtonTexts.Settings}
          icon={Setting}
          router={BrowserRouters.ProfileSettings}
          onClick={onCloseMenu}
        />
        {/*
        RPMARKET-192: [BE/FE] Выдавать всем подписку автоматически
        <StringWithIconButton
          text={ButtonTexts.Subscription}
          icon={Subscription}
          router={BrowserRouters.Subscription}
          onClick={onCloseMenu}
        />*/}
        <StringWithIconButton
          text={ButtonTexts.Base}
          icon={Base}
          router={BrowserRouters.ArticleList}
          onClick={onCloseMenu}
        />
        {isMedia && (
          <StringWithIconButton
            text={ButtonTexts.Referral}
            icon={Referral}
            router={BrowserRouters.Referral}
            onClick={onCloseMenu}
          />
        )}

        {!isVip && <ToPlugProButton onCLick={setSubModalOpen.bind(this, true)} />}

        <HorizontalDivider style={{ width: '228px', marginLeft: '8px' }} />

        <StringWithIconButton
          onClick={userHandleLogOut}
          text={ButtonTexts.LogOut}
          icon={LogOut}
          textStyle={{ color: '#7E88BB' }}
          isReverse
        />
      </div>

      {isSubModalOpen && <SubscriptionPaymentModalsContainer onClose={setSubModalOpen.bind(this, false)} />}
    </>
  )
})

export default UserHeaderMenu
