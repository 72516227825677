import {SystemAlertComponent, SystemAlertComponent2} from 'components/ui/SystemAlertComponent'
import styles from './PageWrapper.module.css'

import { ReactNode } from 'react'
import {advertisementFakeSystemAlert, advertisementFakeSystemAlert2} from 'common/systemMessages/advertisementCreate'

type PageWrapperProps = {
  children: ReactNode
  style?: string
  isSystemAlert?: boolean
}

const PageWrapper = ({ children, style, isSystemAlert = false }: PageWrapperProps) => {
  return (
    <div className={`${styles.wrapper} ${style}`}>
       <SystemAlertComponent2 iframe={advertisementFakeSystemAlert2} />
      {isSystemAlert && <SystemAlertComponent iframe={advertisementFakeSystemAlert} />}
      {children}
    </div>
  )
}

export default PageWrapper
