import styles from './HomeFiltersPriceFilterContainer.module.css'

import { DarkGreyContainer } from 'components/pages/Home/components/DarkGreyContainer'
import { Text14Grey, Text14Purple, Text14White } from 'components/styledComponents/text/textStyles'
import { DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'

import PriceTubler from '../price_tumbler.svg'

const HomeFiltersPriceFilterContainer = () => {
  return (
    <DarkGreyContainer style={styles.priceContainer}>
      <Text14Grey style={styles.priceText}>Цена</Text14Grey>

      <DivFlexRow style={styles.priceFilterContainer}>
        <DivFlexRow style={styles.priceValue}>
          <Text14White>от</Text14White>
          <Text14Purple>400.000</Text14Purple>
        </DivFlexRow>
        <DivFlexRow style={styles.priceValue}>
          <Text14White>до</Text14White>
          <Text14Purple>12.000.000</Text14Purple>
        </DivFlexRow>
      </DivFlexRow>

      <ImgBase img={PriceTubler} />
    </DarkGreyContainer>
  )
}

export { HomeFiltersPriceFilterContainer }
