import styles from './InputWidhtCross.module.css'
import CrossIcon from '../../../icons/cross_purple.svg'

type InputWidhtCrossProps = {
  value: string
  onChange: (value: string) => void
  onClick: () => void
  placeHolder?: string
  maxLength?: number
  cssInputContainer?: string
  isValid?: boolean
}

const InputWidhtCross = ({
  value,
  onChange,
  onClick,
  placeHolder,
  maxLength,
  cssInputContainer,
  isValid = true
}: InputWidhtCrossProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const handleClearInput = () => {
    onChange('')
    onClick()
  }

  return (
    <div className={`${styles.container} ${cssInputContainer} ${!isValid && styles.invalid}`}>
      <input
        className={styles.input}
        value={value}
        onChange={handleChange}
        placeholder={placeHolder ?? ''}
        maxLength={maxLength}
      />
      <img className={styles.icon} src={CrossIcon} alt="cross" onClick={handleClearInput} />
    </div>
  )
}

export { InputWidhtCross }
