import styles from './ShopBarGallery.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text16Grey, Text16White } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { PhotoSlider } from 'components/ui/PhotoSlider'
import { useEffect, useState } from 'react'

type Image = {
  id: string
  name: string
  image?: string
}

type ShopBarGalleryProps = {
  loadedImages: Image[]
  activeImage: Image | null
  setActiveImage: (image: Image) => void
  cssContainer?: string
  headerText?: string
  cssDividerStyles?: string
  cssHeaderColor?: string
  isHiddenName?: boolean
}

const ShopBarGallery = ({
  loadedImages,
  activeImage,
  setActiveImage,
  cssContainer,
  headerText,
  cssDividerStyles,
  cssHeaderColor,
  isHiddenName = false
}: ShopBarGalleryProps) => {
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0)
  const [imageQueue, setImageQueue] = useState<Image[]>(loadedImages)

  const photoCounter = `${activeImageIndex + 1} / ${loadedImages.length}`

  useEffect(() => {
    // смена аватарки предмета
    if (activeImage) {
      loadedImages.filter((item) => item.id === activeImage.id && setActiveImage(item))
    }
  }, [loadedImages, activeImage, setActiveImage])

  const handlePreviousImage = () => {
    setImageQueue((prevQueue) => [prevQueue[prevQueue.length - 1], ...prevQueue.slice(0, prevQueue.length - 1)])

    if (activeImageIndex === 0) {
      setActiveImageIndex(loadedImages.length - 1)
    } else setActiveImageIndex(activeImageIndex - 1)
  }

  const handleNextImage = () => {
    setImageQueue((prevQueue) => [...prevQueue.slice(1), prevQueue[0]])

    if (activeImageIndex === loadedImages.length - 1) {
      setActiveImageIndex(0)
    } else setActiveImageIndex(activeImageIndex + 1)
  }

  useEffect(() => {
    setImageQueue(loadedImages)
  }, [loadedImages])

  useEffect(() => {
    setActiveImage(imageQueue[0])
  }, [imageQueue, setActiveImage])

  return (
    <DivFlexColumn style={`${styles.container} ${cssContainer}`}>
      <DivFlexRow style={styles.header}>
        <Text16Grey style={cssHeaderColor}>{headerText ?? 'Галерея'}</Text16Grey>
        <HorizontalDivider cssStyle={`${styles.divider} ${cssDividerStyles}`} />
        <Text16Grey style={cssHeaderColor}>{photoCounter}</Text16Grey>
      </DivFlexRow>

      {activeImage && (
        <Text16White style={`${styles.imageName} ${isHiddenName && styles.hidden}`}>{activeImage.name}</Text16White>
      )}

      {!!loadedImages.length && (
        <PhotoSlider
          loadedImages={imageQueue}
          handleNextImage={handleNextImage}
          handlePreviousImage={handlePreviousImage}
        />
      )}
    </DivFlexColumn>
  )
}

export { ShopBarGallery }
