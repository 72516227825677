import styles from './ChatPage.module.css'

import { useParams } from 'react-router-dom'
import { DivFlexColumn } from 'components/styledComponents/div/div'
import { ChatPageHeaderBlock } from 'components/ui/Chat/ChatPageHeaderBlock'
import { ChatCardProductData } from 'components/ui/Chat/ChatCardProductData'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { ChatPageDialogueBlock } from 'components/ui/Chat/ChatPageDialogueBlock'
import { ChatPageInputBlock } from 'components/ui/Chat/ChatPageInputBlock'

import { useEffect, useState } from 'react'
import { chatClientStore } from '../../../store/chat/chat'
import { observer } from 'mobx-react-lite'
import { getUrlS3 } from '../../../common/utils'
import PageWrapper from '../../ui/wrappers/PageWrapper/PageWrapper'
import { LoaderPage } from '../../ui/loaders/LoaderPage'

const ChatPage = observer(() => {
  const { chatId } = useParams()
  if (!chatId) {
    window.location.href = '/chat'
    return (
      <PageWrapper>
        <LoaderPage />
      </PageWrapper>
    )
  }

  const { loadPrivateChat, privateChat, sendMessage } = chatClientStore
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    loadPrivateChat(chatId).then(() => setLoading(false))
  }, [])

  // для определения размера окна чата
  const anyDivsHeight = 225
  const chatHeight = window.innerHeight - anyDivsHeight

  if (isLoading || !privateChat) {
    return (
      <PageWrapper>
        <LoaderPage />
      </PageWrapper>
    )
  }

  const interlocutorAvatarUrl = privateChat.interlocutor?.avatarId
    ? getUrlS3(privateChat.interlocutor.avatarId)
    : undefined

  return (
    <div className={styles.section} style={{ height: chatHeight }}>
      <DivFlexColumn style={styles.chat}>
        <div className={styles.companionBlock}>
          <ChatPageHeaderBlock
            status={privateChat.interlocutor.activeStatus}
            companionName={privateChat.interlocutor.nickname}
            interlocutorAvatarUrl={interlocutorAvatarUrl}
            gamePhoneId={privateChat.gamePhoneId}
          />
        </div>

        <div className={styles.itemBlock}>
          <ChatCardProductData
            tradeType={privateChat.advertisementType}
            serverName={privateChat.serverName}
            itemName={privateChat.advertisementName}
            itemSourceCategory={privateChat.sourceCategory}
            itemPrice={privateChat.advertisementPrice}
            itemPhotoUrl={getUrlS3(privateChat.advertisementImageId)}
          />
        </div>

        <HorizontalDivider cssStyle={styles.divider} />

        <ChatPageDialogueBlock messages={privateChat.messages} interlocutor={privateChat.interlocutor} />
      </DivFlexColumn>

      <ChatPageInputBlock
        onSendMessage={(text) =>
          sendMessage({
            chatId,
            message: text
          })
        }
      />
    </div>
  )
})

export { ChatPage }
