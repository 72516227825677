import styles from './ChatCardItemStatus.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'

import TopArrow from '../../../icons/chat/arrow-up_azure.svg'
import BottomArrow from '../../../icons/chat/arrow-bottom_yellow.svg'
import { TypeAdvertisement } from '../../../../common/types'
import { convertAdvertisementTradeTypeToText } from '../../../../utils/convert'

type ChatCardItemStatusProps = {
  tradeType: TypeAdvertisement
}

const ChatCardItemStatus = ({ tradeType }: ChatCardItemStatusProps) => {
  const isBuy = tradeType === 'buy'
  const textTradeType = convertAdvertisementTradeTypeToText(tradeType)

  return (
    <DivFlexRow style={`${styles.container} ${isBuy ? styles.buy : styles.cell}`}>
      <p className={`${styles.text} ${isBuy ? styles.buy : styles.cell}`}>{textTradeType}</p>
      <img className={styles.icon} src={isBuy ? BottomArrow : TopArrow} alt="arrow" />
    </DivFlexRow>
  )
}

export { ChatCardItemStatus }
