import { useEffect } from 'react'
import { CSSProperties } from 'react'

type CountDownTimerProps = {
  text: string
  countdown: number
  setCountdown: React.Dispatch<React.SetStateAction<number>>
  anyStyle?: CSSProperties
}

const CountDownTimer = ({ text, countdown, setCountdown, anyStyle }: CountDownTimerProps) => {
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [setCountdown])

  const formattedTime = `${Math.floor(countdown / 60)}:${(countdown % 60).toString().padStart(2, '0')}`

  return (
    <p style={{ fontSize: '14px', ...anyStyle }}>
      {text}
      {formattedTime}
    </p>
  )
}

export default CountDownTimer
