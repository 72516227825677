export const catalog = {
  img: require('./images/catalog-img.png'),
  page: '01',
  header: 'Каталог',
  text: [
    'Все предметы сервера собраны в одном месте и разбиты по категориям',
    'Каждый предмет заскринен, включая расцветки всей одежды',
    'По каждому предмету расписаны его характеристики'
  ],
  buttonText: 'Начать торговать'
}

export const ads = {
  img: require('./images/ads-img.png'),
  page: '02',
  header: 'Объявления',
  text: [
    'Все объявления разбиты по категориям',
    'Можно разместить предмет не только на продажу, но и заявку на покупку'
  ],
  buttonText: 'Создать объявление'
}

export const filters = {
  img: require('./images/filters-img.png'),
  page: '03',
  header: 'Фильтры',
  text: [
    'Множество фильтров по предметам на странице каталога и объявлений',
    'В категории “Одежда” существует фильтр по цвету'
  ],
  buttonText: 'Регистрация'
}

export const notifications = {
  img: require('./images/notifications-img.png'),
  page: '04',
  header: 'Уведомления',
  text: ['Уведомления в Discord/Telegram о новых сообщениях и интересующих вас объявлениях'],
  buttonText: 'Создать объявление'
}

export const chart = {
  img: require('./images/cart-img.png'),
  page: '05',
  header: 'График цен',
  text: ['По каждому предмету можно посмотреть среднюю стоимость продажи в любой отрезок времени'],
  buttonText: 'Начать торговать'
}

export const activity = {
  img: require('./images/activityes-img.png'),
  page: '06',
  header: 'Активность',
  text: ['Игрок имеет три статуса: онлайн, офлайн, в игре', 'На основе статуса вы можете оперативно провести сделку'],
  buttonText: 'Создать аккаунт'
}

export const phone = {
  img: require('./images/phone-img.png'),
  page: '07',
  header: 'Игровой номер телефона',
  text: ['Если игрок сейчас в игре, вы можете оперативно позвонить внутри игры по интересующему вас объявлению'],
  buttonText: 'Начать торговать'
}
