import {userPlatformConfig} from "../../store/platformConfigStore/platformConfigStore";
import {PlatformConfig} from "../../store/platformConfigStore/types";
import api from "../axios/axiosInstance";
import {ELocalStorage} from "../../common/enum/localStorage";

export const getPlatformConfig = async () => {
    const { saveUserPlatformConfig, saveUserCurrentServer } = userPlatformConfig

    try {
        const response: PlatformConfig = await api.get('/api/platform/config')
        saveUserPlatformConfig(response)

        const storageServerJSON = localStorage.getItem(ELocalStorage.server)
        if (storageServerJSON) {
            // Если строка JSON есть в localStorage, то преобразуем ее в объект
            const storageServer = JSON.parse(storageServerJSON)


            // Проверяем, есть ли у объекта поле id
            if ('id' in storageServer) {
                // Если поле id есть, сохраняем его в стор
                saveUserCurrentServer(storageServer)
            } else {
                // Если поля id нет, сохраняем первый сервер из полученных данных в стор и в localStorage
                const defaultServer = response.servers[0]
                saveUserCurrentServer(defaultServer)
                localStorage.setItem(ELocalStorage.server, JSON.stringify(defaultServer))
            }
        } else {
            // Если объекта в localStorage нет, то сохраняем первый сервер из полученных данных в стор и в localStorage
            const defaultServer = response.servers[0]
            saveUserCurrentServer(defaultServer)
            localStorage.setItem(ELocalStorage.server, JSON.stringify(defaultServer))
        }
    } catch (error) {
        console.log(error)
    }
}
