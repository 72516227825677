import styles from './ProfileAccountsBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ProfileSettingsHeaderAlert } from '../../ProfileSettingsHeaderAlert'
import { ProfileSettingsWhatBlock } from '../../ProfileSettingsWhatBlock'
import { ProfileSettingsCreateElementButton } from 'components/ui/ProfileSettings/ProfileSettingsCreateElementButton'
import { ProfileSocialAccount } from '../../ProfileSocialAccount'

import DiscordCreateIcon from '../../../../icons/social/discord_grey.svg'
import TelegramCreateIcon from '../../../../icons/social/telegram_grey.svg'
import DiscordIcon from '../../../../icons/social/discord_in_circle_purple.svg'
import TelegramIcon from '../../../../icons/social/telegram_in_circle_purple.svg'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { linkedAccountStore } from '../../../../../store/linkedAccountStore'
import PageWrapper from '../../../../ui/wrappers/PageWrapper/PageWrapper'
import { LoaderPage } from '../../../../ui/loaders/LoaderPage'
import {
    deleteLinkedAccount, patchLinkedAccount,
    postLinkedAccountGenerateUrl,
    postLinkedAccountTestMessage
} from '../../../../../api/linked-account/linked-account'
import errorAlertsStore from '../../../../../store/errorAlertsStore'
import { MessageType } from '../../../../ui/errors/AlertError'

// TODO сделать логику показа кнопок не добавленной соц сети, когда появятся методы на беке

const alertText = 'У Вас еще нет добавленных аккаунтов'

const ProfileAccountsBlock = observer(() => {
  const { linkedAccount, loadLinkedAccount, deleteAccount, updateAccount } = linkedAccountStore
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    loadLinkedAccount().then(() => setLoading(false))
  }, [])

  const handlerOnClickTestMessage = (type: 'discord' | 'telegram') => async () => {
    const result = await postLinkedAccountTestMessage(type)
    if (result.error) {
      errorAlertsStore.addError({
        errorType: MessageType.Error,
        errorText: result.error.message
      })
    } else {
      errorAlertsStore.addError({
        errorType: MessageType.Success,
        errorText: 'Уведомление отправлено!'
      })
    }
  }

  const handlerOnClickGenerateUrl = (type: 'discord' | 'telegram') => async () => {
    const result = await postLinkedAccountGenerateUrl(type)
    if (result.data) {
      window.open(result.data.url, '_blank')
    }
    if (result.error) {
      errorAlertsStore.addError({
        errorType: MessageType.Error,
        errorText: result.error.message
      })
    }
  }

    const handlerOnClickSenderMessage = (type: 'discord' | 'telegram') => async (isSendMessage: boolean) => {
        const result = await patchLinkedAccount(type, {
            isSendMessage,
            isSendAdvertisement: true,
        })
        if (result.data) {
            updateAccount(type, result.data)
            errorAlertsStore.addError({
                errorType: isSendMessage ? MessageType.Success : MessageType.Warning,
                errorText: isSendMessage ? `Отправка личных сообщений для ${type} включена` : `Отправка личных сообщений для ${type} выключена`
            })
        }
        if (result.error) {
            errorAlertsStore.addError({
                errorType: MessageType.Error,
                errorText: result.error.message
            })
        }
    }

  const handlerDeleteAccount = (type: 'discord' | 'telegram') => async () => {
    const result = await deleteLinkedAccount(type)
    if (result.error) {
      errorAlertsStore.addError({
        errorType: MessageType.Error,
        errorText: result.error.message
      })
    } else {
      deleteAccount(type)
      errorAlertsStore.addError({
        errorType: MessageType.Success,
        errorText: 'Аккаунт успешно отвязан'
      })
    }
  }

  if (isLoading) {
    return (
      <PageWrapper>
        <LoaderPage />
      </PageWrapper>
    )
  }

  return (
    <DivFlexColumn style={styles.container}>
      {!linkedAccount.telegram && !linkedAccount.discord && <ProfileSettingsHeaderAlert alertText={alertText} />}

      <DivFlexRow style={styles.baseButtonsBlock}>
        {!linkedAccount.discord && (
          <ProfileSettingsCreateElementButton
            text="Добавить аккаунт в Discord"
            onClick={handlerOnClickGenerateUrl('discord')}
            icon={DiscordCreateIcon}
            iconSize={'auto'}
            cssCardStyles={styles.baseButton}
          />
        )}

        {!linkedAccount.telegram && (
          <ProfileSettingsCreateElementButton
            text="Добавить аккаунт в Telegram"
            onClick={handlerOnClickGenerateUrl('telegram')}
            icon={TelegramCreateIcon}
            iconSize={'auto'}
            cssCardStyles={styles.baseButton}
          />
        )}
      </DivFlexRow>

      {linkedAccount.discord && (
        <ProfileSocialAccount
          nameMessenger={'Discord'}
          isSocialStatus
          socialIcon={DiscordIcon}
          socialNickname={linkedAccount.discord.name}
          activeSendMessage={linkedAccount.discord.isSendMessage}
          onClickTestMessage={handlerOnClickTestMessage('discord')}
          onClickDeleteAccount={handlerDeleteAccount('discord')}
          onClickSenderMessage={handlerOnClickSenderMessage('discord')}
        />
      )}

      {linkedAccount.telegram && (
        <ProfileSocialAccount
          nameMessenger={'Telegram'}
          isSocialStatus
          socialIcon={TelegramIcon}
          socialNickname={`${linkedAccount.telegram.name}`}
          activeSendMessage={linkedAccount.telegram.isSendMessage}
          onClickTestMessage={handlerOnClickTestMessage('telegram')}
          onClickDeleteAccount={handlerDeleteAccount('telegram')}
          onClickSenderMessage={handlerOnClickSenderMessage('telegram')}
        />
      )}

      <ProfileSettingsWhatBlock
        buttonText="Для чего нужно привязывать аккаунт?"
        onClick={() => window.open('/faq/why-do-i-need-to-link-an-account')}
      />
    </DivFlexColumn>
  )
})

export { ProfileAccountsBlock }
