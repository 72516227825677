import { observer } from 'mobx-react-lite'
import { UserStatusButton } from '../UserStatusButton'
import styles from './UserStatusContainer.module.css'
import { userInfoStore } from 'store/userInfoStore'
import Offline from '../../../../icons/userStatus/offline-status-icon.svg'
import Online from '../../../../icons/userStatus/online-status-icon.svg'
import InGame from '../../../../icons/userStatus/game-status-icon.svg'
import { UserStatusLockButton } from '../UserStatusLockButton'
import { useState } from 'react'
import { changeUserStatus } from 'api/user/userChangeData'
import Loader from 'components/ui/loaders/Loader/Loader'
import { userStatusToText } from '../../../../../utils/userStatusToText'

type UserStatusContainerProps = {
  onCloseMenu: () => void
}

const UserStatusContainer = observer(({ onCloseMenu }: UserStatusContainerProps) => {
  const { isVip } = userInfoStore

  const [isSubmit, setSubmit] = useState<boolean>(false)

  const handleChangeStatus = async (buttonType: string) => {
    setSubmit(true)
    const response = await changeUserStatus(buttonType)
    if (response) {
      onCloseMenu()
    }
    setSubmit(false)
  }

  return (
    <div className={styles.container}>
      <UserStatusButton text={userStatusToText('offline')} icon={Offline} type="offline" onClick={handleChangeStatus} />
      <UserStatusButton text={userStatusToText('online')} icon={InGame} type="online" onClick={handleChangeStatus} />

      {!isVip ? (
        <UserStatusLockButton />
      ) : (
        <UserStatusButton text={userStatusToText('game')} icon={Online} type="game" onClick={handleChangeStatus} />
      )}

      {isSubmit && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </div>
  )
})

export { UserStatusContainer }
