import { SearchItemProperty } from 'store/platformConfigStore/types'
import styles from './ShopBarModalData.module.css'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text16White, Text16Grey, Text14White } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'

type ShopBarModalDataProps = {
  headerText: string
  property: SearchItemProperty[]
  isVisible: boolean
}

const ShopBarModalData = ({ headerText, property, isVisible }: ShopBarModalDataProps) => {
  if (isVisible) {
    return (
      <DivFlexColumn style={styles.container}>
        <DivFlexRow style={styles.header}>
          <Text16White>{headerText}</Text16White>
          <HorizontalDivider cssStyle={styles.divider} />
        </DivFlexRow>

        <DivFlexRow style={styles.propertyesBlock}>
          {!!property.length &&
            property.map((item, index) => {
              return (
                <DivFlexRow key={index} style={styles.property}>
                  <Text16Grey>{item.name}:</Text16Grey>
                  <Text14White>{item.value}</Text14White>
                </DivFlexRow>
              )
            })}
        </DivFlexRow>
      </DivFlexColumn>
    )
  } else {
    return null
  }
}

export { ShopBarModalData }
