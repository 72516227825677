import styles from './AdvertisementCreateChooseServer.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text24White } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { ShopBarHeaderDropdown } from 'components/ui/ShopBar/ShopBarHeaderDropdown'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import { observer } from 'mobx-react-lite'

const headerText = '2. Выберите сервер'

type AdvertisementCreateChooseServerProps = {
  handleChooseServer: (serverId: string) => void
}

const AdvertisementCreateChooseServer = observer(({ handleChooseServer }: AdvertisementCreateChooseServerProps) => {
  const { servers } = userPlatformConfig.platformConfigs

  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexRow style={styles.header}>
        <Text24White>{headerText}</Text24White>
        <HorizontalDivider cssStyle={styles.divider} />
      </DivFlexRow>
      <DivFlexRow style={styles.body}>
        <ShopBarHeaderDropdown
          serversArray={servers}
          cssContainerStyles={styles.dropdown}
          isScarlet
          onChooseServer={handleChooseServer}
          isNotSaveServer
        />
      </DivFlexRow>
    </DivFlexColumn>
  )
})

export { AdvertisementCreateChooseServer }
