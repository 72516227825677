import styles from './HomeNotificationsMainTextContainer.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text18White, Text24White, Text48Grey } from 'components/styledComponents/text/textStyles'
import { Ellipse } from 'components/ui/Ellipse'

const HomeNotificationsMainTextContainer = () => {
  return (
    <DivFlexColumn style={styles.textContainer}>
      <Text24White style={styles.headerNumber}>04</Text24White>
      <Text48Grey style={styles.headerText}>Уведомления</Text48Grey>
      <ul className={styles.itemsList}>
        <li className={styles.item}>
          <Ellipse />
          <Text18White>Уведомления в Discord/Telegram о новых сообщениях и интересующих вас объявлениях</Text18White>
        </li>
      </ul>
    </DivFlexColumn>
  )
}

export { HomeNotificationsMainTextContainer }
