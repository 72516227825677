import { getChatInfo, getChatList, getChatSystemMessageInfo, getChatSystemMessageList } from '../../api/chat/method'
import {
  IChatListChatResponse,
  IChatListResponse,
  IGetChatInfoResponse,
  IGetChatSystemMessageInfo,
  IGetChatSystemMessageList
} from '../../api/chat/type.response'
import { action, makeObservable, observable } from 'mobx'
import { Howl } from 'howler'
import sound_new_message from '../../sound/new_message.mp3'
import { ChatSendMessage } from './chat.type'
import { userInfoStore } from '../userInfoStore'
import { io, Socket } from 'socket.io-client'
import errorAlertsStore from '../errorAlertsStore'
import { MessageType } from '../../components/ui/errors/AlertError'
const context = new AudioContext()

const soundNewMessage = new Howl({
  src: [sound_new_message]
})

export class Chat {
  privateChat?: IGetChatInfoResponse

  chatList: IChatListResponse = {
    chats: []
  }

  systemMessageChatInfo: IGetChatSystemMessageInfo = {
    countNotRead: 0,
    lastMessage: null
  }
  systemMessages: Array<IGetChatSystemMessageList> = []

  private socket: Socket

  constructor() {
    makeObservable(this, {
      privateChat: observable,
      chatList: observable,
      systemMessageChatInfo: observable,
      systemMessages: observable,
      loadPrivateChat: action,
      loadChatList: action,
      loadChatSystemMessageList: action,
      loadChatSystemMessageInfo: action,
      onMessage: action.bound,
      onSystemMessage: action.bound
    })
  }

  connectSocket = (jwtToken: string) => {
    if (this.socket) {
      this.socket.disconnect().connect()
      return
    }

    this.socket = io((process.env.REACT_APP_SOCKET_URL ?? '') + '/chat', {
      auth: {
        token: jwtToken
      }
    })

    this.socket.on('sendMessage', this.onMessage)
    this.socket.on('systemMessage', this.onSystemMessage)
  }

  sendMessage = (data: ChatSendMessage) => {
    this.socket.emit('sendMessage', data)
  }

  onMessage(data: IChatListChatResponse) {
    if (this.privateChat && this.privateChat.chatId === data.chatId) {
      this.privateChat.messages.push(data.chatLastMessage)
    }

    if (this.chatList) {
      const existingIndexChat = this.chatList.chats.findIndex((chat) => chat.chatId === data.chatId)
      if (existingIndexChat !== -1) {
        this.chatList.chats[existingIndexChat].chatLastMessage = data.chatLastMessage
        if (this.chatList.chats.length > 1) {
          const object1 = this.chatList.chats[existingIndexChat]
          const object2 = this.chatList.chats[0]

          this.chatList.chats[existingIndexChat] = object2
          this.chatList.chats[0] = object1
        }
      } else {
        this.chatList.chats.unshift(data)
      }
    }

    if (userInfoStore.userData.id !== data.chatLastMessage.userId) {
      context.resume().then(() => {
        soundNewMessage.play()
      })

      if (!window.location.href.includes('chat')) {
        userInfoStore.addNotReadChat()
        errorAlertsStore.addError({
          errorType: MessageType.Success,
          errorText: 'У вас новое сообщение!'
        })
      }
    }
  }

  onSystemMessage(data: IGetChatSystemMessageList) {
    this.systemMessages.push(data)
    context.resume().then(() => {
      soundNewMessage.play()
    })
  }

  loadPrivateChat = async (chatId: string): Promise<void> => {
    this.privateChat = await getChatInfo(chatId)
  }

  loadChatList = async (): Promise<void> => {
    this.chatList = await getChatList()
  }

  loadChatSystemMessageInfo = async (): Promise<void> => {
    const result = await getChatSystemMessageInfo()
    if (result.data) {
      this.systemMessageChatInfo = result.data
    }
  }

  loadChatSystemMessageList = async (): Promise<void> => {
    const result = await getChatSystemMessageList()
    if (result.data) {
      this.systemMessages = result.data
    }
  }
}

const chatClientStore = new Chat()
export { chatClientStore }
