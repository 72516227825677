import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import styles from './ShopBarFilterContainer.module.css'
import Button from 'components/ui/buttons/Button/Button'
import { Text14Red, Text14White } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { RangeDynamicWithHeader } from 'components/ui/filters/RangeDinamicWithHeader'
import { CheckBoxList } from 'components/ui/lists/CheckBoxList'
import { ScrollCheckboxes } from 'components/ui/filters/ScrollCheckboxes'
import { ScrollCheckboxesWithSearch } from 'components/ui/filters/ScrollCheckboxesWithSearch'
import { observer } from 'mobx-react-lite'
import { FiltersType, PlatformConfigFilter } from 'store/platformConfigStore/types'
import { toJS } from 'mobx'
import {
  TSearchParamsJson,
  TSearchParamsJsonTypesValue,
  TSearchParamsJsonValueTypeRange,
  TSearchParamsJsonValueTypeVariant
} from '../../../../utils/searchParams'
import { userInfoStore } from '../../../../store/userInfoStore'

type ShopBarFilterContainerProps = {
  onCancel: () => void
  onSubmit: () => void
  filters: PlatformConfigFilter[][]
  onSetValueInFilter: (key: string, data: TSearchParamsJsonTypesValue) => void
  valueFilters: TSearchParamsJson
}

const ShopBarFilterContainer = observer(
  ({ onCancel, onSubmit, filters, onSetValueInFilter, valueFilters }: ShopBarFilterContainerProps) => {
    const jsonValueFilters = toJS(valueFilters)
    const { isVip } = userInfoStore

    const handlerOnChangeRange = (key: string) => (inputMin: string, inputMax: string) => {
      onSetValueInFilter(key, {
        type: 'range',
        data: {
          min: inputMin,
          max: inputMax
        }
      })
    }

    const handlerOnChangeVariants = (key: string) => (activeVariants: string[]) => {
      onSetValueInFilter(key, {
        type: 'variant',
        data: { activeVariants }
      })
    }

    const getFilterElement = (filter: PlatformConfigFilter): JSX.Element => {
      const isFilterSubscribeBlock = !isVip && filter.vip

      switch (filter.type) {
        /*case FiltersType.RangeFixed: {
                    const valueFilter = jsonValueFilters[filter.key] as TSearchParamsJsonValueTypeRange

                    const inputMin = valueFilter ? Number(valueFilter.data.min) : undefined
                    const inputMax = valueFilter ? Number(valueFilter.data.max) : undefined

                    return (
                        <RangeWithHeader
                            headerText={filter.name}
                            min={filter.property.from}
                            max={filter.property.to}
                            inputMin={inputMin}
                            inputMax={inputMax}
                            onChange={handlerOnChangeRangeFixed(filter.key)}
                        />
                    )
                }*/
        case FiltersType.RangeDynamic: {
          const valueFilter = jsonValueFilters[filter.key] as TSearchParamsJsonValueTypeRange

          return (
            <RangeDynamicWithHeader
              headerText={filter.name}
              placeholderMin={filter.property.from}
              placeholderMax={filter.property.to}
              inputMin={valueFilter?.data?.min}
              inputMax={valueFilter?.data?.max}
              onChange={handlerOnChangeRange(filter.key)}
            />
          )
        }
        case FiltersType.CheckboxList: {
          const valueFilter = jsonValueFilters[filter.key] as TSearchParamsJsonValueTypeVariant

          return (
            <ScrollCheckboxes
              headerText={filter.name}
              list={filter.property.variants}
              activeCheckBoxes={valueFilter?.data?.activeVariants ?? []}
              onChange={handlerOnChangeVariants(filter.key)}
            />
          )
        }
        case FiltersType.CheckboxListSearch: {
          const valueFilter = jsonValueFilters[filter.key] as TSearchParamsJsonValueTypeVariant
          return (
            <ScrollCheckboxesWithSearch
              headerText={filter.name}
              list={filter.property.variants}
              isBlocked={isFilterSubscribeBlock}
              activeCheckBoxes={valueFilter?.data?.activeVariants ?? []}
              onChange={handlerOnChangeVariants(filter.key)}
            />
          )
        }
        case FiltersType.Checkbox: {
          const valueFilter = jsonValueFilters[filter.key] as TSearchParamsJsonValueTypeVariant
          return (
            <CheckBoxList
              headerText={filter.name}
              list={filter.property.variants}
              isBlocked={isFilterSubscribeBlock}
              isColumn
              activeCheckBoxes={valueFilter?.data?.activeVariants ?? []}
              onChange={handlerOnChangeVariants(filter.key)}
            />
          )
        }
        case FiltersType.CheckboxHorizontal: {
          const valueFilter = jsonValueFilters[filter.key] as TSearchParamsJsonValueTypeVariant

          return (
            <CheckBoxList
              headerText={filter.name}
              list={filter.property.variants}
              isBlocked={isFilterSubscribeBlock}
              activeCheckBoxes={valueFilter?.data?.activeVariants ?? []}
              onChange={handlerOnChangeVariants(filter.key)}
            />
          )
        }
        case FiltersType.ToggleHorizontal: {
          const valueFilter = jsonValueFilters[filter.key] as TSearchParamsJsonValueTypeVariant
          return (
            <CheckBoxList
              headerText={filter.name}
              list={filter.property.variants}
              isBlocked={isFilterSubscribeBlock}
              isTumbler
              activeCheckBoxes={valueFilter?.data?.activeVariants ?? []}
              onChange={handlerOnChangeVariants(filter.key)}
            />
          )
        }
        case FiltersType.ToggleVertical: {
          const valueFilter = jsonValueFilters[filter.key] as TSearchParamsJsonValueTypeVariant

          return (
            <CheckBoxList
              headerText={filter.name}
              list={filter.property.variants}
              isBlocked={isFilterSubscribeBlock}
              isTumbler
              isColumn
              activeCheckBoxes={valueFilter?.data?.activeVariants ?? []}
              onChange={handlerOnChangeVariants(filter.key)}
            />
          )
        }
        case FiltersType.Divider:
          return <HorizontalDivider cssStyle={styles.divider} />
        default:
          return <></>
      }
    }

    const filterElements = toJS(filters).map((filters, groupIndex) => (
      <DivFlexRow key={groupIndex} style={styles.filterTypeBlock}>
        {filters.map((filter, filterIndex) => (
          <div key={filterIndex} style={{ display: 'flex', alignItems: 'start' }}>
            {getFilterElement(filter)}
          </div>
        ))}
      </DivFlexRow>
    ))

    return (
      <DivFlexColumn style={styles.container}>
        {filterElements}

        {filterElements.length > 0 && <HorizontalDivider cssStyle={styles.divider} />}

        {filterElements.length === 0 && <>Для этой категории фильтры отсутствуют</>}
        {filterElements.length > 0 && (
          <>
            <DivFlexRow style={styles.submitBlock}>
              <Button cssButtonStyles={styles.cancel} onClick={onCancel}>
                <Text14Red>Сбросить</Text14Red>
              </Button>
              <Button cssButtonStyles={styles.submit} onClick={onSubmit}>
                <Text14White>Применить</Text14White>
              </Button>
            </DivFlexRow>
          </>
        )}
      </DivFlexColumn>
    )
  }
)

export { ShopBarFilterContainer }
