import styles from './AdvertisementCardDataList.module.css'

import { SearchItemProperty } from 'store/platformConfigStore/types'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text16Grey, Text14White, Text16White } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { CardInCatalogButton } from 'components/ui/buttons/CardInCatalogButton'

type AdvertisementCardDataListProps = {
  headerText: string
  property: SearchItemProperty[]
  isVisible: boolean
  cssBlockStyles?: string
  isButton?: boolean
  id?: string
}

const AdvertisementCardDataList = ({
  headerText,
  property,
  isVisible,
  cssBlockStyles,
  isButton,
  id
}: AdvertisementCardDataListProps) => {
  if (isVisible) {
    return (
      <DivFlexColumn style={`${styles.container} ${cssBlockStyles}`}>
        <DivFlexRow style={styles.header}>
          <Text16White>{headerText}</Text16White>
          <HorizontalDivider cssStyle={styles.divider} />
          {isButton && <CardInCatalogButton id={id} />}
        </DivFlexRow>

        <DivFlexRow style={styles.propertyesBlock}>
          {!!property.length &&
            property.map((item, index) => {
              return (
                <DivFlexRow key={index} style={styles.property}>
                  <Text16Grey>{item.name}:</Text16Grey>
                  <Text14White>{item.value}</Text14White>
                </DivFlexRow>
              )
            })}
        </DivFlexRow>
      </DivFlexColumn>
    )
  } else {
    return null
  }
}

export { AdvertisementCardDataList }
