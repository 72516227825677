import styles from './SubscriptionPaymentModal.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text32White } from 'components/styledComponents/text/textStyles'
import CrossIcon from '../../../icons/cross_purple.svg'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { getUserSubscriptionsInfo } from 'api/subscription/userSubscriptions'
import { SubscriptionsInfoType, subscriptionSore } from 'store/subscriptionStore/subscriptionStore'
import Loader from 'components/ui/loaders/Loader/Loader'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import AcceptRulesBlock from 'components/ui/AcceptRulesBlock/AcceptRulesBlock'
import { SubscriptionPaymentModalHeader } from './SubscriptionPaymentModalHeader'
import { ModalWrapper } from 'components/ui/wrappers/ModalWrapper'
import { isPromoCodeValid } from 'api/referral/referral'
import { SubscriptionPaymentModalButtons } from './SubscriptionPaymentModalButtons'
import { SubscriptionPaymentModalPriceData } from './SubscriptionPaymentModalPriceData'
import { SubscriptionPaymentModalPromoBlock } from './SubscriptionPaymentModalPromoBlock'
import { SubmitButton } from 'components/ui/buttons/SubmitButton'
import { BrowserRouters } from '../../../../PageRouters'

type SubscriptionPaymentModalProps = {
  isOpen: boolean
  onClose: () => void
  inModal?: boolean
  onSubmit: (subscriptionId: string, subscriptionName: string, promoCode: string) => Promise<void>
}

const SubscriptionPaymentModal = observer(
  ({ isOpen, onClose, inModal = false, onSubmit }: SubscriptionPaymentModalProps) => {
    const { subscriptionsInfo } = subscriptionSore

    const [activeSub, setActiveSub] = useState<SubscriptionsInfoType | null>(null)
    const [subButtons, setSubButtons] = useState<SubscriptionsInfoType[]>([])
    const [inputPromoCode, setInputPromoCode] = useState<string>('')

    const [isLoading, setLoading] = useState<boolean>(true)
    const [isAccept, setAccept] = useState<boolean>(true)
    const [isPromoCode, setPromoCode] = useState<boolean>(false)

    useEffect(() => {
      ;(async () => {
        await getUserSubscriptionsInfo()
        setLoading(false)
      })()
    }, [])

    useEffect(() => {
      const buttonsList = subscriptionsInfo.reduce((result: SubscriptionsInfoType[], item) => {
        // получаем нужные для показа кнопки buttonsList
        if ([30, 90, 180, 360].includes(item.term)) {
          result.push(item)
        }
        return result.sort((a, b) => a.term - b.term)
      }, [])
      setSubButtons(buttonsList)
      setActiveSub(buttonsList[0])
    }, [subscriptionsInfo])

    const handleClickButton = (buttonData: SubscriptionsInfoType) => {
      setActiveSub(buttonData)
    }

    const handlePromoCodeSubmit = async (promoCode: string) => {
      setLoading(true)
      const result = await isPromoCodeValid(promoCode)
      if (result) {
        setPromoCode(true)
        setInputPromoCode(promoCode)
      } else {
        setPromoCode(false)
        setInputPromoCode('')
      }
      setLoading(false)
    }

    const getTotalPrice = (): number => {
      if (activeSub) {
        if (isPromoCode && typeof activeSub.pricePromoCode === 'number') {
          return activeSub.pricePromoCode
        }
        if (!isPromoCode && typeof activeSub.priceDiscount === 'number') {
          return activeSub.priceDiscount
        }
        return activeSub.price
      }
      return 0
    }

    const totalPrice = getTotalPrice()

    const handleSubmit = async (subscriptionId: string) => {
      if (isAccept && activeSub) {
        setLoading(true)
        await onSubmit(subscriptionId, activeSub.name, inputPromoCode)
      }
    }

    if (activeSub) {
      return (
        <ModalWrapper isOpen={isOpen} onClose={onClose} isPortal inModal={inModal}>
          <DivFlexColumn style={styles.modal}>
            <DivFlexRow style={styles.header}>
              <Text32White style={styles.headerText}>Оплата подписки</Text32White>
              <img src={CrossIcon} alt="close" className={styles.closeIcon} onClick={onClose} />
            </DivFlexRow>

            <SubscriptionPaymentModalHeader subscriptionName={activeSub.name} isActive />

            <SubscriptionPaymentModalButtons
              subButtons={subButtons}
              handleClickButton={handleClickButton}
              activeSub={activeSub}
            />

            <HorizontalDivider style={{ marginTop: '22px' }} />

            <SubscriptionPaymentModalPriceData activeSub={activeSub} isPromoCode={isPromoCode} />

            <HorizontalDivider style={{ marginTop: '35px' }} />

            {activeSub.discountPromoCode && (
              <SubscriptionPaymentModalPromoBlock handlePromoCodeSubmit={handlePromoCodeSubmit} />
            )}

            <AcceptRulesBlock
              text="Оплатить"
              rules="условиями публичной оферты"
              isCheked={setAccept.bind(this, !isAccept)}
              cssCommonText={styles.acceptCommonText}
              cssTextContainer={styles.acceptTextContainer}
              cssCheckButtonStyle={styles.checkButtonStyle}
              link={BrowserRouters.Offer}
            />

            <SubmitButton
              text={`Оплатить ${totalPrice}.00 ₽`}
              cssButton={styles.submitButton}
              onClick={handleSubmit.bind(this, activeSub.id)}
              isActive={isAccept}
            />
          </DivFlexColumn>

          {isLoading && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
              }}>
              <Loader anyStyles={{ position: 'absolute', top: '45%', left: '50%' }} size={50} bold={4} />
            </div>
          )}
        </ModalWrapper>
      )
    } else return null
  }
)

export { SubscriptionPaymentModal }
