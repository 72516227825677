import styles from './HomeBackgroundImages.module.css'

const HomeBackgroundImages = () => {
  return (
    <div className={styles.container}>
      <div className={styles.welcomeBackground}></div>
      <div className={styles.catalogBackground}></div>
      <div className={styles.filtersBackground}></div>
      <div className={styles.notificationsBackground}></div>
      {/* TODO пока со страницы убрали 2 блока из дизайна, если вернут, раскоментить последний фон и поправить верстку */}
      {/* <div className={styles.activityesBackground} ></div> */}
    </div>
  )
}

export { HomeBackgroundImages }
