import styles from './HomeWelcomeSectionCardMobile.module.css'

import { ImgBase } from 'components/styledComponents/img/img'

import Card from '../../images/welcome-img.png'

const HomeWelcomeSectionCardMobile = () => {
  return <ImgBase style={styles.image} img={Card} />
}

export { HomeWelcomeSectionCardMobile }
