import OvalButton from '../buttons/OvalButton/OvalButton'
import Support from '../../icons/button/question_icn.svg'
import Telegram from '../../icons/social/telegram_white.svg'
import Vk from '../../icons/social/vk_white.svg'
import Discord from '../../icons/social/discord_white.svg'
import { FooterIcon } from '../FooterIcon'
import styles from './FooterDisable.module.css'
import { FooterButtonsText, FooterLinks } from 'constants/footerLinks'

export const FooterDisable = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.copyrightBlock}>
          <div className={styles.social}>
            <FooterIcon src={Telegram} href={FooterLinks.telegram} />
            <FooterIcon src={Vk} href={FooterLinks.vk} />
            <FooterIcon src={Discord} href={FooterLinks.discord} />
          </div>
          <p className={styles.copyrightText}>{FooterButtonsText.Copyright}</p>
        </div>

        <div className={styles.copyrightBlock}>
          <p className={styles.supportText}>{FooterButtonsText.Help}</p>
          <OvalButton text="support@rp.market" icon={Support} />
        </div>
      </div>
    </footer>
  )
}
