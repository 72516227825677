import styles from './FooterLink.module.css'
import { useState } from 'react'

import Sheet from '../../icons/button/sheet.svg'
import SheetWhite from '../../icons/button/sheet_white.svg'

type FooterLinkProps = {
  text: string
  link: string
}

const FooterLink = ({ text, link }: FooterLinkProps) => {
  const [isHover, setHover] = useState<boolean>(false)

  return (
    <a href={link} target={'_blank'} className={styles.document} onMouseEnter={setHover.bind(this, true)} onMouseLeave={setHover.bind(this, false)}>
      <img src={isHover ? SheetWhite : Sheet} alt={text} />
      <p className={`${styles.documetnText} ${isHover && styles.active}`}>{text}</p>
    </a>
  )
}

export default FooterLink
