import styles from './ShopBarCard.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { useEffect, useState } from 'react'
import { getCardImage } from 'api/catalog/catalog.method'
import { observer } from 'mobx-react-lite'
import { ShopBarCardFooter } from './ShopBarCardFooter'
import { ShopBarCardProperty } from './ShopBarCardProperty'
import { ShopBarCardInfo } from './ShopBarCardInfo'
import { ProductBigImage } from 'components/ui/image/ProductBigImage'
import {IGetResCatalogListItem} from "../../../../api/catalog/catalog.type";
import {convertAdvertisementCurrencyToBtnCatalog} from "../../../../utils/convert";

type ShopBarCardProps = {
  card: IGetResCatalogListItem
  isVip: boolean
  onOpen: (cardData: IGetResCatalogListItem, baseImage: string) => void
}

const ShopBarCard = observer(({ card, isVip, onOpen }: ShopBarCardProps) => {
  const { id, name, price, currency, advertisementCurrency, propertyCard, sourceCategory, images, countAdvertisementBuy, countAdvertisementSale } =
    card

  const [image, setImage] = useState<string>('')

  useEffect(() => {
    ;(async () => {
      const getImage = await getCardImage(images[0].id)
      setImage(getImage)
    })()
  }, [images])

  return (
    <DivFlexRow style={styles.card}>
      <ProductBigImage width={228} height={228} image={image} />

      <DivFlexColumn style={styles.textBlock}>
        <DivFlexRow style={styles.informationBlock}>
          <ShopBarCardInfo
            name={name}
            sourceCategory={sourceCategory}
            price={price}
            currency={currency}
            onClick={onOpen.bind(this, card, image)}
          />

          <ShopBarCardProperty property={propertyCard} />
        </DivFlexRow>

        <HorizontalDivider cssStyle={styles.divider} />

        <ShopBarCardFooter
          countAdvertisementBuy={countAdvertisementBuy}
          countAdvertisementSale={countAdvertisementSale}
          nameButtonSale={convertAdvertisementCurrencyToBtnCatalog(advertisementCurrency).buttonSale}
          nameButtonBuy={convertAdvertisementCurrencyToBtnCatalog(advertisementCurrency).buttonBuy}
          catalogId={id}
          isVip={isVip}
        />
      </DivFlexColumn>
    </DivFlexRow>
  )
})

export { ShopBarCard }
