import styles from './HomeInformationContainer.module.css'

import { ImgBase } from 'components/styledComponents/img/img'
import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text14White } from 'components/styledComponents/text/textStyles'
import { Ellipse } from 'components/ui/Ellipse'
import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'

type HomeInformationContainerProps = {
  cardImage: string
  sectionNumber: string
  listHeader: string
  listItem: string[]
  buttonText: string
  sectionStyles?: string
  imageStyle?: string
  numberStyles?: string
  headerStyles?: string
  listStyles?: string
  itemStyles?: string
  backgroundStyles?: string
}

const HomeInformationContainer = ({
  cardImage,
  sectionNumber,
  listHeader,
  listItem,
  buttonText,
  sectionStyles,
  imageStyle,
  numberStyles,
  headerStyles,
  listStyles,
  itemStyles,
  backgroundStyles
}: HomeInformationContainerProps) => {
  return (
    <DivFlexColumn style={`${styles.section} ${sectionStyles}`}>
      <ImgBase style={`${styles.cardImage} ${imageStyle}`} img={cardImage} />

      <p className={`${styles.sectionNumber} ${numberStyles}`}>{sectionNumber}</p>
      <p className={`${styles.listHeader}  ${headerStyles}`}>{listHeader}</p>

      <ul className={`${styles.itemsList}  ${listStyles}`}>
        {listItem?.map((text, index) => {
          return (
            <li key={index} className={`${styles.item}  ${itemStyles}`}>
              <Ellipse />
              <Text14White>{text}</Text14White>
            </li>
          )
        })}
      </ul>

      <PurpleNeonButton text={buttonText} cssStyle={styles.button} fontSize={14} />

      <div className={`${styles.background} ${backgroundStyles}`}></div>
    </DivFlexColumn>
  )
}

export { HomeInformationContainer }
