import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text16Green, Text48Green } from 'components/styledComponents/text/textStyles'
import styles from './AdvertisementCardPrice.module.css'
import {convertCurrencyToText} from "../../../../utils/convert";

type AdvertisementCardPriceProps = {
  price: string
  currency: string
  isActiveStatus: boolean
}

const AdvertisementCardPrice = ({ price, currency, isActiveStatus }: AdvertisementCardPriceProps) => {
  const statusText = isActiveStatus ? 'Активно' : 'Неактивно/снято с продажи'

  return (
    <DivFlexRow style={styles.priceBlock}>
      {price ? (
        <>
          <Text48Green style={`${!isActiveStatus && styles.disable}`}>{price}</Text48Green>
          <Text16Green style={`${styles.dollar} ${!isActiveStatus && styles.disable}`}>{convertCurrencyToText('advertisementCurrency', currency)}</Text16Green>
        </>
      ) : (
        <Text16Green style={`${!isActiveStatus && styles.disable}`}>Нет информации о стоимости</Text16Green>
      )}
      <Text16Green style={`${styles.statusText} ${!isActiveStatus && styles.error}`}>{statusText}</Text16Green>
    </DivFlexRow>
  )
}

export { AdvertisementCardPrice }
