import styles from './ProfileSettings.module.css'

import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { ProfileSettingsPageHeader } from './ProfileSettingsPageHeader'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ProfileSettingsPageContent } from './ProfileSettingsPageContent'
import { ProfileSettingsPageBar } from './ProfileSettingsPageBar'
import { useState } from 'react'
import { AddGamePhoneModal } from 'components/ui/modals/AddGamePhoneModal'
import { TransactionStatusModal } from 'components/ui/modals/TransactionStatusModal'
import { userSettingTransactionType } from 'api/userSettings/userTransactions'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'

export enum ProfileSettingsPagesName {
  /* RPMARKET-192: [BE/FE] Выдавать всем подписку автоматически
  ProfileAndSubscriptionBlock = 'Профиль и подписка'
  */
  ProfileAndSubscriptionBlock = 'Профиль',
  ProfileAccountsBlock = 'Привязанные аккаунты',
  ProfilePhoneNumbersBlock = 'Игровые номера',
  ProfileTransactionBlock = 'Транзакции'
}

const ProfileSettings = observer(() => {
  const { isVip } = userInfoStore

  const [activePage, setActivePage] = useState<string>(ProfileSettingsPagesName.ProfileAndSubscriptionBlock)

  const handleChoosePage = (pageName: string) => {
    setActivePage(pageName)
  }

  const [isAddPhoneOpen, setAddPhoneOpen] = useState<boolean>(false)
  const [isTransactionOpen, setTransactionOpen] = useState<boolean>(false)

  const [transactionData, setTransactionData] = useState<userSettingTransactionType | null>(null)

  const handleOpenNewPhoneModal = () => {
    if (isVip) setAddPhoneOpen(true)
  }

  const handleOpenTransactionModal = (transactionData: userSettingTransactionType) => {
    setTransactionOpen(true)
    setTransactionData(transactionData)
  }

  return (
    <>
      <PageWrapper style={styles.wrapper}>
        <DivFlexColumn style={styles.section}>
          <ProfileSettingsPageHeader />

          <DivFlexRow style={styles.body}>
            <ProfileSettingsPageBar handleClick={handleChoosePage} />
            <ProfileSettingsPageContent
              activePage={activePage}
              handleOpenNewPhoneModal={handleOpenNewPhoneModal}
              handleOpenTransactionModal={handleOpenTransactionModal}
            />
          </DivFlexRow>
        </DivFlexColumn>
      </PageWrapper>

      {isAddPhoneOpen && <AddGamePhoneModal isOpen={isAddPhoneOpen} onClose={setAddPhoneOpen.bind(this, false)} />}
      {isTransactionOpen && transactionData && (
        <TransactionStatusModal
          isOpen={isTransactionOpen}
          onClose={setTransactionOpen.bind(this, false)}
          transactionData={transactionData}
        />
      )}
    </>
  )
})

export default ProfileSettings
