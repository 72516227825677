import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text16Grey } from 'components/styledComponents/text/textStyles'
import styles from './ProfileSettingsHeaderAlert.module.css'

import BallIcon from '../../../icons/ball_grey.svg'

type ProfileSettingsHeaderAlertProps = {
  alertText: string
}

const ProfileSettingsHeaderAlert = ({ alertText }: ProfileSettingsHeaderAlertProps) => {
  return (
    <DivFlexRow style={styles.header}>
      <img src={BallIcon} alt="ball" className={styles.headerIcon} />
      <Text16Grey>{alertText}</Text16Grey>
    </DivFlexRow>
  )
}

export { ProfileSettingsHeaderAlert }
