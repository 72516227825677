import styles from './ShopBarListHeader.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { ShopBarListDropdown } from '../../../dropdowns/ShopBarListDropdown'
import VerticalDivider from 'components/ui/dividers/VerticalDivider/VerticalDivider'
import { ShopBarListResult } from '../ShopBarListResult'

import Frame from './filter-icon.svg'
import { observer } from 'mobx-react-lite'
import { PlatformConfigSort } from '../../../../../store/platformConfigStore/types'

type ShopBarListHeaderProps = {
  sortListItem: PlatformConfigSort[]
  sortSelectedItem: PlatformConfigSort
  sortListOrderBy: PlatformConfigSort[]
  sortSelectedOrderBy: PlatformConfigSort
  onSortSelectedItem: (value: PlatformConfigSort) => void
  onSortSelectedOrderBy: (value: PlatformConfigSort) => void
  onFilterClick: () => void
  itemsCount: number
}

const ShopBarListHeader = observer(
  ({
    onFilterClick,
    itemsCount,
    sortListItem,
    sortSelectedItem,
    onSortSelectedItem,
    onSortSelectedOrderBy,
    sortListOrderBy,
    sortSelectedOrderBy
  }: ShopBarListHeaderProps) => {
    return (
      <DivFlexRow style={styles.container}>
        <ShopBarListResult itemsCount={itemsCount} />

        <DivFlexRow style={styles.dropdownsContainer}>
          <ShopBarListDropdown
            cssStyles={styles.typeDropdown}
            items={sortListItem}
            selectedItem={sortSelectedItem}
            onSelectedItem={onSortSelectedItem}
          />
          <ShopBarListDropdown
            cssStyles={styles.sizeDropdown}
            items={sortListOrderBy}
            selectedItem={sortSelectedOrderBy}
            onSelectedItem={onSortSelectedOrderBy}
          />
        </DivFlexRow>

        <VerticalDivider cssStyle={styles.divider} />

        <OvalButton
          text="Фильтры"
          icon={Frame}
          isSwapContent
          cssContainerStyle={styles.filterButton}
          cssIconStyle={styles.filterButtonIcon}
          onClick={onFilterClick}
        />
      </DivFlexRow>
    )
  }
)

export { ShopBarListHeader }
