import api from 'api/axios/axiosInstance'
import { advertisementMyStore } from 'store/advertisement/advertisementMyStore/advertisementMyStore'
import { MyAdvertisementDashboardResponseDto } from 'store/advertisement/advertisementMyStore/types'

const getMyAdvertisementDashboards = async () => {
  const { setMyAdvertisementDashboard } = advertisementMyStore
  try {
    const response: MyAdvertisementDashboardResponseDto = await api.get('/api/advertisement/my/dashboard')
    setMyAdvertisementDashboard(response)
    return true
  } catch (error) {
    return false
  }
}

export { getMyAdvertisementDashboards }
