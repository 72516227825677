import styles from './HomeWelcomeProductTradeCard.module.css'

import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { Text14Grey, Text16Green, Text18White, Text24Green } from 'components/styledComponents/text/textStyles'
import { DarkPurpleContainer } from 'components/pages/Home/components/DarkPurpleContainer'
import { ImgBase } from 'components/styledComponents/img/img'
import { DivFlexRow } from 'components/styledComponents/div/div'

import CarImg from '../../../../../icons/stock/base_white_car_photo.png'
import InfoIcon from '../../../../../icons/button/info-icon.svg'
import { ButtonCountArrowLeft } from 'components/ui/buttons/ButtonCountArrowLeft'

const carName = 'Obey NSY 2020'
const carSub = 'Автомобили , Спортивные'
const price = '5.200.000'
const priceSymbol = '$'

const ProductTradeCard = () => {
  return (
    <DarkPurpleContainer style={styles.container}>
      <ImgBase img={CarImg} alt="car" style={styles.imageCar} />

      <Text18White style={styles.carNameText}>{carName}</Text18White>
      <Text14Grey style={styles.carSubText}>{carSub}</Text14Grey>

      <DivFlexRow style={styles.priceContainer}>
        <Text24Green>{price}</Text24Green>
        <Text16Green>{priceSymbol}</Text16Green>
      </DivFlexRow>

      <OvalButton
        text="Подробнее"
        icon={InfoIcon}
        containerStyle={OvalButtonStyle}
        iconStyle={iconStyle}
        isSwapContent
      />

      <HorizontalDivider style={divederStyle} />

      <ButtonCountArrowLeft
        text="Продают"
        count={14}
        fontSize={14}
        color="azure"
        containerStyle={styles.buyInfoButton}
      />
      <ButtonCountArrowLeft
        text="Покупают"
        count={9999}
        fontSize={14}
        color="green"
        containerStyle={styles.sellInfoButton}
      />
    </DarkPurpleContainer>
  )
}

export { ProductTradeCard }

const OvalButtonStyle = {
  background: '#2D355D',
  width: '144px',
  height: '36px',
  marginTop: '10px',
  gap: '17px'
}

const iconStyle = {
  width: '20px',
  height: '20px'
}

const divederStyle = {
  margin: '14px auto 0',
  width: '233px'
}
