import styles from './EditorCatalogGalleryColorsMenu.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import {
  CatalogItemColorsType,
  changeCatalogItemColor,
  deleteCatalogItemColor,
  getCatalogItemColors
} from 'api/editor/Catalog/catalogItemColors'
import { useEffect, useState } from 'react'
import Loader from 'components/ui/loaders/Loader/Loader'
import { ImageColorsCheckBoxList } from 'components/ui/lists/ImageColorsCheckBoxList'

type EditorCatalogGalleryColorsMenuProps = {
  imageId: string
}

const EditorCatalogGalleryColorsMenu = ({ imageId }: EditorCatalogGalleryColorsMenuProps) => {
  const [isLoading, setLoading] = useState(true)
  const [colors, setColors] = useState<CatalogItemColorsType[]>([])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const result = await getCatalogItemColors(imageId)
      if (result) {
        setColors(result)
      }

      setLoading(false)
    })()
  }, [imageId])

  const handleItemClick = async (item: CatalogItemColorsType) => {
    setLoading(true)
    if (item.isSelectedColor) {
      await deleteCatalogItemColor(imageId, item.id)
    } else {
      await changeCatalogItemColor(imageId, item.id)
    }
    const result = await getCatalogItemColors(imageId)
    if (result) {
      setColors(result)
    }
    setLoading(false)
  }

  return (
    <DivFlexRow style={styles.container}>
      {isLoading ? (
        <Loader anyStyles={{ margin: 'auto' }} />
      ) : (
        <ImageColorsCheckBoxList list={colors} headerText="Выбор цвета" handleItemClick={handleItemClick} />
      )}
    </DivFlexRow>
  )
}

export { EditorCatalogGalleryColorsMenu }
