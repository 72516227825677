import styles from './HomeNotificationsSocialContainer.module.css'

import { DarkPurpleContainer } from 'components/pages/Home/components/DarkPurpleContainer'
import { ImgBase } from 'components/styledComponents/img/img'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Green, Text14White, Text16Grey, Text16White } from 'components/styledComponents/text/textStyles'
import { PurpleButtonWithIcon } from 'components/ui/buttons/PurpleButtonWithIcon'
import { TumblerPurple } from 'components/ui/buttons/TumblerPurple'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'

import Telegram from './purple_telegram_icon.svg'
import Discord from './purple_discord_item.svg'
import Bell from './bell-dot.svg'

const discordName = 'User#0696'
const telegramName = '@User'

const HomeNotificationsSocialContainer = () => {
  return (
    <DarkPurpleContainer style={styles.container}>
      <DivFlexRow style={styles.header}>
        <ImgBase img={Telegram} />
        <DivFlexColumn>
          <Text16White>Telegram</Text16White>
          <Text16Grey>{telegramName}</Text16Grey>
        </DivFlexColumn>
      </DivFlexRow>

      <PurpleButtonWithIcon
        text="Тестовое уведомление"
        img={Bell}
        cssStyle={styles.testButton}
        textStyle={styles.testButtonText}
      />

      <DivFlexColumn style={styles.checkboxContainer}>
        <DivFlexRow style={styles.checkbox}>
          <Text14White>Уведомлять о сообщениях</Text14White>
          <TumblerPurple />
        </DivFlexRow>

        <DivFlexRow style={styles.checkbox}>
          <Text14White>Уведомлять об объявлениях</Text14White>
          <TumblerPurple />
        </DivFlexRow>
      </DivFlexColumn>

      <HorizontalDivider cssStyle={styles.divider} />

      <DivFlexRow style={styles.header}>
        <ImgBase img={Discord} />
        <DivFlexColumn>
          <Text16White>Discord</Text16White>
          <Text16Grey>{discordName}</Text16Grey>
        </DivFlexColumn>
      </DivFlexRow>

      <Text14Green style={styles.greenText}>Уведомление отправлено!</Text14Green>

      <DivFlexColumn style={styles.checkboxContainer}>
        <DivFlexRow style={styles.checkbox}>
          <Text14White>Уведомлять о сообщениях</Text14White>
          <TumblerPurple isChecked />
        </DivFlexRow>

        <DivFlexRow style={styles.checkbox}>
          <Text14White>Уведомлять об объявлениях</Text14White>
          <TumblerPurple isChecked />
        </DivFlexRow>
      </DivFlexColumn>
    </DarkPurpleContainer>
  )
}

export { HomeNotificationsSocialContainer }
