import styles from './BaseInput.module.css'

import { useEffect, useState } from 'react'

type BaseInputProps = {
  value: string
  onChange: (value: string) => void
  placeHolder?: string | number
  validationError?: string
  isValid: boolean
  bottomText?: string
  width?: number
  maxLength?: number
  valueFormattedFunction?: (inputValue: string) => string
  cssInputContainer?: string
  cssInput?: string
  fontSize?: number
  isCenter?: boolean
}

const BaseInput = ({
  value,
  onChange,
  placeHolder,
  validationError,
  isValid,
  bottomText,
  width = 320,
  maxLength,
  valueFormattedFunction,
  cssInputContainer,
  cssInput,
  fontSize = 14,
  isCenter = false
}: BaseInputProps) => {
  const [isEmpty, setEmpty] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
    setEmpty(event.target.value === '')
  }

  useEffect(() => {
    // отлавливаем ошибку валидации
    if (validationError) {
      setErrorText(validationError)
    } else {
      setErrorText('')
    }
  }, [validationError])

  useEffect(() => {
    // применяем форматирование данных при вводе
    if (valueFormattedFunction) {
      const newValue = valueFormattedFunction(value)
      onChange(newValue)
    } else {
      onChange(value)
    }
  }, [value, onChange, valueFormattedFunction])

  return (
    <div style={{ maxWidth: width }}>
      <div className={`${styles.container} ${cssInputContainer} ${!isValid && styles.invalid}`}>
        <input
          className={`${styles.input} ${cssInput}`}
          value={value}
          onChange={handleChange}
          placeholder={placeHolder ? String(placeHolder) : ''}
          maxLength={maxLength}
          style={{
            fontSize: fontSize,
            textAlign: isCenter ? 'center' : 'unset'
          }}
        />
      </div>

      {bottomText && (
        <p className={`${styles.bottomText} ${!isValid ? styles.invalid : isEmpty && styles.valid}`}>
          {!!errorText ? errorText : bottomText}
        </p>
      )}
    </div>
  )
}

export { BaseInput }
