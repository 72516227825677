import styles from './TumblerPurpleWithText.module.css'
import { TumblerPurple } from '../TumblerPurple'

type TumblerPurpleWithTextProps = {
  text: string
  onClick?: (active: boolean) => void
  isActive: boolean
  isRight?: boolean
  cssContainerStyles?: string
}

const TumblerPurpleWithText = ({
  text,
  onClick,
  isActive,
  isRight = false,
  cssContainerStyles
}: TumblerPurpleWithTextProps) => {
  return (
    <div className={`${styles.container} ${isRight && styles.rightSyle} ${cssContainerStyles}`}>
      <p className={`${styles.text} ${isActive && styles.active}`}>{text}</p>
      <TumblerPurple isScarlet isChecked={isActive} onClick={onClick}/>
    </div>
  )
}

export { TumblerPurpleWithText }
