import styles from './EmailCodeForm.module.css'

import { useState } from 'react'
import AuthSubmitButton from '../../buttons/AuthSubmitButton/AuthSubmitButton'
import EmailAuthCode from '../../EmailAuthCode/EmailAuthCode'
import CountDownTimer from '../../CountDownTimer/CountDownTimer'
import { userAuthByEmail } from 'api/auth/auth'
import { StateErrorType } from '../types'
import { userLoading } from 'api/userLoading'
import { userInfoStore } from '../../../../store/userInfoStore'

type EmailCodeFormProps = {
  confirmationCodeId: string
  onCloseModal?: () => void
}

const EmailCodeForm = ({ confirmationCodeId, onCloseModal }: EmailCodeFormProps) => {
  // TODO доработать ввод в инпуты, поправить баги не давать перескакивать и т.д.
  // TODO если приходит ошибка с бека, добавить стили ошибки и "Вы ввели неверный код"
  const { setJwtToken } = userInfoStore
  const [code, setCode] = useState<string>('')
  const [isSubmit, setSubmit] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(6)
  const [emailError, setEmailError] = useState<StateErrorType>({
    text: '',
    isError: false
  })

  const handlerSubmit = async (e: any) => {
    e.preventDefault()
    if (code.length === 6) {
      setSubmit(true)
      const result = await userAuthByEmail(code, confirmationCodeId)

      if (result.data) {
        setEmailError({ text: '', isError: false })
        setJwtToken(result.data.jwtAuthToken)
        await userLoading()
        if (onCloseModal) onCloseModal()
      }

      if (result.error) {
        setEmailError({ text: result.error.message, isError: true })
      }

      setSubmit(false)
    }
  }

  const handleCodeChange = (value: string) => {
    setCode(value)
    if (emailError.isError) {
      setEmailError({ text: '', isError: false })
    }
  }

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center'
      }}
      onSubmit={handlerSubmit}>
      <p>Введите код, отправленный на ваш e-mail</p>

      <EmailAuthCode
        value={code}
        onChange={handleCodeChange}
        isError={emailError.isError}
        onCodeChange={handleCodeChange}
      />

      <div className={styles.errorContainer}>
        {emailError.isError && <p className={styles.errorText}>{emailError.text}</p>}
      </div>

      <div style={{ marginTop: '12px' }}>
        {countdown === 0 ? (
          <div className={styles.underlinedText} onClick={setCountdown.bind(this, 6)}>
            <span className={styles.pushCodeText}>Отправить код повторно</span>
          </div>
        ) : (
          <CountDownTimer
            text="Отправка повторно через "
            countdown={countdown}
            setCountdown={setCountdown}
            anyStyle={{ color: '#7E88BB' }}
          />
        )}
      </div>

      <AuthSubmitButton
        text="Подтвердить"
        loaderText="Подтверждение"
        anyStyles={{ marginTop: '28px' }}
        isSubmit={isSubmit}
        onSubmit={(e: any) => handlerSubmit(e)}
      />
    </form>
  )
}

export default EmailCodeForm
