import { useCallback, useEffect, useState } from 'react'
import styles from './UserStatusButton.module.css'
import { userInfoStore } from 'store/userInfoStore'
import { observer } from 'mobx-react-lite'

type UserStatusButtonProps = {
  onClick: (value: string) => void
  icon?: string
  text: string
  type: string
}

const UserStatusButton = observer(({ onClick, icon, text, type }: UserStatusButtonProps) => {
  const { activeStatus } = userInfoStore.userData

  const [isActive, setActive] = useState<boolean>(false)

  const checkButtonStatus = useCallback(() => {
    if (type === activeStatus) {
      setActive(true)
    } else setActive(false)
  }, [activeStatus, type])

  useEffect(() => {
    checkButtonStatus()
  }, [activeStatus, checkButtonStatus, type])

  const handleChangeStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    !isActive && onClick(type)
  }

  return (
    <button className={`${styles.container} ${isActive && styles.containerActive}`} onClick={handleChangeStatus}>
      {icon && <img className={styles.icon} src={icon} alt="icon"></img>}
      <p className={styles.text}>{text}</p>
    </button>
  )
})

export { UserStatusButton }
