import styles from './TumblerPurple.module.css'

import { useState } from 'react'
import { ImgBase } from 'components/styledComponents/img/img'
import { DivFlexRow } from 'components/styledComponents/div/div'

import Active from './tubler_active.svg'
import Disable from './tumbler_disable.svg'
import DisableScarlet from './tumbler_disable_scarlet_grey.svg'

type TumblerPurpleProps = {
  isChecked?: boolean
  isScarlet?: boolean
  onClick?: (active: boolean) => void
}

const TumblerPurple = ({ isChecked = false, onClick, isScarlet = false }: TumblerPurpleProps) => {
  const [isActive, setActive] = useState<boolean>(isChecked)

  const handleClick = () => {
    if (onClick) {
      onClick(!isActive)
    }
    setActive(!isActive)
  }

  return (
    <DivFlexRow onClick={handleClick} style={styles.container}>
      {isActive ? <ImgBase img={Active} /> : <ImgBase img={!isScarlet ? Disable : DisableScarlet} />}
    </DivFlexRow>
  )
}

export { TumblerPurple }
