import { convertJsonToSearchParams } from '../utils/searchParams'
import errorAlerts from '../store/errorAlertsStore'
import { MessageType } from '../components/ui/errors/AlertError'
import { userPlatformConfig } from '../store/platformConfigStore/platformConfigStore'
import { advertisementsShopBarStore } from '../store/advertisement/advertisementsShopBar/advertisementsShopBarStore'
import { getAdvertisementList } from '../api/advetrisement/shopbar/advertisements.method'
import { loadingStore } from '../store/loadingStore'

export const actionLoadAdvertisement = async () => {
  const { setLoadingItems } = loadingStore
  const { currentServer } = userPlatformConfig
  const {
    textBySearch,
    categoryIdBySearch,
    paginationSkip,
    paginationTake,
    valueFilters,
    sortSelectedItem,
    sortSelectedOrderBy,
    type,
    dealerStatus,
    setAdvertisementList
  } = advertisementsShopBarStore

  try {
    setLoadingItems(true)
    const result = await getAdvertisementList({
      type,
      serverId: currentServer.id,
      userStatus: dealerStatus.length > 0 ? dealerStatus : undefined,
      name: textBySearch.length > 0 ? textBySearch : undefined,
      categoryId: categoryIdBySearch,
      paginationSkip: paginationSkip,
      paginationTake: paginationTake,
      filter: convertJsonToSearchParams(valueFilters),
      sort: {
        field: sortSelectedItem.key,
        order: sortSelectedOrderBy.key
      }
    })
    setAdvertisementList(result)
  } catch (e) {
    console.log(e)
    errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
  } finally {
    setLoadingItems(false)
  }
}
