import styles from './AdvertisementMySearchInput.module.css'

import Search from '../../../icons/search-icon.svg'
import { useCallback, useEffect, useState } from 'react'
import Loader from 'components/ui/loaders/Loader/Loader'

type AdvertisementMySearchInputProps = {
  value: string
  onSearch: (value: string) => any
  placeholder: string
}

const AdvertisementMySearchInput = ({ value, onSearch, placeholder }: AdvertisementMySearchInputProps) => {
  const [input, setInput] = useState<string>(value)

  const [isSubmit, setSubmit] = useState<boolean>(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }

  const handleSubmit = useCallback(async () => {
    setSubmit(true)
    await onSearch(input)
    setSubmit(false)
  }, [input, onSearch])

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && input) {
      handleSubmit()
    }
  }

  const handleIconPress = () => {
    if (input) {
      handleSubmit()
    }
  }

  useEffect(() => {
    if (input === '') {
      setInput('')
      handleSubmit()
    }
    // eslint-disable-next-line
  }, [input])

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        placeholder={placeholder}
        value={input}
        onChange={onChange}
        onKeyDown={handleKeyPress}
      ></input>
      {isSubmit ? (
        <Loader anyStyles={{ margin: 'auto 17px' }} />
      ) : (
        <img className={styles.icon} onClick={handleIconPress} src={Search} alt="search" />
      )}
    </div>
  )
}

export { AdvertisementMySearchInput }
