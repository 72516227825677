import styles from './Maintenance.module.css'

import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text16Yellow } from 'components/styledComponents/text/textStyles'
import Wrench from './wrench.svg'
import Refresh from './tabler_refresh.svg'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { colors } from 'styled/colors'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

const headerText = 'Уважаемые пользователи!'
const pageText =
  'RP Market временно недоступен в связи с проведением технических работ. Приносим извенения за неудобства'

const Maintenance = () => {
  const navigate = useNavigate()

  const handleRedirect = () => {
    navigate('/')
    window.location.reload()
  }

  return (
    <PageWrapper style={styles.section}>
      <DivFlexColumn style={`${styles.container} ${isMobile && styles.mobile}`}>
        <img src={Wrench} alt="wrench" className={styles.img} />
        <Text16Yellow style={styles.header}>{headerText}</Text16Yellow>
        <Text16Yellow style={styles.text}>{pageText}</Text16Yellow>

        <OvalButton
          text="Обновить"
          icon={Refresh}
          cssContainerStyle={styles.button}
          textStyle={{ color: colors.yellowColor }}
          onClick={handleRedirect}
        />
      </DivFlexColumn>
    </PageWrapper>
  )
}

export { Maintenance }
