import { ReactNode } from 'react'
import styles from './div.module.css'

type DivProps = {
  children?: ReactNode
  style?: string
  onClick?: () => void
}

export const DivFlexRow = ({ children, style, onClick }: DivProps) => {
  return (
    <div className={`${styles.DivFlexRow} ${style}`} onClick={onClick}>
      {children}
    </div>
  )
}

export const DivFlexColumn = ({ children, style, onClick }: DivProps) => {
  return (
    <div className={`${styles.DivFlexColumn} ${style}`} onClick={onClick}>
      {children}
    </div>
  )
}
