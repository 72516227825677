import styles from './BackButton.module.css'

import PurpleArrow from '../../../../components/icons/arrow_left_purple.svg'
import { useNavigate } from 'react-router-dom'

type BackButtonProps = {
  text: string
  link?: string
}

const BackButton = ({ text, link }: BackButtonProps) => {
  const navigate = useNavigate()

  const handleClickButton = () => {
    if (link) {
      window.location.href = link
    } else {
      navigate(-1)
    }
  }

  return (
    <button className={styles.button} onClick={handleClickButton}>
      <img src={PurpleArrow} alt="back" className={styles.img} />
      <p>{text}</p>
    </button>
  )
}

export { BackButton }
