import styles from './FooterMobile.module.css'

import HeaderLogo from 'components/ui/HeaderLogo/HeaderLogo'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { FooterIcon } from 'components/ui/FooterIcon'
import FooterLink from 'components/ui/FooterLink/FooterLink'
import { BrowserRouters, NavigateLinks } from 'PageRouters'
import { ButtonTexts } from 'constants/buttonTexts'
import { FooterButtonsText, FooterLinks } from 'constants/footerLinks'

import Support from '../../../components/icons/button/question_icn.svg'
import Telegram from '../../../components/icons/social/telegram_white.svg'
import Vk from '../../../components/icons/social/vk_white.svg'
import Discord from '../../../components/icons/social/discord_white.svg'
import HomeIcon from '../../../components/icons/button/home.svg'
import Category from '../../../components/icons/button/category_white.svg'
import VoiceSms from '../../../components/icons/button/voice_sms_white.svg'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Grey, Text14White } from 'components/styledComponents/text/textStyles'

const FooterMobile = () => {
  return (
    <footer className={styles.footer}>
      <DivFlexColumn style={styles.container}>
        <HeaderLogo cssStyles={styles.logo} />

        <DivFlexRow style={styles.buttonsBlock}>
          <OvalButton icon={HomeIcon} router={BrowserRouters.Home} containerStyle={{ background: '#2A3152' }} />
          <OvalButton
            text={ButtonTexts.Catalog}
            icon={Category}
            router={NavigateLinks.Catalog}
            containerStyle={{ background: '#2A3152' }}
          />
          <OvalButton
            text={ButtonTexts.Advertisement}
            icon={VoiceSms}
            router={NavigateLinks.Advertisement}
            containerStyle={{ background: '#2A3152' }}
          />
        </DivFlexRow>

        <Text14White style={styles.supportText}>{FooterButtonsText.Help}</Text14White>

        <OvalButton text={ButtonTexts.SupportEmail} icon={Support} cssContainerStyle={styles.supportButton} />

        <DivFlexRow style={styles.social}>
          <FooterIcon src={Telegram} href={FooterLinks.telegram} />
          <FooterIcon src={Vk} href={FooterLinks.vk} />
          <FooterIcon src={Discord} href={FooterLinks.discord} />
        </DivFlexRow>

        <Text14Grey style={styles.copyrightText}>{FooterButtonsText.Copyright}</Text14Grey>

        <DivFlexColumn style={styles.linksContainer}>
          <FooterLink link={BrowserRouters.Offer} text="Оферта" />
          <FooterLink link={BrowserRouters.Agreement} text="Пользовательское соглашение" />
        </DivFlexColumn>
      </DivFlexColumn>
    </footer>
  )
}

export { FooterMobile }
