import styles from './AdvertisementMyList.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { AdvertisementMyCard } from '../AdvertisementMyCard'
import { MyAdvertisementsResponseDto } from 'store/advertisement/advertisementMyStore/types'
import { useState } from 'react'
import { DeleteAdvertisementMyModal } from 'components/ui/modals/DeleteAdvertisementMyModal'
import { observer } from 'mobx-react-lite'

type AdvertisementMyListProps = {
  list: MyAdvertisementsResponseDto[]
}

const AdvertisementMyList = observer(({ list }: AdvertisementMyListProps) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [advertisementData, setAdvertisementDate] = useState<MyAdvertisementsResponseDto | null>(null)

  const handleDeleteButton = (cardData: MyAdvertisementsResponseDto) => {
    setAdvertisementDate(cardData)
    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
    setAdvertisementDate(null)
  }

  return (
    <DivFlexColumn style={styles.container}>
      {!!list.length &&
        list.map((item) => {
          return <AdvertisementMyCard key={item.id} cardData={item} onDeleteButtonClick={handleDeleteButton} />
        })}
      {isDeleteModalOpen && advertisementData && (
        <DeleteAdvertisementMyModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          advertisementDataId={advertisementData.id}
        />
      )}
    </DivFlexColumn>
  )
})

export { AdvertisementMyList }
