import styles from './HomeAbsProuctStatusCard.module.css'

import { ImgBase, ImgRounded } from 'components/styledComponents/img/img'
import { DarkPurpleContainer } from '../../../components/DarkPurpleContainer'
import {
  Text14Grey,
  Text14Green,
  Text16Green,
  Text16White,
  Text18White,
  Text24Green
} from 'components/styledComponents/text/textStyles'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { CallButton } from '../../../../../ui/buttons/CallButton'
import { FackeMessageButton } from '../../../components/FackeMessageButton'

import CapImg from '../../../../../icons/stock/base_white_cap_photo.jpg'
import Avatar from '../../../../../icons/stock/stock-avatar-1.png'
import { TradeStatus } from 'components/pages/Home/components/TradeStatus/TradeStatus'

const itemName = 'Кепка Козырёк Urban'
const itemPrice = '50.000'

const HomeAbsProuctStatusCard = () => {
  return (
    <DarkPurpleContainer style={styles.container}>
      <ImgBase img={CapImg} alt="cap" style={styles.photo} />
      <TradeStatus text="Куплю" containerStyle={styles.tradeStatus} />
      <Text18White style={styles.itemName}>{itemName}</Text18White>
      <Text14Grey style={styles.itemType}>Одежда , Головные уборы</Text14Grey>

      <DivFlexRow style={styles.priceContainer}>
        <Text24Green>{itemPrice}</Text24Green>
        <Text16Green>$</Text16Green>
      </DivFlexRow>

      <HorizontalDivider cssStyle={styles.divider} />

      <DivFlexRow style={styles.userContainer}>
        <ImgRounded img={Avatar} alt="avatar" />
        <DivFlexColumn>
          <Text16White>Ivan D.</Text16White>
          <Text14Green>Онлайн на сайте</Text14Green>
        </DivFlexColumn>
      </DivFlexRow>

      <CallButton cssStyle={styles.button} isDisable />
      <FackeMessageButton cssStyle={styles.button} />
    </DarkPurpleContainer>
  )
}

export { HomeAbsProuctStatusCard }
