import api from 'api/axios/axiosInstance'
import { IGetReqAdvertisementList, IGetResAdvertisementList } from './advertisement.type'

const getAdvertisementList = async (options: IGetReqAdvertisementList): Promise<IGetResAdvertisementList> => {
  const { name, categoryId, paginationSkip, serverId, paginationTake, sort, filter, userStatus, type } = options

  const queryParams = new URLSearchParams()

  if (categoryId) queryParams.append('filter[systemCategoryId]', categoryId)
  if (name) queryParams.append('filter[systemCatalogName]', name)
  if (userStatus) queryParams.append('filter[systemUserActiveStatus]', userStatus)
  queryParams.append('filter[systemServerId]', serverId)
  queryParams.append('filter[systemAdvertisementType]', type)

  queryParams.append('pagination[take]', String(paginationTake))
  queryParams.append('pagination[skip]', String(paginationSkip ?? 0))

  queryParams.append('sort[field]', String(sort.field))
  queryParams.append('sort[order]', String(sort.order))

  if (filter) {
    for (const keyFilter in filter) {
      queryParams.append(`filter[${keyFilter}]`, String(filter[keyFilter]))
    }
  }

  const url = `/api/advertisement/list?${queryParams.toString()}`
  return api.get(url)
}

export { getAdvertisementList }
