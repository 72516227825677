import styles from './HomeCatalogCategoryInfoContainer.module.css'

import VerticalDivider from 'components/ui/dividers/VerticalDivider/VerticalDivider'
import { ImgBase } from 'components/styledComponents/img/img'
import {
  Text14Grey,
  Text14White,
  Text16Grey,
  Text16Purple,
  Text16White,
  Text24White
} from 'components/styledComponents/text/textStyles'

import PurpleArrow from '../../../../../icons/arrow_bottom_purple.svg'
import GreyArrow from '../../../../../icons/arrow_right_grey.svg'
import Bicke from '../../../icons/bike.svg'

const HomeCatalogCategoryInfoContainer = () => {
  return (
    <div className={styles.container}>
      <Text24White style={styles.headerText}>Категория</Text24White>

      <div className={styles.button}>
        <ImgBase img={Bicke} alt="bicke" style={styles.buttonIcon} />
        <Text16Grey>Транспорт</Text16Grey>
        <img className={styles.buttonArrow} src={PurpleArrow} alt="icon" />
      </div>

      <div className={styles.filterContainer}>
        <VerticalDivider style={{ height: '273px' }} />

        <div className={styles.filter}>
          <div className={styles.filterButton}>
            <Text16Purple>Автомобили</Text16Purple>
            <ImgBase img={PurpleArrow} alt="arrow" />
          </div>

          <div className={styles.filterCategoryes}>
            <Text14White>Спортивные</Text14White>
            <Text14Grey>Ретро</Text14Grey>
            <Text14Grey>Пикапы</Text14Grey>
            <Text14Grey>Грузовики</Text14Grey>
          </div>

          <div className={styles.inactiveButtonsContainer}>
            <div className={styles.filterButton}>
              <Text16White>Мотоциклы</Text16White>
              <ImgBase img={GreyArrow} alt="arrow" />
            </div>

            <div className={styles.filterButton}>
              <Text16White>Велосипеды</Text16White>
              <ImgBase img={GreyArrow} alt="arrow" />
            </div>

            <div className={styles.filterButton}>
              <Text16White>Лодки и катера</Text16White>
              <ImgBase img={GreyArrow} alt="arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { HomeCatalogCategoryInfoContainer }
