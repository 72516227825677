import styles from './CatalogCardPageNameBlock.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text16Grey, Text32White } from 'components/styledComponents/text/textStyles'
import { ProductBigImage } from 'components/ui/image/ProductBigImage'

type CatalogCardPageNameBlockProps = {
  name: string
  description: string | null
  image?: string
}

const CatalogCardPageNameBlock = ({ name, description, image }: CatalogCardPageNameBlockProps) => {
  return (
    <DivFlexColumn style={styles.photoBlock}>
      <Text32White style={styles.nameText}>{name}</Text32White>
      {description !== null && <Text16Grey>{description}</Text16Grey>}

      <ProductBigImage width={497} height={497} image={image} cssBlockStyle={styles.image} />
    </DivFlexColumn>
  )
}

export { CatalogCardPageNameBlock }
