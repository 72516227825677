import styles from './ReferralRequestCard.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text14White } from 'components/styledComponents/text/textStyles'
import { ReferralStatusWithText } from 'components/ui/Refferal/ReferralStatusWithText'
import { ReferralDisbursementType } from 'store/referralStore/refferralStore'
import { formatDate } from 'utils/dateFormated'

type ReferralRequestCardProps = {
  requestData: ReferralDisbursementType
}

const ReferralRequestCard = ({ requestData }: ReferralRequestCardProps) => {
  const { createdAt, amount, details, status } = requestData

  return (
    <div className={styles.card}>
      <DivFlexRow style={styles.dateBlock}>
        <Text14White style={styles.date}>{formatDate(createdAt)}</Text14White>
      </DivFlexRow>

      <DivFlexRow style={styles.priceBlock}>
        <Text14White style={styles.registerCount}>{amount}</Text14White>
        <Text14White>₽</Text14White>
      </DivFlexRow>

      <DivFlexRow style={styles.cardBlock}>
        <Text14White style={styles.paymenCount}>{details}</Text14White>
      </DivFlexRow>

      <ReferralStatusWithText status={status} cssContainerStyles={styles.status} />
    </div>
  )
}

export { ReferralRequestCard }
