import styles from './ShopBarCategory.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ShopBarOneLevelButton } from './ShopBarOneLevelButton'
import { useState } from 'react'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text16White, Text24White } from 'components/styledComponents/text/textStyles'

import AllIcon from './all-sharp.svg'
import AllIconPurple from './all-sharp_purple.svg'
import { IPlatformCategory } from 'store/platformConfigStore/types'

const defaultCategory: IPlatformCategory = {
  id: 'all',
  name: 'Все',
  label: '',
  categories: []
}

type ShopBarCategoryProps = {
  setCategoryData: (category: IPlatformCategory) => void
}

const ShopBarCategory = ({ setCategoryData }: ShopBarCategoryProps) => {
  const { categories } = userPlatformConfig.platformConfigs
  const [activeCategory, setActiveCategory] = useState<IPlatformCategory | null>(defaultCategory)
  const isActiveDefaultButton = activeCategory?.id === defaultCategory.id

  const handlerClick = (category: IPlatformCategory) => {
    if (activeCategory && activeCategory.id === category.id) {
      setActiveCategory(null)
    } else {
      setActiveCategory(category)
      setCategoryData(category)
    }
  }

  return (
    <DivFlexColumn style={styles.buttonsContainer}>
      <Text24White style={styles.headerText}>Категории</Text24White>

      <DivFlexRow
        style={`${styles.allButton} ${isActiveDefaultButton && styles.active}`}
        onClick={handlerClick.bind(this, defaultCategory)}
      >
        <ImgBase img={isActiveDefaultButton ? AllIconPurple : AllIcon} />
        <Text16White>Все</Text16White>
      </DivFlexRow>

      {categories.map((category) => {
        const { id } = category
        return (
          <ShopBarOneLevelButton
            key={id}
            category={category}
            onClick={handlerClick.bind(this, category)}
            isActive={activeCategory?.id === category.id}
            setCategoryData={setCategoryData}
          />
        )
      })}
    </DivFlexColumn>
  )
}

export { ShopBarCategory }
