import api from 'api/axios/axiosInstance'
import axios from 'axios'
import { MessageType } from 'components/ui/errors/AlertError'
import errorAlerts from 'store/errorAlertsStore'
import {
  ReferralDisbursementsListType,
  ReferralPromoCodeListType,
  ReferralStatisticsType,
  referralStore
} from 'store/referralStore/refferralStore'

const getReferralStatistic = async () => {
  const { getStatistics } = referralStore

  try {
    const response: ReferralStatisticsType = await api.get('/api/referral/statistics')
    getStatistics(response)
    console.log(response)
  } catch (error) {
    console.log(error)
  }
}

const getReferralPromoCodeList = async (paginationSkip?: number) => {
  const { getPromoCodes } = referralStore

  const paginationTake = 10
  // компонент <Pagination> принимает в себя itemsInPage, который должен быть равен paginationTake
  let url = `/api/referral/promo-code/list?pagination[take]=${paginationTake}&pagination[skip]=${paginationSkip ?? 0}`

  try {
    const response: ReferralPromoCodeListType = await api.get(url)
    getPromoCodes(response)
    console.log(response)
  } catch (error) {
    console.log(error)
  }
}

const createReferralPromoCode = async (promoCodeName: string) => {
  try {
    const response = await api.post('/api/referral/promo-code/create', { name: promoCodeName })
    errorAlerts.addError({ errorType: MessageType.Success, errorText: 'Промокод создан' })

    console.log(response)
    return true
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorAlerts.addError({
          errorType: MessageType.Error,
          errorText: error.response.data.message
        })
      }
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    }

    console.log(error)
    return false
  }
}

const deleteReferralPromoCode = async (promoCodeId: string) => {
  try {
    const response = await api.delete(`/api/referral/promo-code/${promoCodeId}/delete`)
    errorAlerts.addError({ errorType: MessageType.Success, errorText: 'Промокод удален' })

    console.log(response)
    return true
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorAlerts.addError({
          errorType: MessageType.Error,
          errorText: error.response.data.message
        })
      }
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    }
    return false
  }
}

const getReferralDisbursementList = async (paginationSkip?: number) => {
  const { getDisbursements } = referralStore

  const paginationTake = 10
  // компонент <Pagination> принимает в себя itemsInPage, который должен быть равен paginationTake
  let url = `/api/referral/disbursement/list?pagination[take]=${paginationTake}&pagination[skip]=${paginationSkip ?? 0}`

  try {
    const response: ReferralDisbursementsListType = await api.get(url)
    getDisbursements(response)
    console.log(response)
  } catch (error) {
    console.log(error)
  }
}

const createReferralDisbursement = async (amount: number, details: string) => {
  try {
    const response = await api.post('/api/referral/disbursement/create', { amount, details })
    errorAlerts.addError({ errorType: MessageType.Success, errorText: 'Заявка создана' })
    console.log(response)
    return true
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorAlerts.addError({
          errorType: MessageType.Error,
          errorText: error.response.data.message
        })
      }
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    }
    return false
  }
}

const isPromoCodeValid = async (namePromoCode: string) => {
  try {
    const response: { isValidPromoCode: boolean } = await api.post('/api/referral/promo-code/valid', { namePromoCode })

    if (response.isValidPromoCode) {
      errorAlerts.addError({ errorType: MessageType.Success, errorText: '' })
      return true
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: 'Промокод не активен' })
      return false
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorAlerts.addError({
          errorType: MessageType.Error,
          errorText: error.response.data.message
        })
      }
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    }
    return false
  }
}

export {
  getReferralStatistic,
  getReferralPromoCodeList,
  createReferralPromoCode,
  deleteReferralPromoCode,
  getReferralDisbursementList,
  createReferralDisbursement,
  isPromoCodeValid
}
