import styles from './ProfileAccountNotificationButton.module.css'

import { Text14Green, Text14White } from 'components/styledComponents/text/textStyles'
import { useState } from 'react'

import BellIcon from '../../../icons/profileSettings/bell-dot.svg'
import CheckMark from '../../../icons/check-mark_green.svg'

type ProfileAccountNotificationButtonProps = {
  onClick: () => void
}

const ProfileAccountNotificationButton = ({ onClick }: ProfileAccountNotificationButtonProps) => {
  const [isActive, setActive] = useState<boolean>(false)

  const handleClickButton = () => {
    onClick()
    setActive(true)
  }

  const onMouseLeave = () => {
    setTimeout(() => {
      setActive(false)
    }, 3000)
  }

  return (
    <div
      className={`${styles.container} ${isActive && styles.active}`}
      onClick={handleClickButton}
      onMouseLeave={onMouseLeave}
    >
      {!isActive ? <Text14White>Тестовое уведомление</Text14White> : <Text14Green>Отправка уведомления...</Text14Green>}

      <img
        className={`${styles.icon} ${isActive && styles.active}`}
        src={!isActive ? BellIcon : CheckMark}
        alt="notification"
      />
    </div>
  )
}

export { ProfileAccountNotificationButton }
