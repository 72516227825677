import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { userInfoStore } from '../../store/userInfoStore'

export type ResultHandlerAxiosResponse<T> = {
  data?: T
  error?: {
    message: string
    code: number
  }
}

const handlerAxiosResponse = <T>(response: AxiosResponse): ResultHandlerAxiosResponse<T> => {
  if ([200, 201].includes(response.status)) {
    return {
      data: response.data
    }
  }

  if (response.status === 400) {
    const responseData = response.data as any
    const code = responseData.code
    const message = responseData.message

    return {
      error: { message, code }
    }
  }

  return {
    error: {
      message: response.data?.message ?? 'Неизвестна ошибка сервиса',
      code: 0
    }
  }
}

class Api {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
      }
    })

    this.axiosInstance.interceptors.request.use(
      function (config) {
        if (userInfoStore.jwtToken) {
          config.headers['Authorization'] = 'Bearer ' + userInfoStore.jwtToken
        }
        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )
  }

  public async get<T>(url: string, data?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.get(url, data)
    console.log(response)
    return response.data
  }

  public async deleteVersion2<T>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<ResultHandlerAxiosResponse<T>> {
    const response = await this.axiosInstance.delete(
      url,
      Object.assign(
        {
          validateStatus: () => true
        },
        requestConfig
      )
    )
    return handlerAxiosResponse(response)
  }

  public async pathVersion2<T>(
    url: string,
    data: any,
    requestConfig?: AxiosRequestConfig
  ): Promise<ResultHandlerAxiosResponse<T>> {
    const response = await this.axiosInstance.patch(
      url,
      data,
      Object.assign(
        {
          validateStatus: () => true
        },
        requestConfig
      )
    )
    return handlerAxiosResponse(response)
  }

  public async getVersion2<T>(url: string, requestConfig?: AxiosRequestConfig): Promise<ResultHandlerAxiosResponse<T>> {
    const response = await this.axiosInstance.get(
      url,
      Object.assign(
        {
          validateStatus: () => true
        },
        requestConfig
      )
    )
    return handlerAxiosResponse(response)
  }

  public async postVersion2<T>(
    url: string,
    data: any,
    requestConfig?: AxiosRequestConfig
  ): Promise<ResultHandlerAxiosResponse<T>> {
    const response = await this.axiosInstance.post(
      url,
      data,
      Object.assign(
        {
          validateStatus: () => true
        },
        requestConfig
      )
    )
    return handlerAxiosResponse(response)
  }

  public async post<T>(url: string, data?: any): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.post(url, data)
    console.log(response)
    return response.data
  }

  public async patch<T>(url: string, data?: any): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.patch(url, data)
    console.log(response)
    return response.data
  }

  public async delete<T>(url: string): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.delete(url)
    console.log(response)
    return response.data
  }
}

const api = new Api()

export default api
