import styles from './ChatAlert.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text16Grey } from 'components/styledComponents/text/textStyles'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'

import BallIcon from '../../../icons/ball_grey.svg'

const ChatAlert = () => {
  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexRow style={styles.textBlock}>
        <img className={styles.icon} src={BallIcon} alt="alert" />
        <Text16Grey>Сообщения не найдены</Text16Grey>
      </DivFlexRow>
      <OvalButton text="Сбросить фильтры" cssContainerStyle={styles.button} />
    </DivFlexColumn>
  )
}

export { ChatAlert }
