import styles from './ProfileSettingsTransactionStatus.module.css'
import { getTransactionsStatusText, getTransactionsStatusColor } from '../helpers'

type ProfileSettingsTransactionStatusProps = {
  status: string
}

const ProfileSettingsTransactionStatus = ({ status }: ProfileSettingsTransactionStatusProps) => {
  const statusText = getTransactionsStatusText(status)
  const statusColor = getTransactionsStatusColor(status)

  return (
    <div className={styles.container} style={{ backgroundColor: statusColor.bgColor }}>
      <div className={styles.icon} style={{ backgroundColor: statusColor.color }}></div>
      <p className={styles.text} style={{ color: statusColor.color }}>
        {statusText}
      </p>
    </div>
  )
}

export { ProfileSettingsTransactionStatus }
