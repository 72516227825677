import styles from './WhatButton.module.css'

import OvalButton from '../OvalButton/OvalButton'
import Icon from '../../../icons/button/question_fill.svg'

type WhatButtonProps = {
  text: string
  onClick: () => void
}

const WhatButton = ({ text, onClick }: WhatButtonProps) => {
  return <OvalButton text={text} icon={Icon} iconSize={16} onClick={onClick} cssContainerStyle={styles.button} />
}

export { WhatButton }
