import { action, makeObservable, observable, toJS } from 'mobx'
import { IPlatformServer, PlatformConfig, PlatformConfigFilter, PlatformConfigSort } from './types'
import { IGetResCatalogList, IGetResCatalogListItem } from '../../api/catalog/catalog.type'

class UserPlatformConfig {
  platformConfigs: PlatformConfig = {
    categories: [],
    servers: [],
    myAdvertisement: {
      maxCountOnBuy: '',
      maxCountOnSale: '',
      maxCountOnBuyBySubscription: '',
      maxCountOnSaleBySubscription: '',
      maxCountHourShow: '',
      maxCountHourShowSubscription: ''
    },
    filterAndSort: {},
    advertisement: {}
  }

  currentServer: IPlatformServer = {
    id: '',
    name: '',
    categories: [],
    label: null
  }

  searchItems: IGetResCatalogList = {
    pagination: {
      totalCount: 0,
      currentPage: 0,
      take: 0
    },
    items: []
  }

  catalogItem: IGetResCatalogListItem = {
    id: '',
    name: '',
    price: undefined,
    currency: undefined,
    advertisementCurrency: '',
    description: undefined,
    propertyPage: [],
    propertyCard: [],
    sourceCategory: '',
    images: [],
    countAdvertisementBuy: 0,
    countAdvertisementSale: 0
  }

  filters: PlatformConfigFilter[][] = []

  constructor() {
    makeObservable(this, {
      platformConfigs: observable,
      saveUserPlatformConfig: action,
      currentServer: observable,
      saveUserCurrentServer: action,
      searchItems: observable,
      saveUserSearchResult: action,
      clearUserSearchResult: action,
      catalogItem: observable,
      saveCatalogItem: action,
      filters: observable
    })
  }

  saveUserPlatformConfig = (platformConfig: PlatformConfig) => {
    return (this.platformConfigs = platformConfig)
  }

  saveUserCurrentServer = (server: IPlatformServer) => {
    return (this.currentServer = server)
  }

  saveUserSearchResult = (items: IGetResCatalogList) => {
    return (this.searchItems = items)
  }

  clearUserSearchResult = () => {
    return (this.searchItems = {
      pagination: {
        totalCount: 0,
        currentPage: 0,
        take: 0
      },
      items: []
    })
  }

  saveCatalogItem = (catalogItem: IGetResCatalogListItem) => {
    return (this.catalogItem = catalogItem)
  }

  getFiltersByLabelCategory = (categoryLabel: string, type: 'catalog' | 'advertisement'): PlatformConfigFilter[][] => {
    if (type === 'catalog' && this.platformConfigs.filterAndSort?.[categoryLabel]?.catalog?.filter) {
      return toJS(this.platformConfigs.filterAndSort[categoryLabel].catalog.filter)
    }
    if (type === 'advertisement' && this.platformConfigs.filterAndSort?.[categoryLabel]?.advertisement?.filter) {
      return toJS(this.platformConfigs.filterAndSort[categoryLabel].advertisement.filter)
    }
    return []
  }

  getSortsByLabelCategory = (categoryLabel: string, type: 'catalog' | 'advertisement'): PlatformConfigSort[] => {
    if (type === 'catalog' && this.platformConfigs.filterAndSort?.[categoryLabel]?.catalog?.sort) {
      return toJS(this.platformConfigs.filterAndSort[categoryLabel].catalog.sort)
    }
    if (type === 'advertisement' && this.platformConfigs.filterAndSort?.[categoryLabel]?.advertisement?.sort) {
      return toJS(this.platformConfigs.filterAndSort[categoryLabel].advertisement.sort)
    }
    return []
  }

  /*setFilters = (filterLabel: string, type: 'catalog' | 'advertisement') => {
    const catalogConfig = toJS(this.platformConfigs[type])
    const foundEntry = catalogConfig[filterLabel]
    if (foundEntry) {
      this.filters = foundEntry.filter
    }
  }*/
}

const userPlatformConfig = new UserPlatformConfig()

export { userPlatformConfig }
