import styles from './ShopBarHeader.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14White, Text32White } from 'components/styledComponents/text/textStyles'
import { ImgBase } from 'components/styledComponents/img/img'
import { observer } from 'mobx-react-lite'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import { ShopBarHeaderDropdown } from '../ShopBarHeaderDropdown'

import CatalogImage from './catalog.png'
import AdvertisementImage from './advertisement.png'

type ShopBarHeaderProps = {
  type: 'catalog' | 'advertisement'
  onChooseServer?: (serverId: string) => void
}

const ShopBarHeader = observer(({ type, onChooseServer }: ShopBarHeaderProps) => {
  const { servers } = userPlatformConfig.platformConfigs

  return (
    <DivFlexRow style={styles.header}>
      <DivFlexColumn style={styles.filterSection}>
        <Text32White style={styles.headerText}>GTA5RP.COM</Text32White>
        <DivFlexRow style={styles.filterContainer}>
          <Text14White style={styles.filterHeader}>Выбран сервер</Text14White>
          <ShopBarHeaderDropdown serversArray={servers} onChooseServer={onChooseServer} />
        </DivFlexRow>
      </DivFlexColumn>
      <ImgBase img={type === 'catalog' ? CatalogImage : AdvertisementImage} style={styles.pageName} />
    </DivFlexRow>
  )
})

export { ShopBarHeader }
