import Bike from '../../components/icons/shopBar/motorbike.svg'
import Hanger from '../../components/icons/shopBar/hanger.svg'
import Gun from '../../components/icons/shopBar/gun.svg'
import Burger from '../../components/icons/shopBar/burger.svg'
import Exchange from '../../components/icons/shopBar/exchange-rounded.svg'
import Fish from '../../components/icons/shopBar/fish.svg'
import Other from '../../components/icons/shopBar/other.svg'
import Services from '../../components/icons/shopBar/services_grey.svg'
import Property from '../../components/icons/shopBar/property_grey.svg'
import Money from '../../components/icons/shopBar/money_grey.svg'

import BikePurple from '../../components/icons/shopBar/motorbike_purple.svg'
import HangerPurple from '../../components/icons/shopBar/hanger_purple.svg'
import GunPurple from '../../components/icons/shopBar/gun_purple.svg'
import BurgerPurple from '../../components/icons/shopBar/burger_purple.svg'
import ExchangePurple from '../../components/icons/shopBar/exchange-rounded_purple.svg'
import FishPurple from '../../components/icons/shopBar/fish_purple.svg'
import OtherPurple from '../../components/icons/shopBar/other_purple.svg'
import ServicesPurple from '../../components/icons/shopBar/services_purple.svg'
import PropertyPurple from '../../components/icons/shopBar/property_purple.svg'
import MoneyPurple from '../../components/icons/shopBar/money_purple.svg'

export const getCategoryButtonIcon = (isActive: boolean, name: string): string => {
  switch (name) {
    case 'Транспорт':
      return !isActive ? Bike : BikePurple
    case 'Одежда':
      return !isActive ? Hanger : HangerPurple
    case 'Амуниция':
      return !isActive ? Gun : GunPurple
    case 'Пища':
      return !isActive ? Burger : BurgerPurple
    case 'Скины':
      return !isActive ? Exchange : ExchangePurple
    case 'Добыча':
      return !isActive ? Fish : FishPurple
    case 'Прочее':
      return !isActive ? Other : OtherPurple
    case 'Услуги':
      return !isActive ? Services : ServicesPurple
    case 'Имущество':
      return !isActive ? Property : PropertyPurple
    case 'Вирты':
      return !isActive ? Money : MoneyPurple
  }
  return !isActive ? Other : OtherPurple
}
