import styles from './AdvertisementMy.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text32White } from 'components/styledComponents/text/textStyles'
import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import MegaphoneIcon from '../../icons/megaphone_purple.svg'
import { AdvertisementMyLimitsBlock } from 'components/ui/AdvertisementMy/AdvertisementMyLimitsBlock'
import { AdvertisementMyFilterBlock } from 'components/ui/AdvertisementMy/AdvertisementMyFilterBlock'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { AdvertisementMyList } from 'components/ui/AdvertisementMy/AdvertisementMyList'
import { observer } from 'mobx-react-lite'
import { advertisementMyStore } from 'store/advertisement/advertisementMyStore/advertisementMyStore'
import { useEffect, useRef, useState } from 'react'
import { getMyAdvertisement } from 'api/advetrisement/my/advertisementMy'
import Loader from 'components/ui/loaders/Loader/Loader'
import { Pagination } from 'components/ui/Pagination'
import { scrollToItemTop } from 'utils/pageScrollers'
import { getMyAdvertisementDashboards } from 'api/advetrisement/my/advertisementMyDashboard'
import { AdvertisementMySearchInput } from 'components/ui/AdvertisementMy/AdvertisementMySearchInput'

const AdvertisementMy = observer(() => {
  const { advertisements } = advertisementMyStore.myAdvertisements
  const { totalCount, currentPage, take } = advertisementMyStore.myAdvertisements.pagination
  const { limit, statistic } = advertisementMyStore.myAdvertisementDashboard

  const scrollToRef = useRef<any>(null)

  const [isListLoading, setListLoading] = useState<boolean>(true)

  // key что бы сбросить инпут при очистке фильтров
  const [key, setKey] = useState<number>(Math.random())

  const [type, setType] = useState<'buy' | 'sale' | ''>('')
  const [serverId, setServerId] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [input, setInput] = useState<string>('')

  const isClearFilterButton = !!type || !!serverId || !!status || !!input

  useEffect(() => {
    ;(async () => {
      setListLoading(true)
      await getMyAdvertisement({
        type: type,
        serverId: serverId,
        status: status,
        text: input
      })
      setListLoading(false)
    })()
  }, [type, serverId, status, input])

  useEffect(() => {
    ;(async () => {
      await getMyAdvertisementDashboards()
    })()
  }, [])

  const handleChooseTypeFilter = (name: string) => {
    scrollToItemTop(scrollToRef)
    if (name === 'Покупка') {
      return setType('buy')
    }
    if (name === 'Продажа') {
      return setType('sale')
    }
    return setType('')
  }

  const handleChooseServerFilter = (serverId: string) => {
    setServerId(serverId)
    scrollToItemTop(scrollToRef)
  }

  const handleChooseStatusFilter = (status: string) => {
    scrollToItemTop(scrollToRef)
    switch (status) {
      case 'Активное':
        return setStatus('active')
      case 'Просрочено':
        return setStatus('expired')
      case 'Скрыто':
        return setStatus('hidden')
      case 'На модерации':
        return setStatus('moderation')
      case 'Отклонено':
        return setStatus('cancel')
      case 'Заблокировано':
        return setStatus('banned')
      default:
        return setStatus('')
    }
  }

  const handleInputFilter = async (value: string) => {
    setInput(value)
    setListLoading(true)
    await getMyAdvertisement({
      type: type,
      serverId: serverId,
      status: status,
      text: value
    })
    setListLoading(false)
  }

  const handlePagination = async (number?: number): Promise<void> => {
    await getMyAdvertisement({ type: type, paginationSkip: number })
    scrollToItemTop(scrollToRef)
  }

  const handleClearFilters = async () => {
    scrollToItemTop(scrollToRef)
    setListLoading(true)
    setType('')
    setServerId('')
    setStatus('')
    setInput('')
    setKey(Math.random())
    await getMyAdvertisement({ type: '' })
    setListLoading(false)
  }

  return (
    <PageWrapper>
      <DivFlexColumn style={styles.container}>
        <DivFlexRow style={styles.header}>
          <DivFlexRow style={styles.headerTextBlock}>
            <img src={MegaphoneIcon} alt="users" className={styles.headerIcon} />
            <Text32White style={styles.headerText}>Мои объявления</Text32White>
          </DivFlexRow>

          <AdvertisementMySearchInput
            key={key}
            placeholder="Поиск по объявлениям"
            value={input}
            onSearch={handleInputFilter}
          />
        </DivFlexRow>

        <AdvertisementMyLimitsBlock limit={limit} statistic={statistic} />

        <HorizontalDivider style={{ height: 1, marginTop: 40 }} />

        <AdvertisementMyFilterBlock
          count={totalCount}
          handleChooseTypeFilter={handleChooseTypeFilter}
          handleChooseServerFilter={handleChooseServerFilter}
          handleChooseStatusFilter={handleChooseStatusFilter}
          handleClearFilters={handleClearFilters}
          isClearFilterButton={isClearFilterButton}
        />

        <div ref={scrollToRef}></div>

        <AdvertisementMyList list={advertisements} />

        {!!advertisements.length && !isListLoading && (
          <Pagination
            itemsInPage={8}
            totalCount={totalCount}
            currentPage={currentPage}
            take={take}
            changePaginationPage={handlePagination}
            cssBlockStyles={styles.pagination}
          />
        )}

        {isListLoading && <Loader anyStyles={{ margin: '50px auto' }} size={30} bold={3} />}
      </DivFlexColumn>
    </PageWrapper>
  )
})

export default AdvertisementMy
