import styles from './SearchFilter.module.css'

import Search from '../../icons/search-icon.svg'
import { useCallback, useEffect, useState } from 'react'
import Loader from '../loaders/Loader/Loader'

type SearchFilterProps = {
  onSearch: (value: string) => any
  onClick: () => void
  placeholder: string
}

const SearchFilter = ({ onSearch, onClick, placeholder }: SearchFilterProps) => {
  const [filterValue, setFilterValue] = useState<string>('')
  const [isSubmit, setSubmit] = useState<boolean>(false)
  const [isFiltred, setFiltred] = useState<boolean>(false)

  const handleFilterOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value)
  }

  const handleSubmit = useCallback(async () => {
    setSubmit(true)
    const response = await onSearch(filterValue)
    if (response && filterValue) {
      setFiltred(true)
      onClick()
    } else {
      setFiltred(false)
    }
    setSubmit(false)
  }, [filterValue, onClick, onSearch])

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && filterValue) {
      handleSubmit()
    }
  }

  const handleIconPress = () => {
    if (filterValue) {
      handleSubmit()
    }
  }

  useEffect(() => {
    if (!filterValue && isFiltred) {
      onClick()
      handleSubmit()
    }
  }, [filterValue, onClick, isFiltred, handleSubmit])

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        placeholder={placeholder}
        value={filterValue}
        onChange={handleFilterOnChange}
        onKeyDown={handleKeyPress}
      ></input>
      {isSubmit ? (
        <Loader anyStyles={{ margin: 'auto 17px' }} />
      ) : (
        <img className={styles.icon} onClick={handleIconPress} src={Search} alt="search"></img>
      )}
    </div>
  )
}

export { SearchFilter }
