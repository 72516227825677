import { FilterCheckBoxType } from 'store/platformConfigStore/types'
import styles from './ScrollCheckboxes.module.css'
import { CheckBoxList } from 'components/ui/lists/CheckBoxList'

type ScrollCheckboxesProps = {
  headerText: string
  fontSize?: number
  list: FilterCheckBoxType[]
  activeCheckBoxes: string[]
  onChange: (value: string[]) => void
}

const ScrollCheckboxes = ({ headerText, fontSize = 14, list, activeCheckBoxes, onChange }: ScrollCheckboxesProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.headerText} style={{ fontSize: fontSize }}>
        {headerText}
      </p>

      <div className={styles.list}>
        <CheckBoxList
          list={list}
          cssListStyles={styles.listStyles}
          activeCheckBoxes={activeCheckBoxes}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export { ScrollCheckboxes }
