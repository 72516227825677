import api, { ResultHandlerAxiosResponse } from '../axios/axiosInstance'
import { userInfoStore } from 'store/userInfoStore'

// Регистрация по электронной почте
const userRegistration = (
  email: string,
  nickname: string
): Promise<ResultHandlerAxiosResponse<{ confirmationCodeId: string }>> => {
  return api.postVersion2('/api/auth/email/register', { email, nickname })
}

// Вход с помощью почты
const authEmailLogin = async (email: string): Promise<ResultHandlerAxiosResponse<{ confirmationCodeId: string }>> => {
  return api.postVersion2('/api/auth/email/login', { email })
}

// Авторизация с использованием кода отправленного на email
const userAuthByEmail = async (
  code: string,
  codeId: string
): Promise<ResultHandlerAxiosResponse<{ jwtAuthToken: string }>> => {
  return api.postVersion2('/api/auth/email/check-code', { code, codeId })
}

// Редирект на авторизацию/регистрацию через Discord
const authDiscordOauth2Url = async (): Promise<ResultHandlerAxiosResponse<{ redirectUrl: string }>> => {
  return api.postVersion2('/api/auth/discord/oauth2/url', {})
}

// Обработчик авторизации/регистрации через Discord
const authDiscordOauth2Callback = async (code: string): Promise<ResultHandlerAxiosResponse<{ authToken: string }>> => {
  return api.postVersion2('/api/auth/discord/oauth2/callback', { code })
}

const userAuthLogOut = async (): Promise<any> => {
  const { setLogOut } = userInfoStore

  try {
    const response: any = await api.post('/api/auth/logout')
    setLogOut()
    return response
  } catch (error) {
    return error
  }
}

export {
  userRegistration,
  authEmailLogin,
  userAuthByEmail,
  authDiscordOauth2Url,
  authDiscordOauth2Callback,
  userAuthLogOut
}
