import styles from './AdvertisementCard.module.css'

import { getCardImage } from 'api/catalog/catalog.method'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text32White, Text16Grey } from 'components/styledComponents/text/textStyles'
import { LoaderPage } from 'components/ui/loaders/LoaderPage'
import { ShopBarChartBlock } from 'components/ui/ShopBar/ShopBarChartBlock'
import { ShopBarGallery } from 'components/ui/modals/ShopBarModal/ShopBarGallery'
import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { observer } from 'mobx-react-lite'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ShopBarBackButtonBlock } from '../../ui/ShopBar/ShopBarBackButtonBlock'
import { CopyLinkButton } from 'components/ui/buttons/CopyLinkButton'
import { ProductBigImage } from 'components/ui/image/ProductBigImage'
import { AdvertisementCardPrice } from './AdvertisementCardPrice'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { ShopBarContanctBlock } from 'components/ui/ShopBar/ShopBarContanctBlock'
import { AdvertisementCardDataList } from './AdvertisementCardDataList'
import { advertisementDataStore } from 'store/advertisement/advertisementDataStore'
import { getAdvertisement } from 'api/advetrisement/advertisementPage/getAdvertisement'
import { ShopBarProductDescription } from 'components/ui/modals/ShopBarModal/ShopBarProductDescription'
import { NavigateLinks } from '../../../PageRouters'
import { formatPrice } from '../../../utils/formatPrice'

type Image = {
  id: string
  name: string
  image?: string
}

const AdvertisementCard = observer(() => {
  const { cardId } = useParams()
  const {
    id,
    name,
    price,
    currency,
    catalogDescription,
    propertyCatalogPage,
    propertyAdvertisementPage,
    sourceCategory,
    catalogId,
    user,
    gamePhoneId,
    images,
    status
  } = advertisementDataStore.advertisement

  const isActive = status === 'active'

  const [isLoading, setLoading] = useState<boolean>(true)
  const [isFirstImageLoading, setFirstImageLoading] = useState<boolean>(false)

  const [loadedImages, setLoadedImages] = useState<Image[]>([])
  const [activeImage, setActiveImage] = useState<Image>({
    id: '',
    name: '',
    image: ''
  })

  useLayoutEffect(() => {
    // подгружаем данные карточки
    ;(async () => {
      if (cardId) {
        await getAdvertisement(cardId)
        setLoading(false)
      }
    })()
  }, [cardId])

  useEffect(() => {
    //  подгружаем главную картинку
    ;(async () => {
      if (images[0]) {
        const image = images[0]
        const firstImage = await getCardImage(image.id)
        const newImage = { id: image.id, name: image.name, image: firstImage }
        const fakeImages = Array.from(images.slice(1))
        setActiveImage(newImage)
        setLoadedImages([newImage, ...fakeImages])
        setFirstImageLoading(true)
      }
    })()
  }, [images])

  useEffect(() => {
    // подгружаем остальные фото
    if (isFirstImageLoading) {
      const loadImages = async () => {
        const loadedImagePromises = images.slice(1).map(async (image) => {
          const getImage = await getCardImage(image.id)
          return { id: image.id, name: image.name, image: getImage }
        })

        const loadedAllImages = await Promise.all(loadedImagePromises)

        // Обновляем массив loadedImages с учетом совпадающих id
        // это потому что мы хотим на каждой картинке крутилку пока она грузится...
        setLoadedImages((prev) => {
          // Создаем копию массива loadedImages
          const updatedLoadedImages = [...prev]
          loadedAllImages.forEach((loadedImage) => {
            // Проверяем, есть ли объект с таким же id в текущем массиве
            const index = updatedLoadedImages.findIndex((image) => image.id === loadedImage.id)

            if (index !== -1) {
              // Обновляем объект, добавляя ключ image
              updatedLoadedImages[index].image = loadedImage.image
            } else {
              // Добавляем новый объект с ключом image
              updatedLoadedImages.push(loadedImage)
            }
          })
          return updatedLoadedImages
        })
      }

      loadImages()
    }
  }, [isFirstImageLoading, images])

  if (isLoading) {
    return (
      <PageWrapper>
        <LoaderPage />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <DivFlexColumn style={styles.section}>
        <ShopBarBackButtonBlock link={NavigateLinks.Advertisement} />

        <DivFlexRow style={styles.mainTextBlock}>
          <DivFlexColumn>
            <Text32White>{name}</Text32White>
            <Text16Grey>{sourceCategory}</Text16Grey>
          </DivFlexColumn>

          <CopyLinkButton
            text="Скопировать ссылку"
            cssContainerStyles={styles.copyButton}
            fontSize={14}
            iconSize={16}
            isDisable={!isActive}
            link={`${NavigateLinks.AdvertisementCard}/${id}`}
          />
        </DivFlexRow>

        <DivFlexRow style={styles.mainData}>
          <ProductBigImage width={439} height={439} image={activeImage?.image} isDisable={!isActive} />

          <DivFlexColumn style={styles.mainInfo}>
            <AdvertisementCardPrice price={formatPrice(price)} currency={currency} isActiveStatus={isActive} />

            <HorizontalDivider style={{ marginTop: '28px', width: '100%' }} />

            <ShopBarContanctBlock user={user} gamePhoneId={gamePhoneId} isDisable={!isActive} advertisementId={id} />

            {isActive && (
              <ShopBarGallery
                cssContainer={styles.gallery}
                loadedImages={loadedImages}
                activeImage={activeImage}
                setActiveImage={setActiveImage}
              />
            )}
          </DivFlexColumn>
        </DivFlexRow>

        {isActive && (
          <>
            <AdvertisementCardDataList
              headerText="Данные продаваемого предмета"
              property={propertyAdvertisementPage}
              isVisible={!!propertyAdvertisementPage.length}
              cssBlockStyles={styles.dataList}
            />

            <AdvertisementCardDataList
              headerText="Данные предмета в каталоге"
              property={propertyCatalogPage}
              isVisible={!!propertyCatalogPage.length}
              cssBlockStyles={styles.dataList}
              isButton
              id={catalogId}
            />

            <HorizontalDivider style={{ marginTop: '24px' }} />

            {!!catalogDescription && <ShopBarProductDescription description={catalogDescription} mt={32} />}

            <ShopBarChartBlock cssContainer={styles.chartBlock} headerText="График изменения цены" />
          </>
        )}
      </DivFlexColumn>
    </PageWrapper>
  )
})

export default AdvertisementCard
