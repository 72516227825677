import styles from './Loader.module.css'
import { CSSProperties } from 'react'

// size - одно значение, задает размеры высоты и ширины
// bold - толщина полосы

type LoaderProps = {
  anyStyles?: Record<string, string | number> | CSSProperties
  size?: number
  bold?: number
}

const Loader = ({ anyStyles, size, bold }: LoaderProps) => {
  return (
    <div
      className={styles.loader}
      style={{
        width: size,
        height: size,
        border: `${bold}px solid transparent`,
        borderTop: `${bold}px solid #60AE32`,
        ...anyStyles
      }}
    ></div>
  )
}

export default Loader
