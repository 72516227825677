import styles from './ShopBarListAlert.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text16Grey } from 'components/styledComponents/text/textStyles'

import Icon from './bell.svg'

const text = 'К сожалению, мы не смогли найти позиции.'

const ShopBarListAlert = () => {
  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexRow style={styles.textContainer}>
        <ImgBase img={Icon} style={styles.icon} />
        <Text16Grey style={styles.text}>{text}</Text16Grey>
      </DivFlexRow>

      <OvalButton text="Понятно" cssContainerStyle={styles.button} />
    </DivFlexColumn>
  )
}

export { ShopBarListAlert }
