import styles from './AdvertisementCreateChooseProduct.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text16Grey } from 'components/styledComponents/text/textStyles'
import { SearchFilterBig } from 'components/ui/SearchFilterBig'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { advertisementCatalogSearch } from 'api/advetrisement/create/advetrisementCreate'
import { AdvertisementCatalogSearch, advertisementCreateStore } from 'store/advertisement/advertisementCreateStore'
import ReactDOM from 'react-dom'

type AdvertisementCreateChooseProductProps = {
  choseItem: AdvertisementCatalogSearch | null
  handleChooseItem: (item: AdvertisementCatalogSearch) => void
  isError: boolean
}

const AdvertisementCreateChooseProduct = observer(
  ({ choseItem, handleChooseItem, isError }: AdvertisementCreateChooseProductProps) => {
    const { catalogSearch, setCatalogSearch } = advertisementCreateStore

    const [inputValue, setInputValue] = useState<string>('')
    const [isDropDown, setDropDown] = useState<boolean>(false)

    const handleInputSubmit = async (inputValue: string) => {
      await advertisementCatalogSearch({
        searchText: inputValue
      })
      setDropDown(true)
    }

    const handleItemClick = (item: AdvertisementCatalogSearch) => {
      handleChooseItem(item)
      setDropDown(false)
    }

    const onClearFunction = () => {
      setDropDown(false)
      setCatalogSearch([])
    }

    useEffect(() => {
      if (choseItem) {
        setInputValue(choseItem.name)
      }
    }, [])

    return (
      <DivFlexColumn style={styles.container}>
        <DivFlexColumn style={styles.inputBlock}>
          <SearchFilterBig
            inputValue={inputValue}
            setInputValue={setInputValue}
            cssStylesContainer={styles.searchInput}
            onSubmit={handleInputSubmit}
            onFocusFunction={setDropDown.bind(this, true)}
            onClearFunction={onClearFunction}
            isError={isError}
            isDelayCheckInput={true}
          />

          {!!catalogSearch.length && isDropDown && (
            <div className={styles.dropContainer}>
              <ul className={styles.itemsSection}>
                {catalogSearch.map((item) => {
                  return (
                    <li key={item.id} className={styles.item} onClick={handleItemClick.bind(this, item)}>
                      <Text16Grey
                        style={`
                      ${styles.itemText} 
                      ${choseItem !== null && choseItem.id === item.id && styles.active}
                      `}>
                        {`${item.name} - ${item.sourceCategory}`}
                      </Text16Grey>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {isDropDown &&
            ReactDOM.createPortal(
              <span className={styles.dropdownWall} onClick={setDropDown.bind(this, false)} />,
              document.body
            )}
        </DivFlexColumn>
      </DivFlexColumn>
    )
  }
)

export { AdvertisementCreateChooseProduct }
