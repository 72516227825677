import { ArrowInCircleWithPurpleBorder } from '../ArrowInCircleWithPurpleBorder'
import { useEffect, useRef, useState } from 'react'

import styles from './HorizontalSlider.module.css'
import { HorizontalSliderShadowContainer } from './HorizontalSliderShadowContainer'

type HorizontalSliderProps = {
  cssStylesContainer?: string
  items: string[]
  onClickItem?: (itemName: string) => void
}

const HorizontalSlider = ({ cssStylesContainer, items, onClickItem }: HorizontalSliderProps) => {
  // Создаем ссылку на элемент контейнера, используя хук useRef
  const sliderContainerRef = useRef<HTMLDivElement>(null)

  // Состояния для отслеживания текущего значения скролла и максимального значения скролла
  const [scrollLeftValue, setScrollLeftValue] = useState<number>(0)
  const [maxScrollLeft, setMaxScrollLeft] = useState<number>(0)

  // Состояния для отслеживания видимости стрелок
  const [isRightArrowHidden, setIsRightArrowHidden] = useState<boolean>(false)
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState<boolean>(true)

  // Состояние для отслеживания состояния зажатия мыши
  const [isDragging, setIsDragging] = useState<boolean>(false)

  // Состояния для отслеживания начальной позиции курсора и начальной позиции скролла при перемещении мыши
  const [startX, setStartX] = useState<number>(0)
  const [scrollStartX, setScrollStartX] = useState<number>(0)

  // Обработчик скролла контейнера
  const handleScroll = () => {
    const sliderContainer = sliderContainerRef.current
    if (sliderContainer) {
      setScrollLeftValue(sliderContainer.scrollLeft)
    }
  }

  // Обработчик прокрутки вправо
  const handleRightScroll = () => {
    const sliderContainer = sliderContainerRef.current
    if (sliderContainer) {
      sliderContainer.scrollBy({
        top: 0,
        left: 200,
        behavior: 'smooth'
      })
    }
  }

  // Обработчик прокрутки влево
  const handleLeftScroll = () => {
    const sliderContainer = sliderContainerRef.current
    if (sliderContainer) {
      sliderContainer.scrollBy({
        top: 0,
        left: -200,
        behavior: 'smooth'
      })
    }
  }

  // Обработчик прокрутки колесика мыши, если очень захочется, но нужно будет блокать скролл body
  // const handleWheelScroll = (event: React.WheelEvent<HTMLDivElement>) => {
  //   const sliderContainer = sliderContainerRef.current
  //   if (sliderContainer) {
  //     const direction = event.deltaY < 0 ? -1 : 1
  //     sliderContainer.scrollBy({
  //       top: 0,
  //       left: direction * 300,
  //       behavior: 'smooth',
  //     })
  //   }
  // }

  // Обработчик нажатия кнопки мыши внутри контейнера
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true)
    setStartX(event.pageX)
    setScrollStartX(sliderContainerRef.current?.scrollLeft || 0)
  }

  // Обработчик отпускания кнопки мыши
  const handleMouseUp = () => {
    setIsDragging(false)
  }

  // Обработчик движения мыши внутри контейнера при зажатой кнопке мыши
  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return
    const sliderContainer = sliderContainerRef.current
    if (sliderContainer) {
      const moveX = event.pageX - startX
      sliderContainer.scrollLeft = scrollStartX - moveX
    }
  }

  // Эффект для установки максимального значения скролла и добавления обработчика скролла
  useEffect(() => {
    const sliderContainer = sliderContainerRef.current
    if (sliderContainer) {
      sliderContainer.addEventListener('scroll', handleScroll)
      setScrollLeftValue(sliderContainer.scrollLeft)
      setMaxScrollLeft(sliderContainer.scrollWidth - sliderContainer.clientWidth)
    }

    return () => {
      if (sliderContainer) {
        sliderContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [items])

  // Эффект для обновления состояния видимости стрелок при изменении скролла
  useEffect(() => {
    setIsRightArrowHidden(scrollLeftValue >= maxScrollLeft)
    setIsLeftArrowHidden(scrollLeftValue === 0)
  }, [scrollLeftValue, maxScrollLeft])

  return (
    <div className={`${styles.slider} ${cssStylesContainer}`}>
      {!isLeftArrowHidden && (
        <HorizontalSliderShadowContainer cssStyles={styles.leftShadow}>
          <ArrowInCircleWithPurpleBorder cssStyle={styles.leftArrow} isLeft onClick={handleLeftScroll} />
        </HorizontalSliderShadowContainer>
      )}

      <div
        className={`${styles.sliderContainer}`}
        ref={sliderContainerRef}
        // onWheel={handleWheelScroll}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseUp}
      >
        {items.map((item, index) => (
          <div key={index} className={styles.itemContainer} onClick={onClickItem?.bind(this, item)}>
            <p className={styles.itemText}>{item}</p>
          </div>
        ))}
      </div>

      {!isRightArrowHidden && (
        <HorizontalSliderShadowContainer cssStyles={styles.rightShadow} isRight>
          <ArrowInCircleWithPurpleBorder cssStyle={styles.rightArrow} onClick={handleRightScroll} />
        </HorizontalSliderShadowContainer>
      )}
    </div>
  )
}

export { HorizontalSlider }
