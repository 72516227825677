import styles from './HomePhoneSectionPhoneContainer.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { DarkGreyContainer } from 'components/pages/Home/components/DarkGreyContainer'
import { Text14Grey, Text14White } from 'components/styledComponents/text/textStyles'
import { ImgBase } from 'components/styledComponents/img/img'

import Call from '../../../../../icons/button/green_phone-call.svg'

const firstPhone = '267-12-45'
const secondPhone = '567-34-09'

const HomePhoneSectionPhoneContainer = () => {
  return (
    <DivFlexColumn style={styles.container}>
      <DarkGreyContainer style={styles.callContainer}>
        <Text14Grey style={styles.name}>Игровой номер #1</Text14Grey>
        <DivFlexRow style={styles.phoneContainer}>
          <ImgBase img={Call} style={styles.icon} />
          <Text14White style={styles.phone}>{firstPhone}</Text14White>
        </DivFlexRow>
      </DarkGreyContainer>

      <DarkGreyContainer style={styles.callContainer}>
        <Text14Grey style={styles.name}>Игровой номер #2</Text14Grey>
        <DivFlexRow style={styles.phoneContainer}>
          <ImgBase img={Call} style={styles.icon} />
          <Text14White style={styles.phone}>{secondPhone}</Text14White>
        </DivFlexRow>
      </DarkGreyContainer>
    </DivFlexColumn>
  )
}

export { HomePhoneSectionPhoneContainer }
