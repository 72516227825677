import styles from './PaginationInput.module.css'

import SpreadIcon from '../../../icons/spread_white.svg'
import { useEffect, useRef, useState } from 'react'

type PaginationInputProps = {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const PaginationInput = ({ value, onChange, onKeyDown }: PaginationInputProps) => {
  const [isActive, setActive] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleDocumentClick = (e: MouseEvent) => {
    // Проверить, был ли клик сделан вне контейнера и вне инпута, и сбросить состояние
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setActive(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const handleClickImage = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    setActive(true)
  }

  useEffect(() => {
    // Устанавливаем фокус на инпут, когда isActive становится true
    // и делам click что бы закрыть другие такие инпуты
    if (isActive && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.click()
    }
  }, [isActive])

  return (
    <div className={styles.container}>
      {isActive ? (
        <input
          ref={inputRef}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={styles.input}
          style={{ width: value.length === 1 ? '12px' : `${12 + value.length * 3.5}px` }}
        />
      ) : (
        <div className={styles.spread} onClick={handleClickImage}>
          <img src={SpreadIcon} alt="" />
        </div>
      )}
    </div>
  )
}

export { PaginationInput }
