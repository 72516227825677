import { CSSProperties } from 'react'
import styles from './LoaderPage.module.css'

type LoaderPageProps = {
  anyStyles?: Record<string, string | number> | CSSProperties
  containerStyle?: Record<string, string | number> | CSSProperties
}

const LoaderPage = ({ anyStyles, containerStyle }: LoaderPageProps) => {
  return (
    <div className={styles.container} style={{ ...containerStyle }}>
      <div className={styles.loader} style={{ ...anyStyles }}></div>
    </div>
  )
}

export { LoaderPage }
