import { getUserInfo } from './user/userProfileData'
import { getUserAvatar } from './userSettings/userAvatar'
import { getPlatformConfig } from './platform/platform.config'

export const userLoading = async () => {
  try {
    await getUserInfo()
    await getPlatformConfig()
    await getUserAvatar()
  } catch (e) {
    console.log(e)
    throw e
  }
}
