import styles from './PaginationSwitchButton.module.css'

import BackIcon from '../../../icons/arrow_left_white.svg'
import ForwardIcon from '../../../icons/arrow_right_white.svg'
import { Text16White } from 'components/styledComponents/text/textStyles'

type PaginationSwitchButtonProps = {
  isBack?: boolean
  onClick: () => void
  isActive: boolean
}

const PaginationSwitchButton = ({ isBack, onClick, isActive }: PaginationSwitchButtonProps) => {
  return (
    <div className={`${styles.button} ${isBack && styles.back} ${isActive && styles.active}`} onClick={onClick}>
      {isBack && <img src={BackIcon} alt="back" className={styles.icon} />}

      <Text16White>{isBack ? 'Назад' : 'Далее'}</Text16White>

      {!isBack && <img src={ForwardIcon} alt="forward" className={styles.icon} />}
    </div>
  )
}

export { PaginationSwitchButton }
