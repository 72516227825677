import styles from './HomePhoneSectionCallContainer.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { ContactContainer } from 'components/pages/Home/components/ContactContainer'
import { DarkPurpleContainer } from 'components/pages/Home/components/DarkPurpleContainer'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text14Grey, Text16White } from 'components/styledComponents/text/textStyles'

import CallIcon from './call-icon.svg'
import Cancel from './cancel.svg'

const HomePhoneSectionCallContainer = () => {
  return (
    <DivFlexColumn style={styles.section}>
      <DarkPurpleContainer style={styles.contactCall}>
        <ImgBase img={CallIcon} style={styles.avatar} />
        <Text16White style={styles.name}>Ivan D.</Text16White>
        <Text14Grey style={styles.time}>00:45</Text14Grey>
        <ImgBase img={Cancel} style={styles.icon} />
      </DarkPurpleContainer>

      <ContactContainer containerStyle={styles.contactContainer} />
    </DivFlexColumn>
  )
}

export { HomePhoneSectionCallContainer }
