import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { useLayoutEffect, useState } from 'react'
import AuthModal from 'components/ui/modals/AuthModal/AuthModal'
import { useSearchParams } from 'react-router-dom'
import DiscordError from '../DiscordError/DiscordError'
import { LoaderPage } from 'components/ui/loaders/LoaderPage'
import { pathLinkedAccountBindDiscord } from '../../../api/linked-account/linked-account'

const titleText = 'Привязка аккаунта'

const DiscordCallbackBind = () => {
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')
  const state = searchParams.get('state')

  const [errorText, errorTextSet] = useState<string>('Неизвестная ошибка. Пожалуйста, обратитесь к администратору!')
  const [isAuthModal, setAuthModal] = useState<boolean>(false)

  const [isLoading, setLoading] = useState<boolean>(true)

  useLayoutEffect(() => {
    ;(async () => {
      if (!code) {
        errorTextSet('Ошибка. Обязательный параметр "code" отсутствует!')
        setLoading(false)
        return
      }

      const result = await pathLinkedAccountBindDiscord(code)
      if (result.error) {
        errorTextSet(result.error.message)
      } else {
          errorTextSet('Вы успешно привязали аккаунт!')
      }

      setLoading(false)
    })()
  }, [code, state])

  return (
    <PageWrapper>
      {!isLoading && (
        <DiscordError
          onFormClick={setAuthModal.bind(this, true)}
          onDiscordClick={setAuthModal.bind(this, true)}
          headerText={titleText}
          subText={errorText}
        />
      )}

      {isAuthModal && <AuthModal isOpen={isAuthModal} onClose={setAuthModal.bind(this, false)} />}

      {isLoading && <LoaderPage containerStyle={{ background: 'transparent' }} />}
    </PageWrapper>
  )
}

export { DiscordCallbackBind }
