import styles from './TextUnderlined.module.css'

type TextUnderlinedProps = {
  text: string
  containerStyle?: string
  underlineStyle?: string
  isActive?: boolean
  fontSize?: number
  color?: {
    grey: boolean
    green: boolean
  }
}

const TextUnderlined = ({ text, containerStyle, underlineStyle, isActive, fontSize, color }: TextUnderlinedProps) => {
  const changeColorText = (): string => {
    if (color?.grey) {
      return '#7E88BB'
    }
    if (color?.green) {
      return '#CFFB58'
    }
    if (isActive) {
      return '#7B4CFF'
    } else return '#FFFFFF'
  }

  return (
    <div className={`${styles.container} ${containerStyle} ${isActive && styles.active}`}>
      <p
        className={`${styles.text} ${isActive && styles.active}`}
        style={{
          fontSize: fontSize,
          color: changeColorText()
        }}
      >
        {text}
      </p>

      <div className={`${styles.underline} ${underlineStyle} ${isActive && styles.active}`}></div>
    </div>
  )
}

export { TextUnderlined }
