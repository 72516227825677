import styles from './NotFound.module.css'
import Icon from '../../icons/404.svg'
import Arrow from '../../icons/arrow_left_purple.svg'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'

const errorText = 'Ошибка 404'
const text =
  'К сожалению, запрашиваемая страница не найдена. Возможно, вы перешли по ссылке, в которой была допущена ошибка, или ресурс был удален. Попробуйте перейти на главную страницу или каталог'

const NotFound = () => {
  return (
    <PageWrapper>
      <div className={styles.container}>
        <img src={Icon} alt="icon"></img>
        <p className={styles.errorText}>{errorText}</p>
        <p className={styles.text}>{text}</p>
        <OvalButton
          text="Назад"
          icon={Arrow}
          router=".."
          containerStyle={{ width: '80px', justifyContent: 'start', marginTop: '24px' }}
        />
      </div>
    </PageWrapper>
  )
}

export default NotFound
