import { action, makeObservable, observable } from 'mobx'
import { IGetLinkedAccountList, IPatchLinkedAccountEditSetting } from '../api/linked-account/types'
import { getLinkedAccountList } from '../api/linked-account/linked-account'

class LinkedAccountStore {
  linkedAccount: IGetLinkedAccountList

  constructor() {
    makeObservable(this, {
      linkedAccount: observable,
      loadLinkedAccount: action,
      deleteAccount: action,
      updateAccount: action
    })
  }

  loadLinkedAccount = async () => {
    const result = await getLinkedAccountList()
    if (result.data) {
      this.linkedAccount = result.data
      console.log(result.data)
    }
  }

  deleteAccount = (type: 'discord' | 'telegram') => {
    delete this.linkedAccount[type]
  }

  updateAccount = (type: 'discord' | 'telegram', data: IPatchLinkedAccountEditSetting) => {
    this.linkedAccount[type] = data
  }
}

export const linkedAccountStore = new LinkedAccountStore()
