export type PlatformConfig = {
    categories: IPlatformCategory[]
    servers: IPlatformServer[]
    myAdvertisement: MyAdvertisementPlatformConfig
    catalog: {
        [categoryLabel in string]: {
            filter: PlatformConfigFilter[][]
            sort: PlatformConfigSort[]
        }
    }
    advertisement: {
        [categoryLabel in string]: {
            filter: PlatformConfigFilter[][]
            sort: PlatformConfigSort[]
        }
    }
}

export interface IPlatformCategory {
    id: string
    name: string
    categories: IPlatformCategory[]
    label: string | null
}

export interface IPlatformServer extends IPlatformCategory {
    id: string
    name: string
}

export type SearchPagination = {
    totalCount: number
    currentPage: number
    take: number
}

export type SearchItem = {
    id: string
    name: string
    price: string | null
    currency: string | null
    description: string | null
    property: SearchItemProperty[] | []
    propertyCard: SearchItemProperty[] | []
    sourceCategory: string
    images: SearchItemImage[] | []
    countAdvertisementBuy: number
    countAdvertisementSale: number
}

export type SearchItemProperty = {
    name: string
    value: string
}

export type SearchItemImage = {
    id: string
    name: string
}

export type SearchItems = {
    pagination: SearchPagination
    items: SearchItem[]
}

export type MyAdvertisementPlatformConfig = {
    maxCountOnBuy: string
    maxCountOnSale: string
    maxCountOnBuyBySubscription: string
    maxCountOnSaleBySubscription: string
    maxCountHourShow: string
    maxCountHourShowSubscription: string
}

export type PlatformConfigSort = {
    name: string
    key: string
}

export type PlatformConfigFilter =
    | {
    key: string
    name: string
    type: FiltersType.RangeFixed
    vip: boolean
    property: {
        from: number
        to: number
    }
}
    | {
    key: string
    name: string
    type: FiltersType.RangeDynamic
    vip: boolean
    property: {
        from?: number
        to?: number
    }
}
    | {
    key: string
    name: string
    type:
        | FiltersType.CheckboxList
        | FiltersType.CheckboxListSearch
        | FiltersType.Checkbox
        | FiltersType.CheckboxHorizontal
        | FiltersType.ToggleHorizontal
        | FiltersType.ToggleVertical
    vip: boolean
    property: {
        variants: FilterCheckBoxType[]
    }
}
    | {
    key: string
    name: string
    type: FiltersType.Divider
    vip: boolean
    property: {}
}

export type FilterCheckBoxType = {
    name: string
    key: string
    selected: boolean
}

export enum FiltersType {
    RangeFixed = 'range_fixed',
    RangeDynamic = 'range_dynamic',
    CheckboxList = 'checkbox_list',
    CheckboxListSearch = 'checkbox_list_search',
    Checkbox = 'checkbox',
    CheckboxHorizontal = 'checkbox_horizontal',
    ToggleHorizontal = 'toggle_horizontal',
    ToggleVertical = 'toggle_vertical',
    Divider = 'divider'
}
