import styles from './CatalogCardPageBuyBlock.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { ButtonCountArrowLeft } from 'components/ui/buttons/ButtonCountArrowLeft'
import { useState } from 'react'
import { userInfoStore } from 'store/userInfoStore'
import { observer } from 'mobx-react-lite'
import { SubscriptionPaymentModalsContainer } from 'components/ui/modals/SubscriptionPaymentModalsContainer'
import { createLinkAdvertisementBuy, createLinkAdvertisementSale } from '../../../../utils/link'

type CatalogCardPageBuyBlockProps = {
  countAdvertisementBuy: number
  countAdvertisementSale: number
  catalogId: string
  nameButtonBuy?: string
  nameButtonSale?: string
  cssContainer?: string
  cssButtonBuy?: string
  cssButtonSale?: string
}

const CatalogCardPageBuyBlock = observer(
  ({
    countAdvertisementBuy,
    countAdvertisementSale,
    catalogId,
    nameButtonBuy,
    nameButtonSale,
    cssContainer,
    cssButtonBuy,
    cssButtonSale
  }: CatalogCardPageBuyBlockProps) => {
    const { isVip } = userInfoStore

    const [isSubModal, setSubModal] = useState<boolean>(false)

    const handleClickBuyButton = () => {
      if (!isVip) {
        setSubModal(true)
      } else {
        window.open(createLinkAdvertisementBuy(catalogId), '_blank')
      }
    }

    const handleClickSaleButton = () => {
      window.open(createLinkAdvertisementSale(catalogId), '_blank')
    }

    return (
      <DivFlexColumn style={`${styles.buttonsContainer} ${!isVip && styles.vipDisable} ${cssContainer}`}>
        <ButtonCountArrowLeft
          text={nameButtonBuy ?? 'Покупают'}
          count={countAdvertisementBuy}
          color="green"
          fontSize={16}
          containerStyle={`${isVip ? styles.saleButton : styles.buyButton} ${isVip ? cssButtonSale : cssButtonBuy}`}
          isVip={isVip}
          onClick={handleClickBuyButton}
        />
        <ButtonCountArrowLeft
          text={nameButtonSale ?? 'Продают'}
          count={countAdvertisementSale}
          color="azure"
          fontSize={16}
          containerStyle={`${styles.saleButton} ${cssButtonSale}`}
          onClick={handleClickSaleButton}
        />

        {isSubModal && <SubscriptionPaymentModalsContainer onClose={setSubModal.bind(this, false)} />}
      </DivFlexColumn>
    )
  }
)

export { CatalogCardPageBuyBlock }
