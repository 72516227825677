import { DivFlexRow } from 'components/styledComponents/div/div'
import styles from './HandleCountInput.module.css'
import { useState } from 'react'
import { BaseInput } from '../BaseInput'

type HandleCountInputProps = {
  min: number
  max: number
  isInput?: boolean
  onChange: (count: number) => void
}

const HandleCountInput = ({ min, max, onChange, isInput = false }: HandleCountInputProps) => {
  const [input, setInput] = useState<string>(String(min))

  const handleOnChange = (value: string) => {
    const onlyNumbers = value.replace(/[^0-9]/g, '')

    if (onlyNumbers.length > 0 && input.length > 0 && Number(onlyNumbers) !== Number(input)) {
      onChange(Number(onlyNumbers))
    } else if (onlyNumbers.length === 0 && input.length > 0) {
      onChange(0)
    } else if (onlyNumbers.length > 0 && input.length === 0) {
      onChange(Number(onlyNumbers))
    }

    setInput(onlyNumbers)
  }

  const handleCountIncrement = () => {
    const numberInput = Number(input)
    const resultNumberInput = numberInput - 1

    if (numberInput > min) {
      setInput(String(resultNumberInput))
      onChange(resultNumberInput)
    }
  }

  const handleCountDecrement = () => {
    const numberInput = Number(input)
    const resultNumberInput = numberInput + 1

    if (numberInput < max) {
      setInput(String(resultNumberInput))
      onChange(resultNumberInput)
    }
  }

  const isIncrementActive = input.length > 0 && Number(input) > min
  const isDecrementActive = input.length === 0 || Number(input) < max

  return (
    <DivFlexRow style={styles.container}>
      <button className={`${styles.button} ${isIncrementActive && styles.active}`} onClick={handleCountIncrement}>
        <p className={styles.buttonText}>-</p>
      </button>

      {isInput ? (
        <BaseInput
          value={input}
          onChange={handleOnChange}
          isValid={true}
          maxLength={max}
          cssInputContainer={styles.inputContainer}
          isCenter
          fontSize={16}
        />
      ) : (
        <div className={styles.countBlock}>
          <p className={styles.count}>{input}</p>
        </div>
      )}

      <button className={`${styles.button} ${isDecrementActive && styles.active}`} onClick={handleCountDecrement}>
        <p className={styles.buttonText}>+</p>
      </button>
    </DivFlexRow>
  )
}

export { HandleCountInput }
