import styles from './ShopBarListResult.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Green, Text14Grey, Text24White } from 'components/styledComponents/text/textStyles'
import { observer } from 'mobx-react-lite'
import Loader from 'components/ui/loaders/Loader/Loader'
import {loadingStore} from "../../../../../store/loadingStore";

type ShopBarListResultProps = {
  itemsCount: number
}

const ShopBarListResult = observer(({ itemsCount }: ShopBarListResultProps) => {
  const { isLoadingItems } = loadingStore

  return (
    <DivFlexColumn style={styles.resultContainer}>
      <Text24White>Результат поиска</Text24White>
      {isLoadingItems ? (
        <DivFlexRow>
          <Text14Green style={styles.loadingText}>Загрузка результатов</Text14Green>
          <Loader />
        </DivFlexRow>
      ) : (
        <Text14Grey>показано {itemsCount} результа (ов)</Text14Grey>
      )}
    </DivFlexColumn>
  )
})

export { ShopBarListResult }
