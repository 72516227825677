export const formatPrice = (number: number): string => {
  // добавлят . в число - пример 3.000.000
  const numberStr = number.toString()

  const groups = []
  let group = ''
  let count = 0

  for (let i = numberStr.length - 1; i >= 0; i--) {
    group = numberStr[i] + group
    count++

    if (count === 3 && i !== 0) {
      groups.unshift(group)
      group = ''
      count = 0
    }
  }

  if (group !== '') {
    groups.unshift(group)
  }

  return groups.join('.')
}
