import { action, makeAutoObservable, observable } from 'mobx'
import { IGetResAdvertisementListItem } from '../../api/advetrisement/shopbar/advertisement.type'

class AdvertisementDataStore {
  advertisement: IGetResAdvertisementListItem = {
    id: '',
    name: '',
    price: 0,
    catalogDescription: null,
    propertyAdvertisementPage: [],
    propertyCatalogPage: [],
    propertyCard: [],
    sourceCategory: '',
    catalogId: '',
    user: {
      nickname: '',
      activeStatus: 'offline'
    },
    gamePhoneId: null,
    images: [],
    status: '',
    currency: '',
  }

  constructor() {
    makeAutoObservable(this, {
      advertisement: observable,
      setAdvertisement: action.bound
    })
  }

  setAdvertisement(data: IGetResAdvertisementListItem) {
    this.advertisement = data
  }
}

const advertisementDataStore = new AdvertisementDataStore()

export { advertisementDataStore }
