import { action, makeObservable, observable } from 'mobx'

export enum LoadingStatusType {
  Loading = 'start',
  Error = 'error',
  Stop = 'stop'
}

class LoadingStatusStore {
  loading: LoadingStatusType = LoadingStatusType.Stop

  constructor() {
    makeObservable(this, {
      loading: observable,
      setLoading: action.bound
    })
  }

  setLoading(loadingType: LoadingStatusType) {
    this.loading = loadingType
  }
}

const loadingStatusStore = new LoadingStatusStore()

export { loadingStatusStore }
