import styles from './ShopBarList.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { ShopBarListHeader } from './ShopBarListHeader'
import { ShopBarFilterContainer } from '../ShopBarFilterContainer'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { PlatformConfigFilter, PlatformConfigSort } from '../../../../store/platformConfigStore/types'
import { TSearchParamsJson, TSearchParamsJsonTypesValue } from '../../../../utils/searchParams'

type ShopBarListProps = {
  sortListItem: PlatformConfigSort[]
  sortSelectedItem: PlatformConfigSort
  sortListOrderBy: PlatformConfigSort[]
  sortSelectedOrderBy: PlatformConfigSort
  onSortSelectedItem: (value: PlatformConfigSort) => void
  onSortSelectedOrderBy: (value: PlatformConfigSort) => void
  children: ReactNode
  filters: PlatformConfigFilter[][]
  itemsCount: number
  isFilterOpen: boolean
  onClickFilter: () => void
  onClickFiltersApply: () => void
  onSetValueInFilter: (key: string, data: TSearchParamsJsonTypesValue) => void
  valueFilters: TSearchParamsJson
}

const ShopBarList = observer(
  ({
    filters,
    valueFilters,
    itemsCount,
    onClickFiltersApply,
    onClickFilter,
    onSetValueInFilter,
    isFilterOpen,
    children,
    sortListItem,
    sortSelectedItem,
    onSortSelectedItem,
    sortSelectedOrderBy,
    onSortSelectedOrderBy,
    sortListOrderBy
  }: ShopBarListProps) => {
    return (
      <DivFlexColumn style={styles.container}>
        <ShopBarListHeader
          itemsCount={itemsCount}
          onFilterClick={onClickFilter}
          sortListItem={sortListItem}
          sortSelectedItem={sortSelectedItem}
          onSortSelectedItem={onSortSelectedItem}
          sortListOrderBy={sortListOrderBy}
          sortSelectedOrderBy={sortSelectedOrderBy}
          onSortSelectedOrderBy={onSortSelectedOrderBy}
        />

        {isFilterOpen && (
          <>
            <ShopBarFilterContainer
              onSubmit={onClickFiltersApply}
              onCancel={onClickFilter}
              filters={filters}
              valueFilters={valueFilters}
              onSetValueInFilter={onSetValueInFilter}
            />
            <HorizontalDivider cssStyle={styles.divider} />
          </>
        )}

        {children}
      </DivFlexColumn>
    )
  }
)

export { ShopBarList }
