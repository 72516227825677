import styles from './EmailAuthCode.module.css'

import { ChangeEvent, useEffect, useRef, useState } from 'react'

type EmailAuthCodeProps = {
  value: string
  onChange: (value: string) => void
  isError: boolean
  onCodeChange: (value: string) => void
}

const EmailAuthCode = ({ value, onChange, isError, onCodeChange }: EmailAuthCodeProps) => {
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null)
  ]

  useEffect(() => {
    // фокус на первом элементе inputRefs при монтировании компонента
    if (inputRefs[0].current) {
      inputRefs[0].current.focus()
    }
    // eslint-disable-next-line
  }, [])

  const [hasValue, setHasValue] = useState<boolean[]>(Array.from({ length: 6 }, () => false))

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target
    if (/^\d$/.test(value)) {
      if (index < inputRefs.length - 1 && value !== '') {
        inputRefs[index + 1].current?.focus()
      }
    } else {
      e.target.value = ''
    }

    const updatedHasValue = [...hasValue]
    updatedHasValue[index] = value !== ''
    setHasValue(updatedHasValue)

    const newValue = inputRefs.map((ref) => ref.current?.value || '').join('')
    onChange(newValue)
    onCodeChange(newValue)
  }

  const handleInputKeyDown = (e: any, index: number) => {
    if (e.key === 'Backspace' && index > 0 && e.currentTarget.value === '') {
      inputRefs[index - 1].current?.focus()
    }
  }

  useEffect(() => {
    const inputValues = inputRefs.map((ref) => ref.current?.value || '')
    const updatedHasValue = inputValues.map((value) => value !== '')
    setHasValue(updatedHasValue)
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    inputRefs.forEach((ref, index) => {
      if (ref.current && index === 0) {
        ref.current.value = value[index] || ''
      }
    })
    // eslint-disable-next-line
  }, [value])

  return (
    <div className={styles.codeContainer}>
      {inputRefs.map((ref, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          ref={ref}
          className={`${hasValue[index] ? (!isError ? styles.inputWithValue : styles.inputError) : styles.input}`}
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleInputKeyDown(e, index)}
        />
      ))}
    </div>
  )
}

export default EmailAuthCode
