import styles from './ProfilePhoneNumbersBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ProfileSettingsCreateElementButton } from 'components/ui/ProfileSettings/ProfileSettingsCreateElementButton'
import { ProfileSettingsPhoneCard } from 'components/ui/ProfileSettings/ProfileSettingsPhoneCard'
import { Text16Grey } from 'components/styledComponents/text/textStyles'
import { getUserGamePhones } from 'api/userSettings/userGamePhones'
import { useEffect, useState } from 'react'
import Loader from 'components/ui/loaders/Loader/Loader'
import { userGamePhonesStore } from 'store/profileSettingsStore/gamePhonesStore'
import { observer } from 'mobx-react-lite'
import { VipBlockButton } from 'components/ui/VipBlockButton'
import { ProfileSettingsWhatBlock } from '../../ProfileSettingsWhatBlock'
import { ProfileSettingsHeaderAlert } from '../../ProfileSettingsHeaderAlert'

import BallIcon from '../../../../icons/ball_grey.svg'
import CreeateButtonIcon from '../../../../icons/profileSettings/phone_add.svg'
import { userInfoStore } from '../../../../../store/userInfoStore'

const alertText = 'У Вас еще нет добавленных номеров'
const footerTextFirst = 'Вы добавили максимальное количество номеров'
const footerTextSecond = 'Удалите один из них, что бы добавить новый'

type ProfilePhoneNumbersBlockProps = {
  handleOpenNewPhoneModal: () => void
}

const ProfilePhoneNumbersBlock = observer(({ handleOpenNewPhoneModal }: ProfilePhoneNumbersBlockProps) => {
  const { userPhones } = userGamePhonesStore
  const { isVip } = userInfoStore

  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getUserGamePhones()
      setLoading(false)
    })()
  }, [])

  const isPhones = !!userPhones.length
  const isMaxPhones = userPhones.length >= 10

  if (isLoading) {
    return <Loader anyStyles={{ margin: ' 100px auto' }} />
  }

  return (
    <DivFlexColumn style={styles.container}>
      {!isPhones && <ProfileSettingsHeaderAlert alertText={alertText} />}

      <DivFlexRow style={styles.list}>
        {isPhones &&
          userPhones.map((item) => {
            return <ProfileSettingsPhoneCard key={item.id} item={item} />
          })}
        {!isMaxPhones ? (
          <VipBlockButton isBlocked={!isVip} isChildrenInside>
            <ProfileSettingsCreateElementButton
              text="Создать номер"
              icon={CreeateButtonIcon}
              onClick={handleOpenNewPhoneModal}
            />
          </VipBlockButton>
        ) : (
          <DivFlexColumn style={styles.footer}>
            <img src={BallIcon} alt="ball" className={styles.headerIcon} />
            <Text16Grey>{footerTextFirst}</Text16Grey>
            <Text16Grey>{footerTextSecond}</Text16Grey>
          </DivFlexColumn>
        )}
      </DivFlexRow>

      <ProfileSettingsWhatBlock buttonText="Для чего нужно создавать номер?" onClick={() => window.open('/faq/why-do-i-need-a-gaming-phone-number', '_blank')} />
    </DivFlexColumn>
  )
})

export { ProfilePhoneNumbersBlock }
