import styles from './ChatPageCompanionData.module.css'

import { DivFlexRow, DivFlexColumn } from 'components/styledComponents/div/div'
import { Text16White, Text14Green, Text14Grey } from 'components/styledComponents/text/textStyles'
import { ChatPageAvatar } from '../ChatPageAvatar'
import { TUserStatus } from '../../../../common/types'
import { userStatusToText } from '../../../../utils/userStatusToText'

type ChatPageCompanionDataProps = {
  companionName: string
  interlocutorAvatarUrl?: string
  status: TUserStatus
}

const ChatPageCompanionData = ({ companionName, interlocutorAvatarUrl, status }: ChatPageCompanionDataProps) => {
  const statusText = userStatusToText(status)

  return (
    <DivFlexRow style={styles.companionData}>
      <ChatPageAvatar avatar={interlocutorAvatarUrl} />
      <DivFlexColumn style={styles.companionTextBlock}>
        <Text16White style={styles.companionName}>Диалог с {companionName}</Text16White>
        {status === 'online' ? (
          <Text14Green style={styles.companionStatus}>{statusText}</Text14Green>
        ) : status === 'game' ? (
          <Text14Green style={styles.companionStatus}>{statusText}</Text14Green>
        ) : (
          <Text14Grey style={styles.companionStatus}>{statusText}</Text14Grey>
        )}
      </DivFlexColumn>
    </DivFlexRow>
  )
}

export { ChatPageCompanionData }
