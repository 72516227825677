import styles from './Catalog.module.css'

import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { ShopBarHeader } from '../../ui/ShopBar/ShopBarHeader'
import { SearchFilterBig } from 'components/ui/SearchFilterBig'
import { Text24White } from 'components/styledComponents/text/textStyles'
import { HorizontalSlider } from 'components/ui/HorizontalSlider'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ShopBarBody } from '../../ui/ShopBar/ShopBarBody'
import { useEffect, useRef, useState } from 'react'
import { IPlatformCategory } from 'store/platformConfigStore/types'
import { observer } from 'mobx-react-lite'
import { ShopBarModal } from 'components/ui/modals/ShopBarModal'
import { ShopBarCategory } from 'components/ui/ShopBar/ShopBarCategory'
import { ShopBarList } from 'components/ui/ShopBar/ShopBarList'
import { Pagination } from 'components/ui/Pagination'
import { scrollToItemTop } from 'utils/pageScrollers'
import { ShopBarListAlert } from 'components/ui/ShopBar/ShopBarList/ShopBarListAlert'
import { ShopBarCard } from 'components/ui/ShopBar/ShopBarCard'
import { userInfoStore } from 'store/userInfoStore'
import { loadCatalog } from '../../../action/loadCatalog'
import { catalogStore } from '../../../store/catalog/catalogStore'
import { userPlatformConfig } from '../../../store/platformConfigStore/platformConfigStore'
import { TSearchParamsJsonTypesValue } from '../../../utils/searchParams'
import { PlatformConfigSort } from '../../../api/platform/platform.type'
import { loadingStore } from '../../../store/loadingStore'
import { IGetResCatalogListItem } from '../../../api/catalog/catalog.type'

const Catalog = observer(() => {
  const { items } = catalogStore.catalogItems
  const { take, totalCount, currentPage } = catalogStore.catalogItems.pagination
  const {
    sortSelectedItem,
    sortListItem,
    paginationTake,
    filters,
    searchWords,
    valueFilters,
    isFiltersOpen,
    sortSelectedOrderBy,
    sortListOrderBy,
    textBySearch,
    setPaginationSkip,
    setCategoryIdBySearch,
    removeFiltersValue,
    setFilterValue,
    setInputTextBySearch,
    setFilterOpen,
    setFiltersList,
    setSortsList,
    setSortItem,
    setSortItemOrderBy,
    saveTextBySearchToStore
  } = catalogStore
  const { isVip } = userInfoStore
  const { getFiltersByLabelCategory, getSortsByLabelCategory } = userPlatformConfig
  const { isLoadingItems } = loadingStore

  useEffect(() => {
    loadCatalog()
  }, [])

  const [isModal, setModal] = useState<boolean>(false)
  const [cardData, setCardData] = useState<IGetResCatalogListItem>({
    id: '',
    name: '',
    price: '',
    currency: '',
    advertisementCurrency: '',
    description: '',
    propertyPage: [],
    propertyCard: [],
    sourceCategory: '',
    images: [],
    countAdvertisementBuy: 0,
    countAdvertisementSale: 0,
  })

  const scrollToRef = useRef<any>(null)

  const [baseModalImage, setBaseModalImage] = useState<{
    id: string
    name: string
    image: string
  }>({
    id: '',
    name: '',
    image: ''
  })

  const startLoadCatalogItem = (trigger: {
    searchText?: boolean
    pagination?: boolean
    applyFilters?: boolean
    chooseCategory?: boolean
    chooseServer?: boolean
    orderBy?: boolean
  }) => {
    if (trigger.searchText) {
      setCategoryIdBySearch(undefined)
      setFilterOpen(false)
      setPaginationSkip(0)
      removeFiltersValue()
      setFilterAndSortByLabel('')
    }

    if (trigger.pagination) {
      setFilterOpen(false)
    }

    if (trigger.chooseCategory) {
      setFilterOpen(false)
      setPaginationSkip(0)
      removeFiltersValue()
      setInputTextBySearch('')
    }

    if (trigger.applyFilters) {
      setFilterOpen(false)
      setPaginationSkip(0)
      setInputTextBySearch('')
    }

    if (trigger.chooseServer) {
      setFilterOpen(false)
      setPaginationSkip(0)
      removeFiltersValue()
    }

    if (trigger.orderBy) {
      setFilterOpen(false)
      setPaginationSkip(0)
    }

    scrollToItemTop(scrollToRef)
    return loadCatalog()
  }

  // Установка фильтров и сортировки по label категории
  const setFilterAndSortByLabel = (label: string) => {
    const filters = getFiltersByLabelCategory(label, 'catalog')
    setFiltersList(filters)

    const sorts = getSortsByLabelCategory(label, 'catalog')
    setSortsList(sorts)
  }

  // Открытие модального окна через "Подробнее"
  const handleOpenCardModal = (cardData: IGetResCatalogListItem, baseImage: string) => {
    setCardData(cardData)
    setModal(true)
    const imageData = {
      id: cardData.images[0].id,
      name: cardData.images[0].name
    }
    setBaseModalImage({
      ...baseModalImage,
      id: imageData.id,
      name: imageData.name,
      image: baseImage
    })
  }

  // Event ввод текста в глобальный поиск
  const handleOnChangeInputSearchByText = (text: string) => {
    setInputTextBySearch(text)
  }

  // Запуск глобального поиска
  const handleOnSubmitSearchByText = () => {
    saveTextBySearchToStore()
    startLoadCatalogItem({ searchText: true })
  }

  // Очистка текста в глобальном поиске
  const handleOnInputClear = async () => {
    setInputTextBySearch('')
    startLoadCatalogItem({ searchText: true })
  }

  // Выбор ранее введенного текста
  const handleSliderItemClick = async (itemName: string) => {
    setInputTextBySearch(itemName)
    startLoadCatalogItem({ searchText: true })
  }

  // Пагинация
  const handlePagination = async (skip: number): Promise<void> => {
    setPaginationSkip(skip)
    startLoadCatalogItem({ pagination: true })
  }

  // Смена категории
  const handleChooseCategory = async (category: IPlatformCategory) => {
    if (category.id === 'all') {
      setCategoryIdBySearch(undefined)
      setFilterAndSortByLabel('')
      startLoadCatalogItem({ chooseCategory: true })
    } else if (category.categories.length === 0 && category.label) {
      setCategoryIdBySearch(category.id)
      setFilterAndSortByLabel(category.label)
      startLoadCatalogItem({ chooseCategory: true })
    }
  }

  // Применение фильтров
  const handlerFiltersApply = async () => {
    startLoadCatalogItem({ applyFilters: true })
  }

  // Открыть/Закрыть фильтры
  const handlerFiltersClick = async () => {
    setFilterOpen(!isFiltersOpen)
  }

  // Установить значение фильтра
  const handlerFiltersSetValue = (key: string, data: TSearchParamsJsonTypesValue) => {
    setFilterValue(key, data)
  }

  // Выбрать сервер
  const handleChooseServer = () => {
    startLoadCatalogItem({ chooseServer: true })
  }

  // Выбрать поле сортировки
  const handleSortSelectedItem = async (value: PlatformConfigSort) => {
    setSortItem(value)
    startLoadCatalogItem({ orderBy: true })
  }

  // Выбрать order сортировки
  const handleSortSelectedOrderBy = async (value: PlatformConfigSort) => {
    setSortItemOrderBy(value)
    startLoadCatalogItem({ orderBy: true })
  }

  return (
    <>
      <PageWrapper style={styles.wrapper}>
        <DivFlexColumn style={styles.headerContainer}>
          <ShopBarHeader type="catalog" onChooseServer={handleChooseServer} />

          <DivFlexColumn style={styles.searchContainer}>
            <Text24White style={styles.searchText}> Поиск </Text24White>

            <SearchFilterBig
              inputValue={textBySearch}
              setInputValue={handleOnChangeInputSearchByText}
              cssStylesContainer={styles.searchInput}
              onSubmit={handleOnSubmitSearchByText}
              onClearFunction={handleOnInputClear}
            />

            <HorizontalSlider
              cssStylesContainer={styles.slider}
              items={searchWords}
              onClickItem={handleSliderItemClick}
            />
          </DivFlexColumn>
        </DivFlexColumn>
        <ShopBarBody>
          <DivFlexRow style={styles.shopBarBody}>
            <ShopBarCategory setCategoryData={handleChooseCategory} />

            <div ref={scrollToRef}></div>

            <DivFlexColumn style={styles.itemsBlock}>
              <ShopBarList
                itemsCount={totalCount}
                onClickFiltersApply={handlerFiltersApply.bind(this)}
                onSetValueInFilter={handlerFiltersSetValue}
                onClickFilter={handlerFiltersClick.bind(this)}
                sortListItem={sortListItem}
                sortSelectedItem={sortSelectedItem}
                onSortSelectedItem={handleSortSelectedItem.bind(this)}
                sortListOrderBy={sortListOrderBy}
                sortSelectedOrderBy={sortSelectedOrderBy}
                onSortSelectedOrderBy={handleSortSelectedOrderBy.bind(this)}
                isFilterOpen={isFiltersOpen}
                filters={filters}
                valueFilters={valueFilters}>
                {items.map((item) => {
                  return <ShopBarCard key={item.id} card={item} isVip={isVip} onOpen={handleOpenCardModal} />
                })}
              </ShopBarList>

              {items.length === 0 && !isLoadingItems && <ShopBarListAlert />}

              <Pagination
                changePaginationPage={handlePagination}
                itemsInPage={paginationTake}
                totalCount={totalCount}
                currentPage={currentPage}
                take={take}
                cssBlockStyles={styles.pagination}
              />
            </DivFlexColumn>
          </DivFlexRow>
        </ShopBarBody>
      </PageWrapper>

      {isModal && (
        <ShopBarModal
          isOpen={isModal}
          onClose={setModal.bind(this, false)}
          cardData={cardData}
          baseImage={baseModalImage}
          isCatalog
        />
      )}
    </>
  )
})

export default Catalog
