import styles from './Ellipse.module.css'

type EllipseProps = {
  bgColor?: string
}

const Ellipse = ({ bgColor }: EllipseProps) => {
  return <div className={styles.elipse} style={{ backgroundColor: bgColor }}></div>
}

export { Ellipse }
