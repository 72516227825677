import styles from './HomeCatalogCarInfoContainer.module.css'

import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { informationArray } from './informationArray'
import { Text14Grey, Text14White } from 'components/styledComponents/text/textStyles'

import InfoIcon from '../../../../../icons/button/info-icon.svg'

const HomeCatalogCarInfoContainer = () => {
  return (
    <div className={styles.container}>
      <OvalButton
        text="Подробнее"
        icon={InfoIcon}
        containerStyle={OvalButtonStyle}
        iconStyle={iconStyle}
        isSwapContent
      />

      <div className={styles.infoSection}>
        {informationArray.map((info, index) => {
          const { name, text } = info
          return (
            <div key={index} className={styles.infoContainer}>
              <Text14Grey>{name}</Text14Grey>
              <Text14White>{text}</Text14White>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { HomeCatalogCarInfoContainer }

const OvalButtonStyle = {
  background: '#2D355D',
  width: '144px',
  height: '36px',
  marginTop: '10px',
  gap: '17px'
}

const iconStyle = {
  width: '20px',
  height: '20px'
}
