import { DivFlexRow } from 'components/styledComponents/div/div'
import styles from './GamePhoneInput.module.css'

import Icon from './phone-call.svg'
import { ChangeEvent } from 'react'
import { formatGamePhoneNumber } from 'utils/forms/helpers'

type GamePhoneInputProps = {
  cssContainerStyles?: string
  value: string
  setValue: (value: React.SetStateAction<string>) => void
}

const GamePhoneInput = ({ cssContainerStyles, value, setValue }: GamePhoneInputProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = formatGamePhoneNumber(e.target.value)
    setValue(newValue)
  }

  return (
    <DivFlexRow style={`${styles.container} ${cssContainerStyles}`}>
      <img src={Icon} alt="phone" className={styles.icon} />
      <input className={styles.input} value={value} onChange={onChange} maxLength={9} />
    </DivFlexRow>
  )
}

export { GamePhoneInput }
