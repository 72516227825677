import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import styles from './ReferralCreateLinksPage.module.css'
import { Text16Grey } from 'components/styledComponents/text/textStyles'
import { ReferralPromoCodeCard } from '../ReferralPromoCodeCard'
import { ReferralPromoCodeType, referralStore } from 'store/referralStore/refferralStore'
import { Pagination } from 'components/ui/Pagination'
import { getReferralPromoCodeList } from 'api/referral/referral'
import { observer } from 'mobx-react-lite'

type ReferralCreateLinksPageProps = {
  promoCodes: ReferralPromoCodeType[]
}

const ReferralCreateLinksPage = observer(({ promoCodes }: ReferralCreateLinksPageProps) => {
  const { totalCount, currentPage, take } = referralStore.promoCodeList.pagination

  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexRow style={styles.header}>
        <Text16Grey style={styles.headerText}>Промокод</Text16Grey>
        <Text16Grey style={styles.headerText}>Кол-во регистраций</Text16Grey>
        <Text16Grey style={styles.headerText}>Кол-во оплат</Text16Grey>
        <Text16Grey style={styles.headerText}>Дата создания</Text16Grey>
        <Text16Grey style={styles.headerText}>Управление</Text16Grey>
      </DivFlexRow>

      <DivFlexColumn style={styles.list}>
        {!!promoCodes.length &&
          promoCodes.map((promoCode) => {
            return <ReferralPromoCodeCard key={promoCode.id} promoCodeData={promoCode} />
          })}
      </DivFlexColumn>

      <Pagination
        totalCount={totalCount}
        currentPage={currentPage}
        take={take}
        itemsInPage={10}
        changePaginationPage={getReferralPromoCodeList}
        cssBlockStyles={styles.pagination}
      />
    </DivFlexColumn>
  )
})

export { ReferralCreateLinksPage }
