import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'
import AuthModal from '../AuthModal/AuthModal'
import { useEffect, useState } from 'react'
import { SubscriptionPaymentModal } from '../SubscriptionPaymentModal'
import { SubscriptionPaymentStatusModal } from '../SubscriptionPaymentModal/SubscriptionPaymentStatusModal'
import {
  ReferralTransactionDataType,
  ReferralTransactionStatus,
  getPaymentReferralTransactionData,
  paymentSubscriptionPay
} from 'api/payment/payment'
import { getUserInfo } from 'api/user/userProfileData'
import errorAlerts from '../../../../store/errorAlertsStore'
import { MessageType } from '../../errors/AlertError'

type SubscriptionPaymentModalsContainerProps = {
  onClose: () => void
  inModal?: boolean
}

const SubscriptionPaymentModalsContainer = observer(
  ({ onClose, inModal = false }: SubscriptionPaymentModalsContainerProps) => {
    const { isAuth } = userInfoStore

    const [isAuthModal, setAuthModal] = useState<boolean>(false)
    const [isSubModal, setSubModal] = useState<boolean>(false)
    const [isStatusModal, setStatusModal] = useState<boolean>(true)

    const [transaction, setTransaction] = useState<ReferralTransactionDataType | null | undefined>(null)
    const [subscriptionName, setSubscriptionName] = useState<string>('')
    const [respnseInterval, setRsponseInterval] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {
      if (isAuth) {
        setSubModal(true)
      }
      if (!isAuth) {
        setAuthModal(true)
      }
    }, [isAuth])

    const handleSubmitTransactionForm = async (
      subscriptionId: string,
      subscriptionName: string,
      promoCode?: string
    ) => {
      const code = promoCode ? promoCode : ''
      const result = await paymentSubscriptionPay(subscriptionId, code)

      if (result && result.subscriptionAdded) {
        errorAlerts.addError({
          errorType: MessageType.Success,
          errorText: 'Подписка успешно активирована!'
        })
        handleCloseModal()
        getUserInfo()
        return
      }
      if (result && result.confirmationUrl) {
        const transactionData = await getPaymentReferralTransactionData(result.transactionId)
        setTransaction(transactionData)
        setSubscriptionName(subscriptionName)

        window.open(result.confirmationUrl, '_blank')

        setSubModal(false)
        setStatusModal(true)

        const checkTransactionStatus = async () => {
          // статус отслеживаем в useEffect ниже
          const transactionData = await getPaymentReferralTransactionData(result.transactionId)
          setTransaction(transactionData)
        }

        // Проверяем статус транзакции каждые 5 секунд
        const newInterval = setInterval(checkTransactionStatus, 5000)
        setRsponseInterval(newInterval)
        return
      }
    }

    const handleCloseModal = () => {
      if (respnseInterval) {
        clearInterval(respnseInterval)
        setRsponseInterval(null)
      }

      setAuthModal(false)
      setSubModal(false)
      setStatusModal(false)
      onClose()
    }

    const returnToPaymenModal = () => {
      setAuthModal(false)
      setStatusModal(false)
      setSubModal(true)
    }

    useEffect(() => {
      // отслеживаем статус и выполняем условия
      if (transaction?.status !== ReferralTransactionStatus.Pending && respnseInterval) {
        clearInterval(respnseInterval)
        if (transaction?.status === ReferralTransactionStatus.Succeeded) {
          getUserInfo()
        }
      }
    }, [transaction?.status, respnseInterval])

    return (
      <>
        {isSubModal && (
          <SubscriptionPaymentModal
            isOpen={isSubModal}
            onClose={handleCloseModal}
            inModal={inModal}
            onSubmit={handleSubmitTransactionForm}
          />
        )}
        {isStatusModal && (
          <SubscriptionPaymentStatusModal
            isOpen={isStatusModal}
            onClose={handleCloseModal}
            transaction={transaction}
            subscriptionName={subscriptionName}
            returnToPaymenModal={returnToPaymenModal}
          />
        )}
        {isAuthModal && <AuthModal isOpen={isAuthModal} onClose={handleCloseModal} inModal={inModal} />}
      </>
    )
  }
)

export { SubscriptionPaymentModalsContainer }
