import { ReactNode } from 'react'
import styles from './AlertErrorWrapper.module.css'

type AlertErrorWrapperProps = {
  children: ReactNode
}

const AlertErrorWrapper = ({ children }: AlertErrorWrapperProps) => {
  return <div className={styles.container}>{children}</div>
}

export default AlertErrorWrapper
