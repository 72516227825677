import styles from './TransactionStatusModal.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Grey, Text14White, Text32White } from 'components/styledComponents/text/textStyles'
import CloseIcon from '../../../icons/cross_purple.svg'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { getTransactionsStatusColor, getTransactionsStatusText } from 'components/ui/ProfileSettings/helpers'
import { ModalWrapper } from 'components/ui/wrappers/ModalWrapper'
import { userSettingTransactionType } from 'api/userSettings/userTransactions'
import { formatDate } from 'utils/dateFormated'

type TransactionStatusModalProps = {
  isOpen: boolean
  onClose: () => void
  transactionData: userSettingTransactionType
}

const TransactionStatusModal = ({ isOpen, onClose, transactionData }: TransactionStatusModalProps) => {
  const { name, description, reasonCanceled, status, amount, createdAt } = transactionData

  const statusText = getTransactionsStatusText(status)
  const statusColor = getTransactionsStatusColor(status)

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <DivFlexColumn style={styles.modal}>
        <DivFlexRow style={styles.header}>
          <Text32White>{name}</Text32White>
          <img src={CloseIcon} alt="close" className={styles.cross} onClick={onClose} />
        </DivFlexRow>

        <HorizontalDivider style={{ margin: '24px auto 22px', width: '100%' }} />

        <DivFlexColumn style={styles.list}>
          <DivFlexRow style={styles.listItem}>
            <Text14Grey style={styles.listDataName}>Дата транзакции:</Text14Grey>
            <Text14White style={styles.listDataText}>{formatDate(createdAt)}</Text14White>
          </DivFlexRow>

          <DivFlexRow style={styles.listItem}>
            <Text14Grey style={styles.listDataName}>Описание:</Text14Grey>
            <Text14White style={styles.listDataText}>{description}</Text14White>
          </DivFlexRow>

          <DivFlexRow style={styles.listItem}>
            <Text14Grey style={styles.listDataName}>Сумма:</Text14Grey>
            <Text14White style={styles.listDataText}>{amount} руб</Text14White>
          </DivFlexRow>

          <DivFlexRow style={styles.listItem}>
            <Text14Grey style={styles.listDataName}>Статус операции:</Text14Grey>
            <p className={styles.statusText} style={{ color: statusColor.color }}>
              {statusText}
            </p>
          </DivFlexRow>

          {reasonCanceled && (
            <DivFlexRow style={styles.listItem}>
              <Text14Grey style={styles.listDataName}>Причина отмены:</Text14Grey>
              <Text14White style={styles.listDataText}>{reasonCanceled}</Text14White>
            </DivFlexRow>
          )}
        </DivFlexColumn>

        <HorizontalDivider style={{ margin: '24px auto', width: '100%' }} />

        <OvalButton text="Понятно" cssContainerStyle={styles.button} onClick={onClose} />
      </DivFlexColumn>
    </ModalWrapper>
  )
}

export { TransactionStatusModal }
