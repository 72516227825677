import styles from './ChatPageHeaderBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ChatPageBackButton } from 'components/ui/buttons/ChatPageBackButton'
import { ChatPageCompanionData } from '../ChatPageCompanionData'
import { CallButton } from 'components/ui/buttons/CallButton'
import { ChatPageDropdown } from 'components/ui/dropdowns/ChatPageDropdown'
import { TUserStatus } from '../../../../common/types'
import { useState } from 'react'
import { getUserGamePhone } from '../../../../api/userSettings/userGamePhones'

type ChatPageHeaderBlockProps = {
  status: TUserStatus
  companionName: string
  interlocutorAvatarUrl?: string
  gamePhoneId?: string
}

const ChatPageHeaderBlock = ({
  status,
  companionName,
  interlocutorAvatarUrl,
  gamePhoneId
}: ChatPageHeaderBlockProps) => {
  const [isActivePhone, setActivePhone] = useState<boolean>(false)
  const handleCopyPhone = async () => {
    if (gamePhoneId) {
      const userPhone = await getUserGamePhone(gamePhoneId)
      if (userPhone) {
        navigator.clipboard
          .writeText(userPhone.number)
          .then(() => {
            console.log('Телефон скопирован в буфер обмена: ', userPhone)
            setActivePhone(true)

            setTimeout(() => {
              setActivePhone(false)
            }, 2000)
          })
          .catch((err) => {
            console.error('Ошибка при копировании текста: ', err)
            setActivePhone(false)
          })
      }
    }
  }

  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexRow style={styles.chatData}>
        <div className={styles.chatDataSection}>
          <ChatPageBackButton />
          <ChatPageCompanionData
            companionName={companionName}
            interlocutorAvatarUrl={interlocutorAvatarUrl}
            status={status}
          />
        </div>

        <div className={styles.chatDataSection}>
          {gamePhoneId && <CallButton handleClick={handleCopyPhone} isActiveCopy={isActivePhone} />}
          {/*<ChatPageDropdown />*/}
        </div>
      </DivFlexRow>

      <DivFlexRow style={styles.productData}></DivFlexRow>
    </DivFlexColumn>
  )
}

export { ChatPageHeaderBlock }
