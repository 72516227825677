import styles from './HomeNotificationsSection.module.css'

import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'
import { BackFilter } from '../../components/BackFilter'
import { HomeNotificationsMainTextContainer } from './HomeNotificationsMainTextContainer'
import { HomeNotificationsSocialContainer } from './HomeNotificationsSocialContainer'
import { HomeNotificationsMessageContainer } from './HomeNotificationsMessageContainer'

import Back from './back-filter.svg'

const HomeNotificationsSection = () => {
  return (
    <section className={styles.section}>
      <BackFilter backContainer={Back} cssStyle={styles.back} />

      <div style={{ zIndex: 2, position: 'relative', pointerEvents: 'none' }}>
        <HomeNotificationsMainTextContainer />

        <HomeNotificationsSocialContainer />

        <HomeNotificationsMessageContainer />
      </div>

      <PurpleNeonButton text="Создать объявление" cssStyle={styles.button} />
    </section>
  )
}

export { HomeNotificationsSection }
