import styles from './ProfileSettingsPageBar.module.css'

import { ProfileSettingsBarButton } from 'components/ui/ProfileSettings/ProfileSettingsBarButton'
import { useState } from 'react'
import { DivFlexColumn } from 'components/styledComponents/div/div'

import UserIcon from '../../../icons/profileSettings/user-circle-2.svg'
import AccountsIcon from '../../../icons/profileSettings/circuit-board.svg'
import CallIcon from '../../../icons/profileSettings/phone-call.svg'
import TransactionsIcon from '../../../icons/profileSettings/arrow-down-up.svg'

import UserIconPurple from '../../../icons/profileSettings/user-circle-2_purple.svg'
import AccountsIconPurple from '../../../icons/profileSettings/circuit-board_purple.svg'
import CallIconPurple from '../../../icons/profileSettings/phone-call_purple.svg'
import TransactionsIconPurple from '../../../icons/profileSettings/arrow-down-up_purple.svg'

const buttons: { text: string; icon: string; activeIcon: string }[] = [
  /*
  RPMARKET-192: [BE/FE] Выдавать всем подписку автоматически
  { text: 'Профиль и подписка', icon: UserIcon, activeIcon: UserIconPurple }
  */
  { text: 'Профиль', icon: UserIcon, activeIcon: UserIconPurple },
  { text: 'Привязанные аккаунты', icon: AccountsIcon, activeIcon: AccountsIconPurple },
  { text: 'Игровые номера', icon: CallIcon, activeIcon: CallIconPurple },
  { text: 'Транзакции', icon: TransactionsIcon, activeIcon: TransactionsIconPurple }
]

type ProfileSettingsPageBarProps = {
  handleClick: (pageName: string) => void
}

const ProfileSettingsPageBar = ({ handleClick }: ProfileSettingsPageBarProps) => {
  const [activeButton, setActiveButton] = useState<string>(buttons[0].text)

  const handleClickButton = (name: string) => {
    setActiveButton(name)
    handleClick(name)
  }

  return (
    <DivFlexColumn style={styles.container}>
      {buttons.map((item, index) => {
        const { text, icon, activeIcon } = item
        return (
          <ProfileSettingsBarButton
            key={index}
            text={text}
            src={text === activeButton ? activeIcon : icon}
            isActive={text === activeButton}
            onClick={handleClickButton.bind(this, text)}
          />
        )
      })}
    </DivFlexColumn>
  )
}

export { ProfileSettingsPageBar }
