import styles from './CopyButtonWithText.module.css'
import { useState } from 'react'

import EpLinkIcon from './icons/copy_icon.svg'
import CheckIcon from './icons/check_icon.svg'
import { DivFlexRow } from 'components/styledComponents/div/div'

type CopyButtonWithTextProps = {
  cssContainerStyles?: string
  onClick: () => void
}

const CopyButtonWithText = ({ cssContainerStyles, onClick }: CopyButtonWithTextProps) => {
  const [isCopy, setCopy] = useState<boolean>(false)

  const handleCopy = () => {
    onClick()
    setCopy(true)
  }

  const returnInActiveStale = () => {
    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }

  return (
    <button
      className={`${styles.button} ${cssContainerStyles}`}
      onClick={handleCopy}
      onMouseLeave={returnInActiveStale}
    >
      {isCopy ? (
        <DivFlexRow style={styles.status}>
          <img src={CheckIcon} alt="copy icon" className={styles.icon} />
          <p className={styles.text}>Скопировано</p>
        </DivFlexRow>
      ) : (
        <img src={EpLinkIcon} alt="copy icon" className={styles.icon} />
      )}
    </button>
  )
}

export { CopyButtonWithText }
