import styles from './ReferralPromoCodeCard.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'

import { Text14White } from 'components/styledComponents/text/textStyles'
import { useState } from 'react'
import { CopyButtonWithText } from 'components/ui/buttons/CopyButtonWithText'
import ChainIcon from '../../../icons/referral/chain.svg'
import TrashIconRed from '../../../icons/trash_base_red.svg'
import TrashIconGrey from '../../../icons/trash_base_grey.svg'
import { ReferralPromoCodeType } from 'store/referralStore/refferralStore'
import Loader from 'components/ui/loaders/Loader/Loader'
import { deleteReferralPromoCode, getReferralPromoCodeList } from 'api/referral/referral'
import { formatDate } from 'utils/dateFormated'

type ReferralPromoCodeCardProps = {
  promoCodeData: ReferralPromoCodeType
}

const ReferralPromoCodeCard = ({ promoCodeData }: ReferralPromoCodeCardProps) => {
  const { id, name, countRegister, countPay, createdAt } = promoCodeData

  const [isHover, setHover] = useState<boolean>(false)

  const [isLoader, setLoader] = useState<boolean>(false)

  const handleDeleteCard = async (cardId: string) => {
    setLoader(true)
    await deleteReferralPromoCode(cardId)
    await getReferralPromoCodeList()
    setLoader(false)
  }

  const handleCopyPomoCode = () => {
    const protocol = window.location.protocol
    const host = window.location.hostname
    const port = window.location.port

    const promoCodeLink = port ? `${protocol}//${host}:${port}/?ref=${name}` : `${protocol}//${host}/?ref=${name}`

    navigator.clipboard
      .writeText(promoCodeLink)
      .then(() => {
        console.log('Текст скопирован в буфер обмена: ', promoCodeLink)
      })
      .catch((err) => {
        console.error('Ошибка при копировании текста: ', err)
      })
  }

  if (isLoader) {
    return (
      <div className={styles.card}>
        <Loader anyStyles={{ margin: 'auto' }} />
      </div>
    )
  }

  return (
    <div className={styles.card} onMouseEnter={setHover.bind(this, true)} onMouseLeave={setHover.bind(this, false)}>
      <DivFlexRow style={styles.nameBlock}>
        <img src={ChainIcon} alt="link icon" className={styles.chainIcon} />
        <Text14White style={styles.name}>{name}</Text14White>
      </DivFlexRow>

      <DivFlexRow style={styles.textBlock}>
        <Text14White style={styles.registerCount}>{countRegister}</Text14White>
      </DivFlexRow>

      <DivFlexRow style={styles.textBlock}>
        <Text14White style={styles.paymenCount}>{countPay}</Text14White>
      </DivFlexRow>

      <DivFlexRow style={styles.textBlock}>
        <Text14White style={styles.createDate}>{formatDate(createdAt)}</Text14White>
      </DivFlexRow>

      <DivFlexRow style={styles.buttonsBlock}>
        <CopyButtonWithText onClick={handleCopyPomoCode} cssContainerStyles={styles.copyButton} />
        <img
          className={styles.trashIcon}
          src={isHover ? TrashIconRed : TrashIconGrey}
          alt="trash icon"
          onClick={handleDeleteCard.bind(this, id)}
        />
      </DivFlexRow>
    </div>
  )
}

export { ReferralPromoCodeCard }
