import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'

export const Offer = () => {
    return (
        <PageWrapper>
            <div>
                Договор публичной оферты об оказании услуг <br/>
                <br/>
                ИП Ковальчук Артур Вячеславович ИНН 366324411128 ОГРНИП: 322619600009200 (далее — Исполнитель) предлагает договор публичной оферты для физических и юридических лиц (далее — Заказчик) о нижеследующем.<br/>
                <br/>
                1. Термины и определения<br/>
                1.1 Оферта — документ «Договор публичной оферты об оказании услуг», опубликованный  на сайте Исполнителя https://rp.market<br/>
                1.2. Договор Оферты — договор между Исполнителем и Заказчиком об оказании услуг, который заключается через Акцепт Оферты.<br/>
                1.3. Акцепт Оферты — принятие Оферты Заказчиком, когда он обращается к Исполнителю за оказанием услуг.  Акцептом Оферты считается нажатие Заказчиком кнопки «Оплатить» под сформированным заказом на сайте https://rp.market.<br/>
                1.4. Тарифы — перечень услуг Исполнителя с ценами, указанными в Прайс-листе на сайте https://rp.market/subscription.<br/>
                1.5. Заказчик — лицо, совершившее Акцепт Оферты.<br/>
                1.6. Исполнитель — ИП Ковальчук Артур Вячеславовчи.<br/>
                1.7. Договор не требует скрепления печатями и/или подписания Заказчиком и Исполнителем.<br/>
                1.8 Торговая площадка – текущий сайт на котором находится пользователь.<br/>
                2. Общие положения<br/>
                2.1 Настоящая Оферта определяет условия предоставления доступа к дополнительным функциям торговой площадки при оплате подписки.<br/>
                2.2 Пользователь, принимая настоящую Оферту, подтверждает, что ознакомился со всеми условиями, положениями и требованиями, изложенными в данной Оферте, и соглашается с ними.<br/>
                3. Предмет договора<br/>
                3.1 Администрация Сайта предоставляет возможность Пользователю получить доступ к дополнительным функциям Сайта при оплате подписки.<br/>
                3.2 Подписка является платной и может быть приобретена на один или несколько предопределенных периодов времени, указанных на Сайте.<br/>
                4. Срок действия договора<br/>
                4.1. Договор вступает в силу со дня акцепта заказчиком и действует до окончания действия подписки.<br/>
                5. Стоимость работ и порядок расчетов<br/>
                5.1. Стоимость услуг определяется в соответствии с действующими ценами и прописана на сайте https://rp.market/subscription.<br/>
                5.2. Оплата производится в рублях с помощью карты или иным способом предусмотренным платежной системой установленной на https://rp.market.<br/>
                6. Права и обязанности сторон<br/>
                6.1. Исполнитель обязуется:<br/>
                6.1.1. Предоставить Пользователю доступ к дополнительным функциям Сайта при оплате подписки, в соответствии с условиями, изложенными в данной Оферте.<br/>
                6.2. Исполнитель имеет право:<br/>
                6.2.1 Получать от Заказчика необходимые данные и информацию для оказания услуг и полагаться на них без дополнительной проверки.<br/>
                6.2.2 Аннулировать подписку по своему усмотрению без объяснения причины.<br/>
                6.2.3 В одностороннем порядке вносить изменения в действующую оферту без предварительного уведомления заказчика.<br/>
                6.3 Пользователь обязуется:<br/>
                6.3.1  Оплатить подписку на Сайте в соответствии с указанными ценами и периодами.<br/>
                6.3.2 Соблюдать правила использования Сайта и дополнительных функций, предоставляемых по подписке.<br/>
                6. Основания и порядок расторжения Договора<br/>
                6.1 После оформления и оплаты заказа  Заказчик имеет право отказаться от услуг Исполнителя и запросить возврат денег, направив письменное уведомление с темой письма «Отказ от предоставления услуг» на адрес электронной почты support@rp.market.<br/>
                6.2 Исполнитель вправе удержать из уплаченной Заказчиком суммы стоимость уже оказанных услуг на момент получения уведомления об отказе.<br/>
                6.3 Возврат производится на платежное средство с которого была совершена оплата.<br/>
                7. Разрешение споров<br/>
                6.1. Споры и разногласия по вопросам исполнения Договора разрешаются на переговорах.<br/>
                6.2. Если на переговорах Стороны не смогут достичь согласия, спор передается в суд.<br/>
                <br/>
                Контакты для связи:<br/>
                - Техническая поддержка: support@rp.market<br/>
                - Чат в правом нижнем углу сайт<br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </PageWrapper>
    )
}
