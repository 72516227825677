import styles from './AdvertisementCreateDataMain.module.css'

import { DivFlexRow, DivFlexColumn } from 'components/styledComponents/div/div'
import { Text32White, Text16Green, Text16Grey } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { HandleCountInput } from 'components/ui/inputs/HandleCountInput'
import { PriceInputWithIcon } from 'components/ui/inputs/PriceInputWithIcon'
import { ShopBarGallery } from 'components/ui/modals/ShopBarModal/ShopBarGallery'
import { RefObject, useEffect, useState } from 'react'

import { getCardImage } from 'api/catalog/catalog.method'
import { ProductBigImage } from 'components/ui/image/ProductBigImage'
import { AdvertisementCreateForm } from 'api/advetrisement/create/advetrisementCreate'
import { formatPrice } from '../../../../utils/formatPrice'
import { convertCurrencyToText } from '../../../../utils/convert'

type Image = {
  id: string
  name: string
  image?: string
}

type AdvertisementCreateDataMainProps = {
  choseItemName: string
  choseItemSub: string
  choseItemCatalogPrice?: number
  choseItemCatalogCurrency?: string
  choseItemAdvertisementCurrency: string
  choseItemImages: { id: string; name: string }[]
  isCounter: boolean
  submitForm: AdvertisementCreateForm
  setSubmitForm: React.Dispatch<React.SetStateAction<AdvertisementCreateForm>>
  isError: boolean
  scrollToPrice: RefObject<HTMLDivElement>
}

const AdvertisementCreateDataMain = ({
  choseItemName,
  choseItemSub,
  choseItemImages,
  choseItemCatalogPrice,
  choseItemCatalogCurrency,
  choseItemAdvertisementCurrency,
  isCounter,
  submitForm,
  setSubmitForm,
  isError,
  scrollToPrice
}: AdvertisementCreateDataMainProps) => {
  const status = submitForm.type === 'sale' ? 'Продажа' : 'Покупка'

  const [priceInput, setPriceInput] = useState<string>('')

  const [isFirstImageLoading, setFirstImageLoading] = useState<boolean>(false)

  const [loadedImages, setLoadedImages] = useState<Image[]>([])
  const [activeImage, setActiveImage] = useState<Image>({
    id: '',
    name: '',
    image: ''
  })

  const onChangeCount = (count: number) => {
    setSubmitForm({ ...submitForm, count })
  }

  useEffect(() => {
    // обновляем в форме цену
    setSubmitForm((prevSubmitForm) => ({
      ...prevSubmitForm,
      price: Number(priceInput.replace(/\./g, ''))
    }))
  }, [priceInput, setSubmitForm])

  useEffect(() => {
    // обновляем в форме id фотки
    setSubmitForm((prevSubmitForm) => ({
      ...prevSubmitForm,
      itemImageId: activeImage?.id
    }))
  }, [activeImage?.id, setSubmitForm])

  useEffect(() => {
    // подгружаем главную картинку
    // TODO этот хук и хук ниже, переиспользуется 3 раза, вынести в отдельный блок
    ;(async () => {
      if (choseItemImages[0]) {
        const image = choseItemImages[0]
        const firstImage = await getCardImage(image.id)
        const newImage = { id: image.id, name: image.name, image: firstImage }
        const fakeImages = Array.from(choseItemImages.slice(1))
        setActiveImage(newImage)
        setLoadedImages([newImage, ...fakeImages])
        setFirstImageLoading(true)
      }
    })()
  }, [choseItemImages])

  useEffect(() => {
    // подгружаем остальные фото
    if (isFirstImageLoading) {
      const loadImages = async () => {
        const loadedImagePromises = choseItemImages.slice(1).map(async (image) => {
          const getImage = await getCardImage(image.id)
          return { id: image.id, name: image.name, image: getImage }
        })

        const loadedAllImages = await Promise.all(loadedImagePromises)

        // Обновляем массив loadedImages с учетом совпадающих id
        // это потому что мы хотим на каждой картинке крутилку пока она грузится...
        setLoadedImages((prev) => {
          // Создаем копию массива loadedImages
          const updatedLoadedImages = [...prev]
          loadedAllImages.forEach((loadedImage) => {
            // Проверяем, есть ли объект с таким же id в текущем массиве
            const index = updatedLoadedImages.findIndex((image) => image.id === loadedImage.id)

            if (index !== -1) {
              // Обновляем объект, добавляя ключ image
              updatedLoadedImages[index].image = loadedImage.image
            } else {
              // Добавляем новый объект с ключом image
              updatedLoadedImages.push(loadedImage)
            }
          })
          return updatedLoadedImages
        })
      }

      loadImages()
    }
  }, [isFirstImageLoading, choseItemImages])

  const getPlaceHolderInputAmount = () => {
    if (!choseItemCatalogPrice || !choseItemCatalogCurrency) {
      return '1.000.000 $'
    }

    return String(formatPrice(choseItemCatalogPrice)) + ' ' + convertCurrencyToText('advertisementCreateInputPlaceHolder', choseItemCatalogCurrency)
  }

  return (
    <DivFlexRow style={styles.bodyHeader}>
      {activeImage && (
        <ProductBigImage width={372} height={372} image={activeImage.image} cssBlockStyle={styles.image} />
      )}

      <DivFlexColumn style={styles.bodyData}>
        <DivFlexRow style={styles.bodyDataHeader}>
          <Text32White style={styles.bodyDataHeaderText}>{choseItemName}</Text32White>

          <DivFlexRow style={styles.bodyDataHeaderStatus}>
            <Text16Green>{status}</Text16Green>
          </DivFlexRow>
        </DivFlexRow>

        <Text16Grey style={styles.bodyDataHeaderSub}>{choseItemSub}</Text16Grey>

        <HorizontalDivider cssStyle={styles.headerDivider} />

        <DivFlexRow style={styles.bodyDataInputs}>
          <DivFlexColumn style={styles.bodyDataInputsPriceBlock}>
            <Text16Grey style={styles.bodyDataPrice}>{convertCurrencyToText('advertisementCreateInputLabel', choseItemAdvertisementCurrency)}</Text16Grey>
            <span ref={scrollToPrice} />
            <PriceInputWithIcon
              value={priceInput}
              onChange={setPriceInput}
              isValid={isError}
              placeHolder={getPlaceHolderInputAmount()}
            />
          </DivFlexColumn>

          {isCounter && (
            <DivFlexColumn style={styles.bodyDataInputsCountBlock}>
              <Text16Grey>Количество, ед </Text16Grey>
              <HandleCountInput min={1} max={9999999} onChange={onChangeCount} isInput />
            </DivFlexColumn>
          )}
        </DivFlexRow>

        {activeImage && (
          <ShopBarGallery
            headerText="Выберите вариант расцветки"
            loadedImages={loadedImages}
            activeImage={activeImage}
            setActiveImage={setActiveImage}
            cssContainer={styles.headerGalerry}
            cssDividerStyles={styles.galleryDivider}
            isHiddenName
          />
        )}
      </DivFlexColumn>
    </DivFlexRow>
  )
}

export { AdvertisementCreateDataMain }
