import styles from './ModalWrapper.module.css'

import { ReactNode, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

type ModalWrapperProps = {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  isPortal?: boolean
  inModal?: boolean
  marginTop?: number
}

const ModalWrapper = ({ children, isOpen, onClose, isPortal, inModal = false, marginTop = 40 }: ModalWrapperProps) => {
  const childrenRef = useRef<HTMLDivElement>(null)

  const [showBottomPadding, setShowBottomPadding] = useState(true)

  useEffect(() => {
    if (!inModal) {
      // Функция для отключения скролла
      const disableScroll = () => {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

        document.documentElement.style.overflow = 'hidden'
        document.body.style.overflow = 'hidden'
        document.body.style.paddingRight = `${scrollbarWidth}px`
      }

      // Функция для включения скролла
      const enableScroll = () => {
        document.documentElement.style.overflow = 'auto'
        document.body.style.overflow = 'auto'
        document.body.style.paddingRight = '0'
      }

      // При монтировании компонента
      if (isOpen) {
        disableScroll()
      }

      // При размонтировании компонента
      return () => {
        enableScroll()
      }
    }
  }, [isOpen, inModal])

  const childrenHeight = childrenRef.current?.getBoundingClientRect().height

  useEffect(() => {
    const windowHeight = window.innerHeight

    // добавляем отступы если модальное окно больше по высоте экрана пользователя
    if (childrenRef.current && childrenHeight) {
      setShowBottomPadding(childrenHeight + marginTop > windowHeight)
    }
  }, [marginTop, childrenHeight])

  const handleModalClick = (event: any) => {
    // Останавливаем распространение события, чтобы клик на модальном окне не вызывал закрытие
    event.stopPropagation()
  }

  if (isPortal) {
    return (
      <>
        {ReactDOM.createPortal(
          <div className={styles.wrapper} style={{ visibility: isOpen ? 'visible' : 'hidden' }} onClick={onClose}>
            <div>
              <div className={styles.paddingContainer} style={{ height: marginTop }}></div>

              <div ref={childrenRef} className={styles.childrenContainer} onClick={handleModalClick}>
                {children}
              </div>

              {showBottomPadding && <div className={styles.paddingContainer} style={{ height: marginTop }}></div>}
            </div>
          </div>,
          document.body
        )}
      </>
    )
  }

  return (
    <div className={styles.wrapper} style={{ visibility: isOpen ? 'visible' : 'hidden' }} onClick={onClose}>
      <div>
        <div className={styles.paddingContainer} style={{ height: marginTop }}></div>

        <div ref={childrenRef} className={styles.childrenContainer} onClick={handleModalClick}>
          {children}
        </div>

        {showBottomPadding && <div className={styles.paddingContainer} style={{ height: marginTop }}></div>}
      </div>
    </div>
  )
}

export { ModalWrapper }
