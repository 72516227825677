export const advertisementFakeSystemAlert = `
<div>
<p style="color: #F8D55A;">Если Вы не смогли найти предмет, напишите в тех-поддержку которая находится справа внизу.</p>
</div>
`

export const advertisementFakeSystemAlert2 = `
<div>
<p style="color: #F8D55A;">Вступайте в наш дискорд сервер <a style="color: inherit;" href="https://discord.com/invite/rp-market" target="_blank">discord.com/invite/rp-market</a> для отслеживания новостей</p>
</div>
`
