import { useState, useEffect } from 'react'

const useEmailValidation = (value: string): { isValid: boolean; errorText: string } => {
  const [isValid, setIsValid] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string>('')

  useEffect(() => {
    if (value !== '') {
      if (!/^\S+@(?:\S+\.)?(ru|com|dev\.it-moysha\.ru)$/.test(value)) {
        setIsValid(false)
        setErrorText('Некорректный адрес электронной почты')
      } else {
        setIsValid(true)
        setErrorText('')
      }
    }
  }, [value])

  return { isValid, errorText }
}

export default useEmailValidation
