import styles from './ShopBarTwoLevelButton.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text16White } from 'components/styledComponents/text/textStyles'
import { ImgBase } from 'components/styledComponents/img/img'
import { IPlatformCategory } from 'store/platformConfigStore/types'

import ArrowRight from '../../../../icons/arrow_right_purple.svg'
import ArrowBottom from '../../../../icons/arrow_bottom_purple.svg'

import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getCategoryButtonIcon } from 'utils/shopBar/getCategoryButtonIcon'
import { ShopBarSecondLevelButton } from '../ShopBarSecondLevelButton'

type ShopBarFirstLevelButtonProps = {
  category: IPlatformCategory
  onClick: () => void
  isActive: boolean
  setCategoryData: (category: IPlatformCategory) => void
}

const ShopBarOneLevelButton = observer(
  ({ category, onClick, isActive, setCategoryData }: ShopBarFirstLevelButtonProps) => {
    const [activeCategory, setActiveCategory] = useState<IPlatformCategory | null>(null)
    const buttonImage = getCategoryButtonIcon(isActive, category.name)
    const isShowArrow = category.categories.length > 0

    const handlerClick = (category: IPlatformCategory) => {
      if (activeCategory && activeCategory.id === category.id) {
        setActiveCategory(null)
      } else {
        setActiveCategory(category)
        setCategoryData(category)
      }
    }

    return (
      <>
        <DivFlexRow style={`${styles.button} ${isActive && styles.active}`} onClick={onClick}>
          <ImgBase img={buttonImage} style={styles.icon} />
          <Text16White>{category.name}</Text16White>

          {isShowArrow && <ImgBase img={isActive ? ArrowBottom : ArrowRight} style={styles.arrow} />}
        </DivFlexRow>

        {category.categories && isActive && (
          <DivFlexColumn style={styles.secondLevel}>
            {category.categories.map((category) => {
              return (
                <ShopBarSecondLevelButton
                  key={category.id}
                  category={category}
                  onClick={handlerClick.bind(this, category)}
                  isActive={activeCategory?.id === category.id}
                  setCategoryData={setCategoryData}
                />
              )
            })}
          </DivFlexColumn>
        )}
      </>
    )
  }
)

export { ShopBarOneLevelButton }
