import styles from './ToPlugProButton.module.css'

import Icon from '../../../icons/big_pro_bage.svg'

const text = 'Подключите RP Pro'

type ToPlugProButtonProps = {
  onCLick: () => void
}

const ToPlugProButton = ({ onCLick }: ToPlugProButtonProps) => {
  const handleClick = (e: any) => {
    onCLick()
    e.stopPropagation()
  }

  return (
    <button className={styles.container} onClick={handleClick}>
      <p className={styles.text}>{text}</p>
      <img className={styles.icon} src={Icon} alt="icon"></img>
    </button>
  )
}

export default ToPlugProButton
