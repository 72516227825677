import api from 'api/axios/axiosInstance'
import { MessageType } from 'components/ui/errors/AlertError'
import errorAlerts from 'store/errorAlertsStore'
import { UserGamePhone, userGamePhonesStore } from 'store/profileSettingsStore/gamePhonesStore'

const getUserGamePhone = async (gamePhoneId: string): Promise<UserGamePhone | null> => {
  try {
    const response: UserGamePhone = await api.get(`/api/game-phone/${gamePhoneId}/item`)

    if (response) {
      return response
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

const getUserGamePhones = async () => {
  const { setPhones } = userGamePhonesStore

  try {
    const response: { phones: UserGamePhone[] } = await api.get('/api/game-phone/list')
    setPhones(response.phones)
    console.log(response)
  } catch (error) {
    console.log(error)
  }
}

const addNewUserPhone = async (phone: { number: string; serverId: string }) => {
  try {
    const response: UserGamePhone = await api.post('/api/game-phone/create', phone)
    console.log(response)
    errorAlerts.addError({ errorType: MessageType.Success, errorText: '' })
    return true
  } catch (error) {
    console.log(error)
    errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    return false
  }
}

const deleteCurrentUserPhone = async (phoneId: string) => {
  try {
    await api.delete(`/api/game-phone/${phoneId}/delete`)
    errorAlerts.addError({ errorType: MessageType.Success, errorText: '' })
    return true
  } catch (error) {
    errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    return false
  }
}

export { getUserGamePhone, getUserGamePhones, addNewUserPhone, deleteCurrentUserPhone }
