import styles from './FooterIcon.module.css'

type FooterIconProps = {
  src: string
  href: string
}

export const FooterIcon = ({ src, href }: FooterIconProps) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <img src={src} className={styles.icon} alt="icon"></img>
    </a>
  )
}
