import { makeObservable, observable, action } from 'mobx'

export type FaqType = {
  id: string
  name: string
  body: null | string
  articles: FaqType[] | []
}

class FaqList {
  sections: FaqType[] = []

  constructor() {
    makeObservable(this, {
      sections: observable,
      saveFaqList: action
    })
  }

  saveFaqList = (sections: FaqType[]) => {
    return (this.sections = sections)
  }
}

const faqListStore = new FaqList()

export { faqListStore }
