import { UserAvatar } from 'components/ui/UserAvatar/UserAvatar'
import styles from './UserDataContainer.module.css'
import { userInfoStore } from 'store/userInfoStore'
import { formatUserVipDate } from 'utils/forms/helpers'
import ProBadge from '../../../../icons/userStatus/pro_badge.svg'
import { observer } from 'mobx-react-lite'

const baseStatus = 'Стандартный профиль'

const UserDataContainer = observer(() => {
  const { isVip } = userInfoStore
  const { nickname, subscriptionExpirationDate } = userInfoStore.userData

  const dateFormat = formatUserVipDate(subscriptionExpirationDate)

  return (
    <div className={styles.userData}>
      <UserAvatar containerStyle={{ width: '48px', height: '48px' }} />
      <div className={styles.userDataTextContainer}>
        <p className={styles.userDataName}>{nickname}</p>
        {isVip ? (
          <div className={styles.userDateContainer}>
            <img className={styles.userBadge} src={ProBadge} alt="pro badge"></img>
            <p className={styles.userDataStatus}>до {dateFormat}</p>
          </div>
        ) : (
          <p className={styles.userDataStatus}>{baseStatus}</p>
        )}
      </div>
    </div>
  )
})

export { UserDataContainer }
