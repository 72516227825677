import { ReactNode, useState } from 'react'
import styles from './Button.module.css'

type ButtonProps = {
  text?: string
  hoverText?: string
  onClick?: () => void
  cssButtonStyles?: string
  children?: ReactNode
  fontSize?: number
  isTransparent?: boolean
  cssTextStyle?: string
  textColor?: string
  bgColor?: string
}

const Button = ({
  text,
  hoverText,
  onClick,
  cssButtonStyles,
  children,
  fontSize = 14,
  isTransparent,
  cssTextStyle,
  textColor,
  bgColor
}: ButtonProps) => {
  const [isHover, setHover] = useState<boolean>(false)

  return (
    <button
      className={`${styles.button} ${cssButtonStyles}`}
      onClick={onClick}
      style={{
        background: isTransparent ? 'transparent' : bgColor
      }}
      onMouseEnter={setHover.bind(this, true)}
      onMouseLeave={setHover.bind(this, false)}
    >
      {text && (
        <p
          className={`${cssTextStyle}`}
          style={{
            fontSize: fontSize,
            color: isHover ? hoverText : textColor
          }}
        >
          {text}
        </p>
      )}

      {children && children}
    </button>
  )
}

export default Button
