import { useState, useEffect } from 'react'

const useNicknameValidation = (value: string): { isValid: boolean; errorText: string } => {
  const [isValid, setIsValid] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string>('')

  useEffect(() => {
    if (value !== '') {
      if (value.length < 4) {
        setIsValid(false)
        setErrorText('Минимальная длина nickname - 4 символа')
      } else if (value.length > 10) {
        setIsValid(false)
        setErrorText('Максимальная длина nickname - 10 символов')
      } else if (value.replace(/[^a-zA-Z0-9_]/, '').length !== value.length) {
        setIsValid(false)
        setErrorText('Разрешены англ. буквы, цифры, нижнее подчеркивание')
      } else if (!/[a-zA-Z]/.test(value[0])) {
        setIsValid(false)
        setErrorText('Первый символ должен быть буквой')
      } else if (!/[a-zA-Z0-9]/.test(value[value.length - 1])) {
        setIsValid(false)
        setErrorText('Последний символ должен быть буквой или цифрой')
      } else {
        setIsValid(true)
        setErrorText('')
      }
    }
  }, [value])

  return { isValid, errorText }
}

export default useNicknameValidation
