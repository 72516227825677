import Success from '../../../icons/error_icons/emoji-success.svg'
import Error from '../../../icons/error_icons/emoji-error.svg'
import Danger from '../../../icons/error_icons/emoji-danger.svg'
import styles from './AlertError.module.css'

type AlertErrorProps = {
  error: { errorType: string; errorText: string }
}

export enum MessageType {
  Success = 'success',
  Error = 'error',
  Warning = 'info',
  Clear = ''
}

enum BaseMessageText {
  Success = 'Успех',
  Error = 'Ошибка',
  Warning = 'Предупреждение'
}

const AlertError = ({ error }: AlertErrorProps) => {
  const { errorType, errorText } = error

  return (
    <div className={styles.visible}>
      {errorType === MessageType.Success && (
        <div className={styles.successType}>
          <div className={styles.errorInfoContainer}>
            <p className={styles.errorText}>{errorText ? errorText : BaseMessageText.Success}</p>
            <img src={Success} alt="success" className={styles.icon} />
          </div>
          <div className={styles.loader}>
            <div className={styles.loaderBar}></div>
          </div>
        </div>
      )}
      {errorType === MessageType.Error && (
        <div className={styles.errorType}>
          <div className={styles.errorInfoContainer}>
            <p className={styles.errorText}>{errorText ? errorText : BaseMessageText.Error}</p>
            <img src={Error} alt="error" className={styles.icon} />
          </div>
          <div className={styles.errorLoader}>
            <div className={styles.loaderBar}></div>
          </div>
        </div>
      )}
      {errorType === MessageType.Warning && (
        <div className={styles.warningType}>
          <div className={styles.errorInfoContainer}>
            <p className={styles.errorText}>{errorText ? errorText : BaseMessageText.Warning}</p>
            <img src={Danger} alt="warning" className={styles.icon} />
          </div>
          <div className={styles.warningLoader}>
            <div className={styles.loaderBar}></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AlertError
