import styles from './ProductBigImage.module.css'
import { PhotoLoader } from 'components/ui/loaders/PhotoLoader'

type ProductBigImageProps = {
  onClick?: () => void
  image: string | undefined
  width: number
  height: number
  cssBlockStyle?: string
  isDisable?: boolean
}

const ProductBigImage = ({ image, width, height, cssBlockStyle, isDisable, onClick }: ProductBigImageProps) => {
    const handlerOnClick = () => {
        if (onClick) onClick()
    }

  return (
    <div onClick={handlerOnClick}
      className={`${styles.imageBlock} ${isDisable && styles.iconDisable} ${cssBlockStyle}`}
      style={{ width: width, height: height }}>
      {!image ? (
        <PhotoLoader />
      ) : (
        <img src={image} alt="product" className={styles.image} style={{ width: width, height: height }} />
      )}
    </div>
  )
}

export { ProductBigImage }
