import discord from '../../../icons/social/discord.svg'
import styles from './AuthDiscordButton.module.css'
import { CSSProperties } from 'react'

type AuthDiscordButtonProps = {
  text: string
  anyStyles?: CSSProperties
  submit: () => void
}

const AuthDiscordButton = ({ text, anyStyles, submit }: AuthDiscordButtonProps) => {
  return (
    <button className={styles.button} style={{ ...anyStyles }} onClick={submit}>
      <p className={styles.text}>{text}</p>
      <img src={discord} alt="discord icon" className={styles.icon}></img>
    </button>
  )
}

export default AuthDiscordButton
