import { ReactNode } from 'react'
import styles from './DarkPurpleContainer.module.css'

type DarkPurpleContainerProps = {
  children: ReactNode
  style?: string
}

const DarkPurpleContainer = ({ children, style }: DarkPurpleContainerProps) => {
  return <div className={`${styles.container} ${style}`}>{children}</div>
}

export { DarkPurpleContainer }
