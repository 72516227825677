import { TypeAdvertisement } from '../common/types'
import { ECurrency } from '../common/enum'

export const convertAdvertisementTradeTypeToText = (tradeType: TypeAdvertisement) => {
  switch (tradeType) {
    case 'buy': {
      return 'Покупка'
    }
    case 'sale': {
      return 'Продажа'
    }
  }
}

type TypePage =
  | 'catalogCurrency'
  | 'catalogLabel'
  | 'advertisementCurrency'
  | 'advertisementCreateInputPlaceHolder'
  | 'advertisementCreateInputLabel'
export const convertCurrencyToText = (page: TypePage, currency: string) => {
  const configMap: Record<TypePage, Record<string, string>> = {
    catalogCurrency: {
      [ECurrency.gameDollar]: '$',
      [ECurrency.gameDollarPerHour]: '$',
      [ECurrency.gameBonusPoint]: 'BP'
    },
    catalogLabel: {
      [ECurrency.gameDollar]: 'Цена магазина',
      [ECurrency.gameDollarPerHour]: 'Цена магазина',
      [ECurrency.gameBonusPoint]: 'Bonus Point'
    },
    advertisementCurrency: {
      [ECurrency.gameDollar]: '$',
      [ECurrency.gameDollarPerHour]: '$/час'
    },
    advertisementCreateInputPlaceHolder: {
      [ECurrency.gameDollar]: '$',
      [ECurrency.gameDollarPerHour]: '$ за 1 час',
      [ECurrency.gameBonusPoint]: 'BP'
    },
    advertisementCreateInputLabel: {
      [ECurrency.gameDollar]: 'Цена, $',
      [ECurrency.gameDollarPerHour]: 'Цена, стоимость $ в час',
      [ECurrency.gameBonusPoint]: 'Цена, $'
    }
  }

  return configMap?.[page]?.[currency] ?? 'Не определено'
}

export const convertAdvertisementCurrencyToBtnCatalog = (currency: string) => {
  switch (currency) {
    case ECurrency.gameDollarPerHour:
      return {
        buttonBuy: 'Берут в аренду',
        buttonSale: 'Сдают в аренду'
      }
    case ECurrency.gameDollar:
    default:
      return {
        buttonBuy: 'Покупают',
        buttonSale: 'Продают'
      }
  }
}

export const convertAdvertisementCurrencyToBtnCreateAd = (currency?: string) => {
  switch (currency) {
    case ECurrency.gameDollarPerHour:
      return {
        buttonBuy: 'Взять в аренду',
        buttonSale: 'Сдать в аренду'
      }
    case ECurrency.gameDollar:
    default:
      return {
        buttonBuy: 'Купить',
        buttonSale: 'Продать'
      }
  }
}
