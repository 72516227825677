import api from 'api/axios/axiosInstance'

export type userSettingTransactionType = {
  id: string
  name: string
  description: string
  reasonCanceled: string
  status: 'pending' | 'succeeded' | 'canceled'
  amount: number
  createdAt: string
}

export enum userSettingTransactionStatus {
  Pending = 'pending',
  Succeeded = 'succeeded',
  Canceled = 'canceled'
}

const getUserSettingTransactions = async () => {
  try {
    const response: userSettingTransactionType[] = await api.get('/api/payment/transactions/list')
    return response
  } catch (error) {
    return console.log(error)
  }
}

export { getUserSettingTransactions }
