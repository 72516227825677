import styles from './AuthForm.module.css'
import { useState } from 'react'
import EmailFormInput from '../../inputs/EmailFormInput/EmailFormInput'
import AuthSubmitButton from '../../buttons/AuthSubmitButton/AuthSubmitButton'
import AuthDiscordButton from '../../buttons/AuthDiscordButton/AuthDiscordButton'
import { authEmailLogin, authDiscordOauth2Url } from '../../../../api/auth/auth'
import { StateErrorType } from '../types'
import EmailCodeForm from '../EmailCodeForm/EmailCodeForm'
import useEmailValidation from 'utils/forms/emailValidationHook'
import FormTextBackButton from 'components/ui/buttons/FormTextBackButton/FormTextBackButton'
import errorAlertsStore from '../../../../store/errorAlertsStore'
import { MessageType } from '../../errors/AlertError'

const emailBottomText = 'Введите адрес почты, указанный при регистрации'
const backButtonText = '< Вернуться к окну авторизации'

type AuthFormType = {
  onClose?: () => void
}

const AuthForm = ({ onClose }: AuthFormType) => {
  const [isOpenCodePage, setOpenCodePage] = useState<boolean>(false)

  const [email, setEmail] = useState<string>('')
  const [codeId, setCodeId] = useState<string>('')

  const [isSubmit, setSubmit] = useState<boolean>(false)

  const [emailError, setEmailError] = useState<StateErrorType>({
    text: '',
    isError: false
  })

  const { isValid } = useEmailValidation(email)

  const handlerSubmit = async (e: any) => {
    e.preventDefault()
    if (email && isValid && !emailError.isError) {
      setSubmit(true)
      const result = await authEmailLogin(email)

      if (result.data) {
        setCodeId(result.data.confirmationCodeId)
        setOpenCodePage(true)
        setEmailError({ text: '', isError: false })
      }

      if (result.error) {
        setEmailError({ text: result.error.message, isError: true })
      }

      setSubmit(false)
    }
  }

  const handleDiscordSubmit = async () => {
    setSubmit(true)
    const result = await authDiscordOauth2Url()
    if (result.data) {
      window.location.href = result.data.redirectUrl
    }

    if (result.error) {
      errorAlertsStore.addError({ errorType: MessageType.Error, errorText: result.error.message })
    }
    setSubmit(false)
  }

  // форма с email кодом
  if (isOpenCodePage) {
    return (
      <div className={styles.form}>
        <EmailCodeForm confirmationCodeId={codeId} onCloseModal={onClose} />
        <FormTextBackButton text={backButtonText} onClick={setOpenCodePage.bind(this, false)} />
      </div>
    )
  }

  return (
    <form className={styles.form} onSubmit={handleDiscordSubmit}>
      <EmailFormInput
        value={email}
        onChange={setEmail}
        placeHolder="Ваш e-mail"
        bottomText={emailBottomText}
        backendError={{
          backendText: emailError.text,
          isBackandError: emailError.isError
        }}
        clearError={setEmailError.bind(this, { text: '', isError: false })}
      />

      <AuthSubmitButton
        text="Войти"
        loaderText="Авторизация"
        anyStyles={{ marginTop: '16px' }}
        isSubmit={isSubmit}
        onSubmit={(e: any) => handlerSubmit(e)}
      />

      <AuthDiscordButton
        text="Авторизация с помощью Discord"
        anyStyles={{ margin: '28px auto 0' }}
        submit={handleDiscordSubmit}
      />
    </form>
  )
}

export default AuthForm
