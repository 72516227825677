import User from '../../../icons/new_user.svg'
import UserError from '../../../icons/new_user_error.svg'
import CheckMark from '../../../icons/check-mark_green.svg'
import styles from './NicknameFormInput.module.css'
import { useEffect, useState } from 'react'
import useNicknameValidation from 'utils/forms/nicknameValidationHook'

type NicknameFormInputProps = {
  value: string
  onChange: (value: string) => void
  placeHolder: string
  bottomText: string
  backendError: {
    backendText: string
    isBackandError: boolean
  }
  clearError: () => void
}

const NicknameFormInput = ({
  value,
  onChange,
  placeHolder,
  bottomText,
  backendError: { backendText, isBackandError },
  clearError
}: NicknameFormInputProps) => {
  const { isValid, errorText: validationError } = useNicknameValidation(value)
  const [isEmpty, setEmpty] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string>('')

  useEffect(() => {
    if (isBackandError) {
      setErrorText(backendText)
    } else if (validationError) {
      setErrorText(validationError)
    } else {
      setErrorText('')
    }
  }, [isBackandError, backendText, validationError])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
    setEmpty(event.target.value === '')

    if (isBackandError) {
      clearError()
    }
  }

  const isInvalid = !isValid || isBackandError

  return (
    <>
      <div className={`${styles.container} ${isInvalid && styles.invalid}`}>
        <img src={isValid ? User : UserError} alt="mail" style={{ margin: 'auto' }} />

        <input className={styles.input} value={value} onChange={handleChange} placeholder={placeHolder} />

        <img src={CheckMark} alt="true" style={{ visibility: value && !!value !== isInvalid ? 'visible' : 'hidden' }} />
      </div>

      <p className={`${styles.bottomText} ${isInvalid ? styles.invalid : isEmpty && styles.valid}`}>
        {!!errorText ? errorText : bottomText}
      </p>
    </>
  )
}

export default NicknameFormInput
