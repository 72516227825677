export type TSearchParamsJson = { [keyFilter in string]: TSearchParamsJsonTypesValue }

export type TSearchParamsJsonValueTypeRange = {
  type: 'range'
  data: {
    min: string
    max: string
  }
}

export type TSearchParamsJsonValueTypeVariant = {
  type: 'variant'
  data: {
    activeVariants: string[]
  }
}

export type TSearchParamsJsonValueTypeValue = {
  type: 'value'
  data: {
    value: string
  }
}

export type TSearchParamsJsonTypesValue =
  | TSearchParamsJsonValueTypeRange
  | TSearchParamsJsonValueTypeVariant
  | TSearchParamsJsonValueTypeValue

export const convertSearchParamsToJson = (URLSearchParams: URLSearchParams): TSearchParamsJson => {
  const result: TSearchParamsJson = {}
  URLSearchParams.forEach((value, key) => {
    if (value.length === 0) {
      return
    }

    if (value.includes('[', 0) && value.includes(']', value.length - 1)) {
      const clearedValue = value.replace('[', '').replace(']', '')
      const split = clearedValue.split(',')

      result[key] = {
        type: 'variant',
        data: {
          activeVariants: split
        }
      }
    } else if (value.includes('-')) {
      const split = value.split('-')

      if (split[0].length === 0 && split[1].length === 0) {
        return
      }

      result[key] = {
        type: 'range',
        data: {
          min: split[0],
          max: split[1]
        }
      }
    }
  })
  return result
}

export const convertJsonToSearchParams = (data: TSearchParamsJson): Record<string, string> => {
  const queryParams: { [key in string]: string } = {}
  for (const key in data) {
    const value = data[key]

    switch (value.type) {
      case 'range': {
        const inputMin = value.data.min
        const inputMax = value.data.max

        if (inputMin.length === 0 && inputMax.length === 0) {
          break
        }

        queryParams[key] = inputMin + '-' + inputMax
        break
      }
      case 'variant': {
        if (value.data.activeVariants.length > 0) {
          queryParams[key] = value.data.activeVariants.reduce((accumulator, currentValue, currentIndex, array) => {
            if (currentIndex === 0) {
              accumulator += '['
            }

            if (currentIndex === array.length - 1) {
              accumulator += currentValue
              accumulator += ']'
            } else {
              accumulator += `${currentValue},`
            }

            return accumulator
          }, '')
        }
        break
      }
      case 'value': {
          const input = value.data.value
          if (input.length > 0) {
              queryParams[key] = input
          }
      }
    }
  }
  return queryParams
}
