import styles from './HeaderMobile.module.css'

import HeaderLogo from 'components/ui/HeaderLogo/HeaderLogo'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { ButtonTexts } from 'constants/buttonTexts'
import Category from '../../../components/icons/button/category.svg'
import VoiceSms from '../../../components/icons/button/voice_sms.svg'
import { colors } from 'styled/colors'
import { BrowserRouters, NavigateLinks } from 'PageRouters'

const HeaderMobile = () => {
  return (
    <header className={styles.header}>
      <HeaderLogo cssStyles={styles.headerLogo} />

      <OvalButton
        text={ButtonTexts.Catalog}
        icon={Category}
        textStyle={{ color: '#CFFB58', fontSize: '12px' }}
        router={NavigateLinks.Catalog}
        cssContainerStyle={styles.catalogButton}
      />
      <OvalButton
        text={ButtonTexts.Advertisement}
        icon={VoiceSms}
        textStyle={{ color: colors.azure.main, fontSize: '12px' }}
        router={NavigateLinks.Advertisement}
        cssContainerStyle={styles.advertismentButton}
      />
    </header>
  )
}

export { HeaderMobile }
