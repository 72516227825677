import styles from './ImageColorsCheckBoxList.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { CatalogItemColorsType } from 'api/editor/Catalog/catalogItemColors'
import { CheckBoxWithText } from 'components/ui/checkboxes/CheckBoxWithText'

type ImageColorsCheckBoxListProps = {
  headerText?: string
  fontSize?: number
  list: CatalogItemColorsType[]
  handleItemClick: (colorId: CatalogItemColorsType) => void
}

const ImageColorsCheckBoxList = ({ headerText, fontSize, list, handleItemClick }: ImageColorsCheckBoxListProps) => {
  return (
    <DivFlexColumn style={styles.container}>
      <p className={styles.headerText} style={{ fontSize: fontSize }}>
        {headerText}
      </p>

      <DivFlexRow style={styles.list}>
        {!!list.length &&
          list.map((item) => {
            return (
              <CheckBoxWithText
                key={item.id}
                text={item.name}
                isActive={item.isSelectedColor}
                onClick={handleItemClick.bind(this, item)}
              />
            )
          })}
      </DivFlexRow>
    </DivFlexColumn>
  )
}

export { ImageColorsCheckBoxList }
