import styles from './ShopBarThirdLevelButton.module.css'

import { IPlatformCategory } from 'store/platformConfigStore/types'
import { Text14Grey } from 'components/styledComponents/text/textStyles'

type ShopBarThirdLevelButtonProps = {
  category: IPlatformCategory
  isActive: boolean
  onClick: () => void
}

const ShopBarThirdLevelButton = ({ category, isActive, onClick }: ShopBarThirdLevelButtonProps) => {
  return (
    <div onClick={onClick}>
      <Text14Grey style={`${styles.text} ${isActive && styles.active}`}>{category.name}</Text14Grey>
    </div>
  )
}

export { ShopBarThirdLevelButton }
