import styles from './ReferralResourcesPage.module.css'

import { Text16Grey } from 'components/styledComponents/text/textStyles'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ReferralDisbursementType, referralStore } from 'store/referralStore/refferralStore'
import { Pagination } from 'components/ui/Pagination'
import { observer } from 'mobx-react-lite'
import { getReferralDisbursementList } from 'api/referral/referral'
import { ReferralRequestCard } from '../ReferralRequestCard'

type ReferralResourcesPageProps = {
  disbursements: ReferralDisbursementType[]
}

const ReferralResourcesPage = observer(({ disbursements }: ReferralResourcesPageProps) => {
  const { totalCount, currentPage, take } = referralStore.disbursementsList.pagination

  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexRow style={styles.header}>
        <Text16Grey style={styles.headerText}>Дата создания</Text16Grey>
        <Text16Grey style={styles.headerText}>Сумма вывода</Text16Grey>
        <Text16Grey style={styles.headerText}>Карта вывода</Text16Grey>
        <Text16Grey style={styles.headerText}>Статус</Text16Grey>
      </DivFlexRow>

      <DivFlexColumn style={styles.list}>
        {!!disbursements.length &&
          disbursements.map((disbursement) => {
            return <ReferralRequestCard key={disbursement.id} requestData={disbursement} />
          })}
      </DivFlexColumn>

      <Pagination
        totalCount={totalCount}
        currentPage={currentPage}
        take={take}
        itemsInPage={10}
        changePaginationPage={getReferralDisbursementList}
        cssBlockStyles={styles.pagination}
      />
    </DivFlexColumn>
  )
})

export { ReferralResourcesPage }
