import styles from './ShopBarModalPriceBlock.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text12Green, Text14Green, Text16Green, Text32Green } from 'components/styledComponents/text/textStyles'
import { useLocation } from 'react-router-dom'
import { formatPrice } from 'utils/formatPrice'
import { convertCurrencyToText } from '../../../../../utils/convert'

type ShopBarModalPriceBlockProps = {
  price?: string
  isActiveStatus: boolean
  isDisable: boolean
  currency?: string
}

const ShopBarModalPriceBlock = ({ price, isActiveStatus, isDisable, currency }: ShopBarModalPriceBlockProps) => {
  const location = useLocation()
  const isCatalog = location.pathname === '/catalog'

  const statusText = isActiveStatus ? 'Активно' : 'Неактивно/снято с продажи'

  return (
    <DivFlexRow style={styles.priceBlock}>
      {price && currency ? (
        <>
          <Text32Green style={`${isDisable && styles.disable}`}>{formatPrice(Number(price))}</Text32Green>
          <Text16Green style={`${styles.dollar} ${isDisable && styles.disable}`}>
            {isCatalog ? convertCurrencyToText('catalogCurrency', currency) : convertCurrencyToText('advertisementCurrency', currency)}
          </Text16Green>
          {isCatalog && (
            <Text14Green style={`${styles.priceText} ${isDisable && styles.disable}`}>
              {convertCurrencyToText('catalogLabel', currency)}
            </Text14Green>
          )}
        </>
      ) : (
        <Text14Green style={`${isDisable && styles.disable}`}>Нет информации о стоимости</Text14Green>
      )}
      {!isCatalog && (
        <Text12Green style={`${styles.statusText} ${!isActiveStatus && styles.error}`}>{statusText}</Text12Green>
      )}
    </DivFlexRow>
  )
}

export { ShopBarModalPriceBlock }
