import { CSSProperties } from 'react'
import styles from './BackFilter.module.css'

type BackFilterProps = {
  backContainer: string
  style?: CSSProperties
  cssStyle?: string
}

const BackFilter = ({ backContainer, style, cssStyle }: BackFilterProps) => {
  return <img className={`${styles.backFilter} ${cssStyle}`} src={backContainer} style={{ ...style }} alt="icon" />
}

export { BackFilter }
