import styles from './ShopBarCardProperty.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Grey, Text14White } from 'components/styledComponents/text/textStyles'
import {ItemProperty} from "../../../../../api/type";

type ShopBarCardPropertyProps = {
  property: ItemProperty[]
}

const ShopBarCardProperty = ({ property }: ShopBarCardPropertyProps) => {
  return (
    <DivFlexColumn style={styles.featureBlock}>
      {property &&
        property.map((item, index) => {
          const { name, value } = item
          return (
            <DivFlexRow key={index} style={styles.featureItem}>
              <Text14Grey>{name}:</Text14Grey>
              <Text14White>{value}</Text14White>
            </DivFlexRow>
          )
        })}
    </DivFlexColumn>
  )
}

export { ShopBarCardProperty }
