import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import styles from './ProfileSettingsPhoneCard.module.css'
import { Text14White, Text16White } from 'components/styledComponents/text/textStyles'

import PhoneIcon from '../../../icons/phone_purple.svg'
import TrashIconRed from '../../../icons/trash_base_red.svg'
import TrashIconGrey from '../../../icons/trash_base_grey.svg'
import { useState } from 'react'
import { UserGamePhone } from 'store/profileSettingsStore/gamePhonesStore'
import { deleteCurrentUserPhone, getUserGamePhones } from 'api/userSettings/userGamePhones'
import Loader from 'components/ui/loaders/Loader/Loader'
import { formatGamePhone } from 'utils/formatGamePhone'

type ProfileSettingsPhoneCardProps = {
  item: UserGamePhone
}

const ProfileSettingsPhoneCard = ({ item }: ProfileSettingsPhoneCardProps) => {
  const { id, serverName, number } = item

  const [isSubmit, setSubmit] = useState<boolean>(false)

  const [isDelete, setDelete] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)

  const handleDeletePhone = async () => {
    setSubmit(true)
    const result = await deleteCurrentUserPhone(id)

    if (result) {
      await getUserGamePhones()
      setSubmit(false)
    }
  }

  if (isSubmit) {
    return (
      <div className={styles.card}>
        <Loader anyStyles={{ margin: 'auto' }} />
      </div>
    )
  }

  return (
    <div
      className={`${styles.card} ${isHover && styles.active}`}
      onMouseEnter={setHover.bind(this, true)}
      onMouseLeave={setHover.bind(this, false)}
    >
      {!isDelete ? (
        <>
          <DivFlexRow style={styles.header}>
            <div className={styles.circle}></div>
            <Text14White>{serverName}</Text14White>
            <img
              src={isHover ? TrashIconRed : TrashIconGrey}
              alt="delete"
              className={styles.delete}
              onClick={setDelete.bind(this, true)}
            />
          </DivFlexRow>

          <DivFlexRow style={styles.phoneBlock}>
            <img src={PhoneIcon} alt="phone" />
            <Text14White>{formatGamePhone(number)}</Text14White>
          </DivFlexRow>
        </>
      ) : (
        <DivFlexColumn style={styles.deleteBlock}>
          <Text16White style={styles.deleteText}>Удалить номер?</Text16White>
          <DivFlexRow style={styles.buttonsBlock}>
            <button className={`${styles.button} ${styles.delete}`} onClick={handleDeletePhone}>
              Да
            </button>
            <button className={`${styles.button} ${styles.cancel}`} onClick={setDelete.bind(this, false)}>
              Нет
            </button>
          </DivFlexRow>
        </DivFlexColumn>
      )}
    </div>
  )
}

export { ProfileSettingsPhoneCard }
