import styles from './ReferralStatusWithText.module.css'

type ReferralStatusWithTextProps = {
  status: string
  cssContainerStyles?: string
}

const ReferralStatusWithText = ({ status, cssContainerStyles }: ReferralStatusWithTextProps) => {
  const getColors = (): { bgColor: string; textColor: string; circleColor: string } => {
    let colors = {
      bgColor: '',
      textColor: '',
      circleColor: ''
    }

    if (status === 'cancel') {
      colors = {
        bgColor: '#f85a5a1a',
        textColor: '#F85A5A',
        circleColor: '#F85A5A'
      }
    } else if (status === 'moderation') {
      colors = {
        bgColor: '#7e88bb1a',
        textColor: '#7E88BB',
        circleColor: '#7E88BB'
      }
    } else if (status === 'success') {
      colors = {
        bgColor: '#94f84f2e',
        textColor: '#8EF84F',
        circleColor: '#8EF84F'
      }
    }

    return colors
  }

  const getStatusText = (): string => {
    if (status === 'cancel') {
      return 'Отклонено'
    } else if (status === 'moderation') {
      return 'На модерации'
    } else return 'Выведено'
  }

  const colors = getColors()
  const text = getStatusText()

  return (
    <div className={`${styles.container} ${cssContainerStyles}`} style={{ backgroundColor: colors.bgColor }}>
      <div className={styles.circle} style={{ backgroundColor: colors.circleColor }}></div>
      <p className={styles.text} style={{ color: colors.textColor }}>
        {text}
      </p>
    </div>
  )
}

export { ReferralStatusWithText }
