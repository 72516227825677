import api, { ResultHandlerAxiosResponse } from '../axios/axiosInstance'
import {
  IChatListResponse,
  IGetChatInfoResponse,
  IGetChatSystemMessageInfo,
  IGetChatSystemMessageList
} from './type.response'
import { IGetChatListRequest } from './type.request'

export const getChatList = async (data?: IGetChatListRequest): Promise<IChatListResponse> => {
  const queryParams = new URLSearchParams()
  if (data?.filterLastMessageId) queryParams.append('filter[lastMessageId]', String(data.filterLastMessageId))
  if (data?.isNotRead) queryParams.append('filter[isNotRead]', 'yes')

  const url = '/api/chat/list?' + queryParams.toString()
  return api.get<IChatListResponse>(url)
}

export const getChatInfo = async (chatId: string): Promise<IGetChatInfoResponse> => {
  const url = '/api/chat/private-chat/info'
  return api.get<IGetChatInfoResponse>(url, {
    params: {
      chatId
    }
  })
}

export const getChatIdByAdvertisementId = async (
  advertisementId: string
): Promise<ResultHandlerAxiosResponse<{ chatId: string }>> => {
  const url = `/api/chat/advertisement-chat-id`
  return api.getVersion2(url, {
    params: {
      advertisementId
    }
  })
}

export const getChatSystemMessageList = async (): Promise<
  ResultHandlerAxiosResponse<Array<IGetChatSystemMessageList>>
> => {
  const url = `/api/chat/system-message/list`
  return api.getVersion2(url)
}

export const getChatSystemMessageInfo = async (): Promise<ResultHandlerAxiosResponse<IGetChatSystemMessageInfo>> => {
  const url = '/api/chat/system-chat/info'
  return api.getVersion2(url)
}
