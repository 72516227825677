import styles from './AdvertisementMyCardStatus.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Green } from 'components/styledComponents/text/textStyles'

import TimerGreenIcon from '../../../icons/timer_green.svg'
import { calculateTimeRemaining } from 'utils/dateFormated'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { MyAdvertisementsStatus } from 'store/advertisement/advertisementMyStore/types'
import { advertisementMyStore } from 'store/advertisement/advertisementMyStore/advertisementMyStore'

type AdvertisementMyCardStatusProps = {
  status: MyAdvertisementsStatus
  expirationAt: string | null
}

const AdvertisementMyCardStatus = observer(({ status, expirationAt }: AdvertisementMyCardStatusProps) => {
  const { maxCountHourShow, maxCountHourShowVip } = advertisementMyStore.myAdvertisementDashboard.limit

  const [timeRemaining, setTimeRemaining] = useState<string>('')

  const expiredStatus =
    (status === MyAdvertisementsStatus.Active && timeRemaining === '') || status === MyAdvertisementsStatus.Expired
  const statusStyles = expiredStatus ? 'overdue' : status

  useEffect(() => {
    const updateRemainingTime = () => {
      const remainingTime = expirationAt ? calculateTimeRemaining(expirationAt) : ''
      setTimeRemaining(remainingTime)

      if (remainingTime === '') {
        clearInterval(intervalId)
      }
    }

    const intervalId = setInterval(updateRemainingTime, 60000)

    updateRemainingTime()

    return () => clearInterval(intervalId)
  }, [expirationAt, maxCountHourShow, maxCountHourShowVip])

  const getStatusText = (): string => {
    if (status === MyAdvertisementsStatus.Cancel) {
      return 'Отклонено'
    }
    if (status === MyAdvertisementsStatus.Hidden) {
      return 'Скрыто'
    }
    if (status === MyAdvertisementsStatus.Moderation) {
      return 'На модерации'
    }
    if (status === MyAdvertisementsStatus.Banned) {
      return 'Заблокировано'
    }
    if (expiredStatus) {
      return 'Просрочено'
    }
    if (status === MyAdvertisementsStatus.Deleted) {
      return 'Удалено'
    }
    if (status === MyAdvertisementsStatus.Active) {
      return 'Активно'
    }
    return 'Неизвестно'
  }

  const statusText = getStatusText()

  return (
    <DivFlexRow style={`${styles.status} ${styles[statusStyles]}`}>
      <span className={`${styles.statusCircle}  ${styles[statusStyles]}`} />
      <p className={`${styles.statusText}  ${styles[statusStyles]}`}>{statusText}</p>

      {status === MyAdvertisementsStatus.Active && !expiredStatus && (
        <DivFlexRow style={styles.activeStatusBlock}>
          <img className={styles.activeStatusIcon} src={TimerGreenIcon} alt="timer" />
          <Text14Green>{timeRemaining}</Text14Green>
        </DivFlexRow>
      )}
    </DivFlexRow>
  )
})

export { AdvertisementMyCardStatus }
