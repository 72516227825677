import styles from './CatalogCardPageParamsBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Green, Text24Green, Text48Green } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { SearchItemProperty } from 'store/platformConfigStore/types'
import { CatalogCardPagePropertyBlock } from '../CatalogCardPagePropertyBlock'
import { CatalogCardPageBuyBlock } from '../CatalogCardPageBuyBlock'
import { formatPrice } from '../../../../utils/formatPrice'
import {convertAdvertisementCurrencyToBtnCatalog, convertCurrencyToText} from '../../../../utils/convert'

type CatalogCardPageParamsBlockProps = {
  currency?: string
  advertisementCurrency: string
  price?: string
  property: SearchItemProperty[]
  countAdvertisementBuy: number
  countAdvertisementSale: number
  catalogId: string
}

const CatalogCardPageParamsBlock = ({
  price,
  currency,
  advertisementCurrency,
  property = [],
  countAdvertisementBuy,
  countAdvertisementSale,
  catalogId
}: CatalogCardPageParamsBlockProps) => {
  return (
    <DivFlexColumn style={styles.container}>
      {typeof price === 'string' && typeof currency === 'string' ? (
        <>
          <DivFlexRow style={styles.priceBlock}>
            <Text48Green style={styles.price}>{formatPrice(Number(price))}</Text48Green>
            <Text24Green style={styles.dollar}>{convertCurrencyToText('catalogCurrency', currency)}</Text24Green>
          </DivFlexRow>

          <Text14Green style={styles.priceText}>{convertCurrencyToText('catalogLabel', currency)}</Text14Green>
        </>
      ) : (
        <Text24Green style={styles.noPriceText}>Нет информации о стоимости</Text24Green>
      )}

      <HorizontalDivider style={{ marginTop: '14px' }} />

      <CatalogCardPagePropertyBlock property={property} />

      {!!property.length && <HorizontalDivider style={{ marginTop: '16px' }} />}

      <CatalogCardPageBuyBlock
        cssContainer={styles.buyButtonContainer}
        countAdvertisementBuy={countAdvertisementBuy}
        countAdvertisementSale={countAdvertisementSale}
        catalogId={catalogId}
        nameButtonBuy={convertAdvertisementCurrencyToBtnCatalog(advertisementCurrency).buttonBuy}
        nameButtonSale={convertAdvertisementCurrencyToBtnCatalog(advertisementCurrency).buttonBuy}
      />
    </DivFlexColumn>
  )
}

export { CatalogCardPageParamsBlock }
