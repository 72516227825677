import styles from './AdvertisementMyLimitCard.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Grey, Text40White, Text24Grey } from 'components/styledComponents/text/textStyles'
import { VipDisableMessage } from 'components/ui/VipDisableMessage'
import { useState } from 'react'
import { SubscriptionPaymentModalsContainer } from 'components/ui/modals/SubscriptionPaymentModalsContainer'

type AdvertisementMyLimitCardProps = {
  headerText: string
  firstCount: string
  secondCount?: string
  cardIcon: string
  isDisable?: boolean
  vipText?: string
}

const AdvertisementMyLimitCard = ({
  headerText,
  firstCount,
  secondCount,
  cardIcon,
  isDisable,
  vipText
}: AdvertisementMyLimitCardProps) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleClick = () => {
    if (isDisable) {
      setOpen(true)
    }
  }

  return (
    <DivFlexColumn style={`${styles.card} ${isDisable && styles.disable}`} onClick={handleClick}>
      <Text14Grey style={styles.cardText}>{headerText}</Text14Grey>

      <DivFlexRow style={styles.cardData}>
        <DivFlexRow style={styles.cardDataText}>
          <Text40White style={styles.cardDataWhiteText}>{firstCount}</Text40White>
          {secondCount && <Text40White style={styles.slash}>/</Text40White>}
          <Text24Grey style={styles.cardDataGreyText}>{secondCount}</Text24Grey>
        </DivFlexRow>

        <img className={styles.cardDataIcon} src={cardIcon} alt="card-icon" />
      </DivFlexRow>

      {isDisable && (
        <VipDisableMessage text={vipText} cssStyles={styles.alertContainer} cssIconStyle={styles.alertIcon} />
      )}

      {isOpen && <SubscriptionPaymentModalsContainer onClose={setOpen.bind(this, false)} />}
    </DivFlexColumn>
  )
}

export { AdvertisementMyLimitCard }
