import styles from './ChatSupportCard.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import {
  Text12WhiteRoboto,
  Text14Grey,
  Text16WhiteRoboto,
  Text18White
} from 'components/styledComponents/text/textStyles'
import { ChatCardUserBlock } from '../ChatCardUserBlock'

import SupportAvatar from '../../../icons/chat/support_avatar.png'
import SupportIcon from '../../../icons/chat/support.svg'
import { observer } from 'mobx-react-lite'
import { formatDate } from '../../../../utils/dateFormated'
import { truncateText } from '../../../../utils/text'

const textBlockSub = 'Если Вы столкнулись с проблемой в процессе сделки - напишите нам, мы решим Ваш вопрос'

type ChatSupportCardProps = {
  onClick: () => void
  messageText?: string
  messageCreatedAt?: string
  countMessageNotRead: number
}

const ChatSupportCard = observer(
  ({ countMessageNotRead, messageText, messageCreatedAt, onClick }: ChatSupportCardProps) => {
    const headerText =
      countMessageNotRead > 0
        ? `Системные уведомления (${countMessageNotRead} новых сообщений)`
        : 'Системные уведомления'
    messageText = messageText ?? 'Новых сообщений нет..'
    messageCreatedAt = messageCreatedAt ? formatDate(messageCreatedAt) : ''

    return (
      <DivFlexRow style={styles.container} onClick={onClick}>
        <img className={styles.avatar} src={SupportIcon} alt="avatar" />

        <DivFlexColumn style={styles.body}>
          <DivFlexRow style={styles.headerBlock}>
            <DivFlexColumn style={styles.textBlock}>
              <Text18White>{headerText}</Text18White>
              <Text14Grey>{''}</Text14Grey>
            </DivFlexColumn>

            <ChatCardUserBlock avatar={SupportAvatar} nickname="Система" status="online" />
          </DivFlexRow>

          <DivFlexColumn style={styles.messageBlock}>
            <Text12WhiteRoboto style={styles.messageDate}>{messageCreatedAt}</Text12WhiteRoboto>
            <Text16WhiteRoboto style={styles.messageText}>{truncateText(messageText, 300)}</Text16WhiteRoboto>
          </DivFlexColumn>
        </DivFlexColumn>
      </DivFlexRow>
    )
  }
)

export { ChatSupportCard }
