import styles from './ProfileSettingsPageAvatar.module.css'

import CameraIcon from '../../../icons/profileSettings/camera_white.svg'
import TrashIcon from '../../../icons/profileSettings/trash_red.svg'
import { deleteUserAvatar, getUserAvatar, postUserAvatar } from 'api/userSettings/userAvatar'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'
import FackeAvatar from '../../../icons/facke/avatar.svg'
import { getUserInfo } from 'api/user/userProfileData'

const ProfileSettingsPageAvatar = observer(() => {
  const { userAvatar, isVip } = userInfoStore

  const handleEditClick = () => {
    // Нажатие на кнопку для выбора файла
    const fileInput = document.getElementById('fileInput')
    if (fileInput) {
      fileInput.click()
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]

    if (file) {
      await postUserAvatar(file)
      await getUserInfo()
      await getUserAvatar()
    }
  }

  const photoFormat = isVip ? '.png, .jpg, .webp, .gif' : '.png, .jpg, .webp'

  return (
    <div className={`${styles.container} ${isVip && styles.vip}`}>
      <img className={styles.avatar} src={userAvatar ? userAvatar : FackeAvatar} alt="user avatar" />
      <img className={styles.edit} src={CameraIcon} onClick={handleEditClick} alt="add avatar" />
      <input
        id="fileInput"
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        max-size="5000000"
        accept={photoFormat}
      />
      <img className={styles.delete} src={TrashIcon} onClick={deleteUserAvatar} alt="delete avatar" />
    </div>
  )
})

export { ProfileSettingsPageAvatar }
