import styles from './GorizontalDivider.module.css'
import { CSSProperties } from 'react'

type GorizontalDividerProps = {
  style?: CSSProperties
  cssStyle?: string
}

const HorizontalDivider = ({ style, cssStyle }: GorizontalDividerProps) => {
  return <div className={`${styles.divider} ${cssStyle}`} style={{ ...style }}></div>
}

export default HorizontalDivider
