import styles from './ProfileSocialAccount.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import {
  Text14Green,
  Text14Orange,
  Text14Red,
  Text14White,
  Text16White
} from 'components/styledComponents/text/textStyles'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { ProfileAccountNotificationButton } from '../ProfileAccountNotificationButton'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { TumblerPurpleWithText } from 'components/ui/buttons/TumblerPurpleWithText'
import { useState } from 'react'
import Button from 'components/ui/buttons/Button/Button'

import ErrorIcon from '../../../icons/profileSettings/error_yellow_icon.svg'
import ClipIcon from '../../../icons/profileSettings/clip_crunch_grey.svg'
import InfoIcon from '../../../icons/info_grey.svg'
import { colors } from 'styled/colors'

type ProfileSocialAccountProps = {
  nameMessenger: string
  isSocialStatus: boolean
  socialIcon: string
  socialNickname: string
  activeSendMessage: boolean
  onClickTestMessage: () => void
  onClickDeleteAccount: () => void
  onClickSenderMessage: (active: boolean) => void
}

const ProfileSocialAccount = ({
  nameMessenger,
  isSocialStatus,
  socialIcon,
  socialNickname,
  activeSendMessage,
  onClickTestMessage,
  onClickDeleteAccount,
  onClickSenderMessage
}: ProfileSocialAccountProps) => {
  const [isModal, setModal] = useState<boolean>(false)

  return (
    <DivFlexColumn style={styles.container}>
      <div className={styles.header}>
        {isSocialStatus ? (
          <DivFlexRow style={styles.activeStatus}>
            <span className={styles.circle} />
            <Text14Green>{nameMessenger}</Text14Green>
          </DivFlexRow>
        ) : (
          <DivFlexRow style={styles.errorStatus}>
            <img className={styles.errorIcon} src={ErrorIcon} alt="error" />
            <Text14Orange>Отправьте тестовое уведомление</Text14Orange>
          </DivFlexRow>
        )}
      </div>

      <DivFlexRow style={styles.body}>
        <img className={styles.socialIcon} src={socialIcon} alt="social icon" />

        <Text16White>{socialNickname}</Text16White>

        <OvalButton
          text="Отвязать"
          onClick={setModal.bind(this, true)}
          icon={ClipIcon}
          iconSize={20}
          textColor={colors.grey.scarlet}
          cssContainerStyle={styles.clipButton}
          isSwapContent
        />

        <ProfileAccountNotificationButton onClick={onClickTestMessage} />
      </DivFlexRow>

      <HorizontalDivider cssStyle={styles.divider} />

      <DivFlexRow style={styles.footer}>
        <TumblerPurpleWithText text="Уведомлять о сообщениях" onClick={onClickSenderMessage} isActive={activeSendMessage} />

        {/*{isSocialStatus && (
          <TumblerPurpleWithText text="Уведомлять об объявлениях" onClick={() => console.log()} isActive={false} />
        )}*/}

        {isSocialStatus && <img className={styles.footerIcon} src={InfoIcon} alt="atantion" />}
      </DivFlexRow>

      {isModal && (
        <DivFlexColumn style={styles.modal}>
          <Text16White>Отвязать аккаунт?</Text16White>

          <DivFlexRow style={styles.modalData}>
            <img className={styles.modalIcon} src={socialIcon} alt="social icon" />
            <Text16White>{socialNickname}</Text16White>
          </DivFlexRow>

          <DivFlexRow style={styles.modalButtonsBlock}>
            <Button
              onClick={() => {
                setModal(false)
                onClickDeleteAccount()
              }}
              cssButtonStyles={styles.yesButton}>
              <Text14Red>Да</Text14Red>
            </Button>

            <Button onClick={setModal.bind(this, false)} cssButtonStyles={styles.noButton}>
              <Text14White>Нет</Text14White>
            </Button>
          </DivFlexRow>
        </DivFlexColumn>
      )}
    </DivFlexColumn>
  )
}

export { ProfileSocialAccount }
