import { ImgBase } from 'components/styledComponents/img/img'
import ActiveIconRight from './active_arrow.svg'
import ActiveIconLeft from './active_arrow_left.svg'
import DisableArrowRight from './disable_arrow_right.svg'
import DisableIconLeft from './disable_arrow.svg'
import { useState } from 'react'

type ArrowInCircleProps = {
  isActive?: boolean
  isLeft?: boolean
  isRight?: boolean
  onClick?: () => void
}

const ArrowInCircle = ({ isActive = true, isLeft, isRight, onClick }: ArrowInCircleProps) => {
  const [isHover, setHover] = useState<boolean>(false)

  return (
    <div
      style={{ minWidth: 25, cursor: 'pointer' }}
      onClick={onClick}
      onMouseEnter={setHover.bind(this, true)}
      onMouseLeave={setHover.bind(this, false)}
    >
      {isLeft && <ImgBase img={isHover && isActive ? ActiveIconLeft : DisableIconLeft} />}
      {isRight && <ImgBase img={isHover && isActive ? ActiveIconRight : DisableArrowRight} />}
    </div>
  )
}

export { ArrowInCircle }
