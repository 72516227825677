export enum ButtonTexts {
  AdvertisementMy = 'Мои объявления',
  Chat = 'Сообщения',
  Catalog = 'Каталог',
  Advertisement = 'Объявления',
  AdvertisementCreate = 'Создать',
  Settings = 'Настройки',
  Subscription = 'Подписка',
  Base = 'База знаний',
  Referral = 'Реферральная система',
  Faq = 'FAQ',
  SupportEmail = 'support@rp.market',
  LogOut = 'Выйти',
  LogIn = 'Войти',
  ToCall = 'Позвонить',
  WriteTheChat = 'Написать в чат'
}
