import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text16White } from 'components/styledComponents/text/textStyles'
import { DinamicModal } from '../DinamicModal'
import { DinamicModalHeader } from '../DinamicModal/DinamicModalHeader'
import styles from './DeleteAdvertisementMyModal.module.css'
import Button from 'components/ui/buttons/Button/Button'
import { colors } from 'styled/colors'
import { useState } from 'react'
import Loader from 'components/ui/loaders/Loader/Loader'
import { shooseMyAdvertisementStatus } from 'api/advetrisement/my/advertisementMy'
import { observer } from 'mobx-react-lite'
import { advertisementMyStore } from 'store/advertisement/advertisementMyStore/advertisementMyStore'

type DeleteAdvertisementMyModalProps = {
  isOpen: boolean
  onClose: () => void
  advertisementDataId: string
}

const DeleteAdvertisementMyModal = observer(
  ({ isOpen, onClose, advertisementDataId }: DeleteAdvertisementMyModalProps) => {
    const { updateMyAdvertisement } = advertisementMyStore

    const [isLoading, setLoading] = useState(false)

    const handleDealButton = async () => {
      setLoading(true)
      const result = await shooseMyAdvertisementStatus(advertisementDataId, 'deleted', 'deal')
      if (result) {
        updateMyAdvertisement(result)
        onClose()
        setLoading(false)
      }
    }

    const handleOtherButton = async () => {
      setLoading(true)
      const result = await shooseMyAdvertisementStatus(advertisementDataId, 'deleted', 'other')
      if (result) {
        updateMyAdvertisement(result)
        onClose()
        setLoading(false)
      }
    }

    return (
      <DinamicModal isOpen={isOpen} onClose={onClose}>
        <DinamicModalHeader text="Удаление" onClose={onClose} />
        <Text16White>Пожалуйста, выберите причину удаления объявления</Text16White>
        {isLoading ? (
          <DivFlexRow style={styles.loaderBlock}>
            <Loader size={40} bold={4} />
          </DivFlexRow>
        ) : (
          <DivFlexRow style={styles.buttonsBlock}>
            <Button text="Продано" bgColor={colors.purple.main} onClick={handleDealButton} />
            <Button text="Другое" bgColor={colors.purple.main} onClick={handleOtherButton} />
            <Button text="Отмена" onClick={onClose} isTransparent textColor={colors.red} />
          </DivFlexRow>
        )}
      </DinamicModal>
    )
  }
)

export { DeleteAdvertisementMyModal }
