import { action, makeObservable, observable } from 'mobx'

export type UserGamePhone = {
  id: string
  number: string
  serverName: string
}

class UserGamePhonesStore {
  userPhones: UserGamePhone[] = []

  constructor() {
    makeObservable(this, {
      userPhones: observable,
      setPhones: action.bound
    })
  }

  setPhones = (phones: UserGamePhone[]) => {
    this.userPhones = phones
  }
}

const userGamePhonesStore = new UserGamePhonesStore()

export { userGamePhonesStore }
