import styles from './SystemAlertComponent.module.css'

import GearIcon from '../../icons/gear_yellow.svg'
import { DivFlexRow } from 'components/styledComponents/div/div'

type SystemAlertComponentProps = {
  iframe: string
}

const SystemAlertComponent = ({ iframe }: SystemAlertComponentProps) => {
  return (
    <DivFlexRow style={styles.container}>
      <img className={styles.icon} src={GearIcon} alt="alert" />
      <div dangerouslySetInnerHTML={{ __html: iframe }}></div>
    </DivFlexRow>
  )
}


const SystemAlertComponent2 = ({ iframe }: SystemAlertComponentProps) => {
    return (
        <DivFlexRow style={styles.container2}>
            <img className={styles.icon} src={GearIcon} alt="alert" />
            <div dangerouslySetInnerHTML={{ __html: iframe }}></div>
        </DivFlexRow>
    )
}

export { SystemAlertComponent, SystemAlertComponent2 }
