import styles from './ArticleList.module.css'

import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import Shield from '../../icons/shield-question.svg'
import { SearchFilter } from 'components/ui/SearchFilter'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { useEffect, useState } from 'react'
import { getFaqList } from 'api/faqList'
import { FaqType, faqListStore } from 'store/faqListStore'
import { observer } from 'mobx-react-lite'
import { ButtonDropdown } from 'components/ui/buttons/ButtonDropdown'
import Loader from 'components/ui/loaders/Loader/Loader'
import { useNavigate, useParams } from 'react-router-dom'

const headerText = 'База знаний RP Market'

const ArticleList = observer(() => {
  const navigate = useNavigate()
  const { cardId } = useParams()

  const sections = faqListStore.sections

  const [articleBody, setArticleBody] = useState<string>('')
  const [articleHeader, setArticleHeader] = useState<string>('')
  const [activeButton, setActiveButton] = useState<FaqType | null>(null)

  const [isLoader, setLoader] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getFaqList()
      setLoader(false)
    })()
  }, [])

  useEffect(() => {
    // TODO переделать код и сделать 1 useState = activeButton, а не 3
    // TODO придумать отработку хука только при загрузке страницы
    if (cardId && sections.length) {
      for (let index = 0; index < sections.length; index++) {
        const article = sections[index]

        if (article.id === cardId) {
          setActiveButton(article)
          setArticleHeader(article.name)
          if (article.body) {
            setArticleBody(article.body)
          }
          return
        }

        if (article.articles) {
          const children = article.articles

          for (let indexChild = 0; indexChild < children.length; indexChild++) {
            const childrenElement = children[indexChild]
            // setActiveButton(article)
            if (childrenElement.id === cardId) {
              setActiveButton(article)
              setArticleHeader(childrenElement.name)
              if (childrenElement.body) {
                setArticleBody(childrenElement.body)
              }
              return
            }
          }
        }
      }
    }
  }, [cardId, sections])

  const handleToggleDropdown = (item: FaqType) => {
    setActiveButton(item)
    setArticleHeader(item.name)

    if (activeButton && activeButton.id !== item.id) {
      if (item.body !== null) {
        setArticleBody(item.body)
        navigate(`/faq/${item.id}`)
      } else {
        setArticleBody('Данных нет')
        navigate(`/faq/`)
      }
    }
    if (activeButton && activeButton.id === item.id) {
      handleClearContainer()
      navigate(`/faq/`)
    }
  }

  const handleItemClick = (item: FaqType) => {
    if (item.body !== null) {
      setArticleBody(item.body)
      navigate(`/faq/${item.id}`)
    }
    if (item.body === null) {
      setArticleBody('Данных нет')
      navigate(`/faq/`)
    }
    setArticleHeader(item.name)
  }

  const handleClearContainer = () => {
    setArticleBody('')
    setArticleHeader('')
    setActiveButton(null)
  }

  if (isLoader) {
    return (
      <PageWrapper style={styles.loaderPage}>
        <Loader anyStyles={{ margin: 'auto' }} size={40} bold={3} />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <section className={styles.section}>
        <div className={styles.header}>
          <div className={styles.textContainer}>
            <img className={styles.headericon} src={Shield} alt="article" />
            <h1 className={styles.headerText}>{headerText}</h1>
          </div>
          <SearchFilter placeholder="Поиск по базе знаний" onSearch={getFaqList} onClick={handleClearContainer} />
        </div>

        <HorizontalDivider />

        <div className={styles.articlesContainer}>
          <div className={styles.articlesList}>
            <h2 className={styles.articlesHeaderText}>Разделы</h2>

            <div className={styles.buttonsContainer}>
              {!!sections &&
                !!sections.length &&
                sections.map((item) => (
                  <ButtonDropdown
                    key={item.id}
                    item={item}
                    activeButton={activeButton}
                    onItemClick={handleItemClick}
                    onToggleDropdown={handleToggleDropdown}
                    activeChildId={cardId ? cardId : ''}
                  />
                ))}
            </div>
          </div>

          {articleHeader && (
            <div className={styles.information}>
              <div className={styles.informationHeader}>
                <h2 className={styles.informationHeaderText}>{articleHeader}</h2>
                <HorizontalDivider style={{ width: '100%' }} />
              </div>
              <div className={styles.informationSub}>
                <div dangerouslySetInnerHTML={{ __html: articleBody }}></div>
              </div>
            </div>
          )}
        </div>
      </section>
    </PageWrapper>
  )
})

export default ArticleList
