import styles from './RangeDinamic.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { BaseInput } from 'components/ui/inputs/BaseInput'
import { useState } from 'react'
import { formatOnlyNumber } from '../../../../utils/forms/helpers'

type RangeDynamicProps = {
  onChange: (inputMin: string, inputMax: string) => void
  placeholderMin?: number | string
  placeholderMax?: number | string
  inputMin?: string
  inputMax?: string
}

const RangeDynamic = ({ placeholderMin, placeholderMax, inputMin, inputMax, onChange }: RangeDynamicProps) => {
  const [isValid, setValid] = useState<boolean>(true)

  const handlerOnChange = (type: 'min' | 'max') => (value: string) => {
    const valueOnlyNumbers = formatOnlyNumber(value)

    const resultMin = type === 'min' ? valueOnlyNumbers : inputMin ?? ''
    const resultMax = type === 'max' ? valueOnlyNumbers : inputMax ?? ''

    const isNotValid = resultMin.length > 0 && resultMax.length > 0 && Number(resultMax) < Number(resultMin)
    setValid(!isNotValid)

    onChange(resultMin, resultMax)
  }

  const textPlaceholderMin = placeholderMin === undefined ? 'От' : `От ${placeholderMin}`
  const textPlaceholderMax = placeholderMax === undefined ? 'До' : `До ${placeholderMax}`

  return (
    <DivFlexRow style={styles.container}>
      <BaseInput
        value={inputMin ?? ''}
        onChange={handlerOnChange('min')}
        isValid={isValid}
        placeHolder={textPlaceholderMin}
        cssInputContainer={styles.input}
        width={120}
      />
      <BaseInput
        value={inputMax ?? ''}
        onChange={handlerOnChange('max')}
        isValid={isValid}
        placeHolder={textPlaceholderMax}
        cssInputContainer={styles.input}
        width={120}
      />
    </DivFlexRow>
  )
}

export { RangeDynamic }
