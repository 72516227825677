import styles from './CheckBoxWithText.module.css'
import { CheckboxItem } from '../CheckboxItem'

type CheckBoxWithTextProps = {
  text: string
  isActive: boolean
  onClick?: () => void
  fontSize?: number
  isBlocked?: boolean
  checkBoxRef?: React.RefObject<HTMLDivElement>
}

const CheckBoxWithText = ({
  text,
  isActive,
  onClick,
  fontSize = 14,
  isBlocked = false,
  checkBoxRef
}: CheckBoxWithTextProps) => {
  const handleClick = () => {
    if (!isBlocked) {
      if (onClick) onClick()
    }
  }

  return (
    <div ref={checkBoxRef} className={styles.container} onClick={handleClick}>
      <CheckboxItem isCheck={isActive} isBlocked={isBlocked} />
      <p className={`${styles.text} ${isActive && styles.active}`} style={{ fontSize: fontSize }}>
        {text}
      </p>
    </div>
  )
}

export { CheckBoxWithText }
