import styles from './AdvertisementCreate.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { AdvertisementCreateHeader } from './AdvertisementCreateHeader'
import { AdvertisementCreateChooseType } from './AdvertisementCreateChooseType'
import { AdvertisementCreateChooseServer } from './AdvertisementCreateChooseServer'
import { AdvertisementCreateData } from './AdvertisementCreateData'
import { AdvertisementCreateChooseProduct } from './AdvertisementCreateChooseProduct'
import { useEffect, useRef, useState } from 'react'
import { AdvertisementCatalogSearch, advertisementCreateStore } from 'store/advertisement/advertisementCreateStore'
import { AdvertisementCreateForm, advertisementCreate } from 'api/advetrisement/create/advetrisementCreate'
import { observer } from 'mobx-react-lite'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import { TumblerPurple } from 'components/ui/buttons/TumblerPurple'
import { Text14White, Text24Grey, Text24White } from 'components/styledComponents/text/textStyles'
import Button from 'components/ui/buttons/Button/Button'
import { SubmitButton } from 'components/ui/buttons/SubmitButton'
import { userInfoStore } from 'store/userInfoStore'
import { AdvertisementCreatePhoneBlock } from './AdvertisementCreatePhoneBlock'
import { AdvertisementCreateAlertBlock } from './AdvertisementCreateAlertBlock'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BrowserRouters } from 'PageRouters'
import VipIcon from '../../icons/userStatus/pro_badge.svg'
import LockIcon from '../../icons/lock-icon.svg'
import { SubscriptionPaymentModalsContainer } from 'components/ui/modals/SubscriptionPaymentModalsContainer'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import QuestionIcon from '../../icons/question_grey_in_circle.svg'
import QuestionActiveIcon from '../../icons/question_white_in_circle.svg'
import { colors } from 'styled/colors'
import { scrollToItemCenter } from 'utils/pageScrollers'
import { LoaderPage } from '../../ui/loaders/LoaderPage'
import { advertisementCatalogSearch } from 'api/advetrisement/create/advetrisementCreate'

const alertText = 'Предмет не выбран. Выберите предмет, для отображения формы объявления'

const AdvertisementCreate = observer(() => {
  const navigation = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const { currentServer } = userPlatformConfig
  const { setCatalogSearch } = advertisementCreateStore
  const { isVip } = userInfoStore

  const scrollToSearch = useRef<any>(null)
  const scrollToPrice = useRef<any>(null)
  const scrollToPhoto = useRef<any>(null)

  const [key, setKey] = useState<number>(Math.random())

  const [choseItem, setChoseItem] = useState<AdvertisementCatalogSearch | null>(null)

  const [submitForm, setSubmitForm] = useState<AdvertisementCreateForm>({
    type: 'sale',
    serverId: currentServer.id,
    catalogId: '',
    count: 1,
    price: 0,
    gamePhoneId: null,
    itemImageId: '',
    isLoadImages: false,
    isPublication: true,
    customImages: []
  })

  const [isLoading, setLoading] = useState<boolean>(true)
  const [isVipModal, setVipModal] = useState<boolean>(false)
  const [isErrorTheme, setErrorTheme] = useState<boolean>(false)

  const handleChooseAdvertisementType = (type: 'buy' | 'sale') => {
    setSubmitForm({ ...submitForm, type: type })
  }

  const handleChooseServer = (serverId: string) => {
    setSubmitForm({ ...submitForm, serverId: serverId })
  }

  const handleChooseItem = (item: AdvertisementCatalogSearch) => {
    setChoseItem(item)
    setSubmitForm({ ...submitForm, catalogId: item.id })
  }

  const handleIsPublication = () => {
    setSubmitForm({ ...submitForm, isPublication: !submitForm.isPublication })
  }

  const handleOnLoadImage = (name: string, base64: string) => {
    submitForm.customImages = [...submitForm.customImages, { name, base64 }]
    setSubmitForm(submitForm)
  }

  const handleCancelPublication = () => {
    setCatalogSearch([])
    setSubmitForm({
      type: 'buy',
      serverId: currentServer.id,
      catalogId: '',
      count: 1,
      price: 1,
      gamePhoneId: null,
      itemImageId: '',
      isLoadImages: false,
      isPublication: true,
      customImages: []
    })
    setChoseItem(null)
    setKey(Math.random())
  }

  const onSubmitForm = async () => {
    setLoading(true)
    const result = await advertisementCreate(submitForm)
    if (result) {
      navigation(BrowserRouters.AdvertisementMy)
    }
    setLoading(false)
  }

  const handleSubmitForm = async () => {
    if (!submitForm.catalogId) {
      setErrorTheme(true)
      scrollToItemCenter(scrollToSearch)
    } else if (!submitForm.itemImageId) {
      setErrorTheme(true)
      scrollToItemCenter(scrollToPhoto)
    } else if (!submitForm.price) {
      setErrorTheme(true)
      scrollToItemCenter(scrollToPrice)
    } else if (submitForm.catalogId && submitForm.itemImageId && submitForm.price) {
      setErrorTheme(false)
      await onSubmitForm()
    }
  }

  const handleWhatPhoneInfo = () => {
    navigation(BrowserRouters.ArticleList)
  }

  useEffect(() => {
    const systemCatalogId = searchParams.get('systemCatalogId')
    if (!systemCatalogId) {
      setLoading(false)
      return
    }

    advertisementCatalogSearch({ systemCatalogId }).then(() => {
      if (advertisementCreateStore.catalogSearch.length > 0) {
        handleChooseItem(advertisementCreateStore.catalogSearch[0])
      }
      setSearchParams('')
      setLoading(false)
    })
  }, [])

  if (isLoading) {
    return <LoaderPage />
  }

  return (
    <PageWrapper key={key} style={styles.section} isSystemAlert>
      <DivFlexColumn style={styles.body}>
        <AdvertisementCreateHeader />

        <DivFlexRow style={styles.chooseTypeAndServerBlock}>
          <AdvertisementCreateChooseType
            handleChooseAdvertisementType={handleChooseAdvertisementType}
            choseItem={choseItem}
          />
          <AdvertisementCreateChooseServer handleChooseServer={handleChooseServer} />
        </DivFlexRow>

        <span ref={scrollToSearch} />
        <Text24White>3. Выберите предмет</Text24White>

        <AdvertisementCreateChooseProduct
          choseItem={choseItem}
          handleChooseItem={handleChooseItem}
          isError={isErrorTheme && !submitForm.catalogId}
        />

        <Text24White>4. Данные объявления</Text24White>

        {choseItem ? (
          <AdvertisementCreateData
            choseItem={choseItem}
            submitForm={submitForm}
            setSubmitForm={setSubmitForm}
            handleOnLoadImage={handleOnLoadImage}
            isError={isErrorTheme}
            scrollToPrice={scrollToPrice}
            scrollToPhoto={scrollToPhoto}
          />
        ) : (
          <AdvertisementCreateAlertBlock alertText={alertText} />
        )}

        {isVip ? (
          <Text24White>5. Контактный номер телефона</Text24White>
        ) : (
          <DivFlexRow>
            <DivFlexRow style={styles.vipPhoneBlock} onClick={setVipModal.bind(this, true)}>
              <img className={styles.lockIcon} src={LockIcon} alt="lock" />

              <Text24Grey>5. Контактный номер телефона</Text24Grey>

              <DivFlexRow style={`${styles.badgeBlock}`}>
                <img src={VipIcon} alt="vip" className={`${styles.icon}`} />
                <p className={`${styles.text}`}>Доступно в подписке</p>
              </DivFlexRow>
            </DivFlexRow>

            <OvalButton
              text="Для чего нужен игровой номер телефона?"
              icon={QuestionIcon}
              hoverIcon={QuestionActiveIcon}
              isSwapContent
              textColor={colors.grey.scarlet}
              iconSize={24}
              isTransparent
              onClick={handleWhatPhoneInfo}
              cssContainerStyle={styles.infoPhoneButton}
            />
          </DivFlexRow>
        )}

        {isVip && <AdvertisementCreatePhoneBlock submitForm={submitForm} setSubmitForm={setSubmitForm} />}

        <DivFlexRow style={styles.submitBlock}>
          <DivFlexRow style={styles.tublerBlock}>
            <TumblerPurple onClick={handleIsPublication} isChecked={submitForm.isPublication} />
            <Text14White>Сделать объявление видимым сразу после публикации</Text14White>
          </DivFlexRow>
          <DivFlexRow style={styles.buttonsBlock}>
            <Button
              text="Отменить публикацию"
              onClick={handleCancelPublication}
              cssButtonStyles={styles.cancellButton}
              isTransparent
              cssTextStyle={styles.cancellButtonText}
            />
            <SubmitButton
              text="Опубликовать объявление"
              cssButton={styles.submitButton}
              textSize={14}
              onClick={handleSubmitForm}
            />
          </DivFlexRow>
        </DivFlexRow>
      </DivFlexColumn>

      {isVipModal && <SubscriptionPaymentModalsContainer onClose={setVipModal.bind(this, false)} />}
    </PageWrapper>
  )
})

export default AdvertisementCreate
