import styles from './HomeCatalogHomeCatalogSection.module.css'

import { HomeCatalogCarInfoContainer } from './HomeCatalogCarInfoContainer'
import { HomeCatalogCategoryInfoContainer } from './HomeCatalogCategoryInfoContainer'
import { HomeCatalogNormalCarCard } from './HomeCatalogNormalCarCard'
import { BackFilter } from '../../components/BackFilter'
import { Ellipse } from '../../../../ui/Ellipse'
import { Text18White, Text24White, Text48Grey } from 'components/styledComponents/text/textStyles'
import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'

import Back from './back-bottom-right.svg'

const HomeCatalogSection = () => {
  return (
    <section className={styles.container}>
      <BackFilter backContainer={Back} style={{ top: '138px' }} />

      <div className={styles.carSection}>
        <HomeCatalogNormalCarCard />
        <HomeCatalogCarInfoContainer />
        <HomeCatalogCategoryInfoContainer />
      </div>

      <div className={styles.textSection}>
        <Text24White style={styles.number}>01</Text24White>
        <Text48Grey style={styles.headerText}>Каталог</Text48Grey>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Ellipse />
            <Text18White>Все предметы сервера собраны в одном месте и разбиты по категориям</Text18White>
          </li>
          <li className={styles.listItem}>
            <Ellipse />
            <Text18White>Каждый предмет заскринен, включая расцветки всей одежды</Text18White>
          </li>
          <li className={styles.listItem}>
            <Ellipse />
            <Text18White>
              По каждому предмету расписаны <br /> его характеристики
            </Text18White>
          </li>
        </ul>
      </div>

      <PurpleNeonButton text="Начать торговать" cssStyle={styles.button} />
    </section>
  )
}

export { HomeCatalogSection }
