import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import styles from './ReferralHeaderCard.module.css'

import { Text14Grey, Text40White, Text24Grey } from 'components/styledComponents/text/textStyles'

type ReferralHeaderCardProps = {
  text: string
  count: number
  sub: string
  icon: string
}

const ReferralHeaderCard = ({ text, count, sub, icon }: ReferralHeaderCardProps) => {
  return (
    <DivFlexColumn style={styles.card}>
      <Text14Grey style={styles.cardText}>{text}</Text14Grey>
      <DivFlexRow style={styles.cardData}>
        <Text40White style={styles.cardCount}>{count}</Text40White>
        <Text24Grey style={styles.cardSub}>{sub}</Text24Grey>
        <img src={icon} alt="card icon" className={styles.cardIcon} />
      </DivFlexRow>
    </DivFlexColumn>
  )
}

export { ReferralHeaderCard }
