import styles from './ChatSystem.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { ChatPageHeaderBlock } from 'components/ui/Chat/ChatPageHeaderBlock'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { ChatPageDialogueBlock } from 'components/ui/Chat/ChatPageDialogueBlock'

import { useEffect, useState } from 'react'
import { chatClientStore } from '../../../store/chat/chat'
import { observer } from 'mobx-react-lite'
import PageWrapper from '../../ui/wrappers/PageWrapper/PageWrapper'
import { LoaderPage } from '../../ui/loaders/LoaderPage'

const ChatSystem = observer(() => {
  const { loadChatSystemMessageList, systemMessages } = chatClientStore
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    loadChatSystemMessageList().then(() => setLoading(false))
  }, [])

  // для определения размера окна чата
  const anyDivsHeight = 400
  const chatHeight = window.innerHeight - anyDivsHeight

  if (isLoading) {
    return (
      <PageWrapper>
        <LoaderPage />
      </PageWrapper>
    )
  }

  const formattedMessage = () => {
      return systemMessages.map((message) => ({
          id: message.id,
          text: message.text,
          userId: '',
          createdAt: message.createdAt,
      }))
  }

  return (
    <div className={styles.section} style={{ height: chatHeight }}>
      <DivFlexColumn style={styles.chat}>
        <div className={styles.companionBlock}>
          <ChatPageHeaderBlock status={'online'} companionName={'площадкой'} interlocutorAvatarUrl={''} />
        </div>

        <HorizontalDivider cssStyle={styles.divider} />

        <ChatPageDialogueBlock
          messages={formattedMessage()}
          interlocutor={{
            id: '',
            nickname: 'System',
            activeStatus: 'online'
          }}
        />
      </DivFlexColumn>
    </div>
  )
})

export { ChatSystem }
