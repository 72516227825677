import styles from './ReferralGetMoneyModal.module.css'

import { Text16Grey } from 'components/styledComponents/text/textStyles'
import { DinamicModal } from '../DinamicModal'
import { DinamicModalHeader } from '../DinamicModal/DinamicModalHeader'
import { BankCardInput } from 'components/ui/inputs/BankCardInput'
import { useEffect, useState } from 'react'
import { MoneyInput } from 'components/ui/inputs/MoneyInput'
import { SubmitButton } from 'components/ui/buttons/SubmitButton'
import useMoneyInputValidationHook from 'utils/forms/useMoneyInputValidationHook'
import useBankCardValidation from 'utils/forms/useBankCardValidationHook'
import { createReferralDisbursement } from 'api/referral/referral'

const modalSubText = 'Для вывода средств заполните форму ниже. Вывод производится вручную. Только РФ карты!'

type ReferralGetMoneyModalProps = {
  isOpen: boolean
  onClose: () => void
}

const ReferralGetMoneyModal = ({ isOpen, onClose }: ReferralGetMoneyModalProps) => {
  const [moneyInput, setMoneyInput] = useState<string>('')
  const [cardInput, setCatdInput] = useState<string>('')
  const [isValidForm, setValidForm] = useState<boolean>(false)

  const [isLoading, setLoading] = useState<boolean>(false)

  const { isValid: isMoneyValid, errorText: moneyErrorText } = useMoneyInputValidationHook(moneyInput)
  const { isValid: isCardValid, errorText: cardErrorText } = useBankCardValidation(cardInput)

  const handleSubmitButton = async () => {
    if (isValidForm) {
      setLoading(true)
      const result = await createReferralDisbursement(Number(moneyInput), cardInput)
      if (result) {
        onClose()
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (moneyInput.length !== 0 && cardInput.length !== 0 && isMoneyValid && isCardValid) {
      setValidForm(true)
    } else {
      setValidForm(false)
    }
  }, [moneyInput.length, cardInput.length, isMoneyValid, isCardValid])

  return (
    <DinamicModal isOpen={isOpen} onClose={onClose} width={400}>
      <DinamicModalHeader text="Вывод средств" onClose={onClose} />

      <Text16Grey>{modalSubText}</Text16Grey>

      <div className={styles.inputsBlock}>
        <MoneyInput value={moneyInput} onChange={setMoneyInput} isValid={isMoneyValid} errorText={moneyErrorText} />

        <BankCardInput value={cardInput} onChange={setCatdInput} isValid={isCardValid} errorText={cardErrorText} />
      </div>

      <SubmitButton text="Создать заявку" isActive={isValidForm} isLoading={isLoading} onClick={handleSubmitButton} />
    </DinamicModal>
  )
}

export { ReferralGetMoneyModal }
