import { action, makeObservable, observable } from 'mobx'
import { SearchPagination } from 'store/platformConfigStore/types'

export type ReferralStatisticsType = {
  countRegistrations: number
  countTransactions: number
  sumDisbursement: number
  availableMoney: null | number
}

export type ReferralPromoCodeType = {
  id: string
  name: string
  countRegister: number
  countPay: number
  createdAt: string
}

export type ReferralPromoCodeListType = {
  pagination: SearchPagination
  promoCodes: ReferralPromoCodeType[]
}

export type ReferralDisbursementType = {
  id: 0
  amount: 0
  details: string
  status: string
  createdAt: string
}

export type ReferralDisbursementsListType = {
  pagination: SearchPagination
  disbursements: ReferralDisbursementType[]
}

class ReferralStore {
  referralStatistic: ReferralStatisticsType = {
    countRegistrations: 0,
    countTransactions: 0,
    sumDisbursement: 0,
    availableMoney: null
  }

  promoCodeList: ReferralPromoCodeListType = {
    pagination: {
      totalCount: 0,
      currentPage: 0,
      take: 0
    },
    promoCodes: []
  }

  disbursementsList: ReferralDisbursementsListType = {
    pagination: {
      totalCount: 0,
      currentPage: 0,
      take: 0
    },
    disbursements: []
  }

  constructor() {
    makeObservable(this, {
      referralStatistic: observable,
      getStatistics: action,
      promoCodeList: observable,
      getPromoCodes: action,
      disbursementsList: observable,
      getDisbursements: action
    })
  }

  getPromoCodes = (newList: ReferralPromoCodeListType) => {
    this.promoCodeList = newList
  }

  getDisbursements = (newList: ReferralDisbursementsListType) => {
    this.disbursementsList = newList
  }

  getStatistics = (newStatistics: ReferralStatisticsType) => {
    this.referralStatistic = newStatistics
  }
}

const referralStore = new ReferralStore()

export { referralStore }
