import styles from './HomeWelcomeSection.module.css'

import { ProductTradeCard } from './HomeWelcomeProductTradeCard'
import { ContactContainer } from '../../components/ContactContainer'
import { ContactMessageContainer } from '../../components/ContactContainerMessage'
import { BackFilter } from '../../components/BackFilter'
import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text18Grey, Text64White } from 'components/styledComponents/text/textStyles'
import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'

import AvatarOne from '../../../../icons/stock/stock-avatar-1.png'
import AvatarTwo from '../../../../icons/stock/stock-avatar-2.png'
import back from './back-filter-home-page.svg'
import AuthModal from 'components/ui/modals/AuthModal/AuthModal'
import { useState } from 'react'

const headerText = 'Торговая площадка для GTA5RP'
const subText = 'RP.Market - первая торговая площадка для обмена внутри-игровыми предметами на серверах GTA5RP.'

const firstContact = {
  name: 'Sergei G.',
  who: '(Вы)',
  date: '20:45 23/03/2023',
  message: 'Привет! Машина 🔥 Еще продаётся?'
}

const secondContact = {
  name: 'mazafaker01',
  date: '20:45 23/03/2023',
  message: 'Йоу! Да, еще продаю, давай обсудим '
}

const HomeWelcomeSection = () => {
  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <>
      <section className={styles.container}>
        <BackFilter backContainer={back} style={{ top: '-133px', left: 0 }} />

        <DivFlexColumn style={styles.textContainer}>
          <Text64White style={styles.headerText}>{headerText}</Text64White>
          <Text18Grey style={styles.subText}>{subText}</Text18Grey>
          <PurpleNeonButton
            text="Зарегистрироваться"
            cssStyle={styles.button}
            fontSize={14}
            onClick={setOpen.bind(this, true)}
          />
        </DivFlexColumn>

        <div className={styles.imageContainer}>
          <ProductTradeCard />

          <DivFlexColumn style={styles.contactBlock}>
            <ContactContainer containerStyle={styles.contactContainer} />

            <ContactMessageContainer
              avatar={AvatarTwo}
              name={firstContact.name}
              who={firstContact.who}
              date={firstContact.date}
            >
              {firstContact.message}
            </ContactMessageContainer>

            <ContactMessageContainer avatar={AvatarOne} name={secondContact.name} date={secondContact.date}>
              {secondContact.message} <span className={styles.span}>👍🏻</span>
            </ContactMessageContainer>
          </DivFlexColumn>
        </div>
      </section>

      {isOpen && <AuthModal isOpen={isOpen} onClose={setOpen.bind(this, false)} />}
    </>
  )
}

export { HomeWelcomeSection }
