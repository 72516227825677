export const buttonsArray = [
  {
    text: 'Транспорт',
    icon: require('../../icons/bike.svg').default
  },
  {
    text: 'Одежда',
    icon: require('../../icons/clothes.svg').default
  },
  {
    text: 'Амуниция',
    icon: require('../../icons/gun.svg').default
  },
  {
    text: 'Пища',
    icon: require('../../icons/hamburger.svg').default
  },
  {
    text: 'Скины',
    icon: require('../../icons/rounded.svg').default
  },
  {
    text: 'Добыча',
    icon: require('../../icons/fish.svg').default
  },
  {
    text: 'Услуги',
    icon: require('../../icons/trade.svg').default
  },
  {
    text: 'Имущество',
    icon: require('../../icons/home.svg').default
  },
  {
    text: 'Черный рынок',
    icon: require('../../icons/money.svg').default
  },
  {
    text: 'Прочее',
    icon: require('../../icons/other.svg').default
  }
]
