import styles from './ContactContainerMessage.module.css'

import { ReactNode } from 'react'

type ContactMessageContainerProps = {
  avatar: string
  name: string
  who?: string
  date: string
  children: ReactNode
}

const ContactMessageContainer = ({ avatar, name, who, date, children }: ContactMessageContainerProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.avatar} src={avatar} alt="avatar" />

        <div className={styles.dataContainer}>
          <div className={styles.nameContainer}>
            <p className={styles.name}>{name}</p>
            {who && <p className={styles.who}>{who}</p>}
          </div>

          <p className={styles.date}>{date}</p>
        </div>
      </div>

      <p className={styles.message}>{children}</p>
    </div>
  )
}

export { ContactMessageContainer }
