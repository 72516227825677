import styles from './CheckboxItem.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import { useEffect, useState } from 'react'

import Checked from '../../../icons/button/checked-icon.svg'
import UnCheked from '../../../icons/button/un-checked-icon.svg'
import Disable from '../../../icons/lock-icon.svg'

type CheckboxItemProps = {
  isCheck: boolean
  isBlocked?: boolean
}

const CheckboxItem = ({ isCheck, isBlocked = false }: CheckboxItemProps) => {
  const [isChecked, setCheck] = useState<boolean>(isCheck)

  const handleClick = () => {
    if (!isBlocked) {
      setCheck(!isChecked)
    }
  }

  useEffect(() => {
    if (!isBlocked) {
      setCheck(isCheck)
    }
  }, [isCheck])

  return (
    <>
      {!isBlocked ? (
        <DivFlexRow style={styles.container} onClick={handleClick}>
          {isChecked ? <ImgBase img={Checked} alt="" /> : <ImgBase img={UnCheked} alt="" />}
        </DivFlexRow>
      ) : (
        <img src={Disable} alt="lock" className={styles.lock} />
      )}
    </>
  )
}

export { CheckboxItem }
