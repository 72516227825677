import { action, makeObservable, observable } from 'mobx'

export type SubscriptionsInfoType = {
  id: string
  name: string
  term: number
  price: number
  discount: number
  priceDiscount: number | null
  discountPromoCode: null | number
  pricePromoCode: null | number
}

class SubscriptionSore {
  subscriptionsInfo: SubscriptionsInfoType[] = []

  constructor() {
    makeObservable(this, {
      subscriptionsInfo: observable,
      setSubscriptionsInfo: action
    })
  }

  setSubscriptionsInfo = (subscriptions: SubscriptionsInfoType[]) => {
    this.subscriptionsInfo = subscriptions
  }
}

const subscriptionSore = new SubscriptionSore()

export { subscriptionSore }
