import { getCatalogSearch } from '../api/catalog/catalog.method'
import { catalogStore } from '../store/catalog/catalogStore'
import { userPlatformConfig } from '../store/platformConfigStore/platformConfigStore'
import errorAlerts from '../store/errorAlertsStore'
import { MessageType } from '../components/ui/errors/AlertError'
import { convertJsonToSearchParams } from '../utils/searchParams'
import { loadingStore } from '../store/loadingStore'

export const loadCatalog = async () => {
  const { setLoadingItems } = loadingStore
  const {
    textBySearch,
    categoryIdBySearch,
    paginationSkip,
    setCatalogList,
    valueFilters,
    paginationTake,
    sortSelectedOrderBy,
    sortSelectedItem
  } = catalogStore
  const { currentServer } = userPlatformConfig

  try {
    setLoadingItems(true)
    const result = await getCatalogSearch({
      serverId: currentServer.id,
      name: textBySearch.length > 0 ? textBySearch : undefined,
      categoryId: categoryIdBySearch,
      paginationSkip: paginationSkip,
      paginationTake: paginationTake,
      filter: convertJsonToSearchParams(valueFilters),
      sort: {
        field: sortSelectedItem.key,
        order: sortSelectedOrderBy.key
      }
    })
    setCatalogList(result)
  } catch (e) {
    console.log(e)
    errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
  } finally {
    setLoadingItems(false)
  }
}
