import styles from './ReferralStatistic.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text20Green, Text32White } from 'components/styledComponents/text/textStyles'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { ReferralHeaderCard } from '../ReferralHeaderCard'

import UserPlusIcon from '../../../icons/referral/user-plus.svg'
import UserCheckIcon from '../../../icons/referral/user-check.svg'
import CoinsIcon from '../../../icons/referral/coins.svg'
import WalletIcon from '../../../icons/referral/wallet.svg'
import HandIcon from '../../../icons/referral/coin_hand.svg'
import { observer } from 'mobx-react-lite'
import { referralStore } from 'store/referralStore/refferralStore'
import { useEffect, useState } from 'react'
import { getReferralStatistic } from 'api/referral/referral'
import Loader from 'components/ui/loaders/Loader/Loader'

type ReferralStatisticProps = {
  handleOpenModal: () => void
}

const ReferralStatistic = observer(({ handleOpenModal }: ReferralStatisticProps) => {
  const { referralStatistic } = referralStore

  const { countRegistrations, countTransactions, sumDisbursement, availableMoney } = referralStatistic

  const [isLoader, setLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getReferralStatistic()
      setLoading(false)
    })()
  }, [])

  if (isLoader) {
    return (
      <DivFlexColumn style={styles.container}>
        <Loader anyStyles={{ margin: '88px auto' }} />
      </DivFlexColumn>
    )
  }

  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexRow style={styles.header}>
        <Text32White>Статистика</Text32White>

        <OvalButton text="Доступно к выплате" cssContainerStyle={styles.availableButton} />

        <img src={WalletIcon} alt="wallet" className={styles.walletIcon} />

        <Text20Green style={styles.price}>{availableMoney ?? 0}</Text20Green>
        <Text20Green style={styles.ruble}>₽</Text20Green>

        <OvalButton
          text="Вывести средства"
          icon={HandIcon}
          cssContainerStyle={styles.getCoin}
          onClick={handleOpenModal}
        />
      </DivFlexRow>

      <DivFlexRow style={styles.main}>
        <ReferralHeaderCard
          text="Общее кол-во зарегистрированных"
          count={countRegistrations}
          sub="чел."
          icon={UserPlusIcon}
        />
        <ReferralHeaderCard
          text="Общее кол-во оплативших подписку"
          count={countTransactions}
          sub="человек"
          icon={UserCheckIcon}
        />
        <ReferralHeaderCard text="Выплачено" count={sumDisbursement} sub="₽" icon={CoinsIcon} />
      </DivFlexRow>
    </DivFlexColumn>
  )
})

export { ReferralStatistic }
