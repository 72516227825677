import styles from './AdvertisementMyCard.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Grey, Text18Grey } from 'components/styledComponents/text/textStyles'
import { AdvertisementMyCardStatus } from '../AdvertisementMyCardStatus'
import { AdvertisementMyCardButtonsBlock } from '../AdvertisementMyCardButtonsBlock'
import { formatPrice } from 'utils/formatPrice'
import { useEffect, useState } from 'react'
import { getCardImage } from 'api/catalog/catalog.method'
import { MyAdvertisementsResponseDto } from 'store/advertisement/advertisementMyStore/types'
import { ProductBigImage } from 'components/ui/image/ProductBigImage'
import { NavigateLinks } from '../../../../PageRouters'

type AdvertisementMyCardProps = {
  cardData: MyAdvertisementsResponseDto
  onDeleteButtonClick: (cardData: MyAdvertisementsResponseDto) => void
}

const AdvertisementMyCard = ({ cardData, onDeleteButtonClick }: AdvertisementMyCardProps) => {
  const { id, name, type, price, count, sourceCategory, server, status, expirationAt, imageId } = cardData

  const isDisable = status === 'hidden'
  const typeText = type === 'sale' ? 'Продажа' : 'Покупка'

  const [cardImage, setCardImage] = useState<string>('')

    const openAdvertisement = () => {
        const linkAdvertisement = `${location.protocol}//${window.location.host}/${NavigateLinks.AdvertisementCard}/${id}`
        window.open(linkAdvertisement)
    }

  useEffect(() => {
    ;(async () => {
      const image = await getCardImage(imageId)
      setCardImage(image)
    })()
  }, [imageId])

  return (
    <DivFlexRow style={styles.card}>
      <div className={styles.photoBlock}>
        <ProductBigImage width={120} height={120} image={cardImage} onClick={openAdvertisement} />
        {isDisable && <span className={styles.photoFilter} />}
      </div>

      <DivFlexColumn style={styles.body}>
        <DivFlexRow style={styles.headerBlock}>
          <DivFlexColumn style={styles.cardNameBlock}>
            <p className={`${styles.cardName} ${isDisable && styles.disable}`}>{name}</p>
            <Text14Grey>{sourceCategory}</Text14Grey>
          </DivFlexColumn>

          <DivFlexRow style={`${styles.priceBlock} ${isDisable && styles.disable}`}>
            <p className={`${styles.price} ${isDisable && styles.disable}`}>{formatPrice(price)}</p>
            <p className={`${styles.dolar} ${isDisable && styles.disable}`}>$</p>
          </DivFlexRow>

          <DivFlexRow style={styles.count}>
            <Text18Grey>{formatPrice(count)} ед</Text18Grey>
          </DivFlexRow>

          <DivFlexRow style={styles.count}>
            <Text18Grey>{typeText}</Text18Grey>
          </DivFlexRow>

          <AdvertisementMyCardStatus status={status} expirationAt={expirationAt} />
        </DivFlexRow>

        <DivFlexRow style={styles.footerBlock}>
          <DivFlexRow style={`${styles.server} ${isDisable && styles.disable}`}>
            <span className={`${styles.serverCircle} ${isDisable && styles.disable}`} />
            <p className={`${styles.serverName} ${isDisable && styles.disable}`}>{server}</p>
          </DivFlexRow>

          <AdvertisementMyCardButtonsBlock
            status={status}
            onDeleteButtonClick={onDeleteButtonClick.bind(this, cardData)}
            advertisementId={cardData.id}
          />
        </DivFlexRow>
      </DivFlexColumn>
    </DivFlexRow>
  )
}

export { AdvertisementMyCard }
