import styles from './ButtonDropdown.module.css'
import { FaqType } from 'store/faqListStore'
import ArrowRight from '../../../icons/arrow_right_purple.svg'
import { useState } from 'react'

type ButtonDropdownProps = {
  item: FaqType
  activeButton: FaqType | null
  onItemClick: (item: FaqType) => void
  onToggleDropdown: (item: FaqType) => void
  activeChildId: string
}

const ButtonDropdown = ({ item, activeButton, onItemClick, onToggleDropdown, activeChildId }: ButtonDropdownProps) => {
  const isActiveItem = activeButton === item
  const isOpenItem = isActiveItem && !!item.articles.length

  const [isActiveChild, setActiveChild] = useState<string | null>(activeChildId)

  const handleToggleDropdown = () => {
    onToggleDropdown(item)
    setActiveChild(null)
  }

  const handleItemClick = (item: FaqType) => {
    onItemClick(item)
    setActiveChild(item.id)
  }

  return (
    <div className={styles.container}>
      <button className={`${styles.button} ${isActiveItem && styles.buttonActive}`} onClick={handleToggleDropdown}>
        <p className={styles.text}>{item.name}</p>
        <img className={`${styles.icon} ${isActiveItem && styles.rotated}`} src={ArrowRight} alt="dropdown icon"></img>
      </button>
      {isOpenItem && (
        <ul className={styles.items}>
          {item.articles.map((item) => (
            <li className={styles.item} key={item.id} onClick={handleItemClick.bind(this, item)}>
              <p className={`${styles.text} ${isActiveChild === item.id && styles.activeText}`}>{item.name}</p>
              <img className={styles.icon} src={ArrowRight} alt="dropdown icon"></img>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export { ButtonDropdown }
