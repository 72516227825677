import { CSSProperties } from 'react'
import styles from './StringWithIconButton.module.css'
import { Link } from 'react-router-dom'

type StringWithIconButtonProps = {
  text: string
  icon?: string
  textStyle?: CSSProperties
  router?: string
  onClick?: () => void
  iconStyle?: CSSProperties
  isReverse?: boolean
}

const StringWithIconButton = ({
  text,
  icon,
  textStyle,
  router,
  onClick,
  iconStyle,
  isReverse
}: StringWithIconButtonProps) => {
  const handleClick = (e: any) => {
    e.stopPropagation()
    if (onClick) {
      onClick()
    }
  }

  if (router) {
    return (
      <Link to={router} className={styles.container} onClick={handleClick}>
        {icon && <img className={styles.icon} src={icon} style={{ ...iconStyle }} alt="icon"></img>}
        <p className={styles.text} style={{ ...textStyle }}>
          {text}
        </p>
      </Link>
    )
  }

  if (isReverse) {
    return (
      <button className={styles.container} onClick={handleClick}>
        <p className={styles.text} style={{ ...textStyle }}>
          {text}
        </p>
        {icon && <img className={styles.icon} src={icon} style={{ ...iconStyle }} alt="icon"></img>}
      </button>
    )
  }

  return (
    <button className={styles.container} onClick={handleClick}>
      {icon && <img className={styles.icon} src={icon} style={{ ...iconStyle }} alt="icon"></img>}
      <p className={styles.text} style={{ ...textStyle }}>
        {text}
      </p>
    </button>
  )
}

export default StringWithIconButton
