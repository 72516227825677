import { UserInfoType, userInfoStore } from 'store/userInfoStore'
import api from '../axios/axiosInstance'
import { chatClientStore } from '../../store/chat/chat'

const getUserInfo = async (): Promise<UserInfoType> => {
  const { setAuth, setLogOut, setUserInfo, isSubscriptionExpired, checkUserRoles, jwtToken } = userInfoStore
  const { connectSocket } = chatClientStore

  try {
    const data: UserInfoType = await api.get('/api/user/info')

    if (jwtToken) connectSocket(jwtToken)
    setAuth()
    setUserInfo(data)
    isSubscriptionExpired()
    checkUserRoles()

    return data
  } catch (error: any) {
    console.log(error)

    setLogOut()

    if (error.response && error.response.status === 401) {
      console.log('Не авторизован')
    }

    return error
  }
}

export { getUserInfo }
