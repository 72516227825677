import styles from './CountAlertMarker.module.css'

type CountAlertMarkerProps = {
  text: string
}

const TextAlertMarker = ({ text }: CountAlertMarkerProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default TextAlertMarker
