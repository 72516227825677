import { BaseInput } from '../BaseInput'
import { formatOnlyNumber } from 'utils/forms/helpers'

type MoneyInputProps = {
  value: string
  onChange: (value: string) => void
  isValid: boolean
  errorText: string
}

const MoneyInput = ({ value, onChange, isValid, errorText }: MoneyInputProps) => {
  const placeHolder = 'Доступная сумма вывода'
  const bottomText = 'Сумма'

  return (
    <BaseInput
      value={value}
      onChange={onChange}
      valueFormattedFunction={formatOnlyNumber}
      validationError={errorText}
      isValid={isValid}
      placeHolder={placeHolder}
      bottomText={bottomText}
      maxLength={19}
    />
  )
}

export { MoneyInput }
