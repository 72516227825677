import { ReactNode, useEffect, useRef } from 'react'

type DropdownWrapperProps = {
  children: ReactNode
  onClose: () => void
}

const DropdownWrapper = ({ children, onClose }: DropdownWrapperProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Проверяем, кликнули ли вне dropdownRef
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        onClose() // Закрываем dropdown, если кликнули вне него
      }
    }

    // Добавляем обработчик события 'click' к глобальному объекту document
    document.addEventListener('click', handleOutsideClick)

    return () => {
      // Удаляем обработчик события при размонтировании компонента
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [onClose])

  return <div ref={dropdownRef}>{children}</div>
}

export { DropdownWrapper }
