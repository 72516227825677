import { DivFlexColumn } from 'components/styledComponents/div/div'
import styles from './ShopBarCardInfo.module.css'
import { Text14Grey, Text18White } from 'components/styledComponents/text/textStyles'
import { ShopBarCardPrice } from '../ShopBarCardPrice'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import InfoIcon from './Info.svg'

type ShopBarCardInfoProps = {
  name: string
  sourceCategory: string
  price?: string
  currency?: string
  onClick: () => void
}

const ShopBarCardInfo = ({ name, sourceCategory, price, currency, onClick }: ShopBarCardInfoProps) => {
  return (
    <DivFlexColumn style={styles.aboutBlock}>
      <Text18White>{name}</Text18White>

      {!!sourceCategory.length && <Text14Grey style={styles.categoryText}>{sourceCategory}</Text14Grey>}

      <ShopBarCardPrice price={price} currency={currency} />

      <OvalButton
        text="Подробнее"
        icon={InfoIcon}
        cssContainerStyle={styles.infoButton}
        isSwapContent
        onClick={onClick}
      />
    </DivFlexColumn>
  )
}

export { ShopBarCardInfo }
