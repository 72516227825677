import styles from './PhotoSlider.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { ArrowInCircle } from '../ArrowInCircle'
import { ImgBase } from 'components/styledComponents/img/img'
import Loader from '../loaders/Loader/Loader'

type Image = {
  id: string
  name: string
  image?: string
}

type PhotoSliderProps = {
  loadedImages: Image[]
  handleNextImage: () => void
  handlePreviousImage: () => void
}

const PhotoSlider = ({ loadedImages, handleNextImage, handlePreviousImage }: PhotoSliderProps) => {
  return (
    <DivFlexRow style={styles.photoSlider}>
      <ArrowInCircle onClick={handlePreviousImage} isActive={true} isLeft />

      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <div className={styles.photoBlock}>
          {loadedImages.map((loadedImage, index) => (
            <div key={loadedImage.id && index} style={{ opacity: loadedImage.id === loadedImages[0].id ? 1 : 0.5 }}>
              <div className={styles.imageContainer}>
                {loadedImage.image ? <ImgBase img={loadedImage.image} style={styles.photo} /> : <Loader />}
              </div>
            </div>
          ))}
        </div>
      </div>

      <ArrowInCircle onClick={handleNextImage} isActive={true} isRight />
    </DivFlexRow>
  )
}

export { PhotoSlider }
