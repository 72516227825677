import styles from './AdvertisementProductModal.module.css'
import { getCardImage } from 'api/catalog/catalog.method'
import { useState, useEffect } from 'react'
import { userInfoStore } from 'store/userInfoStore'
import { DivFlexColumn } from 'components/styledComponents/div/div'
import { ShopBarChartBlock } from 'components/ui/ShopBar/ShopBarChartBlock'
import { EditorCatalogGalleryColorsMenu } from 'components/ui/editor/EditorCatalogGalleryColorsMenu'
import { ModalWrapper } from 'components/ui/wrappers/ModalWrapper'
import { ShopBarGallery } from '../ShopBarModal/ShopBarGallery'
import { ShopBarModalCatalogData } from '../ShopBarModal/ShopBarModalCatalogData'
import { ShopBarModalData } from '../ShopBarModal/ShopBarModalData'
import { ShopBarModalHeader } from '../ShopBarModal/ShopBarModalHeader'
import { ShopBarProductDescription } from '../ShopBarModal/ShopBarProductDescription'
import { IGetResAdvertisementListItem } from '../../../../api/advetrisement/shopbar/advertisement.type'

type Image = {
  id: string
  name: string
  image?: string
}

type AdvertisementProductModalProps = {
  isOpen: boolean
  onClose: () => void
  cardData: IGetResAdvertisementListItem
  baseImage: Image
}

const AdvertisementProductModal = ({ isOpen, onClose, cardData, baseImage }: AdvertisementProductModalProps) => {
  const { isEditor } = userInfoStore

  const {
    id,
    name,
    price,
    catalogDescription,
    sourceCategory,
    images,
    propertyCatalogPage,
    propertyAdvertisementPage,
    user,
    gamePhoneId,
    catalogId,
    status,
    currency
  } = cardData

  const [loadedImages, setLoadedImages] = useState<Image[]>([])

  const [activeImage, setActiveImage] = useState<Image>(baseImage)

  const isActiveStatus = status === 'active'

  useEffect(() => {
    const loadImages = async () => {
      // это потому что мы хотим на каждой картинке крутилку пока она грузится...
      const fakeImages = Array.from(images.slice(1))
      setLoadedImages([baseImage, ...fakeImages])

      // Асинхронная загрузка остальных изображений, кроме первого
      const remainingImages = cardData.images.slice(1)
      const loadedImagePromises = remainingImages.map(async (image) => {
        const getImage = await getCardImage(image.id)
        return { id: image.id, name: image.name, image: getImage }
      })

      const loadedImages = await Promise.all(loadedImagePromises)

      // Объединение baseImage и загруженных остальных изображений в один массив
      const allImages = [baseImage, ...loadedImages]

      setLoadedImages(allImages)
    }

    loadImages()
  }, [baseImage, cardData.images, images])

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} isPortal>
      <DivFlexColumn style={`${styles.modal} ${styles.advertisement}`}>
        <ShopBarModalHeader
          activeImage={activeImage}
          name={name}
          onClose={onClose}
          sourceCategory={sourceCategory}
          price={String(price)}
          isActiveStatus={isActiveStatus}
          user={user}
          gamePhoneId={gamePhoneId}
          catalogId={id}
          page={'advertisement'}
          currency={currency}
          advertisementId={id}
        />

        {isActiveStatus && (
          <ShopBarGallery
            cssContainer={styles.gallery}
            loadedImages={loadedImages}
            activeImage={activeImage}
            setActiveImage={setActiveImage}
          />
        )}

        {isEditor && <EditorCatalogGalleryColorsMenu imageId={activeImage.id} />}

        {isActiveStatus && (
          <ShopBarModalData
            headerText="Данные продаваемого предмета"
            property={propertyAdvertisementPage}
            isVisible={!!propertyAdvertisementPage.length}
          />
        )}

        {isActiveStatus && (
          <ShopBarModalCatalogData
            headerText="Данные предмета в каталоге"
            property={propertyCatalogPage}
            id={catalogId}
          />
        )}

        {!!catalogDescription && isActiveStatus && (
          <ShopBarProductDescription description={catalogDescription} mt={8} />
        )}

        {isActiveStatus && <ShopBarChartBlock headerText="График изменения цены" />}
      </DivFlexColumn>
    </ModalWrapper>
  )
}

export { AdvertisementProductModal }
