import styles from './PagintaionNumberButton.module.css'

type PagintaionNumberButtonProps = {
  count: number
  isActive?: boolean
  onClick?: () => void
}

const PagintaionNumberButton = ({ count, isActive, onClick }: PagintaionNumberButtonProps) => {
  return (
    <p className={`${styles.button} ${isActive && styles.active}`} onClick={onClick}>
      {count}
    </p>
  )
}

export { PagintaionNumberButton }
