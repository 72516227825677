import styles from './HomeNotificationsMessageContainer.module.css'

import { DarkGreyContainer } from 'components/pages/Home/components/DarkGreyContainer'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text12Grey, Text16White } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'

import Icon from './chat-icon.svg'
import Logo from './logo-rounded.svg'

const firstMessage = 'Вам написал Trevor по объявлению "BMW X7'
const secodnMessage = 'Мы нашли интересующий Вас предмет!'

const HomeNotificationsMessageContainer = () => {
  return (
    <DivFlexColumn style={styles.section}>
      <DarkGreyContainer style={styles.container}>
        <DivFlexRow>
          <ImgBase img={Logo} style={styles.logo} />

          <DivFlexColumn style={styles.headerText}>
            <Text16White>RP Market</Text16White>
            <Text12Grey>20:45 23/03/2023</Text12Grey>
          </DivFlexColumn>

          <ImgBase img={Icon} style={styles.icon} />
        </DivFlexRow>

        <HorizontalDivider cssStyle={styles.divider} />

        <Text16White style={styles.text}>{firstMessage}</Text16White>
      </DarkGreyContainer>

      <DarkGreyContainer style={styles.container}>
        <DivFlexRow>
          <ImgBase img={Logo} style={styles.logo} />

          <DivFlexColumn style={styles.headerText}>
            <Text16White>RP Market</Text16White>
            <Text12Grey>20:45 23/03/2023</Text12Grey>
          </DivFlexColumn>

          <ImgBase img={Icon} style={styles.icon} />
        </DivFlexRow>

        <HorizontalDivider cssStyle={styles.divider} />

        <Text16White style={styles.text}>{secodnMessage}</Text16White>
      </DarkGreyContainer>
    </DivFlexColumn>
  )
}

export { HomeNotificationsMessageContainer }
