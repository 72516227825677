import styles from './ChatPageAvatar.module.css'

import NoAvatarIcon from '../../../icons/chat/no_avatar_icon.svg'

type ChatPageAvatarProps = {
  avatar?: string
}

const ChatPageAvatar = ({ avatar }: ChatPageAvatarProps) => {
  return <img className={`${styles.avatar} ${!avatar && styles.disable}`} src={avatar || NoAvatarIcon} alt="avatar" />
}

export { ChatPageAvatar }
