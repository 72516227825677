import styles from './AdvertisementCreateHeader.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { Text32White } from 'components/styledComponents/text/textStyles'

import Icon from '../../../icons/AdvertisementCreate/package-plus.svg'

const AdvertisementCreateHeader = () => {
  return (
    <DivFlexColumn style={styles.header}>
      <DivFlexRow style={styles.textBlock}>
        <img className={styles.icon} src={Icon} alt="create-advertisement" />
        <Text32White>Создание объявления</Text32White>
      </DivFlexRow>
      <HorizontalDivider cssStyle={styles.divider} />
    </DivFlexColumn>
  )
}

export { AdvertisementCreateHeader }
