import styles from './HomeFiltersShopItemsContainer.module.css'

import { DarkGreyContainer } from 'components/pages/Home/components/DarkGreyContainer'
import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text16Grey, Text16White } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { ArrowInCircle } from 'components/ui/ArrowInCircle'
import { ImgBase } from 'components/styledComponents/img/img'

import HeadOne from '../icons/head_1.svg'
import HeadTwo from '../icons/head_2.svg'
import HeadThree from '../icons/head_3.svg'

const HomeFiltersShopItemsContainer = () => {
  return (
    <DarkGreyContainer style={styles.shopContainer}>
      <DivFlexRow style={styles.shopHeader}>
        <Text16Grey>Выберите расцветку</Text16Grey>
        <HorizontalDivider cssStyle={styles.gorizontalDivider} />
        <Text16Grey style={styles.dropText}>1 / 3</Text16Grey>
      </DivFlexRow>

      <Text16White>Красный</Text16White>

      <DivFlexRow style={styles.shopPageContainer}>
        <ArrowInCircle isActive={false} isLeft />

        <DivFlexRow style={styles.iconsContainer}>
          <ImgBase img={HeadOne} />
          <ImgBase img={HeadTwo} />
          <ImgBase img={HeadThree} />
        </DivFlexRow>

        <ArrowInCircle isActive isRight />
      </DivFlexRow>
    </DarkGreyContainer>
  )
}

export { HomeFiltersShopItemsContainer }
