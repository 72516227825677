import styles from './HomeMobile.module.css'

import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { HomeWelcomeSectionMobile } from './HomeWelcomeSectionMobile'

import { HomeInformationContainer } from './HomeInformationContainer'
import { HomeSubscriptionSectionMobile } from './HomeSubscriptionSectionMobile'
import { catalog, ads, filters, notifications, chart, activity, phone } from './sectionsContent'

const HomeMobile = () => {
  return (
    <PageWrapper style={styles.wrapper}>
      <HomeWelcomeSectionMobile />
      <HomeSubscriptionSectionMobile />

      <HomeInformationContainer
        cardImage={catalog.img}
        sectionNumber={catalog.page}
        listHeader={catalog.header}
        listItem={catalog.text}
        buttonText={catalog.buttonText}
        sectionStyles={styles.catalogStyles}
        numberStyles={styles.catalogNumber}
      />

      <HomeInformationContainer
        cardImage={ads.img}
        sectionNumber={ads.page}
        listHeader={ads.header}
        listItem={ads.text}
        buttonText={ads.buttonText}
        sectionStyles={styles.adsStyles}
        numberStyles={styles.adsNumber}
      />

      <HomeInformationContainer
        cardImage={filters.img}
        sectionNumber={filters.page}
        listHeader={filters.header}
        listItem={filters.text}
        buttonText={filters.buttonText}
        imageStyle={styles.filtersImage}
        sectionStyles={styles.filtersStyles}
        numberStyles={styles.filtersNumber}
        itemStyles={styles.filtersItem}
      />

      <HomeInformationContainer
        cardImage={notifications.img}
        sectionNumber={notifications.page}
        listHeader={notifications.header}
        listItem={notifications.text}
        buttonText={notifications.buttonText}
        imageStyle={styles.notificationsImage}
        sectionStyles={styles.notificationsStyles}
        numberStyles={styles.notificationsNumber}
        itemStyles={styles.notificationsItem}
      />

      <HomeInformationContainer
        cardImage={chart.img}
        sectionNumber={chart.page}
        listHeader={chart.header}
        listItem={chart.text}
        buttonText={chart.buttonText}
        imageStyle={styles.chartImage}
        sectionStyles={styles.chartStyles}
        numberStyles={styles.chartNumber}
        headerStyles={styles.chartHeader}
      />

      <HomeInformationContainer
        cardImage={activity.img}
        sectionNumber={activity.page}
        listHeader={activity.header}
        listItem={activity.text}
        buttonText={activity.buttonText}
        imageStyle={styles.activityImage}
        sectionStyles={styles.activityStyles}
        numberStyles={styles.activityNumber}
        headerStyles={styles.activityHeader}
      />

      <HomeInformationContainer
        cardImage={phone.img}
        sectionNumber={phone.page}
        listHeader={phone.header}
        listItem={phone.text}
        buttonText={phone.buttonText}
        imageStyle={styles.phoneImages}
        sectionStyles={styles.phoneStyles}
        numberStyles={styles.phoneNumber}
        headerStyles={styles.phoneHeader}
        listStyles={styles.headerList}
        itemStyles={styles.phoneItem}
      />
    </PageWrapper>
  )
}

export { HomeMobile }
