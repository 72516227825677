import { MessageType } from 'components/ui/errors/AlertError'
import { makeObservable, observable, action } from 'mobx'

type ErrorItem = {
  errorType: MessageType
  errorText: string
}

class ErrorAlerts {
  array: ErrorItem[] = []

  constructor() {
    makeObservable(this, {
      array: observable,
      addError: action,
      removeError: action
    })
  }

  addError({ errorType, errorText }: { errorType: MessageType; errorText: string }) {
    this.array.unshift({ errorType, errorText })
    setTimeout(() => {
      this.removeError()
    }, 6000)
  }

  removeError() {
    this.array = this.array.filter((error, index) => index !== this.array.length - 1)
  }

  get hasErrors() {
    return this.array.length > 0
  }
}

const errorAlerts = new ErrorAlerts()

export default errorAlerts
