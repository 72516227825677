import Advertisement from 'components/pages/Advertisement/Advertisement'
import AdvertisementCreate from 'components/pages/AdvertisementCreate/AdvertisementCreate'
import AdvertisementMy from 'components/pages/AdvertisementMy/AdvertisementMy'
import ArticleList from 'components/pages/ArticleList/ArticleList'
import Catalog from 'components/pages/Catalog/Catalog'
import Chat from 'components/pages/Chat/Chat'
import { DiscordAuth } from 'components/pages/DiscordCallback/DiscordAuth'
import Home from 'components/pages/Home/Home'
import NotFound from 'components/pages/NotFound/NotFound'
import ProfileSettings from 'components/pages/ProfileSettings/ProfileSettings'
import Referral from 'components/pages/Referral/Referral'
import Subscription from 'components/pages/Subscription/Subscription'
import { Routes, Route } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { HomeMobile } from 'mobileComponents/pages/HomeMobile'
import { CatalogCard } from 'components/pages/CatalogCard'
import { Maintenance } from 'components/pages/Maintenance'
import AdvertisementCard from 'components/pages/AdvertisementCard/AdvertisementCard'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'
import { ChatPage } from 'components/pages/ChatPage'
import { Offer } from '../components/pages/Document/Offer'
import { Agreement } from '../components/pages/Document/Agreement'
import { ChatSystem } from '../components/pages/ChatSystem'
import { DiscordCallbackBind } from '../components/pages/DiscordCallback/DiscordCallbackBind'
import { TelegramCallbackBind } from '../components/pages/TelegramCallback'

export enum NavigateLinks {
  Catalog = '/catalog',
  Advertisement = '/advertisement',
  AdvertisementCard = 'advertisement/card',
  CatalogCard = 'catalog/card'
}

export enum BrowserRouters {
  Home = '/',
  Catalog = '/catalog',
  CatalogCard = '/catalog/card/:cardId',
  Advertisement = '/advertisement',
  AdvertisementCard = '/advertisement/card/:cardId',
  AdvertisementCreate = '/advertisement/create',
  AdvertisementMy = '/advertisement/my',
  Chats = '/chat',
  SystemChat = '/system-chat',
  ChatPage = '/chat/:chatId',
  ProfileSettings = '/profile/settings',
  Referral = '/referral',
  Subscription = '/subscription',
  ArticleList = '/faq',
  ArticleListId = '/faq/:cardId',
  NotFound = '/page/404',
  DiscordAuth = '/auth/discord/oauth2/callback',
  DiscordBindAccountCallback = '/bind-account/discord/callback',
  TelegramBindAccountCallback = '/bind-account/telegram/callback',
  Maintenance = '/503',
  Offer = '/offer',
  Agreement = '/agreement'
}

const routers = [
  {
    path: BrowserRouters.Home,
    element: isMobile ? <HomeMobile /> : <Home />
  },
  {
    path: BrowserRouters.Catalog,
    element: <Catalog />
  },
  {
    path: BrowserRouters.CatalogCard,
    element: <CatalogCard />
  },
  {
    path: BrowserRouters.Advertisement,
    element: <Advertisement />
  },
  {
    path: BrowserRouters.AdvertisementCard,
    element: <AdvertisementCard />
  },
  /* RPMARKET-192: [BE/FE] Выдавать всем подписку автоматически {
    path: BrowserRouters.Subscription,
    element: <Subscription />
  },*/
  {
    path: BrowserRouters.ArticleList,
    element: <ArticleList />
  },
  {
    path: BrowserRouters.ArticleListId,
    element: <ArticleList />
  },
  {
    path: BrowserRouters.DiscordAuth,
    element: <DiscordAuth />
  },
  {
    path: BrowserRouters.Maintenance,
    element: <Maintenance />
  },
  {
    path: BrowserRouters.Offer,
    element: <Offer />
  },
  {
    path: BrowserRouters.Agreement,
    element: <Agreement />
  },
  {
    path: '*',
    element: <NotFound />
  }
]

const PageRouters = observer(() => {
  const { isAuth, isMedia } = userInfoStore

  const authRouters = [
    {
      path: BrowserRouters.ProfileSettings,
      element: <ProfileSettings />
    },
    {
      path: BrowserRouters.Referral,
      element: isMedia ? <Referral /> : <NotFound />
    },
    {
      path: BrowserRouters.AdvertisementCreate,
      element: <AdvertisementCreate />
    },
    {
      path: BrowserRouters.AdvertisementMy,
      element: <AdvertisementMy />
    },
    {
      path: BrowserRouters.Chats,
      element: <Chat />
    },
    {
      path: BrowserRouters.ChatPage,
      element: <ChatPage />
    },
    {
      path: BrowserRouters.SystemChat,
      element: <ChatSystem />
    },
    {
      path: BrowserRouters.DiscordBindAccountCallback,
      element: <DiscordCallbackBind />
    },
    {
      path: BrowserRouters.TelegramBindAccountCallback,
      element: <TelegramCallbackBind />
    }
  ]

  return (
    <Routes>
      {isAuth &&
        authRouters.map((route, index) => {
          return <Route key={index} path={route.path} element={route.element} />
        })}
      {routers.map((route, index) => {
        return <Route key={index} path={route.path} element={route.element} />
      })}
    </Routes>
  )
})

export default PageRouters
