import styles from './DropdownStatusesBase.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text14White, Text14Grey } from 'components/styledComponents/text/textStyles'
import { DropdownWrapper } from 'components/ui/wrappers/DropdownWrapper'
import { useState } from 'react'

import ArrowGrey from '../../../../components/icons/arrow_bottom_grey.svg'
import ArrowPurple from '../../../../components/icons/arrow_bottom_purple.svg'

type DropdownStatusesBaseProps = {
  baseText?: string
  items: string[]
  cssStyles?: string
  onClickItem: (name: string) => void
  cssItemsSection?: string
}

const DropdownStatusesBase = ({
  baseText = '',
  items,
  cssStyles,
  onClickItem,
  cssItemsSection
}: DropdownStatusesBaseProps) => {
  const [text, setText] = useState<string>(baseText === '' ? items[0] : baseText)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleClickItem = (name: string) => {
    setText(name)
    onClickItem(name)
  }

  return (
    <DropdownWrapper onClose={setOpen.bind(this, false)}>
      <DivFlexRow
        style={`${styles.button} ${isOpen && styles.active} ${cssStyles}`}
        onClick={setOpen.bind(this, !isOpen)}
      >
        <Text14White style={styles.text}>{text}</Text14White>

        <ImgBase img={isOpen ? ArrowPurple : ArrowGrey} style={styles.arrow} />

        {isOpen && (
          <div className={styles.dropContainer}>
            <ul className={`${styles.itemsSection} ${cssItemsSection}`}>
              {text !== baseText && (
                <li className={styles.item} onClick={handleClickItem.bind(this, baseText)}>
                  <Text14Grey style={`${styles.itemText}`}>{'Не выбрано'}</Text14Grey>
                </li>
              )}
              {items.map((item, index) => {
                return (
                  <li key={index} className={styles.item} onClick={handleClickItem.bind(this, item)}>
                    <Text14Grey style={`${styles.itemText} ${text === item && styles.active}`}>{item}</Text14Grey>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </DivFlexRow>
    </DropdownWrapper>
  )
}

export { DropdownStatusesBase }
