import styles from './HomeWelcomeSectionTextMobile.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text14Grey, Text31White } from 'components/styledComponents/text/textStyles'
import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'

const headerText = 'Торговая площадка для GTA5RP'
const subText = 'RP.Market - первая торговая площадка для обмена внутри-игровыми предметами на серверах GTA5RP'

const HomeWelcomeSectionTextMobile = () => {
  return (
    <DivFlexColumn style={styles.textContainer}>
      <Text31White style={styles.headerText}>{headerText}</Text31White>
      <Text14Grey style={styles.subText}>{subText}</Text14Grey>
      <PurpleNeonButton text="Зарегистрироваться" cssStyle={styles.button} fontSize={14} />
    </DivFlexColumn>
  )
}

export { HomeWelcomeSectionTextMobile }
