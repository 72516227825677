import { action, makeObservable, observable } from 'mobx'
import {
  MyAdvertisementDashboardResponseDto,
  MyAdvertisementsResponseDto,
  MyAdvertisementsStatus,
  MyAdvertisementsStoreType
} from './types'

class AdvertisementMyStore {
  myAdvertisements: MyAdvertisementsStoreType = {
    pagination: {
      totalCount: 0,
      currentPage: 0,
      take: 0
    },
    advertisements: []
  }

  myAdvertisementDashboard: MyAdvertisementDashboardResponseDto = {
    limit: {
      maxCountBuy: 0,
      maxCountSale: 0,
      maxCountBuyVip: 0,
      maxCountSaleVip: 0,
      maxCountHourShow: 0,
      maxCountHourShowVip: 0
    },
    statistic: {
      countBuy: 0,
      countSale: 0
    }
  }

  constructor() {
    makeObservable(this, {
      myAdvertisements: observable,
      setMyAdvertisement: action.bound,
      updateMyAdvertisement: action.bound,
      myAdvertisementDashboard: observable,
      setMyAdvertisementDashboard: action.bound
    })
  }

  private getCountDifference(newStatus: MyAdvertisementsStatus, lastStatus: MyAdvertisementsStatus): number {
    if (newStatus === lastStatus) return 0
    switch (newStatus) {
      case MyAdvertisementsStatus.Active:
        return 1
      case MyAdvertisementsStatus.Hidden:
        return -1
      case MyAdvertisementsStatus.Deleted:
        return -1
    }
    return 0
  }

  setMyAdvertisement(advertisement: MyAdvertisementsStoreType) {
    this.myAdvertisements = advertisement
  }

  updateMyAdvertisement(updateItem: MyAdvertisementsResponseDto) {
    const existsIndexAdvertisement = this.myAdvertisements.advertisements.findIndex((item) => item.id === updateItem.id)
    if (existsIndexAdvertisement !== -1) {
      const lastStatus = this.myAdvertisements.advertisements[existsIndexAdvertisement].status
        console.log(lastStatus)
        console.log(updateItem.status)
      this.myAdvertisements.advertisements[existsIndexAdvertisement] = updateItem

        console.log(lastStatus)
        console.log(updateItem.status)

      switch (updateItem.type) {
        case 'buy': {
          this.myAdvertisementDashboard.statistic.countBuy += this.getCountDifference(updateItem.status, lastStatus)
          break
        }
        case 'sale': {
          this.myAdvertisementDashboard.statistic.countSale += this.getCountDifference(updateItem.status, lastStatus)
          break
        }
      }
    }
  }

  setMyAdvertisementDashboard(dashboard: MyAdvertisementDashboardResponseDto) {
    this.myAdvertisementDashboard = dashboard
  }
}

const advertisementMyStore = new AdvertisementMyStore()

export { advertisementMyStore }
