import styles from './AdvertisementMyCardButtonsBlock.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'

import DividerIcon from '../../../icons/AdvertisementMy/vertical_vector_grey.svg'
import EditIcon from '../../../icons/edit_grey.svg'
import EditIconActive from '../../../icons/edit_white.svg'
import HiddenIcon from '../../../icons/hidden_eye_grey.svg'
import HiddenIconActive from '../../../icons/hidden_eye_white.svg'
import RotateIcon from '../../../icons/rotate_grey.svg'
import RotateIconActive from '../../../icons/rotate_orange.svg'
import TrashIcon from '../../../icons/trash_base_grey.svg'
import TrashIconActive from '../../../icons/trash_base_white.svg'
import EyeIcon from '../../../icons/eye_grey.svg'
import EyeIconActive from '../../../icons/eye_purple.svg'
import ShareIconActive from '../../../icons/share_white.svg'
import ShareIcon from '../../../icons/share_grey.svg'
import CheckIcon from '../../../icons/check_green.svg'
import { MyAdvertisementsStatus } from 'store/advertisement/advertisementMyStore/types'
import { shooseMyAdvertisementStatus } from 'api/advetrisement/my/advertisementMy'
import { observer } from 'mobx-react-lite'
import { advertisementMyStore } from 'store/advertisement/advertisementMyStore/advertisementMyStore'
import { useState } from 'react'
import { colors } from 'styled/colors'
import { NavigateLinks } from '../../../../PageRouters'

type AdvertisementMyCardButtonsBlockProps = {
  status: MyAdvertisementsStatus
  onDeleteButtonClick: () => void
  advertisementId: string
}

const AdvertisementMyCardButtonsBlock = observer(
  ({ status, onDeleteButtonClick, advertisementId }: AdvertisementMyCardButtonsBlockProps) => {
    const { updateMyAdvertisement } = advertisementMyStore

    const [isLoading, setLoading] = useState<boolean>(false)
    const [isActiveCopyBtn, setActiveCopyBtn] = useState<boolean>(false)

    const isShowEditButton = [
      MyAdvertisementsStatus.Active,
      MyAdvertisementsStatus.Hidden,
      MyAdvertisementsStatus.Expired
    ].includes(status)
    const isShowRotateButton = [MyAdvertisementsStatus.Active, MyAdvertisementsStatus.Expired].includes(status)
    const isShowHiddenButton = status === MyAdvertisementsStatus.Active
    const isShowVisibleButton = status === MyAdvertisementsStatus.Hidden
    const isShowDeletedButton = [
      MyAdvertisementsStatus.Active,
      MyAdvertisementsStatus.Hidden,
      MyAdvertisementsStatus.Moderation,
      MyAdvertisementsStatus.Cancel,
      MyAdvertisementsStatus.Banned,
      MyAdvertisementsStatus.Expired
    ].includes(status)
    const isCopyLink = [MyAdvertisementsStatus.Active].includes(status)

    const handleMakeHidden = async () => {
      setLoading(true)
      const updateAdvertisement = await shooseMyAdvertisementStatus(advertisementId, 'hidden')
      if (updateAdvertisement) {
        updateMyAdvertisement(updateAdvertisement)
      }
      setLoading(false)
    }

    const handleMakeVisible = async () => {
      setLoading(true)
      const updateAdvertisement = await shooseMyAdvertisementStatus(advertisementId, 'active')
      if (updateAdvertisement) {
        updateMyAdvertisement(updateAdvertisement)
      }
      setLoading(false)
    }

    const handlerCopyLinkReloadComponent = () => {
      if (isActiveCopyBtn) {
        setTimeout(() => setActiveCopyBtn(false), 1500)
      }
    }

    const handlerCopyLink = () => {
      const url = `${location.protocol}//${window.location.host}/${NavigateLinks.AdvertisementCard}/${advertisementId}`
      navigator.clipboard
        .writeText(url)
        .then(() => {
          setActiveCopyBtn(true)
          console.log('Текст скопирован в буфер обмена: ', url)
        })
        .catch((err) => {
          setActiveCopyBtn(false)
          console.error('Ошибка при копировании текста: ', err)
        })
    }

    return (
      <DivFlexRow style={styles.container}>
        {/*{isShowEditButton && (
          <OvalButton
            text="Редактировать"
            icon={EditIcon}
            hoverIcon={EditIconActive}
            textHoverColor={colors.white}
            textColor={colors.grey.scarlet}
            cssIconStyle={styles.icon}
            cssContainerStyle={styles.button}
            isSwapContent
          />
        )}*/}

        {/*{isShowEditButton && <img className={styles.divider} src={DividerIcon} alt="divider" />}*/}

        {isCopyLink && (
          <OvalButton
            text={isActiveCopyBtn ? 'Ссылка скопирована' : 'Скопировать ссылку'}
            icon={isActiveCopyBtn ? CheckIcon : ShareIcon}
            hoverIcon={isActiveCopyBtn ? CheckIcon : ShareIconActive}
            textHoverColor={colors.white}
            textColor={colors.grey.scarlet}
            cssIconStyle={styles.icon}
            cssContainerStyle={styles.button}
            onClick={handlerCopyLink}
            onMouseLeave={handlerCopyLinkReloadComponent}
          />
        )}

        {isCopyLink && <img className={styles.divider} src={DividerIcon} alt="divider" />}

        {isShowHiddenButton && (
          <OvalButton
            text="Скрыть"
            icon={HiddenIcon}
            hoverIcon={HiddenIconActive}
            textHoverColor={colors.white}
            textColor={colors.grey.scarlet}
            cssIconStyle={styles.icon}
            cssContainerStyle={styles.button}
            isSwapContent
            onClick={handleMakeHidden}
          />
        )}

        {isShowHiddenButton && <img className={styles.divider} src={DividerIcon} alt="divider" />}

        {isShowVisibleButton && (
          <OvalButton
            text="Сделать видимым"
            icon={EyeIcon}
            hoverIcon={EyeIconActive}
            textColor={colors.grey.scarlet}
            textHoverColor={colors.white}
            cssIconStyle={styles.icon}
            cssContainerStyle={styles.button}
            isSwapContent
            onClick={handleMakeVisible}
          />
        )}
        {isShowVisibleButton && <img className={styles.divider} src={DividerIcon} alt="divider" />}

        {isShowRotateButton && (
          <OvalButton
            text="Продлить"
            icon={RotateIcon}
            hoverIcon={RotateIconActive}
            cssIconStyle={styles.icon}
            textHoverColor={colors.white}
            textColor={colors.grey.scarlet}
            cssContainerStyle={styles.button}
            isSwapContent
            onClick={handleMakeVisible}
          />
        )}

        {isShowRotateButton && <img className={styles.divider} src={DividerIcon} alt="divider" />}

        {isShowDeletedButton && (
          <OvalButton
            text="Удалить"
            icon={TrashIcon}
            hoverIcon={TrashIconActive}
            textHoverColor={colors.white}
            textColor={colors.grey.scarlet}
            cssIconStyle={styles.icon}
            cssContainerStyle={styles.button}
            onClick={onDeleteButtonClick}
          />
        )}

        <span className={`${styles.loaderBlock} ${isLoading && styles.active}`} />
      </DivFlexRow>
    )
  }
)

export { AdvertisementMyCardButtonsBlock }
