import styles from './ChatCardPrice.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'

type ChatCardPriceProps = {
  status: string
  price: string
}

const ChatCardPrice = ({ status, price }: ChatCardPriceProps) => {
  const isBuy = status === 'Покупка'

  return (
    <DivFlexRow style={`${styles.container} ${isBuy ? styles.buy : styles.cell}`}>
      <p className={`${styles.price} ${isBuy ? styles.buy : styles.cell}`}>{price}</p>
      <p className={`${styles.value} ${isBuy ? styles.buy : styles.cell}`}>$</p>
    </DivFlexRow>
  )
}

export { ChatCardPrice }
