import styles from './ContactContainer.module.css'

import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { DivFlexRow } from 'components/styledComponents/div/div'
import { ImgRounded } from 'components/styledComponents/img/img'
import { Text14Grey, Text14Green, Text16White } from 'components/styledComponents/text/textStyles'
import { DarkGreyContainer } from '../DarkGreyContainer'

import Avatar from '../../../../icons/stock/stock-avatar-1.png'
import Call from '../../../../icons/button/green_phone-call.svg'
import Chat from '../../../../icons/button/chat-unread-rounded.svg'
import { colors } from 'styled/colors'

const contactName = 'Ivan D.'
const contactStatus = 'Онлайн на сайте'
const contactType = 'Продавец'

type ContactContainerProps = {
  containerStyle?: string
}

const ContactContainer = ({ containerStyle }: ContactContainerProps) => {
  return (
    <DarkGreyContainer style={`${styles.container} ${containerStyle}`}>
      <DivFlexRow style={styles.contactContainer}>
        <ImgRounded img={Avatar} alt="avatar" />
        <div>
          <Text16White>{contactName}</Text16White>
          <Text14Green>{contactStatus}</Text14Green>
        </div>
      </DivFlexRow>

      <DivFlexRow style={styles.dividerContainer}>
        <Text14Grey>{contactType}</Text14Grey>
        <HorizontalDivider style={dividerStyle} />
      </DivFlexRow>

      <OvalButton text="Позвонить" icon={Call} isSwapContent containerStyle={buttonsStyle.firstButtonStyle} />
      <OvalButton text="Написать в чат" icon={Chat} isSwapContent containerStyle={buttonsStyle.secondButtonStyle} />
    </DarkGreyContainer>
  )
}

export { ContactContainer }

const dividerStyle = {
  width: '117px',
  backgroundColor: '#333A70'
}

const buttonsStyle = {
  firstButtonStyle: {
    backgroundColor: 'rgba(142, 248, 79, 0.10)',
    height: '48px'
  },
  secondButtonStyle: {
    backgroundColor: colors.azure.scarlet,
    height: '48px'
  }
}
