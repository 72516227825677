import styles from './SearchFilterBig.module.css'

import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import OvalButton from '../buttons/OvalButton/OvalButton'
import { observer } from 'mobx-react-lite'
import Loader from '../loaders/Loader/Loader'

import SearchIcon from '../../../components/icons/search-icon.svg'
import SearchIconPurple from '../../../components/icons/search-icon_purple.svg'
import CrossPurple from '../../../components/icons/cross_purple.svg'

type SearchFilterBigProps = {
  inputValue: string
  isDelayCheckInput?: boolean
  setInputValue: (value: string) => void
  cssStylesContainer?: string
  onSubmit: (value: string) => void
  onFocusFunction?: () => void
  onClearFunction?: () => void
  isError?: boolean
}

const SearchFilterBig = observer(
  ({
    inputValue,
    setInputValue,
    cssStylesContainer,
    onSubmit,
    onFocusFunction,
    onClearFunction,
    isError = false,
    isDelayCheckInput = false
  }: SearchFilterBigProps) => {
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [isLoader, setLoader] = useState<boolean>(false)
    const [lastNowOnChangeInput, setNowOnChangeInput] = useState<number>(Date.now())
    const [lastInput, setLastInput] = useState<string>('')

    let interval: any

    const startCheckInput = () => {
      interval =
        !interval &&
        setInterval(() => {
          if (Date.now() - lastNowOnChangeInput > 500 && lastInput !== inputValue) {
            setLastInput(inputValue)
            handleSubmitInputText()
          }
        }, 100)
    }

    useEffect(() => {
      if (isDelayCheckInput) {
        startCheckInput()
        return () => clearInterval(interval)
      }
    }, [inputValue, lastInput])

    const isDirty = isFocused || !!inputValue

    const inputRef = useRef<HTMLInputElement | null>(null)

    const onFocus = () => {
      setIsFocused(true)
      if (onFocusFunction) {
        onFocusFunction()
      }
    }

    const onBlur = () => {
      setIsFocused(false)
    }

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setInputValue(value)
      setNowOnChangeInput(Date.now())
    }

    const handleClearInput = () => {
      setInputValue('')
      if (inputRef.current) {
        inputRef.current.focus()
        if (onClearFunction) {
          onClearFunction()
        }
      }
    }

    const handleSubmitInputText = async () => {
      if (inputValue !== '') {
        setLoader(true)
        await onSubmit(inputValue)
        setLoader(false)
      }
    }

    const handleKeyPressEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        await handleSubmitInputText()
      }
    }

    return (
      <div
        className={`${styles.container} ${cssStylesContainer}  ${isDirty && styles.active} ${isError && styles.error}`}>
        <img className={styles.searchIcon} src={isFocused ? SearchIconPurple : SearchIcon} alt="search"></img>

        <input
          className={`${styles.input}`}
          placeholder="Введите название предмета"
          ref={inputRef}
          value={inputValue}
          onChange={onChangeInput}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={handleKeyPressEnter}></input>

        {inputValue && (
          <img className={styles.crossIcon} src={CrossPurple} alt="cross" onClick={handleClearInput}></img>
        )}

        {isLoader ? (
          <Loader anyStyles={{ marginRight: '40px' }} />
        ) : (
          <OvalButton
            cssContainerStyle={isDirty ? styles.buttonActive : styles.button}
            text="Поиск"
            onClick={handleSubmitInputText}
          />
        )}
      </div>
    )
  }
)

export { SearchFilterBig }
