import { RefObject } from 'react'

export const scrollToItemTop = (scrollToRef: RefObject<HTMLDivElement>) => {
  // прокрутка к выбранному ref элементу + px вверх
  if (scrollToRef.current) {
    const { top } = scrollToRef.current.getBoundingClientRect()

    window.scrollTo({
      top: window.scrollY + top - 100,
      behavior: 'smooth'
    })
  }
}

export const scrollToItemCenter = (scrollToRef: RefObject<HTMLDivElement>) => {
  // прокрутка к выбранному элементу и установка экрана в центре
  if (scrollToRef && scrollToRef.current) {
    scrollToRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
}
