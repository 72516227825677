import { DivFlexRow } from 'components/styledComponents/div/div'
import styles from './ShopBarCardPrice.module.css'
import { Text14Green, Text16Green, Text24Green } from 'components/styledComponents/text/textStyles'
import { formatPrice } from 'utils/formatPrice'
import {convertCurrencyToText} from "../../../../../utils/convert";

type ShopBarCardPriceProps = {
  price?: string
  currency?: string
}

const ShopBarCardPrice = ({ price, currency }: ShopBarCardPriceProps) => {
    const isCatalog = location.pathname === '/catalog'

  return (
    <DivFlexRow style={styles.priceBlock}>
      {price && currency ? (
        <>
          <Text24Green>{formatPrice(Number(price))}</Text24Green>
          <Text16Green>{convertCurrencyToText(isCatalog ? 'catalogCurrency' : 'advertisementCurrency', currency)}</Text16Green>
        </>
      ) : (
        <Text14Green>Нет информации о стоимости</Text14Green>
      )}
    </DivFlexRow>
  )
}

export { ShopBarCardPrice }
