import styles from './ShopBarBody.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

type ShopBarBodyProps = {
  children: ReactNode
}

const ShopBarBody = observer(({ children }: ShopBarBodyProps) => {
  return (
    <DivFlexColumn style={styles.section}>
      <DivFlexColumn style={styles.content}>
        <DivFlexColumn style={styles.productSection}>
          {children}
        </DivFlexColumn>
      </DivFlexColumn>
    </DivFlexColumn>
  )
})

export { ShopBarBody }
