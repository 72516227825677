import styles from './AdvertisementCreateChooseType.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text24White } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { useState } from 'react'
import { RadioTabWithText } from 'components/ui/radioButtons/RadioTabWithText'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'
import { AdvertisementCatalogSearch } from '../../../../store/advertisement/advertisementCreateStore'
import { convertAdvertisementCurrencyToBtnCreateAd } from '../../../../utils/convert'

const headerText = '1. Выберите тип сделки'

type AdvertisementCreateChooseTypeProps = {
  handleChooseAdvertisementType: (type: 'buy' | 'sale') => void
  choseItem?: AdvertisementCatalogSearch | null
}

const AdvertisementCreateChooseType = observer(
  ({ handleChooseAdvertisementType, choseItem }: AdvertisementCreateChooseTypeProps) => {
    const { isVip } = userInfoStore

    const [isTrade, setTrade] = useState<'buy' | 'sale'>('sale')

    const handleChooseSale = () => {
      setTrade('sale')
      handleChooseAdvertisementType('sale')
    }

    const handleChooseBuy = () => {
      setTrade('buy')
      handleChooseAdvertisementType('buy')
    }

    return (
      <DivFlexColumn style={styles.container}>
        <DivFlexRow style={styles.header}>
          <Text24White>{headerText}</Text24White>
          <HorizontalDivider cssStyle={styles.divider} />
        </DivFlexRow>
        <DivFlexRow style={styles.body}>
          <RadioTabWithText
            text={convertAdvertisementCurrencyToBtnCreateAd(choseItem?.advertisementCurrency).buttonSale}
            isActive={isTrade === 'sale'}
            onClick={handleChooseSale}
            fontSize={16}
            cssContainerStyles={styles.button}
          />
          <RadioTabWithText
            text={convertAdvertisementCurrencyToBtnCreateAd(choseItem?.advertisementCurrency).buttonBuy}
            isActive={isTrade === 'buy'}
            onClick={handleChooseBuy}
            fontSize={16}
            cssContainerStyles={styles.button}
            isBlocked={!isVip}
          />
        </DivFlexRow>
      </DivFlexColumn>
    )
  }
)

export { AdvertisementCreateChooseType }
