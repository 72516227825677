import { userInfoStore } from 'store/userInfoStore'
import api from '../axios/axiosInstance'
import errorAlerts from 'store/errorAlertsStore'
import { MessageType } from 'components/ui/errors/AlertError'

const changeUserStatus = async (status: string): Promise<any> => {
  // изменить статус пользователя

  const { changeUserStatus } = userInfoStore

  try {
    await api.patch(`/api/user/${status}/status`)
    changeUserStatus(status)
    errorAlerts.addError({ errorType: MessageType.Success, errorText: 'Статус успешно изменен' })
    return true
  } catch (error) {
    errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    console.log(error)
    return false
  }
}

export { changeUserStatus }
