import api from 'api/axios/axiosInstance'
import axios from 'axios'
import { MessageType } from 'components/ui/errors/AlertError'
import errorAlerts from 'store/errorAlertsStore'
import { LoadingStatusType, loadingStatusStore } from 'store/loadingStatusStore'
import { userInfoStore } from 'store/userInfoStore'
import { getUrlS3 } from '../../common/utils'

const getUserAvatar = async (): Promise<any> => {
  // получение аватара пользователя
  const { checkUserAvatar } = userInfoStore
  const { avatarId } = userInfoStore.userData

  if (avatarId)
    try {
      const url = getUrlS3(avatarId)
      const response = await axios.get(url, { responseType: 'arraybuffer' })

      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const imageUrl = URL.createObjectURL(blob)

      checkUserAvatar(imageUrl)
    } catch (error) {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
      console.error('Error:', error)
    }
}

const postUserAvatar = async (selectedFile: File) => {
  // отправка новой аватарки на сервер
  const formData = new FormData()
  formData.append('file', selectedFile, selectedFile.name)

  const headers = {
    'Content-Type': 'multipart/form-data',
    accept: '*/*'
  }

  const response = await api.postVersion2('/api/user/avatar/upload', formData, { headers })
  if (response.error) {
    errorAlerts.addError({ errorType: MessageType.Error, errorText: response.error.message })
  }
  if (response.data) {
    errorAlerts.addError({ errorType: MessageType.Success, errorText: 'Аватарка успешно загружена' })
  }
}

const deleteUserAvatar = async () => {
  // удаление аватарки
  const { checkUserAvatar } = userInfoStore
  const { setLoading } = loadingStatusStore

  setLoading(LoadingStatusType.Loading)

  const response = await api.deleteVersion2('/api/user/avatar/delete')
  if (response.error) {
    errorAlerts.addError({ errorType: MessageType.Error, errorText: response.error.message })
  } else {
    checkUserAvatar('')
  }

  setLoading(LoadingStatusType.Stop)
}

export { getUserAvatar, postUserAvatar, deleteUserAvatar }
