import { Text24White } from 'components/styledComponents/text/textStyles'
import styles from './ShopBarTransactionCategory.module.css'
import { TransactionButton } from 'components/ui/buttons/TransactionButton'
import { DivFlexColumn } from 'components/styledComponents/div/div'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'

type ShopBarTransactionCategoryProps = {
  listType: 'sale' | 'buy'
  dealerStatus: '' | 'online' | 'game'
  handleChooseListType: (type: 'sale' | 'buy') => void
  handleChooseDealerType: (type: '' | 'online' | 'game') => void
}

const ShopBarTransactionCategory = observer(
  ({ listType, dealerStatus, handleChooseListType, handleChooseDealerType }: ShopBarTransactionCategoryProps) => {
    const { isVip } = userInfoStore

    return (
      <div>
        <Text24White style={styles.typeText}>Тип сделки</Text24White>

        <DivFlexColumn style={styles.buttonsBox}>
          <TransactionButton
            text="Продажа"
            countType="об"
            isCheked={listType === 'sale'}
            onClick={handleChooseListType.bind(this, 'sale')}
          />
          <TransactionButton
            text="Покупка"
            countType="чел"
            isCheked={listType === 'buy'}
            isVip={isVip}
            onClick={handleChooseListType.bind(this, 'buy')}
          />
        </DivFlexColumn>

        <Text24White style={styles.statusText}>Статус диллера</Text24White>

        <DivFlexColumn style={styles.buttonsBox}>
          <TransactionButton
            text="Все"
            countType="чел"
            isCheked={dealerStatus === ''}
            onClick={handleChooseDealerType.bind(this, '')}
          />
          <TransactionButton
            text="На сайте"
            countType="чел"
            isCheked={dealerStatus === 'online'}
            onClick={handleChooseDealerType.bind(this, 'online')}
          />
          <TransactionButton
            text="В игре"
            countType="чел"
            isCheked={dealerStatus === 'game'}
            isVip={isVip}
            onClick={handleChooseDealerType.bind(this, 'game')}
          />
        </DivFlexColumn>
      </div>
    )
  }
)

export { ShopBarTransactionCategory }
