import styles from './TransactionButton.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text12Green, Text12White, Text16White } from 'components/styledComponents/text/textStyles'
import { useEffect, useState } from 'react'

import UncheckedIcon from './unchecked.svg'
import CheckedIcon from './checked.svg'
import LockIcon from '../../../../components/icons/lock-icon.svg'
import VipBadge from '../../../../components/icons/userStatus/pro_badge.svg'
import { SubscriptionPaymentModalsContainer } from 'components/ui/modals/SubscriptionPaymentModalsContainer'

type TransactionButtonProps = {
  isCheked: boolean
  text: string
  count?: number
  countType: string
  isVip?: boolean
  onClick: () => void
}

const TransactionButton = ({ isCheked, text, count, countType, isVip = true, onClick }: TransactionButtonProps) => {
  const [isActive, setActive] = useState<boolean>(isCheked)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleClick = () => {
    if (isVip) {
      onClick()
      setActive(!isActive)
    } else {
      setOpen(true)
    }
  }

  useEffect(() => {
    setActive(isCheked)
  }, [isCheked])

  return (
    <DivFlexRow style={`${!isVip && styles.vipDisable}`}>
      <DivFlexRow
        style={`${styles.button} ${isActive && styles.active} ${!isVip && styles.disable}`}
        onClick={handleClick}>
        <img src={!isVip ? LockIcon : isActive ? CheckedIcon : UncheckedIcon} alt="check" className={styles.checkbox} />

        <Text16White style={`${styles.text} ${!isVip && styles.textDisableColor}`}>{text}</Text16White>

        {count && (
          <>
            <Text12Green style={`${!isVip && styles.countDisableColor}`}>
              {count < 9999999 ? count : '9999999+'}
            </Text12Green>
            <Text12Green style={`${styles.countText} ${!isVip && styles.countDisableColor}`}>
              {`${countType}.`}
            </Text12Green>
          </>
        )}
      </DivFlexRow>

      {!isVip && (
        <>
          <DivFlexRow style={styles.vipText}>
            <img src={VipBadge} alt="vip" className={styles.vipBadge} />
            <Text12White>Доступно в подписке</Text12White>
          </DivFlexRow>

          {isOpen && <SubscriptionPaymentModalsContainer onClose={setOpen.bind(this, false)} />}
        </>
      )}
    </DivFlexRow>
  )
}

export { TransactionButton }
