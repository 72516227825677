import styles from './SubscriptionPaymentModalHeader.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text18White, Text14Purple } from 'components/styledComponents/text/textStyles'
import ProBadge from '../icons/big_pro_badge.svg'
import ProBadgeDisable from '../icons/big_pro_badge_disable.svg'

type SubscriptionPaymentModalHeaderProps = {
  subscriptionName: string
  isActive: boolean
}

const SubscriptionPaymentModalHeader = ({ subscriptionName, isActive }: SubscriptionPaymentModalHeaderProps) => {
  return (
    <DivFlexRow style={`${styles.header} ${!isActive && styles.disable}`}>
      <img
        className={`${styles.icon} ${!isActive && styles.disable}`}
        src={isActive ? ProBadge : ProBadgeDisable}
        alt="pro icon"
      />
      <Text18White style={styles.text}>Подписка RP PRO</Text18White>
      <Text14Purple style={`${styles.subText} ${!isActive && styles.disable}`}>{subscriptionName}</Text14Purple>
    </DivFlexRow>
  )
}

export { SubscriptionPaymentModalHeader }
