import humanizeDuration from 'humanize-duration'

function createLabel(number: number, titles: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
}

/**
 * Преобразовывает "2023-08-21T14:23:01.681" в "DD/MM/YYYY"
 * @param inputDate
 */
export const formatDate = (inputDate: string) => {
  const date = new Date(inputDate)
  const day = date.getUTCDate().toString().padStart(2, '0')
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = date.getUTCFullYear().toString()

  return `${day}/${month}/${year}`
}

/**
 * Преобразовывает "2023-08-21T14:23:01.681" в "hh:mm DD/MM/YYYY"
 * @param inputDate
 */
export const formatTimeDate = (inputDate: string) => {
  const currentDate = new Date(inputDate)
  const hours = String(currentDate.getHours()).padStart(2, '0') // Добавляем ведущий ноль для часов
  const minutes = String(currentDate.getMinutes()).padStart(2, '0') // Добавляем ведущий ноль для минут

  // Форматируем дату
  const day = String(currentDate.getDate()).padStart(2, '0') // Добавляем ведущий ноль для дня
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Добавляем ведущий ноль для месяца
  const year = currentDate.getFullYear() // Получаем год

  // Создаем строку формата "hh:mm DD/MM/YYYY"
  return `${hours}:${minutes} ${day}/${month}/${year}`
}

export const calculateTimeRemaining = (expirationAt: string): string => {
  const expiredDate = new Date(expirationAt)
  const currentTime = new Date()

  if (expiredDate > currentTime) {
    const diffTime = expiredDate.getTime() - currentTime.getTime()
    return humanizeDuration(diffTime, {
      language: 'ru',
      units: ['d', 'h', 'm'],
      round: true,
      delimiter: ' '
    })
  } else {
    return ''
  }
}

export const nameDay = (days: number) => {
    return createLabel(days, ['день', 'дня', 'дней'])
}
