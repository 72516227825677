import { DivFlexRow } from 'components/styledComponents/div/div'
import styles from './FackeMessageButton.module.css'
import { Text14White } from 'components/styledComponents/text/textStyles'
import { ImgBase } from 'components/styledComponents/img/img'
import Chat from '../../../../icons/button/chat-unread-rounded.svg'

type FackeMessageButtonProps = {
  cssStyle?: string
}

const FackeMessageButton = ({ cssStyle }: FackeMessageButtonProps) => {
  return (
    <DivFlexRow style={`${styles.div} ${cssStyle}`}>
      <Text14White>Написать в чат</Text14White>
      <ImgBase img={Chat} alt="icon" />
    </DivFlexRow>
  )
}

export { FackeMessageButton }
