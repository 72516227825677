import styles from './ProfileSettingsCreateElementButton.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text14White } from 'components/styledComponents/text/textStyles'

type ProfileSettingsCreateElementButtonProps = {
  text: string
  onClick: () => void
  cssCardStyles?: string
  icon: string
  iconSize?: number | string
}

const ProfileSettingsCreateElementButton = ({
  text,
  onClick,
  cssCardStyles,
  icon,
  iconSize = 20
}: ProfileSettingsCreateElementButtonProps) => {
  return (
    <DivFlexColumn style={`${styles.card} ${cssCardStyles}`} onClick={onClick}>
      <img
        className={styles.icon}
        src={icon}
        alt="add button"
        style={{
          width: iconSize,
          height: iconSize
        }}
      />
      <Text14White>{text}</Text14White>
    </DivFlexColumn>
  )
}

export { ProfileSettingsCreateElementButton }
