import styles from './ShopBarModalButtons.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { ButtonCountArrowLeft } from 'components/ui/buttons/ButtonCountArrowLeft'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { userInfoStore } from 'store/userInfoStore'
import { SubscriptionPaymentModalsContainer } from '../../SubscriptionPaymentModalsContainer'
import {
  createLinkAdvertisementBuy,
  createLinkAdvertisementCreate,
  createLinkAdvertisementSale
} from '../../../../../utils/link'
import AddDocument from '../../../../icons/button/add_document.svg'
import OvalButton from '../../../buttons/OvalButton/OvalButton'
import AuthModal from '../../AuthModal/AuthModal'

type ShopBarModalButtonsProps = {
  countAdvertisementBuy: number
  countAdvertisementSale: number
  catalogId: string
  nameButtonSale?: string
  nameButtonBuy?: string
  cssContainer?: string
  cssButtonBuy?: string
  cssButtonSale?: string
}

const ShopBarModalButtons = observer(
  ({
    countAdvertisementBuy,
    countAdvertisementSale,
    nameButtonBuy,
    nameButtonSale,
    catalogId,
    cssContainer,
    cssButtonBuy,
    cssButtonSale
  }: ShopBarModalButtonsProps) => {
    const { isVip, isAuth } = userInfoStore

    const [isSubModal, setSubModal] = useState<boolean>(false)
    const [isAuthModal, setAuthModal] = useState<boolean>(false)

    const handleClickBuyButton = () => {
      if (!isVip) {
        setSubModal(true)
      } else {
        window.open(createLinkAdvertisementBuy(catalogId), '_blank')
      }
    }

    const handleClickSaleButton = () => {
      window.open(createLinkAdvertisementSale(catalogId), '_blank')
    }

    const handleClickCreateAdvertisementButton = () => {
      if (!isAuth) {
        setAuthModal(true)
        return
      }

      window.open(createLinkAdvertisementCreate(catalogId), '_blank')
    }

    return (
      <DivFlexColumn style={`${styles.buttonsContainer} ${!isVip && styles.vipDisable} ${cssContainer}`}>
        <ButtonCountArrowLeft
          text={nameButtonBuy ?? 'Покупают 1'}
          count={countAdvertisementBuy}
          color="green"
          fontSize={16}
          containerStyle={`${isVip ? styles.saleButton : styles.buyButton} ${isVip ? cssButtonSale : cssButtonBuy}`}
          isVip={isVip}
          onClick={handleClickBuyButton}
        />
        <ButtonCountArrowLeft
          text={nameButtonSale ?? 'Продают'}
          count={countAdvertisementSale}
          color="azure"
          fontSize={16}
          containerStyle={`${styles.saleButton} ${cssButtonSale}`}
          onClick={handleClickSaleButton}
        />
        <OvalButton
          text={'Создать объявление'}
          icon={AddDocument}
          onClick={handleClickCreateAdvertisementButton}
          containerStyle={{
            borderRadius: '14px',
            height: '56px !important',
            width: '100%',
            background: '#7B4CFF'
          }}
        />

        {isSubModal && <SubscriptionPaymentModalsContainer onClose={setSubModal.bind(this, false)} inModal />}
        {isAuthModal && <AuthModal isOpen={isAuthModal} onClose={setAuthModal.bind(this, false)} />}
      </DivFlexColumn>
    )
  }
)

export { ShopBarModalButtons }
