import api from 'api/axios/axiosInstance'
import axios from 'axios'
import { MessageType } from 'components/ui/errors/AlertError'
import { advertisementMyStore } from 'store/advertisement/advertisementMyStore/advertisementMyStore'
import { MyAdvertisementsResponseDto, MyAdvertisementsStoreType } from 'store/advertisement/advertisementMyStore/types'
import errorAlerts from 'store/errorAlertsStore'

const getMyAdvertisement = async (options: {
  type: 'buy' | 'sale' | ''
  paginationSkip?: number
  serverId?: string
  status?: string
  text?: string
}) => {
  const { setMyAdvertisement } = advertisementMyStore
  const { type = '', paginationSkip = 0, serverId = '', status = '', text = '' } = options

  const paginationTake = 8
  // компонент <Pagination> принимает в себя itemsInPage, который должен быть равен paginationTake
  const url = '/api/advertisement/my/list?'
  const urlFilter = type && `filter[type]=${type}`
  const urlServerFilter = serverId && `filter[serverId]=${serverId}`
  const urlStatusFilter = status && `filter[status]=${status}`
  const urlTextFilter = text && `filter[text]=${text}`
  const urlPagination = `pagination[take]=${paginationTake}&pagination[skip]=${paginationSkip ?? 0}`

  try {
    const response: MyAdvertisementsStoreType = await api.get(
      `${url}${urlFilter}&${urlServerFilter}&${urlStatusFilter}&${urlTextFilter}&${urlPagination}`
    )

    setMyAdvertisement(response)
    return true
  } catch (error) {
    return false
  }
}

const shooseMyAdvertisementStatus = async (
  advertisementId: string,
  status: 'active' | 'hidden' | 'deleted' | 'moderation' | 'cancel' | 'banned',
  reasonDelete?: 'deal' | 'other'
): Promise<MyAdvertisementsResponseDto | null> => {
  try {
    const response: MyAdvertisementsResponseDto = await api.patch(`/api/advertisement/${advertisementId}/status`, {
      status,
      reasonDelete
    })

    if (response.id) {
      errorAlerts.addError({ errorType: MessageType.Success, errorText: '' })
      return response
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorAlerts.addError({
          errorType: MessageType.Error,
          errorText: error.response.data.message
        })
      }
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    }
  }
  return null
}

export { getMyAdvertisement, shooseMyAdvertisementStatus }
