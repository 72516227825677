import styles from './ProfileSettingsPageHeader.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { Text32White } from 'components/styledComponents/text/textStyles'

import CogIcon from '../../../icons/profileSettings/cog.svg'

const ProfileSettingsPageHeader = () => {
  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexRow style={styles.header}>
        <img src={CogIcon} alt="settings" className={styles.icon} />
        <Text32White style={styles.text}>Настройки</Text32White>
      </DivFlexRow>

      <HorizontalDivider cssStyle={styles.divider} />
    </DivFlexColumn>
  )
}

export { ProfileSettingsPageHeader }
