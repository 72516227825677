import { DivFlexColumn } from 'components/styledComponents/div/div'
import styles from './DarkGreyContainer.module.css'
import { ReactNode } from 'react'

type DarkGreyContainerProps = {
  children: ReactNode
  style?: string
}

const DarkGreyContainer = ({ children, style }: DarkGreyContainerProps) => {
  return <DivFlexColumn style={`${styles.div} ${style}`}>{children}</DivFlexColumn>
}

export { DarkGreyContainer }
