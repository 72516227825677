import styles from './Chat.module.css'

import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { ChatHeader } from 'components/ui/Chat/ChatHeader'
import { ChatSupportCard } from 'components/ui/Chat/ChatSupportCard'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { ChatCard } from 'components/ui/Chat/ChatCard'
import { DivFlexColumn } from 'components/styledComponents/div/div'
import { ChatAlert } from 'components/ui/Chat/ChatAlert'
import { observer } from 'mobx-react-lite'
import { chatClientStore } from '../../../store/chat/chat'
import { useEffect, useState } from 'react'
import { LoaderPage } from '../../ui/loaders/LoaderPage'
import { useNavigate } from 'react-router-dom'
import { BrowserRouters } from '../../../PageRouters'

const Chat = observer(() => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const { loadChatList, loadChatSystemMessageInfo, chatList, systemMessageChatInfo } = chatClientStore
  const navigate = useNavigate()

  useEffect(() => {
    Promise.all([loadChatList(), loadChatSystemMessageInfo()]).then(() => setLoading(false))
  }, [])

  const handlerOnClickSystemChat = () => {
    navigate(BrowserRouters.SystemChat)
  }

  if (isLoading) {
    return (
      <PageWrapper>
        <LoaderPage />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper style={styles.section}>
      {/*<ChatHeader />*/}
      <ChatSupportCard
        countMessageNotRead={systemMessageChatInfo.countNotRead}
        onClick={handlerOnClickSystemChat}
        messageText={systemMessageChatInfo?.lastMessage?.text}
        messageCreatedAt={systemMessageChatInfo?.lastMessage?.createdAt}
      />
      <HorizontalDivider cssStyle={styles.headerDivider} />

      <DivFlexColumn style={styles.chatsBlock}>
        {chatList?.chats.map((chat) => {
          return <ChatCard key={chat.chatId} chat={chat} />
        })}
      </DivFlexColumn>

      {!chatList.chats.length && <ChatAlert />}
    </PageWrapper>
  )
})

export default Chat
