import { useState, useEffect } from 'react'
import styles from './PriceInputWithIcon.module.css'
import { Text16Purple } from 'components/styledComponents/text/textStyles'
import { formatPrice } from 'utils/formatPrice'

type PriceInputWithIconProps = {
  value: string
  onChange: (value: string) => void
  placeHolder?: string
  validationError?: string
  isValid: boolean
  bottomText?: string
  width?: number
  maxLength?: number
  cssInputContainer?: string
}

const PriceInputWithIcon = ({
  value,
  onChange,
  placeHolder,
  validationError,
  isValid,
  bottomText,
  width = 320,
  maxLength,
  cssInputContainer
}: PriceInputWithIconProps) => {
  const [isEmpty, setEmpty] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string>('')

  const valueFormated = (value: string) => {
    const numericValue = value.replace(/\D/g, '')
    return numericValue
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
    setEmpty(event.target.value === '')
  }

  useEffect(() => {
    // отлавливаем ошибку валидации
    if (validationError) {
      setErrorText(validationError)
    } else {
      setErrorText('')
    }
  }, [validationError])

  useEffect(() => {
    // применяем форматирование данных при вводе
    const newValue = valueFormated(value)
    const newFormat = formatPrice(Number(newValue))

    if (newFormat === '0') {
      onChange('')
    } else {
      onChange(newFormat)
    }
  }, [value, onChange])

  return (
    <div style={{ maxWidth: width }}>
      <div className={`${styles.container} ${cssInputContainer} ${!isValid && styles.invalid}`}>
        <input
          className={styles.input}
          value={value}
          onChange={handleChange}
          placeholder={placeHolder ?? ''}
          maxLength={maxLength}
        />
        <Text16Purple>$</Text16Purple>
      </div>

      {bottomText && (
        <p className={`${styles.bottomText} ${!isValid ? styles.invalid : isEmpty && styles.valid}`}>
          {!!errorText ? errorText : bottomText}
        </p>
      )}
    </div>
  )
}

export { PriceInputWithIcon }
