import styles from './DropdownServersBase.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text14White, Text14Grey } from 'components/styledComponents/text/textStyles'
import { DropdownWrapper } from 'components/ui/wrappers/DropdownWrapper'
import { useState } from 'react'
import { IPlatformServer } from 'store/platformConfigStore/types'

import ArrowGrey from '../../../../components/icons/arrow_bottom_grey.svg'
import ArrowPurple from '../../../../components/icons/arrow_bottom_purple.svg'

type DropdownServersBaseProps = {
  baseText?: string
  items: IPlatformServer[]
  cssStyles?: string
  onClickItem: (serverId: string) => void
}

const DropdownServersBase = ({ baseText = '', items, cssStyles, onClickItem }: DropdownServersBaseProps) => {
  const [text, setText] = useState<string>(baseText === '' ? items[0].name : baseText)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleClickServer = (server: IPlatformServer) => {
    setText(server.name)
    onClickItem(server.id)
  }

  return (
    <DropdownWrapper onClose={setOpen.bind(this, false)}>
      <DivFlexRow
        style={`${styles.button} ${isOpen && styles.active} ${cssStyles}`}
        onClick={setOpen.bind(this, !isOpen)}
      >
        <Text14White style={styles.text}>{text}</Text14White>

        <ImgBase img={isOpen ? ArrowPurple : ArrowGrey} style={styles.arrow} />

        {isOpen && (
          <div className={styles.dropContainer}>
            <ul className={styles.itemsSection}>
              {text !== baseText && (
                <li
                  className={styles.item}
                  onClick={handleClickServer.bind(this, {
                    id: '',
                    name: baseText,
                    label: null,
                    categories: []
                  })}
                >
                  <Text14Grey style={`${styles.itemText}`}>{'Не выбрано'}</Text14Grey>
                </li>
              )}
              {items.map((item, index) => {
                return (
                  <li key={index} className={styles.item} onClick={handleClickServer.bind(this, item)}>
                    <Text14Grey style={`${styles.itemText} ${text === item.name && styles.active}`}>
                      {item.name}
                    </Text14Grey>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </DivFlexRow>
    </DropdownWrapper>
  )
}

export { DropdownServersBase }
