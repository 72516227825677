import api from 'api/axios/axiosInstance'
import { advertisementDataStore } from 'store/advertisement/advertisementDataStore'
import { IGetResAdvertisementListItem } from '../shopbar/advertisement.type'

const getAdvertisement = async (advertisementId: string) => {
  const { setAdvertisement } = advertisementDataStore
  try {
    const response: IGetResAdvertisementListItem = await api.get(`/api/advertisement/${advertisementId}/item`)
    setAdvertisement(response)
  } catch (error) {
    console.log(error)
  }
}

export { getAdvertisement }
