import Loader from 'components/ui/loaders/Loader/Loader'
import styles from './SubmitButton.module.css'

type SubmitButtonProps = {
  text: string
  isActive?: boolean
  onClick: () => void
  textSize?: number
  isLoading?: boolean
  cssButton?: string
}

const SubmitButton = ({ text, isActive = true, onClick, textSize = 16, isLoading, cssButton }: SubmitButtonProps) => {
  const onSubmit = () => {
    if (isActive) {
      onClick()
    }
  }

  return (
    <button className={`${styles.button} ${cssButton} ${isActive && styles.active}`} onClick={onSubmit}>
      {isLoading ? (
        <Loader anyStyles={{ margin: 'auto' }} />
      ) : (
        <p className={styles.text} style={{ fontSize: `${textSize}px` }}>
          {text}
        </p>
      )}
    </button>
  )
}

export { SubmitButton }
