import styles from './ShopBarListDropdown.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text14Grey, Text14White } from 'components/styledComponents/text/textStyles'
import { useState } from 'react'
import { DropdownWrapper } from 'components/ui/wrappers/DropdownWrapper'

import ArrowGrey from '../../../../components/icons/arrow_bottom_grey.svg'
import ArrowPurple from '../../../../components/icons/arrow_bottom_purple.svg'

type Item = { key: string; name: string }

type ShopBarListDropdownProps = {
  items: Item[]
  selectedItem: Item
  onSelectedItem: (data: Item) => void
  cssStyles?: string
}

const ShopBarListDropdown = ({ items, selectedItem, onSelectedItem, cssStyles }: ShopBarListDropdownProps) => {
  const [isOpen, setOpen] = useState<boolean>(false)

    const handleSelectedItem = (item: Item) => {
        onSelectedItem(item)
    }

  return (
    <DropdownWrapper onClose={setOpen.bind(this, false)}>
      <DivFlexRow
        style={`${styles.button} ${isOpen && styles.active} ${cssStyles}`}
        onClick={setOpen.bind(this, !isOpen)}
      >
        <Text14White style={styles.text}>{selectedItem.name}</Text14White>

        <ImgBase img={isOpen ? ArrowPurple : ArrowGrey} style={styles.arrow} />

        {isOpen && (
          <div className={styles.dropContainer}>
            <ul className={styles.itemsSection}>
              {items.map((_item, _index) => {
                return (
                  <li key={_index} className={styles.item} onClick={handleSelectedItem.bind(this, _item)}>
                    <Text14Grey style={`${styles.itemText} ${selectedItem.key === _item.key && styles.active}`}>{_item.name}</Text14Grey>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </DivFlexRow>
    </DropdownWrapper>
  )
}

export { ShopBarListDropdown }
