import { useState, useEffect } from 'react'

const useGamePhoneValidation = (value: string): { isValid: boolean; errorText: string } => {
  const [isValid, setIsValid] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string>('')

  useEffect(() => {
    const formattedValue = value.replace(/\D/g, '')
    const formattedPhoneNumber = formattedValue.replace(/(\d{3})(\d{2})(\d{2})/, '$1-$2-$3')

    if (value !== '' && formattedValue.length !== 0) {
      if (!/^\d{3}-\d{2}-\d{2}$/.test(formattedPhoneNumber)) {
        setIsValid(false)
        setErrorText('Формат должен быть 123-45-67')
      } else {
        setIsValid(true)
        setErrorText('')
      }
    }
  }, [value])

  return { isValid, errorText }
}

export { useGamePhoneValidation }
