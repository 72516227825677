import styles from './Footer.module.css'

import FooterLink from '../FooterLink/FooterLink'
import { FooterIcon } from '../FooterIcon'
import { ButtonTexts } from 'constants/buttonTexts'
import { FooterButtonsText, FooterLinks } from 'constants/footerLinks'
import { BrowserRouters, NavigateLinks } from 'PageRouters'

import OvalButton from '../buttons/OvalButton/OvalButton'
import Support from '../../icons/button/question_icn.svg'
import Telegram from '../../icons/social/telegram_white.svg'
import Vk from '../../icons/social/vk_white.svg'
import Discord from '../../icons/social/discord_white.svg'
import HeaderLogo from '../HeaderLogo/HeaderLogo'
import HomeIcon from '../../icons/button/home.svg'
import Category from '../../icons/button/category_white.svg'
import VoiceSms from '../../icons/button/voice_sms_white.svg'
import Shield from '../../icons/shield-question_white.svg'
import Diamond from '../../icons/diamond_white.svg'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.pagesBlock}>
            <HeaderLogo />
            <div className={styles.buttonsBlock}>
              <OvalButton icon={HomeIcon} router={BrowserRouters.Home} containerStyle={{ background: '#2A3152' }} />
              <OvalButton
                text={ButtonTexts.Catalog}
                icon={Category}
                router={NavigateLinks.Catalog}
                containerStyle={{ background: '#2A3152' }}
              />
              <OvalButton
                text={ButtonTexts.Advertisement}
                icon={VoiceSms}
                router={NavigateLinks.Advertisement}
                containerStyle={{ background: '#2A3152' }}
              />
              <OvalButton
                text={ButtonTexts.Faq}
                icon={Shield}
                router={BrowserRouters.ArticleList}
                containerStyle={{ background: '#2A3152' }}
              />
              {/*
              RPMARKET-192: [BE/FE] Выдавать всем подписку автоматически
              <OvalButton
                text={ButtonTexts.Subscription}
                icon={Diamond}
                router={BrowserRouters.Subscription}
                containerStyle={{ background: '#2A3152' }}
              />*/}
            </div>
          </div>

          <div className={styles.copyrightBlock}>
            <p className={styles.supportText}>{FooterButtonsText.HelpSmall}</p>
            <OvalButton text={ButtonTexts.SupportEmail} icon={Support} />
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.copyrightBlock}>
            <div className={styles.social}>
              <FooterIcon src={Telegram} href={FooterLinks.telegram} />
              <FooterIcon src={Vk} href={FooterLinks.vk} />
              <FooterIcon src={Discord} href={FooterLinks.discord} />
            </div>

            <p className={styles.copyrightText}>{FooterButtonsText.Copyright}</p>
            <p className={styles.copyrightText}>
              {'Разработано в'}{' '}
              <a href={'https://it-moysha.ru'} target="_blank" className={styles.developIt}>
                IT-MOYSHA
              </a>
            </p>
          </div>

          <div className={styles.copyrightBlock}>
            <FooterLink link={BrowserRouters.Offer} text="Оферта" />
            <FooterLink link={BrowserRouters.Agreement} text="Пользовательское соглашение" />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
