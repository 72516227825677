import styles from './ShopBarModal.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { useEffect, useState } from 'react'
import { getCardImage } from 'api/catalog/catalog.method'
import { ShopBarModalHeader } from './ShopBarModalHeader'
import { ShopBarGallery } from './ShopBarGallery'
import { ShopBarModalData } from './ShopBarModalData'
import { ShopBarChartBlock } from '../../ShopBar/ShopBarChartBlock'
import { ShopBarModalCatalogData } from './ShopBarModalCatalogData'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'
import { EditorCatalogGalleryColorsMenu } from 'components/ui/editor/EditorCatalogGalleryColorsMenu'
import { ModalWrapper } from 'components/ui/wrappers/ModalWrapper'
import { ShopBarProductDescription } from './ShopBarProductDescription'
import { IGetResCatalogListItem } from '../../../../api/catalog/catalog.type'

type Image = {
  id: string
  name: string
  image?: string
}

type ShopBarModalProps = {
  isOpen: boolean
  isCatalog: boolean
  onClose: () => void
  cardData: IGetResCatalogListItem
  baseImage: Image
}

const ShopBarModal = observer(({ isOpen, onClose, cardData, baseImage, isCatalog }: ShopBarModalProps) => {
  const { isEditor } = userInfoStore

  const {
    id,
    name,
    price,
    currency,
    advertisementCurrency,
    description,
    propertyPage,
    sourceCategory,
    images,
    countAdvertisementBuy,
    countAdvertisementSale
  } = cardData

  const [loadedImages, setLoadedImages] = useState<Image[]>([])
  const [activeImage, setActiveImage] = useState<Image>(baseImage)
  const isActiveStatus = true

  useEffect(() => {
    const loadImages = async () => {
      // это потому что мы хотим на каждой картинке крутилку пока она грузится...
      const fakeImages = Array.from(images.slice(1))
      setLoadedImages([baseImage, ...fakeImages])

      // Асинхронная загрузка остальных изображений, кроме первого
      const remainingImages = cardData.images.slice(1)
      const loadedImagePromises = remainingImages.map(async (image) => {
        const getImage = await getCardImage(image.id)
        return { id: image.id, name: image.name, image: getImage }
      })

      const loadedImages = await Promise.all(loadedImagePromises)

      // Объединение baseImage и загруженных остальных изображений в один массив
      const allImages = [baseImage, ...loadedImages]

      setLoadedImages(allImages)
    }

    loadImages()
  }, [baseImage, cardData.images, images])

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} isPortal>
      <DivFlexColumn style={`${styles.modal} ${!isCatalog && styles.advertisement}`}>
        <ShopBarModalHeader
          activeImage={activeImage}
          name={name}
          onClose={onClose}
          sourceCategory={sourceCategory}
          catalogId={id}
          price={price}
          countAdvertisementBuy={countAdvertisementBuy}
          countAdvertisementSale={countAdvertisementSale}
          isActiveStatus={isActiveStatus}
          page={'catalog'}
          currency={currency}
          advertisementCurrency={advertisementCurrency}
        />

        {loadedImages[1] && isActiveStatus && (
          <ShopBarGallery
            cssContainer={styles.gallery}
            loadedImages={loadedImages}
            activeImage={activeImage}
            setActiveImage={setActiveImage}
          />
        )}

        {isEditor && <EditorCatalogGalleryColorsMenu imageId={activeImage.id} />}

        {isCatalog && isActiveStatus && (
          <ShopBarModalData headerText="Данные предмета" property={propertyPage} isVisible={!!propertyPage.length} />
        )}

        {!isCatalog && isActiveStatus && (
          <ShopBarModalCatalogData headerText="Данные предмета в каталоге" property={propertyPage} id={id} />
        )}

        {!!description && isActiveStatus && <ShopBarProductDescription description={description} mt={8} />}

        {isActiveStatus && <ShopBarChartBlock headerText="График изменения цены" />}
      </DivFlexColumn>
    </ModalWrapper>
  )
})

export { ShopBarModal }
