import { FiltersType, PlatformConfigFilter, PlatformConfigSort } from '../api/platform/platform.type'

export const defaultItemsSortOrderBy: PlatformConfigSort[] = [
  { name: 'По возрастанию', key: 'asc' },
  { name: 'По убыванию', key: 'desc' }
]

export const defaultItemSort: PlatformConfigSort[] = [
  { name: 'Название', key: 'systemCatalogName' },
  { name: 'Стоимость', key: 'systemCatalogPrice' }
]

export const defaultItemFilterCatalog: PlatformConfigFilter[][] = [
  [
    {
      name: 'Цена магазина',
      type: FiltersType.RangeDynamic,
      key: 'systemCatalogPrice',
      vip: false,
      property: {}
    }
  ]
]

export const defaultItemFilterAdvertisement: PlatformConfigFilter[][] = [
  [
    {
      name: 'Стоимость',
      type: FiltersType.RangeDynamic,
      key: 'systemAdvertisementPrice',
      vip: false,
      property: {}
    }
  ]
]
