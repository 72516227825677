import styles from './ShopBarBackButtonBlock.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'

import { BackButton } from 'components/ui/buttons/BackButton'

type ShopBarBackButtonBlockProps = {
  link?: string
}

const ShopBarBackButtonBlock = ({ link }: ShopBarBackButtonBlockProps) => {
  return (
    <DivFlexRow style={styles.backButtonBlock}>
      <BackButton text="Назад" link={link} />
      <HorizontalDivider cssStyle={styles.backButtonBlockDivider} />
    </DivFlexRow>
  )
}

export { ShopBarBackButtonBlock }
