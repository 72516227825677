import { DivFlexColumn } from 'components/styledComponents/div/div'
import { WhatButton } from 'components/ui/buttons/WhatButton'
import VerticalDivider from 'components/ui/dividers/VerticalDivider/VerticalDivider'
import styles from './ProfileSettingsWhatBlock.module.css'

type ProfileSettingsWhatBlockProps = {
  buttonText: string
  onClick: () => void
}

const ProfileSettingsWhatBlock = ({ buttonText, onClick }: ProfileSettingsWhatBlockProps) => {
  return (
    <DivFlexColumn style={styles.whatBlock}>
      <VerticalDivider cssStyle={styles.whatDivider} />
      <WhatButton text={buttonText} onClick={onClick} />
    </DivFlexColumn>
  )
}

export { ProfileSettingsWhatBlock }
