import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text16Grey } from 'components/styledComponents/text/textStyles'
import styles from './AdvertisementCreateAlertBlock.module.css'
import BallIcon from '../../../icons/ball_grey.svg'

type AdvertisementCreateAlertBlockProps = {
  alertText: string
}

const AdvertisementCreateAlertBlock = ({ alertText }: AdvertisementCreateAlertBlockProps) => {
  return (
    <DivFlexRow style={styles.alert}>
      <img className={styles.ballIcon} src={BallIcon} alt="alert" />
      <Text16Grey>{alertText}</Text16Grey>
    </DivFlexRow>
  )
}

export { AdvertisementCreateAlertBlock }
