import styles from './HomeFiltersItemTypeContainer.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Grey } from 'components/styledComponents/text/textStyles'
import { ArrowInCircleWithPurpleBorder } from 'components/ui/ArrowInCircleWithPurpleBorder'

const HomeFiltersItemTypeContainer = () => {
  return (
    <DivFlexRow style={styles.itemTypeContainer}>
      <Text14Grey style={styles.itemType}>Кепки</Text14Grey>
      <Text14Grey style={styles.itemType}>Шлемы</Text14Grey>
      <ArrowInCircleWithPurpleBorder />
    </DivFlexRow>
  )
}

export { HomeFiltersItemTypeContainer }
