import styles from './ChatPageUserMessage.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import {
  Text12GreyRoboto,
  Text14WhiteRoboto,
  Text16Purple,
  Text16White
} from 'components/styledComponents/text/textStyles'
import { ChatPageAvatar } from '../ChatPageAvatar'
import { IGetChatInfoInterlocutorResponse, IGetChatInfoMessagesResponse } from '../../../../api/chat/type.response'
import { formatTimeDate } from '../../../../utils/dateFormated'
import { userInfoStore } from '../../../../store/userInfoStore'
import { observer } from 'mobx-react-lite'

type ChatPageUserMessageProps = {
  message: IGetChatInfoMessagesResponse
  interlocutor: IGetChatInfoInterlocutorResponse
}


const ChatPageUserMessage = observer(({ message, interlocutor }: ChatPageUserMessageProps) => {
  const isMyMessage = message.userId === userInfoStore.userData.id
  const nickname = isMyMessage ? userInfoStore.userData.nickname : interlocutor.nickname

  return (
    <DivFlexColumn style={`${styles.container} ${isMyMessage && styles.user}`}>
      <DivFlexRow style={styles.header}>
        <ChatPageAvatar avatar="" />
        <DivFlexColumn style={styles.userData}>
          <DivFlexRow style={styles.userNameBlock}>
            <Text16White style={styles.userName}>{nickname}</Text16White>
            {isMyMessage && <Text16Purple style={styles.userNameStatus}>{`(Вы)`}</Text16Purple>}
          </DivFlexRow>
          <Text12GreyRoboto style={styles.messageDate}>{formatTimeDate(message.createdAt)}</Text12GreyRoboto>
        </DivFlexColumn>
      </DivFlexRow>
      <Text14WhiteRoboto style={styles.whiteSpace}>{message.text}</Text14WhiteRoboto>
    </DivFlexColumn>
  )
})

export { ChatPageUserMessage }
