import { ReactNode } from 'react'
import styles from './textStyles.module.css'

type TextProps = {
  children: ReactNode
  style?: string
}

// white

export const Text12White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text12White} ${style}`}>{children}</p>
}

export const Text12WhiteRoboto = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text12WhiteRoboto} ${style}`}>{children}</p>
}

export const Text14White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text14White} ${style}`}>{children}</p>
}

export const Text14WhiteRoboto = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text14WhiteRoboto} ${style}`}>{children}</p>
}

export const Text16White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text16White} ${style}`}>{children}</p>
}

export const Text16WhiteRoboto = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text16WhiteRoboto} ${style}`}>{children}</p>
}

export const Text18White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text18White} ${style}`}>{children}</p>
}

export const Text20White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text20White} ${style}`}>{children}</p>
}

export const Text24White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text24White} ${style}`}>{children}</p>
}

export const Text31White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text31White} ${style}`}>{children}</p>
}

export const Text32White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text32White} ${style}`}>{children}</p>
}

export const Text40White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text40White} ${style}`}>{children}</p>
}

export const Text48White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text48White} ${style}`}>{children}</p>
}

export const Text64White = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text64White} ${style}`}>{children}</p>
}

// green

export const Text10Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text10Green} ${style}`}>{children}</p>
}

export const Text12Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text12Green} ${style}`}>{children}</p>
}

export const Text14Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text14Green} ${style}`}>{children}</p>
}

export const Text16Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text16Green} ${style}`}>{children}</p>
}

export const Text18Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text18Green} ${style}`}>{children}</p>
}

export const Text20Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text20Green} ${style}`}>{children}</p>
}

export const Text24Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text24Green} ${style}`}>{children}</p>
}

export const Text32Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text32Green} ${style}`}>{children}</p>
}

export const Text48Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text48Green} ${style}`}>{children}</p>
}

export const Text64Green = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text64Green} ${style}`}>{children}</p>
}

// Grey

export const Text12Grey = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text12Grey} ${style}`}>{children}</p>
}

export const Text12GreyRoboto = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text12GreyRoboto} ${style}`}>{children}</p>
}

export const Text14Grey = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text14Grey} ${style}`}>{children}</p>
}

export const Text16Grey = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text16Grey} ${style}`}>{children}</p>
}

export const Text18Grey = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text18Grey} ${style}`}>{children}</p>
}

export const Text24Grey = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text24Grey} ${style}`}>{children}</p>
}

export const Text48Grey = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text48Grey} ${style}`}>{children}</p>
}

// purple

export const Text14Purple = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text14Purple} ${style}`}>{children}</p>
}

export const Text16Purple = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text16Purple} ${style}`}>{children}</p>
}

export const Text48Purple = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text48Purple} ${style}`}>{children}</p>
}

// orange

export const Text14Orange = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text14Orange} ${style}`}>{children}</p>
}

// yellow

export const Text16Yellow = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text16Yellow} ${style}`}>{children}</p>
}

// red

export const Text12Red = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text12Red} ${style}`}>{children}</p>
}

export const Text14Red = ({ children, style }: TextProps) => {
  return <p className={`${styles.Text14Red} ${style}`}>{children}</p>
}
