import { CSSProperties } from 'react'
import styles from './AuthSubmitButton.module.css'
import Loader from '../../loaders/Loader/Loader'
import DisableWindowBlock from '../../DisableWindowBlock/DisableWindowBlock'

type AuthSubmitButtonProps = {
  text: string
  loaderText: string
  anyStyles?: CSSProperties
  isSubmit: boolean
  onSubmit: (e: any) => Promise<void>
}

const AuthSubmitButton = ({ text, loaderText, anyStyles, isSubmit, onSubmit }: AuthSubmitButtonProps) => {
  return (
    <>
      <button className={`${styles.button} ${isSubmit && styles.disable}`} style={{ ...anyStyles }} onClick={onSubmit}>
        <p className={styles.text}>{isSubmit ? loaderText : text}</p>
        {isSubmit && <Loader />}
      </button>
      {isSubmit && <DisableWindowBlock />}
    </>
  )
}

export default AuthSubmitButton
