import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import styles from './ShopBarModalHeader.module.css'
import { Text16Grey, Text24White } from 'components/styledComponents/text/textStyles'
import { CopyLinkButton } from 'components/ui/buttons/CopyLinkButton'
import { ShopBarModalPriceBlock } from 'components/ui/modals/ShopBarModal/ShopBarModalPriceBlock'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { ShopBarModalButtons } from 'components/ui/modals/ShopBarModal/ShopBarModalButtons'
import Cross from '../../../../../components/icons/cross_purple.svg'
import { ShopBarContanctBlock } from '../../../ShopBar/ShopBarContanctBlock'
import { AdvertisementPropertyUserDto } from 'store/advertisement/advertisementsShopBar/types'
import { NavigateLinks } from '../../../../../PageRouters'
import {convertAdvertisementCurrencyToBtnCatalog} from "../../../../../utils/convert";

type Image = {
  id: string
  name: string
  image?: string
}

type ShopBarModalHeaderProps = {
  activeImage: Image
  name: string
  onClose: () => void
  sourceCategory: string
  price?: string
  countAdvertisementBuy?: number
  countAdvertisementSale?: number
  isActiveStatus: boolean
  user?: AdvertisementPropertyUserDto
  gamePhoneId?: string | null
  catalogId: string
  currency?: string
  advertisementCurrency?: string
  page: 'catalog' | 'advertisement'
  advertisementId?: string
}

const ShopBarModalHeader = ({
  activeImage,
  name,
  onClose,
  sourceCategory,
  price,
  countAdvertisementBuy,
  countAdvertisementSale,
  isActiveStatus,
  user,
  gamePhoneId,
  catalogId,
  currency,
  advertisementCurrency,
  page,
  advertisementId
}: ShopBarModalHeaderProps) => {
  const getLink = () => {
    if (page === 'advertisement') return `${NavigateLinks.AdvertisementCard}/${catalogId}`
    if (page === 'catalog') return `${NavigateLinks.CatalogCard}/${catalogId}`
    return ''
  }

  return (
    <DivFlexRow style={`${styles.header}`}>
      <div className={`${styles.headerImageContainer} ${!isActiveStatus && styles.iconDisable}`}>
        <img src={activeImage?.image ?? ''} alt="product" className={styles.cardImage} />
      </div>

      <DivFlexColumn style={styles.headerTextBlock}>
        <DivFlexRow style={styles.headerNameBlock}>
          <Text24White style={styles.headerText}>{name}</Text24White>
          <img src={Cross} alt="x" className={styles.cross} onClick={onClose} />
        </DivFlexRow>

        <Text16Grey style={`${!isActiveStatus && styles.disable}`}>{sourceCategory}</Text16Grey>

        <CopyLinkButton
          text="Скопировать ссылку"
          fontSize={14}
          isDisable={!isActiveStatus}
          cssContainerStyles={styles.copyButton}
          link={getLink()}
        />

        <ShopBarModalPriceBlock
          price={price}
          isActiveStatus={isActiveStatus}
          isDisable={!isActiveStatus}
          currency={currency}
        />

        <HorizontalDivider cssStyle={styles.headerDivider} />

        {typeof countAdvertisementBuy === 'number' && typeof countAdvertisementSale === 'number' && advertisementCurrency ? (
          <ShopBarModalButtons
            catalogId={catalogId}
            cssContainer={styles.buttonContainer}
            countAdvertisementBuy={countAdvertisementBuy}
            countAdvertisementSale={countAdvertisementSale}
            nameButtonSale={convertAdvertisementCurrencyToBtnCatalog(advertisementCurrency).buttonSale}
            nameButtonBuy={convertAdvertisementCurrencyToBtnCatalog(advertisementCurrency).buttonBuy}
          />
        ) : (
          <>
            {user && (
              <ShopBarContanctBlock
                isDisable={!isActiveStatus}
                user={user}
                gamePhoneId={gamePhoneId}
                advertisementId={advertisementId}
              />
            )}
          </>
        )}
      </DivFlexColumn>
    </DivFlexRow>
  )
}

export { ShopBarModalHeader }
