import styles from './HomePhoneSection.module.css'

import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'
import { BackFilter } from '../../components/BackFilter'
import { HomePhoneSectionText } from './HomePhoneSectionText'
import { HomePhoneSectionCallContainer } from './HomePhoneSectionCallContainer'
import { HomePhoneSectionPhoneContainer } from './HomePhoneSectionPhoneContainer'

import Back from './back-filter.svg'
import Yarn from './yarns.svg'
import { ImgBase } from 'components/styledComponents/img/img'

const HomePhoneSection = () => {
  return (
    <section className={styles.section}>
      <BackFilter backContainer={Back} cssStyle={styles.back} />

      <div style={{ zIndex: 2, position: 'relative', pointerEvents: 'none' }}>
        <HomePhoneSectionText />

        <HomePhoneSectionCallContainer />

        <HomePhoneSectionPhoneContainer />

        <ImgBase img={Yarn} style={styles.yarn} />
      </div>

      <PurpleNeonButton text="Начать торговать" cssStyle={styles.button} />
    </section>
  )
}

export { HomePhoneSection }
