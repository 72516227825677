type handleInputChangeType = {
  event: React.ChangeEvent<HTMLInputElement>
  onChange: (value: string) => void
  setEmpty: (value: React.SetStateAction<boolean>) => void
  validations: ((value: string) => boolean)[]
  setIsValid: (value: React.SetStateAction<boolean>) => void
}

export const isEmptyInput = (value: string) => {
  // проверка пустоты поля
  return value.trim() === ''
}

export const handleInputChange = ({ event, onChange, setEmpty, validations, setIsValid }: handleInputChangeType) => {
  const newValue = event.target.value
  onChange(newValue)

  setEmpty(isEmptyInput(newValue))
  // проверка валидности при каждом изменении значения поля
  const isValid = validations.every((validation) => validation(newValue))
  setIsValid(isValid)
}

export const submitButtonIsActive = (validations: Function[]): boolean => {
  return validations.every((validation) => validation())
}

export const formatUserVipDate = (dateStr: string): string => {
  // преобразование даты подписки для вывода дня ее окончания
  const dateObj = new Date(dateStr)
  return `${dateObj.getDate().toString().padStart(2, '0')}.${(dateObj.getMonth() + 1)
    .toString()
    .padStart(2, '0')}.${dateObj.getFullYear()}`
}

export const formatGamePhoneNumber = (inputValue: string): string => {
  // Убираем все нецифровые символы
  const formattedValue = inputValue.replace(/\D/g, '')

  if (formattedValue.length > 5) {
    return formattedValue.substring(0, 3) + '-' + formattedValue.substring(3, 5) + '-' + formattedValue.substring(5)
  } else if (formattedValue.length > 3) {
    return formattedValue.substring(0, 3) + '-' + formattedValue.substring(3)
  }

  return formattedValue
}

export const formatBankCardNumber = (inputValue: string): string => {
  const formattedValue = inputValue.replace(/\D/g, '')

  if (formattedValue.length > 12) {
    return (
      formattedValue.substring(0, 4) +
      ' ' +
      formattedValue.substring(4, 8) +
      ' ' +
      formattedValue.substring(8, 12) +
      ' ' +
      formattedValue.substring(12)
    )
  } else if (formattedValue.length > 8) {
    return formattedValue.substring(0, 4) + ' ' + formattedValue.substring(4, 8) + ' ' + formattedValue.substring(8)
  } else if (formattedValue.length > 4) {
    return formattedValue.substring(0, 4) + ' ' + formattedValue.substring(4)
  }

  return formattedValue
}

export const formatOnlyNumber = (inputValue: string): string => {
  return inputValue.replace(/\D/g, '')
}
