import styles from './CrossButton.module.css'
import cross from '../../../icons/cross_purple.svg'
import { CSSProperties } from 'react'

type CrossButtonProps = {
  anyStyles?: CSSProperties
  onClick?: (event: React.MouseEvent) => void
}

const CrossButton = ({ anyStyles, onClick }: CrossButtonProps) => {
  return <img onClick={onClick} style={anyStyles} className={styles.container} src={cross} alt="x" />
}

export default CrossButton
