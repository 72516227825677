import styles from './CallButton.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text14White } from 'components/styledComponents/text/textStyles'
import { ImgBase } from 'components/styledComponents/img/img'

import Call from '../../../icons/button/green_phone-call.svg'
import { ButtonTexts } from '../../../../constants/buttonTexts'

type CallButtonProps = {
  cssStyle?: string
  isDisable?: boolean
  handleClick?: () => void
  isActiveCopy?: boolean
}

const CallButton = ({ cssStyle, isDisable, isActiveCopy, handleClick }: CallButtonProps) => {
  return (
    <DivFlexRow style={`${styles.div} ${cssStyle} ${isDisable && styles.disable}`} onClick={handleClick}>
      <Text14White>{isActiveCopy ? 'Номер скопирован!' : ButtonTexts.ToCall}</Text14White>
      <ImgBase img={Call} alt="icon" />
    </DivFlexRow>
  )
}

export { CallButton }
