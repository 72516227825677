import styles from './Advertisement.module.css'

import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { useEffect, useRef, useState } from 'react'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ShopBarHeader } from '../../ui/ShopBar/ShopBarHeader'
import { Text24White } from 'components/styledComponents/text/textStyles'
import { SearchFilterBig } from 'components/ui/SearchFilterBig'
import { HorizontalSlider } from 'components/ui/HorizontalSlider'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import { observer } from 'mobx-react-lite'
import { ShopBarBody } from '../../ui/ShopBar/ShopBarBody'
import { ShopBarTransactionCategory } from 'components/ui/ShopBar/ShopBarTransactionCategory'
import { ShopBarCategory } from 'components/ui/ShopBar/ShopBarCategory'
import { ShopBarList } from 'components/ui/ShopBar/ShopBarList'
import { ShopBarListAlert } from 'components/ui/ShopBar/ShopBarList/ShopBarListAlert'
import { advertisementsShopBarStore } from 'store/advertisement/advertisementsShopBar/advertisementsShopBarStore'
import { AdvertisementProductCard } from 'components/ui/Advertisement/AdvertisementProductCard'
import { AdvertisementProductModal } from 'components/ui/modals/AdvertisementProductModal'
import { Pagination } from 'components/ui/Pagination'
import { scrollToItemTop } from 'utils/pageScrollers'
import { IPlatformCategory } from 'store/platformConfigStore/types'
import { useSearchParams } from 'react-router-dom'
import { TSearchParamsJsonTypesValue } from '../../../utils/searchParams'
import { PlatformConfigSort } from '../../../api/platform/platform.type'
import { actionLoadAdvertisement } from '../../../action/loadAdvertisement'
import { TypeAdvertisement } from '../../../common/types'
import { IGetResAdvertisementListItem } from '../../../api/advetrisement/shopbar/advertisement.type'

const Advertisement = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isTransitionCatalog, setTransitionCatalog] = useState<boolean>(false)

  const { items } = advertisementsShopBarStore.advertisementItems
  const { take, totalCount, currentPage } = advertisementsShopBarStore.advertisementItems.pagination

  const { getFiltersByLabelCategory, getSortsByLabelCategory } = userPlatformConfig
  const {
    sortSelectedItem,
    sortListItem,
    paginationTake,
    filters,
    searchWords,
    valueFilters,
    isFiltersOpen,
    sortSelectedOrderBy,
    sortListOrderBy,
    textBySearch,
    type,
    dealerStatus,
    setPaginationSkip,
    setTextBySearch,
    setCategoryIdBySearch,
    removeFiltersValue,
    setFilterValue,
    onChangeInputTextBySearch,
    setFilterOpen,
    setFiltersList,
    setSortsList,
    setSortItem,
    setSortItemOrderBy,
    setAdvertisementType,
    setDealerStatus
  } = advertisementsShopBarStore

  useEffect(() => {
    const systemCatalogId = searchParams.get('systemCatalogId')
    const systemAdvertisementType = searchParams.get('systemAdvertisementType')

    if (systemAdvertisementType && systemCatalogId) {
      if (systemCatalogId) {
        setFilterValue('systemCatalogId', {
          type: 'value',
          data: {
            value: systemCatalogId
          }
        })
      }

      if (systemAdvertisementType && ['sale', 'buy'].includes(systemAdvertisementType)) {
        setAdvertisementType(systemAdvertisementType as TypeAdvertisement)
      }

      setTransitionCatalog(true)
    }

    actionLoadAdvertisement()
  }, [])

  const [cardData, setCardData] = useState<IGetResAdvertisementListItem | null>(null)
  const [baseModalImage, setBaseModalImage] = useState<{ id: string; name: string; image: string }>({
    id: '',
    name: '',
    image: ''
  })
  const [isModal, setModal] = useState<boolean>(false)

  const scrollToRef = useRef<any>(null)

  const startLoadItems = (trigger: {
    searchText?: boolean
    pagination?: boolean
    applyFilters?: boolean
    chooseCategory?: boolean
    chooseServer?: boolean
    orderBy?: boolean
    chooseType?: boolean
    chooseDealer?: boolean
  }) => {
    if (trigger.searchText) {
      setCategoryIdBySearch(undefined)
      setFilterOpen(false)
      setPaginationSkip(0)
      removeFiltersValue()
      setFilterAndSortByLabel('')
      if (isTransitionCatalog) setSearchParams(undefined)
    } else if (trigger.pagination) {
      setFilterOpen(false)
    } else if (trigger.chooseCategory) {
      setFilterOpen(false)
      setPaginationSkip(0)
      removeFiltersValue()
      setTextBySearch('')
      if (isTransitionCatalog) setSearchParams(undefined)
    } else if (trigger.applyFilters) {
      setFilterOpen(false)
      setPaginationSkip(0)
      setTextBySearch('')
    } else if (trigger.chooseServer) {
      setFilterOpen(false)
      setPaginationSkip(0)
      removeFiltersValue()
    } else if (trigger.chooseType) {
      setFilterOpen(false)
      setPaginationSkip(0)
      if (!isTransitionCatalog) removeFiltersValue()
      setFilterAndSortByLabel('')
    } else if (trigger.chooseDealer) {
      setFilterOpen(false)
      setPaginationSkip(0)
    }

    actionLoadAdvertisement().then(() => {
      scrollToItemTop(scrollToRef)
    })
  }

  // Установка фильтров и сортировки по label категории
  const setFilterAndSortByLabel = (label: string) => {
    const filters = getFiltersByLabelCategory(label, 'advertisement')
    setFiltersList(filters)

    const sorts = getSortsByLabelCategory(label, 'advertisement')
    setSortsList(sorts)
  }

  // Открытие модального окна через "Подробнее"
  const handleOpenCardModal = (cardData: IGetResAdvertisementListItem, baseImage: string) => {
    setCardData(cardData)
    setModal(true)
    const imageData = {
      id: cardData.images[0].id,
      name: cardData.images[0].name
    }
    setBaseModalImage({
      ...baseModalImage,
      id: imageData.id,
      name: imageData.name,
      image: baseImage
    })
  }

  // Ввод текста в глобальный поиск
  const handleOnChangeInputSearchByText = async (text: string) => onChangeInputTextBySearch(text)

  // Запуск глобального поиска
  const handleOnSubmitSearchByText = async () => startLoadItems({ searchText: true })

  // Очистка текста в глобальном поиске
  const handleOnInputClear = async () => setTextBySearch('')

  // Выбор ранее введенного текста
  const handleSliderItemClick = async (itemName: string) => {
    setTextBySearch(itemName)
    startLoadItems({ searchText: true })
  }

  // Пагинация
  const handlePagination = async (skip: number): Promise<void> => {
    setPaginationSkip(skip)
    startLoadItems({ pagination: true })
  }

  // Смена категории
  const handleChooseCategory = async (category: IPlatformCategory) => {
    if (category.id === 'all') {
      setCategoryIdBySearch(undefined)
      setFilterAndSortByLabel('')
      startLoadItems({ chooseCategory: true })
    } else if (category.categories.length === 0 && category.label) {
      setCategoryIdBySearch(category.id)
      setFilterAndSortByLabel(category.label)
      startLoadItems({ chooseCategory: true })
    }
  }

  // Применение фильтров
  const handlerFiltersApply = async () => {
    setFilterOpen(false)
    setPaginationSkip(0)
      startLoadItems({ applyFilters: true })
  }

  // Открыть/Закрыть фильтры
  const handlerFiltersClick = async () => {
    setFilterOpen(!isFiltersOpen)
  }

  // Установить значение фильтра
  const handlerFiltersSetValue = (key: string, data: TSearchParamsJsonTypesValue) => {
    setFilterValue(key, data)
  }

  // Выбрать поле сортировки
  const handleSortSelectedItem = async (value: PlatformConfigSort) => {
    setSortItem(value)
    startLoadItems({ orderBy: true })
  }

  // Выбрать order сортировки
  const handleSortSelectedOrderBy = async (value: PlatformConfigSort) => {
    setSortItemOrderBy(value)
    startLoadItems({ orderBy: true })
  }

  // Выбрать тип объявлений
  const handleChooseListType = (type: TypeAdvertisement) => {
    setAdvertisementType(type)
    startLoadItems({ chooseType: true })
  }

  // Выбрать статус продавца
  const handleChooseDealerType = (type: '' | 'online' | 'game') => {
    setDealerStatus(type)
    startLoadItems({ chooseType: true })
  }

  // Выбрать сервер
  const handleChooseServer = () => {
    startLoadItems({ chooseServer: true })
  }

  return (
    <>
      <PageWrapper style={styles.wrapper}>
        <DivFlexColumn style={styles.headerContainer}>
          <ShopBarHeader type="advertisement" onChooseServer={handleChooseServer} />

          <DivFlexColumn style={styles.searchContainer}>
            <Text24White style={styles.searchText}>Поиск</Text24White>

            <SearchFilterBig
              inputValue={textBySearch}
              setInputValue={handleOnChangeInputSearchByText}
              cssStylesContainer={styles.searchInput}
              onSubmit={handleOnSubmitSearchByText}
              onClearFunction={handleOnInputClear}
            />

            <HorizontalSlider
              cssStylesContainer={styles.slider}
              items={searchWords}
              onClickItem={handleSliderItemClick}
            />
          </DivFlexColumn>
        </DivFlexColumn>

        <div ref={scrollToRef}></div>

        <ShopBarBody>
          <DivFlexRow style={styles.shopBarBody}>
            <DivFlexColumn style={styles.sortedBlock}>
              <ShopBarTransactionCategory
                listType={type}
                dealerStatus={dealerStatus}
                handleChooseListType={handleChooseListType}
                handleChooseDealerType={handleChooseDealerType}
              />
              <ShopBarCategory setCategoryData={handleChooseCategory} />
            </DivFlexColumn>

            <DivFlexColumn style={styles.itemsBlock}>
              <ShopBarList
                itemsCount={totalCount}
                onClickFiltersApply={handlerFiltersApply.bind(this)}
                onSetValueInFilter={handlerFiltersSetValue}
                onClickFilter={handlerFiltersClick.bind(this)}
                sortListItem={sortListItem}
                sortSelectedItem={sortSelectedItem}
                onSortSelectedItem={handleSortSelectedItem.bind(this)}
                sortListOrderBy={sortListOrderBy}
                sortSelectedOrderBy={sortSelectedOrderBy}
                onSortSelectedOrderBy={handleSortSelectedOrderBy.bind(this)}
                isFilterOpen={isFiltersOpen}
                filters={filters}
                valueFilters={valueFilters}>
                {items.map((item) => {
                  return <AdvertisementProductCard key={item.id} card={item} onOpen={handleOpenCardModal} />
                })}
              </ShopBarList>

              {items.length === 0 && <ShopBarListAlert />}

              {items.length > 0 && (
                <Pagination
                  changePaginationPage={handlePagination}
                  itemsInPage={paginationTake}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  take={take}
                  cssBlockStyles={styles.pagination}
                />
              )}
            </DivFlexColumn>
          </DivFlexRow>
        </ShopBarBody>
      </PageWrapper>

      {isModal && cardData && (
        <AdvertisementProductModal
          isOpen={isModal}
          onClose={setModal.bind(this, false)}
          cardData={cardData}
          baseImage={baseModalImage}
        />
      )}
    </>
  )
})

export default Advertisement
