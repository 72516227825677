import { action, makeObservable, observable } from 'mobx'
import { ELocalStorage } from '../common/enum/localStorage'

enum UserInfoStoreRoles {
  Media = 'media',
  Editor = 'editor'
}

export type UserInfoType = {
  activeStatus: string
  id: string
  email: string
  nickname: string
  avatarId: string
  roles: string[]
  subscriptionExpirationDate: string
  countChatNotRead: number
}

class UserInfoStore {
  isAuth: boolean = false
  isVip: boolean = false
  isMedia: boolean = false
  isEditor: boolean = false
  userAvatar: string = ''
  jwtToken: string | null = null

  userData: UserInfoType = {
    activeStatus: '',
    id: '',
    email: '',
    nickname: '',
    avatarId: '',
    roles: [],
    subscriptionExpirationDate: '',
    countChatNotRead: 0
  }

  constructor() {
    makeObservable(this, {
      jwtToken: observable,
      userData: observable,
      isAuth: observable,
      isVip: observable,
      isMedia: observable,
      isEditor: observable,
      userAvatar: observable,
      setAuth: action.bound,
      addNotReadChat: action.bound,
      setLogOut: action.bound,
      setUserInfo: action.bound,
      isSubscriptionExpired: action.bound,
      checkUserRoles: action.bound,
      checkUserAvatar: action.bound,
      changeUserStatus: action.bound,
      setJwtToken: action
    })
    this.jwtToken = localStorage.getItem(ELocalStorage.jwtToken)
  }

  setAuth() {
    this.isAuth = true
  }

  setLogOut() {
    this.isAuth = false
    this.isVip = false
    this.isMedia = false
    this.isEditor = false
    this.userAvatar = ''
    localStorage.removeItem(ELocalStorage.jwtToken)
  }

  setJwtToken = (token: string) => {
    localStorage.setItem(ELocalStorage.jwtToken, token)
    this.jwtToken = token
  }

  setUserInfo(userInfo: UserInfoType) {
    this.userData = userInfo
  }

  isSubscriptionExpired() {
    // проверка на активный vip
    const vipDate = new Date(this.userData.subscriptionExpirationDate)
    const currentDate = new Date()

    if (vipDate && vipDate >= currentDate) {
      return (this.isVip = true)
    } else return (this.isVip = false)
  }

  checkUserRoles() {
    // проверка ролей
    const roles = this.userData.roles

    if (roles.includes(UserInfoStoreRoles.Editor)) {
      return (this.isEditor = true)
    } else if (roles.includes(UserInfoStoreRoles.Media)) {
      return (this.isMedia = true)
    }
  }

  checkUserAvatar(value: string) {
    // проверка на avatar
    this.userAvatar = value
  }

  changeUserStatus(value: string) {
    this.userData.activeStatus = value
  }

  addNotReadChat() {
    this.userData.countChatNotRead++
  }
}

const userInfoStore = new UserInfoStore()

export { userInfoStore }
