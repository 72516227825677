import { ReactNode } from 'react'
import styles from './HorizontalSliderShadowContainer.module.css'

type HorizontalSliderShadowContainerProps = {
  children: ReactNode
  cssStyles: string
  isRight?: boolean
}

const HorizontalSliderShadowContainer = ({ children, cssStyles, isRight }: HorizontalSliderShadowContainerProps) => {
  return <div className={`${styles.container} ${isRight ? styles.right : styles.left} ${cssStyles}`}>{children}</div>
}

export { HorizontalSliderShadowContainer }
