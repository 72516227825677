import CircleAlertMarker from 'components/ui/CircleAlertMarker/CircleAlertMarker'
import styles from './OvalButton.module.css'
import { CSSProperties, useState } from 'react'
import TextAlertMarker from 'components/ui/CountAlertMarker/TextAlertMarker'
import { Link } from 'react-router-dom'

type OvalButtonProps = {
  text?: string
  textColor?: string
  textHoverColor?: string
  icon?: string
  hoverIcon?: string
  iconSize?: number
  containerStyle?: CSSProperties | any
  cssContainerStyle?: string
  textStyle?: CSSProperties
  cssTextStyle?: string
  marker?: boolean
  textMarker?: string
  router?: string
  isSwapContent?: boolean
  onClick?: () => void
  iconStyle?: CSSProperties
  cssIconStyle?: string
  fontSize?: number
  isTransparent?: boolean
  isDisable?: boolean
  onMouseLeave?: () => void
}
// TODO кнопку с marker сделать отдельным компонентом

const OvalButton = ({
  text,
  textColor,
  textHoverColor,
  icon,
  hoverIcon,
  iconSize,
  containerStyle,
  cssContainerStyle,
  textStyle,
  cssTextStyle,
  marker,
  textMarker,
  router,
  isSwapContent,
  onClick,
  iconStyle,
  cssIconStyle,
  fontSize,
  onMouseLeave,
  isTransparent = false,
  isDisable = false
}: OvalButtonProps) => {
  const [isHover, setHover] = useState<boolean>(false)

  function showIcon(): string {
    if (hoverIcon && icon) {
      if (!isHover) {
        return icon
      } else {
        return hoverIcon
      }
    }
    return icon ?? ''
  }

  function handleClick() {
    if (!isDisable && onClick) onClick()
  }

  function handlerOnMouseLeave() {
    setHover(false)
    if (onMouseLeave) onMouseLeave()
  }

  if (router) {
    return (
      <Link
        onClick={handleClick}
        to={router}
        className={`${styles.container} ${cssContainerStyle}`}
        style={isTransparent ? { backgroundColor: 'transparent' } : {} && { ...containerStyle }}>
        {icon && (
          <img
            className={`${styles.icon} ${cssIconStyle}`}
            src={showIcon()}
            style={{ ...iconStyle, width: iconSize, height: iconSize }}
            alt="icon"
          />
        )}
        {text && (
          <p
            className={`${styles.text} ${cssTextStyle}`}
            style={{
              ...textStyle,
              fontSize: fontSize,
              color: isHover ? textHoverColor : textColor
            }}>
            {text}
          </p>
        )}
        {marker && <CircleAlertMarker />}
        {textMarker && <TextAlertMarker text={textMarker} />}
      </Link>
    )
  }

  if (isSwapContent) {
    return (
      <button
        onClick={handleClick}
        className={`${styles.container} ${cssContainerStyle}`}
        style={isTransparent ? { backgroundColor: 'transparent' } : {} && { ...containerStyle }}
        onMouseEnter={setHover.bind(this, true)}
        onMouseLeave={handlerOnMouseLeave}>
        {text && (
          <p
            className={`${styles.text} ${cssTextStyle}`}
            style={{
              ...textStyle,
              fontSize: fontSize,
              color: isHover ? textHoverColor : textColor
            }}>
            {text}
          </p>
        )}
        {icon && (
          <img
            className={`${styles.icon} ${cssIconStyle}`}
            src={showIcon()}
            style={{ ...iconStyle, width: iconSize, height: iconSize }}
            alt="icon"
          />
        )}
        {marker && <CircleAlertMarker />}
        {textMarker && <TextAlertMarker text={textMarker} />}
      </button>
    )
  }

  return (
    <button
      onClick={handleClick}
      className={`${styles.container} ${cssContainerStyle}`}
      style={isTransparent ? { backgroundColor: 'transparent' } : {} && { ...containerStyle }}
      onMouseEnter={setHover.bind(this, true)}
      onMouseLeave={handlerOnMouseLeave}>
      {icon && (
        <img
          className={`${styles.icon} ${cssIconStyle}`}
          src={showIcon()}
          style={{ ...iconStyle, width: iconSize, height: iconSize }}
          alt="icon"
        />
      )}
      {text && (
        <p
          className={`${styles.text} ${cssTextStyle}`}
          style={{
            ...textStyle,
            fontSize: fontSize,
            color: isHover ? textHoverColor : textColor
          }}>
          {text}
        </p>
      )}
      {marker && <CircleAlertMarker />}
      {textMarker && <TextAlertMarker text={textMarker} />}
    </button>
  )
}

export default OvalButton
