import { useState } from 'react'
import { UserAvatar } from '../UserAvatar/UserAvatar'
import ArrowBottomButton from '../buttons/ArrowBottomButton/ArrowBottomButton'
import styles from './UserHeaderButton.module.css'
import UserHeaderMenu from '../dropdowns/UserHeaderMenu/UserHeaderMenu'

type UserHeaderButtonProps = {
  cssStyles?: string
}

const UserHeaderButton = ({ cssStyles }: UserHeaderButtonProps) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <>
      <div className={`${styles.container} ${cssStyles}`} onClick={setOpen.bind(this, !isOpen)}>
        <UserAvatar />
        <ArrowBottomButton />

        <UserHeaderMenu onCloseMenu={setOpen.bind(this, false)} isOpen={isOpen} />
      </div>

      {isOpen && <div className={styles.back} onClick={setOpen.bind(this, false)}></div>}
    </>
  )
}

export default UserHeaderButton
