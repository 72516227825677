import styles from './DiscordForm.module.css'
import AcceptRulesBlock from 'components/ui/AcceptRulesBlock/AcceptRulesBlock'
import AuthSubmitButton from 'components/ui/buttons/AuthSubmitButton/AuthSubmitButton'
import { useState } from 'react'
import { StateErrorType } from '../types'
import NicknameFormInput from 'components/ui/inputs/NicknameFormInput/NicknameFormInput'
import useNicknameValidation from 'utils/forms/nicknameValidationHook'
import { BrowserRouters } from '../../../../PageRouters'

const placeHolderText = 'Ваш никнейм'
const bottomText = 'Отображается в профиле, латинские буквы, цифры, символы'

type DiscordFormProps = {
  submitButtonText: string
}

const DiscordForm = ({ submitButtonText }: DiscordFormProps) => {
  const [nickname, setNickname] = useState<string>('')
  const [checkbox, setCheckbox] = useState<boolean>(true)
  const [isSubnit, setSubmit] = useState<boolean>(false)

  const [nicknameError, setNicknameError] = useState<StateErrorType>({
    text: '',
    isError: false
  })

  const { isValid: isNicknameValid } = useNicknameValidation(nickname)

  const validationCheckbox = () => {
    setCheckbox((prevCheckbox) => !prevCheckbox)
  }

  const handleDiscordSubmit = async () => {
    if (!nickname) {
      setNicknameError({ text: 'Введите никнейм', isError: true })
    }
    if (isNicknameValid && checkbox && nickname) {
      setSubmit(true)
      /*const result = await authDiscordOauth2Url(nickname)

      if (result.data) {
        window.location.href = result.data.redirectUrl
      }

      if (result.error) {
        setNicknameError({ text: result.error.message, isError: true })
      }*/
      setSubmit(false)
    }
  }

  return (
    <div className={styles.container}>
      <NicknameFormInput
        value={nickname}
        onChange={setNickname}
        placeHolder={placeHolderText}
        bottomText={bottomText}
        backendError={{
          backendText: nicknameError.text,
          isBackandError: nicknameError.isError
        }}
        clearError={setNicknameError.bind(this, { text: '', isError: false })}
      />

      <AcceptRulesBlock
        text="Зарегистрироваться"
        rules="правилами использования сервиса"
        isCheked={validationCheckbox}
        anyStyles={{ marginTop: '12px' }}
        link={BrowserRouters.Agreement}
      />

      <AuthSubmitButton
        text={submitButtonText}
        loaderText="Загрузка"
        isSubmit={isSubnit}
        onSubmit={handleDiscordSubmit}
        anyStyles={{ marginTop: '30px' }}
      />
    </div>
  )
}

export default DiscordForm
