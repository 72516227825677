import styles from './ArrowInCircleWithPurpleBorder.module.css'

type ArrowInCircleWithPurpleBorderProps = {
  isLeft?: boolean
  onClick?: () => void
  cssStyle?: string
}

const ArrowInCircleWithPurpleBorder = ({ isLeft = false, onClick, cssStyle }: ArrowInCircleWithPurpleBorderProps) => {
  return (
    <div className={`${styles.container} ${cssStyle}`} onClick={onClick}>
      <div className={`${isLeft ? styles.triangleLeft : styles.triangleRight}`}></div>
    </div>
  )
}

export { ArrowInCircleWithPurpleBorder }
