import styles from './ChatCardProductData.module.css'

import { DivFlexRow, DivFlexColumn } from 'components/styledComponents/div/div'
import { Text12Red, Text18White, Text14Grey } from 'components/styledComponents/text/textStyles'
import { ChatCardItemStatus } from '../ChatCardItemStatus'
import { ChatCardPrice } from '../ChatCardPrice'
import { TypeAdvertisement } from '../../../../common/types'
import { formatPrice } from '../../../../utils/formatPrice'

type ChatCardProductDataProps = {
  tradeType: TypeAdvertisement
  serverName: string
  itemName: string
  itemSourceCategory: string
  itemPrice: number
  itemPhotoUrl?: string
}

const ChatCardProductData = ({
  tradeType,
  serverName,
  itemName,
  itemSourceCategory,
  itemPrice,
  itemPhotoUrl = ''
}: ChatCardProductDataProps) => {
  return (
    <DivFlexRow style={styles.itemData}>
      <DivFlexColumn style={styles.statusBlock}>
        <ChatCardItemStatus tradeType={tradeType} />
        <Text12Red style={styles.server}>{serverName}</Text12Red>
      </DivFlexColumn>

      {!!itemPhotoUrl && <img className={styles.itemPhoto} src={itemPhotoUrl} alt="itemPhoto" />}

      <DivFlexColumn style={styles.textBlock}>
        <Text18White style={styles.itemName}>{itemName}</Text18White>
        <Text14Grey style={styles.itemSub}>{itemSourceCategory}</Text14Grey>
      </DivFlexColumn>

      <ChatCardPrice status={tradeType} price={formatPrice(itemPrice)} />
    </DivFlexRow>
  )
}

export { ChatCardProductData }
