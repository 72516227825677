import styles from './SubscriptionPaymentModalPriceData.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import {
  Text16Grey,
  Text24Grey,
  Text14Grey,
  Text16Green,
  Text32White,
  Text16White
} from 'components/styledComponents/text/textStyles'
import { SubscriptionsInfoType } from 'store/subscriptionStore/subscriptionStore'

type SubscriptionPaymentModalPriceDataProps = {
  activeSub: SubscriptionsInfoType
  isPromoCode: boolean
}

const SubscriptionPaymentModalPriceData = ({ activeSub, isPromoCode }: SubscriptionPaymentModalPriceDataProps) => {
  const getTotalPrice = (): number => {
    if (activeSub.priceDiscount) {
      return activeSub.priceDiscount
    }
    if (isPromoCode && typeof activeSub.pricePromoCode === 'number') {
      return activeSub.pricePromoCode
    }
    return activeSub.price
  }

  const totalPrice = getTotalPrice()

  return (
    <DivFlexColumn style={styles.priceBlock}>
      <DivFlexRow style={styles.priceString}>
        <Text16Grey style={styles.priceText}>Сумма</Text16Grey>
        <Text24Grey style={styles.price}>{`${activeSub.price}`}</Text24Grey>
        <Text14Grey style={styles.ruble}>₽</Text14Grey>
      </DivFlexRow>

      {activeSub.discount && (
        <DivFlexRow style={styles.priceString}>
          <Text16Grey style={styles.priceText}>Скидка:</Text16Grey>
          <Text16Green style={styles.priceTextDiscount}>{activeSub.discount}%</Text16Green>
          <Text24Grey style={styles.price}>- {`${activeSub.price - (activeSub.priceDiscount ?? 0)}`}</Text24Grey>
          <Text14Grey style={styles.ruble}>₽</Text14Grey>
        </DivFlexRow>
      )}

      {isPromoCode && typeof activeSub.pricePromoCode === 'number' && (
        <DivFlexRow style={styles.priceString}>
          <Text16Grey style={styles.priceText}>Скидка по промокоду:</Text16Grey>
          <Text16Green style={styles.priceTextDiscount}>{activeSub.discountPromoCode}%</Text16Green>
          <Text24Grey style={styles.price}>- {`${activeSub.price - activeSub.pricePromoCode}`}</Text24Grey>
          <Text14Grey style={styles.ruble}>₽</Text14Grey>
        </DivFlexRow>
      )}

      <DivFlexRow style={styles.priceString}>
        <Text16Grey style={`${styles.priceText} ${styles.total}`}>Итого</Text16Grey>
        <Text32White style={`${styles.price} ${styles.total}`}>{totalPrice}</Text32White>
        <Text16White style={`${styles.ruble} ${styles.total}`}>₽</Text16White>
      </DivFlexRow>
    </DivFlexColumn>
  )
}

export { SubscriptionPaymentModalPriceData }
