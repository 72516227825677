import styles from './HomePriceChartChangesContainer.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { DarkPurpleContainer } from 'components/pages/Home/components/DarkPurpleContainer'
import { ImgBase } from 'components/styledComponents/img/img'
import { DarkGreyContainer } from 'components/pages/Home/components/DarkGreyContainer'
import {
  Text10Green,
  Text12Grey,
  Text12White,
  Text14Green,
  Text14White,
  Text16White,
  Text20White
} from 'components/styledComponents/text/textStyles'
import { TextUnderlined } from 'components/ui/text/TextUnderlined'

import Chart from './chart-table.svg'
import Dot from './chart-dot.svg'

const priceColumnText = ['$100K', '$75K', '$50K', '$25K', '$0K']
const timeRowText = ['27.06', '28.06', '29.06']

const HomePriceChartChangesContainer = () => {
  return (
    <DarkPurpleContainer style={styles.section}>
      <DivFlexRow style={styles.header}>
        <Text16White>График изменения цены</Text16White>

        <DivFlexRow style={styles.headerBookmarks}>
          <TextUnderlined text="Неделя" isActive fontSize={14} />
          <TextUnderlined text="Месяц" fontSize={14} />
          <TextUnderlined text="Год" fontSize={14} />
        </DivFlexRow>
      </DivFlexRow>

      <div className={styles.body}>
        <DivFlexRow style={styles.chartContainer}>
          <DivFlexColumn style={styles.chartPriceColumn}>
            {priceColumnText.map((price, index) => {
              return <Text12Grey key={index}>{price}</Text12Grey>
            })}
          </DivFlexColumn>

          <div className={styles.chartTable}>
            <ImgBase img={Chart} style={styles.chart} />
            <ImgBase img={Dot} style={styles.dotFirst} />
            <ImgBase img={Dot} style={styles.dotSecond} />

            <DarkGreyContainer style={`${styles.priceContainer} ${styles.first}`}>
              <Text12White>28.06</Text12White>
              <DivFlexRow>
                <Text14Green>75.000</Text14Green>
                <Text10Green>$</Text10Green>
              </DivFlexRow>
            </DarkGreyContainer>

            <DarkGreyContainer style={`${styles.priceContainer} ${styles.second}`}>
              <Text12White>1.07</Text12White>
              <DivFlexRow>
                <Text14Green>89.000</Text14Green>
                <Text10Green>$</Text10Green>
              </DivFlexRow>
            </DarkGreyContainer>
          </div>
        </DivFlexRow>

        <DivFlexRow style={styles.chartTimeRow}>
          {timeRowText.map((price, index) => {
            return <Text12Grey key={index}>{price}</Text12Grey>
          })}
        </DivFlexRow>
      </div>

      <DivFlexRow style={styles.footer}>
        <Text14White style={styles.footerHeader}>Средняя цена продаж</Text14White>
        <Text20White>82.000</Text20White>
        <Text14White>$</Text14White>
      </DivFlexRow>
    </DarkPurpleContainer>
  )
}

export { HomePriceChartChangesContainer }
