import styles from './SubscriptionPaymentModalPromoBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Purple } from 'components/styledComponents/text/textStyles'
import { SubmitButton } from 'components/ui/buttons/SubmitButton'
import { BaseInput } from 'components/ui/inputs/BaseInput'
import { useState } from 'react'

type SubscriptionPaymentModalPromoBlockProps = {
  handlePromoCodeSubmit: (promoCode: string) => Promise<void>
}

const SubscriptionPaymentModalPromoBlock = ({ handlePromoCodeSubmit }: SubscriptionPaymentModalPromoBlockProps) => {
  const [inputPromoCode, setInputPromoCode] = useState<string>('')

  return (
    <DivFlexColumn style={styles.promoBlock}>
      <Text14Purple>Есть промокод?</Text14Purple>
      <DivFlexRow style={styles.promoInputBlock}>
        <BaseInput
          value={inputPromoCode}
          onChange={setInputPromoCode}
          isValid={true}
          placeHolder="Номер промокода"
          cssInputContainer={styles.promoCodeInputContainer}
        />
        <SubmitButton
          text="Применить"
          isActive={!!inputPromoCode.length}
          onClick={handlePromoCodeSubmit.bind(this, inputPromoCode)}
          textSize={14}
          cssButton={styles.promoButton}
        />
      </DivFlexRow>
    </DivFlexColumn>
  )
}

export { SubscriptionPaymentModalPromoBlock }
