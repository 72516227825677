import styles from './Pagination.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { PaginationInput } from './PaginationInput'
import { RefObject, useEffect, useState } from 'react'
import { scrollToItemTop } from 'utils/pageScrollers'
import { observer } from 'mobx-react-lite'
import { PaginationSwitchButton } from './PaginationSwitchButton'
import { PagintaionNumberButton } from './PagintaionNumberButton'
import Loader from '../loaders/Loader/Loader'

type PaginationProps = {
  cssBlockStyles?: string
  scrollToRef?: RefObject<HTMLDivElement>
  // это метод запроса на бек, в котором есть пагинация
  changePaginationPage: (paginationSkip: number) => Promise<any>
  // сколько предметов на странице
  itemsInPage: number
  // параметры пагинации этого метода из стора
  totalCount: number
  currentPage: number
  take: number
}

const Pagination = observer(
  ({
    cssBlockStyles,
    scrollToRef,
    changePaginationPage,
    itemsInPage,
    totalCount,
    currentPage: page,
    take
  }: PaginationProps) => {
    const [paginationSkip, setPaginationSkip] = useState<number>(0)

    const [isBack, setBack] = useState<boolean>(false)
    const [isForward, setForward] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)

    const [input, setInput] = useState<string>('')

    const currentPage = page + 1
    const pagesCount = totalCount <= take ? 1 : Math.ceil(totalCount / take)
    const showPagintaion = pagesCount > 1

    const lastThreeNumbers = Array.from({ length: 3 }, (_, index) => pagesCount - 2 + index)
    const isFirstNumbers = currentPage < 4
    const isLastNumbers = currentPage > pagesCount - 3
    const firstPage = 1
    const isMainButtons = pagesCount > 5

    const handleNextPage = async () => {
      if (currentPage !== pagesCount) {
        setPaginationSkip((prevPaginationSkip) => prevPaginationSkip + itemsInPage)
        setLoading(true)
        await changePaginationPage(paginationSkip + itemsInPage)
        setLoading(false)
        if (scrollToRef) {
          scrollToItemTop(scrollToRef)
        }
      }
    }

    const handlePrevPage = async () => {
      if (currentPage !== firstPage) {
        setPaginationSkip((prevPaginationSkip) => prevPaginationSkip - itemsInPage)
        setLoading(true)
        await changePaginationPage(paginationSkip - itemsInPage)
        setLoading(false)
        if (scrollToRef) {
          scrollToItemTop(scrollToRef)
        }
      }
    }

    const handleOnCurrentPage = async (number: number) => {
      if (number !== currentPage) {
        const paginationSkip = number * itemsInPage - itemsInPage
        setPaginationSkip((prevPaginationSkip) => (prevPaginationSkip = paginationSkip))
        setLoading(true)
        await changePaginationPage(paginationSkip)
        setLoading(false)
        if (scrollToRef) {
          scrollToItemTop(scrollToRef)
        }
      }
    }

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (value === '' || (!isNaN(Number(value)) && Number(value) >= 1 && Number(value) <= pagesCount)) {
        setInput(value)
      }
    }

    const handleKeyPressEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && input !== '') {
        const paginationSkip = Number(input) * itemsInPage - itemsInPage
        setPaginationSkip((prevPaginationSkip) => (prevPaginationSkip = paginationSkip))
        setLoading(true)
        await changePaginationPage(paginationSkip)
        setLoading(false)
        setInput('')
        if (scrollToRef) {
          scrollToItemTop(scrollToRef)
        }
      }
    }

    useEffect(() => {
      setForward(currentPage < pagesCount ? false : true)
      setBack(currentPage !== 1 ? false : true)
    }, [isBack, isForward, currentPage, pagesCount])

    const firstNumbersBlock = () => {
      if (pagesCount <= 5) {
        return [...Array(pagesCount)].map((_, index) => {
          const number = index + 1
          return (
            <PagintaionNumberButton
              key={index}
              count={number}
              isActive={number === currentPage}
              onClick={handleOnCurrentPage.bind(this, number)}
            />
          )
        })
      }
      if (pagesCount > 5 && isFirstNumbers) {
        return [...Array(3)].map((_, index) => {
          const number = index + 1
          return (
            <PagintaionNumberButton
              key={index}
              count={number}
              isActive={number === currentPage}
              onClick={handleOnCurrentPage.bind(this, number)}
            />
          )
        })
      }
    }

    const lastNumbersBlock = () => {
      return (
        pagesCount > 5 &&
        isLastNumbers &&
        lastThreeNumbers.map((item, index) => {
          return (
            <PagintaionNumberButton
              key={index}
              count={item}
              isActive={item === currentPage}
              onClick={handleOnCurrentPage.bind(this, item)}
            />
          )
        })
      )
    }

    const showFirstNumbers = firstNumbersBlock()
    const showLastNumbers = lastNumbersBlock()

    if (isLoading) {
      return (
        <DivFlexRow style={`${styles.container} ${cssBlockStyles}`}>
          <Loader anyStyles={{ margin: 'auto' }} />
        </DivFlexRow>
      )
    }

    if (showPagintaion) {
      return (
        <DivFlexRow style={`${styles.container} ${cssBlockStyles}`}>
          <PaginationSwitchButton onClick={handlePrevPage} isBack isActive={isBack} />

          {!isFirstNumbers && pagesCount > 5 && (
            <PagintaionNumberButton
              count={firstPage}
              isActive={firstPage === currentPage}
              onClick={handleOnCurrentPage.bind(this, firstPage)}
            />
          )}

          {showFirstNumbers}

          {isMainButtons && !isFirstNumbers && !isLastNumbers && (
            <PaginationInput value={input} onChange={onChangeInput} onKeyDown={handleKeyPressEnter} />
          )}

          {isMainButtons && !isFirstNumbers && !isLastNumbers && (
            <PagintaionNumberButton count={currentPage} isActive />
          )}

          {isMainButtons && <PaginationInput value={input} onChange={onChangeInput} onKeyDown={handleKeyPressEnter} />}

          {showLastNumbers}

          {!isLastNumbers && pagesCount > 5 && (
            <PagintaionNumberButton
              count={pagesCount}
              onClick={handleOnCurrentPage.bind(this, pagesCount)}
              isActive={currentPage === pagesCount}
            />
          )}

          <PaginationSwitchButton onClick={handleNextPage} isActive={isForward} />
        </DivFlexRow>
      )
    } else return null
  }
)

export { Pagination }
