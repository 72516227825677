import styles from './ProfileSettingsPageContent.module.css'

import { ProfileSettingsPagesName } from '../ProfileSettings'
import { ProfileAndSubscriptionBlock } from './ProfileAndSubscriptionBlock'
import { ProfilePhoneNumbersBlock } from './ProfilePhoneNumbersBlock'
import { ProfileTransactionBlock } from './ProfileTransactionBlock'
import { userSettingTransactionType } from 'api/userSettings/userTransactions'
import { ProfileAccountsBlock } from './ProfileAccountsBlock'

type ProfileSettingsPageContentProps = {
  activePage: string
  handleOpenNewPhoneModal: () => void
  handleOpenTransactionModal: (transactionData: userSettingTransactionType) => void
}

const ProfileSettingsPageContent = ({
  activePage,
  handleOpenNewPhoneModal,
  handleOpenTransactionModal
}: ProfileSettingsPageContentProps) => {
  const isProfileAndSubscriptionBlock = activePage === ProfileSettingsPagesName.ProfileAndSubscriptionBlock
  const isProfilePhoneNumbersBlock = activePage === ProfileSettingsPagesName.ProfilePhoneNumbersBlock
  const isProfileTransactionBlock = activePage === ProfileSettingsPagesName.ProfileTransactionBlock
  const isProfileAccountsBlock = activePage === ProfileSettingsPagesName.ProfileAccountsBlock

  return (
    <div className={styles.section}>
      {isProfileAndSubscriptionBlock && <ProfileAndSubscriptionBlock />}
      {isProfileAccountsBlock && <ProfileAccountsBlock />}
      {isProfilePhoneNumbersBlock && <ProfilePhoneNumbersBlock handleOpenNewPhoneModal={handleOpenNewPhoneModal} />}
      {isProfileTransactionBlock && <ProfileTransactionBlock handleOpenTransactionModal={handleOpenTransactionModal} />}
    </div>
  )
}

export { ProfileSettingsPageContent }
