import styles from './ReferralList.module.css'

import { useEffect, useState } from 'react'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { ReferralCreateLinksPage } from '../ReferralCreateLinksPage'
import { ReferralResourcesPage } from '../ReferralResourcesPage'
import { observer } from 'mobx-react-lite'
import { referralStore } from 'store/referralStore/refferralStore'
import { getReferralDisbursementList, getReferralPromoCodeList } from 'api/referral/referral'
import Loader from 'components/ui/loaders/Loader/Loader'

const ReferralList = observer(() => {
  const { promoCodes } = referralStore.promoCodeList
  const { disbursements } = referralStore.disbursementsList

  const [isFirstList, setFirstList] = useState<boolean>(true)

  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getReferralPromoCodeList()
      await getReferralDisbursementList()
      setLoading(false)
    })()
  }, [])

  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexRow style={styles.headerText}>
        <p className={`${styles.pageText} ${isFirstList && styles.active}`} onClick={setFirstList.bind(this, true)}>
          Созданные промокоды
        </p>
        <p className={`${styles.pageText} ${!isFirstList && styles.active}`} onClick={setFirstList.bind(this, false)}>
          Заявки на вывод средств
        </p>
      </DivFlexRow>

      <DivFlexRow style={styles.headerDividers}>
        <HorizontalDivider cssStyle={`${styles.divider} ${isFirstList && styles.active}`} />
        <HorizontalDivider cssStyle={`${styles.divider} ${!isFirstList && styles.active}`} />
      </DivFlexRow>

      {isLoading ? (
        <Loader anyStyles={{ margin: '80px auto' }} size={40} bold={3} />
      ) : (
        <div className={styles.pageContainer}>
          {isFirstList ? (
            <ReferralCreateLinksPage promoCodes={promoCodes} />
          ) : (
            <ReferralResourcesPage disbursements={disbursements} />
          )}
        </div>
      )}
    </DivFlexColumn>
  )
})

export { ReferralList }
