import { useEffect, useState } from 'react'

const useBankCardValidation = (value: string): { isValid: boolean; errorText: string } => {
  const [isValid, setIsValid] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string>('')

  useEffect(() => {
    if (value !== '') {
      // Удаление пробелов из номера карты
      const cardNumberWithoutSpaces = value.replace(/\s/g, '')

      // Проверка на соответствие формату 1111222233334444
      if (!/^\d{16}$/.test(cardNumberWithoutSpaces)) {
        setIsValid(false)
        setErrorText('Формат должен быть - 6759 6498 2643 8453')
      } else {
        setIsValid(true)
        setErrorText('')
      }
    }
  }, [value])

  return { isValid, errorText }
}

export default useBankCardValidation
