import { DarkPurpleContainer } from 'components/pages/Home/components/DarkPurpleContainer'
import styles from './HomeActivitySectionProductCard.module.css'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text14Grey, Text16Green, Text18White, Text24Green } from 'components/styledComponents/text/textStyles'
import { DivFlexRow } from 'components/styledComponents/div/div'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { ButtonCountArrowLeft } from 'components/ui/buttons/ButtonCountArrowLeft'

import ItemIcon from './item-icon.png'

const itemName = 'Weeny Copper S - белый цвет'
const itemPrice = '1.000.000'

const HomeActivitySectionProductCard = () => {
  return (
    <DarkPurpleContainer style={styles.container}>
      <ImgBase img={ItemIcon} style={styles.photo} />

      <Text18White style={styles.itemName}>{itemName}</Text18White>
      <Text14Grey style={styles.itemSub}>Автомобили , Спортивные</Text14Grey>

      <DivFlexRow style={styles.priceContainer}>
        <Text24Green>{itemPrice}</Text24Green>
        <Text16Green>$</Text16Green>
      </DivFlexRow>

      <HorizontalDivider cssStyle={styles.divider} />

      <ButtonCountArrowLeft text="Продают" count={14} fontSize={14} color="azure" />
    </DarkPurpleContainer>
  )
}

export { HomeActivitySectionProductCard }
