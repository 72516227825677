import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { useLayoutEffect, useState } from 'react'
import AuthModal from 'components/ui/modals/AuthModal/AuthModal'
import { useSearchParams } from 'react-router-dom'
import { authDiscordOauth2Callback } from '../../../api/auth/auth'
import DiscordError from '../DiscordError/DiscordError'
import { LoaderPage } from 'components/ui/loaders/LoaderPage'
import { userInfoStore } from '../../../store/userInfoStore'

const titleText = 'Ошибка авторизации/регистрации'

const DiscordAuth = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')

  const [errorText, errorTextSet] = useState<string>('Неизвестная ошибка. Пожалуйста, обратитесь к администратору!')
  const [isAuthModal, setAuthModal] = useState<boolean>(false)

  const [isLoading, setLoading] = useState<boolean>(true)

  useLayoutEffect(() => {
    ;(async () => {
      if (!code) {
        errorTextSet('Ошибка. Обязательный параметр "code" отсутствует!')
        setLoading(false)
        return
      }

      const result = await authDiscordOauth2Callback(code)

      if (result.data) {
        userInfoStore.setJwtToken(result.data.authToken)
        window.location.href = '/'
      }

      if (result.error) {
        errorTextSet(result.error.message)
        setLoading(false)
      }
    })()
  }, [code])

  return (
    <PageWrapper>
      {!isLoading && (
        <DiscordError
          onFormClick={setAuthModal.bind(this, true)}
          onDiscordClick={setAuthModal.bind(this, true)}
          headerText={titleText}
          subText={errorText}
          formText={'Регистрация по e-mail'}
          discordText={'Повторить с помощью Discord'}
        />
      )}

      {isAuthModal && <AuthModal isOpen={isAuthModal} onClose={setAuthModal.bind(this, false)} />}

      {isLoading && <LoaderPage containerStyle={{ background: 'transparent' }} />}
    </PageWrapper>
  )
}

export { DiscordAuth }
