import styles from './ButtonCountArrowLeft.module.css'

import { colors } from 'styled/colors'

import { ImgBase } from 'components/styledComponents/img/img'
import { VipDisableMessage } from 'components/ui/VipDisableMessage'

import LockedIcon from '../../../icons/lock-icon.svg'

type ButtonCountArrowLeftProps = {
  containerStyle?: string
  text: string
  count: number
  fontSize?: number
  color?: 'green' | 'azure'
  onClick?: () => void
  isVip?: boolean
}

const ButtonCountArrowLeft = ({
  containerStyle,
  text,
  count = 0,
  fontSize,
  color,
  onClick,
  isVip = true
}: ButtonCountArrowLeftProps) => {
  const handleClick = () => {
    if (onClick) onClick()
  }

  const changeTextColor = (): string => {
    if (isVip) {
      if (color === 'green') {
        return colors.green.light
      }
      if (color === 'azure') {
        return colors.azure.main
      } else return colors.white
    } else return colors.grey.scarlet
  }

  const changeButtonBgColor = (): string => {
    if (isVip) {
      if (color === 'green') {
        return colors.green.scarlet
      }
      if (color === 'azure') {
        return colors.azure.dark
      } else return 'transparent'
    } else return colors.purple.scarlet
  }

  return (
    <button
      className={`${styles.container} ${!isVip && styles.disable} ${containerStyle}`}
      style={{ backgroundColor: changeButtonBgColor() }}
      onClick={handleClick}
    >
      {!isVip && <ImgBase img={LockedIcon} style={styles.lockedIcon} />}

      <p
        className={styles.text}
        style={{
          fontSize: fontSize,
          color: changeTextColor()
        }}
      >
        {text}
      </p>

      <p
        className={styles.count}
        style={{
          fontSize: fontSize
        }}
      >
        {count > 9999 ? 9999 : count} шт.
        {/* что бы не обрезать числа более 9999 и не портить верстку */}
      </p>

      <div
        className={styles.arrow}
        style={{ border: `1px solid ${changeTextColor()}`, borderWidth: '0 2px 2px 0' }}
      ></div>

      {!isVip && <div className={styles.vipDisable}></div>}
      {!isVip && <VipDisableMessage cssStyles={styles.disableMessage} cssIconStyle={styles.diableMessageIcon} />}
    </button>
  )
}

export { ButtonCountArrowLeft }
