import { BaseInput } from '../BaseInput'
import { formatBankCardNumber } from 'utils/forms/helpers'

type BankCardInputProps = {
  value: string
  onChange: (value: string) => void
  isValid: boolean
  errorText: string
}

const BankCardInput = ({ value, onChange, isValid, errorText }: BankCardInputProps) => {
  const placeHolder = '6759 6498 2643 8453'
  const bottomText = 'Реквизиты'

  return (
    <BaseInput
      value={value}
      onChange={onChange}
      valueFormattedFunction={formatBankCardNumber}
      validationError={errorText}
      isValid={isValid}
      placeHolder={placeHolder}
      bottomText={bottomText}
      maxLength={19}
    />
  )
}

export { BankCardInput }
