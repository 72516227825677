import api from 'api/axios/axiosInstance'
import { MessageType } from 'components/ui/errors/AlertError'
import errorAlerts from 'store/errorAlertsStore'
import { SubscriptionsInfoType, subscriptionSore } from 'store/subscriptionStore/subscriptionStore'

const getUserSubscriptionsInfo = async () => {
  // получить подписки
  const { setSubscriptionsInfo } = subscriptionSore

  try {
    const response: { subscriptions: SubscriptionsInfoType[] } = await api.get('/api/payment/subscription/info-pay')
    console.log(response)
    setSubscriptionsInfo(response.subscriptions)
    return true
  } catch (error) {
    console.log('Error:', error)
    return false
  }
}

const generetePaymentUrl = async (subId: string) => {
  // создать ссылку на оплату
  try {
    const response: { transactionId: string; confirmationUrl: string } = await api.post(
      '/api/payment/subscription/generate-url',
      subId
    )
    window.location.href = response.confirmationUrl
    console.log(response)
  } catch (error) {
    errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    console.log('Error:', error)
    return false
  }
}

const getTransactionStatus = async (transactionId: string) => {
  // получить статус оплаты
  try {
    const response = await api.get(`/api/payment/subscription/${transactionId}/status`)
    return response
  } catch (error) {
    console.log('Error:', error)
    return false
  }
}

export { getUserSubscriptionsInfo, generetePaymentUrl, getTransactionStatus }
