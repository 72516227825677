import { getUserSettingTransactions, userSettingTransactionType } from 'api/userSettings/userTransactions'
import styles from './ProfileTransactionBlock.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text16Grey } from 'components/styledComponents/text/textStyles'
import { ProfileSettingTransactionCard } from 'components/ui/ProfileSettings/ProfileSettingTransactionCard'
import Loader from 'components/ui/loaders/Loader/Loader'
import { useEffect, useState } from 'react'

type ProfileTransactionBlockProps = {
  handleOpenTransactionModal: (transactionData: userSettingTransactionType) => void
}

const ProfileTransactionBlock = ({ handleOpenTransactionModal }: ProfileTransactionBlockProps) => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [transactions, setTransactions] = useState<userSettingTransactionType[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await getUserSettingTransactions()
      if (result) {
        setTransactions(result)
        setLoading(false)
      }
    })()
  }, [])

  return (
    <DivFlexColumn style={styles.contaner}>
      <Text16Grey>Список транзакций</Text16Grey>

      {isLoading ? (
        <Loader anyStyles={{ margin: '50px auto' }} size={30} bold={3} />
      ) : (
        <DivFlexColumn style={styles.list}>
          {!!transactions.length ? (
            transactions.map((item) => {
              return (
                <ProfileSettingTransactionCard
                  key={item.id}
                  transactionData={item}
                  onClick={handleOpenTransactionModal}
                />
              )
            })
          ) : (
            <Text16Grey>Транзакций нет</Text16Grey>
          )}
        </DivFlexColumn>
      )}
    </DivFlexColumn>
  )
}

export { ProfileTransactionBlock }
