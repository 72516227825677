import styles from './HeaderHome.module.css'

import { ButtonTexts } from 'constants/buttonTexts'
import { BrowserRouters, NavigateLinks } from 'PageRouters'
import { userInfoStore } from 'store/userInfoStore'
import { observer } from 'mobx-react-lite'
import HeaderLogo from 'components/ui/HeaderLogo/HeaderLogo'
import UserHeaderButton from 'components/ui/UserHeaderButton/UserHeaderButton'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import VerticalDivider from 'components/ui/dividers/VerticalDivider/VerticalDivider'
import { colors } from 'styled/colors'
import { useState } from 'react'
import AuthModal from 'components/ui/modals/AuthModal/AuthModal'

import Megaphone from '../../../icons/button/megaphone.svg'
import Envelop from '../../../icons/button/envelop.svg'
import HomeIcon from '../../../icons/button/home.svg'
import Category from '../../../icons/button/category.svg'
import VoiceSms from '../../../icons/button/voice_sms.svg'
import LogIn from '../../../icons/button/log-out_white.svg'
import AddDocument from '../../../icons/button/add_document.svg'
import { chatClientStore } from '../../../../store/chat/chat'

const HeaderHome = observer(() => {
  const { isAuth, userData: { countChatNotRead } } = userInfoStore

  const [isAuthModal, setAuthModal] = useState<boolean>(false)

  return (
    <header className={styles.header}>
      <div className={`${isAuth ? styles.container : styles.notAuthContainer}`}>
        <HeaderLogo anyStyles={{ width: '51.49px', height: '56px', marginLeft: '62px', cursor: 'default' }} />

        {isAuth && (
          <div className={styles.buttonsBlock}>
            <UserHeaderButton />
            <OvalButton
              text={ButtonTexts.AdvertisementMy}
              icon={Megaphone}
              router={BrowserRouters.AdvertisementMy}
              containerStyle={{ padding: '0 11px' }}
            />
            <OvalButton
              text={ButtonTexts.Chat}
              icon={Envelop}
              router={BrowserRouters.Chats}
              containerStyle={{ padding: '0 11px' }}
              textMarker={countChatNotRead > 0 ? String(countChatNotRead) : ''}
            />
          </div>
        )}

        {isAuth && <VerticalDivider style={{ height: '22px', marginLeft: '28.5px' }} />}

        <div className={`${isAuth ? styles.linksBlock : styles.notAuthButtonsBlock}`}>
          {!isAuth && (
            <OvalButton icon={HomeIcon} containerStyle={{ background: colors.grey.main, cursor: 'default' }} />
          )}
          <OvalButton
            text={ButtonTexts.Catalog}
            icon={Category}
            textStyle={{ color: '#CFFB58' }}
            router={NavigateLinks.Catalog}
            containerStyle={{ background: '#2d3035', padding: '0 11px' }}
          />
          <OvalButton
            text={ButtonTexts.Advertisement}
            icon={VoiceSms}
            textStyle={{ color: colors.azure.main }}
            router={NavigateLinks.Advertisement}
            containerStyle={{ background: colors.azure.scarlet, padding: '0 11px' }}
          />
        </div>

        {isAuth ? (
          <OvalButton
            text={ButtonTexts.AdvertisementCreate}
            icon={AddDocument}
            router={BrowserRouters.AdvertisementCreate}
            containerStyle={{
              height: '48px',
              width: '111px',
              border: '4px solid rgb(38, 35, 87)',
              background: 'rgb(123, 76, 255)',
              margin: isAuth ? 'auto auto auto 59px' : 'auto 35px auto auto'
            }}
          />
        ) : (
          <OvalButton
            text={ButtonTexts.LogIn}
            icon={LogIn}
            containerStyle={{
              height: '54px',
              width: '111px',
              border: '4px solid rgb(38, 35, 87)',
              background: 'rgb(123, 76, 255)',
              margin: isAuth ? 'auto auto auto 59px' : 'auto 35px auto auto'
            }}
            onClick={setAuthModal.bind(this, true)}
          />
        )}
      </div>

      {isAuthModal && <AuthModal isOpen={isAuthModal} onClose={setAuthModal.bind(this, false)} />}
    </header>
  )
})

export { HeaderHome }
