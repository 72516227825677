import { DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text14White, Text14Grey } from 'components/styledComponents/text/textStyles'
import { DropdownWrapper } from 'components/ui/wrappers/DropdownWrapper'
import styles from './DropdownWithPhones.module.css'
import { useEffect, useState } from 'react'

import ArrowGrey from '../../../../components/icons/arrow_bottom_grey.svg'
import ArrowPurple from '../../../../components/icons/arrow_bottom_purple.svg'
import { UserGamePhone } from 'store/profileSettingsStore/gamePhonesStore'
import { formatGamePhone } from 'utils/formatGamePhone'

type DropdownWithPhonesProps = {
  items: UserGamePhone[]
  cssStyles?: string
  onChoose: (phoneId: string) => void
}

const DropdownWithPhones = ({ items, cssStyles, onChoose }: DropdownWithPhonesProps) => {
  const [activeNumber, setActiveNumber] = useState<UserGamePhone>(items[0])
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleClickItem = (phone: UserGamePhone) => {
    onChoose(phone.id)
    setActiveNumber(phone)
  }

  const formFormat = formatGamePhone(activeNumber.number)

  useEffect(() => {
    setActiveNumber(items[0])
  }, [setActiveNumber, items])

  return (
    <DropdownWrapper onClose={setOpen.bind(this, false)}>
      <DivFlexRow
        style={`${styles.button} ${isOpen && styles.active} ${cssStyles}`}
        onClick={setOpen.bind(this, !isOpen)}
      >
        <Text14White style={styles.text}>{formFormat}</Text14White>

        <ImgBase img={isOpen ? ArrowPurple : ArrowGrey} style={styles.arrow} />

        {isOpen && (
          <div className={styles.dropContainer}>
            <ul className={styles.itemsSection}>
              {items.map((item) => {
                return (
                  <li key={item.id} className={styles.item} onClick={handleClickItem.bind(this, item)}>
                    <Text14Grey style={`${styles.itemText} ${activeNumber.id === item.id && styles.active}`}>
                      {formatGamePhone(item.number)}
                    </Text14Grey>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </DivFlexRow>
    </DropdownWrapper>
  )
}

export { DropdownWithPhones }
