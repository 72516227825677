import { action, makeObservable, observable } from 'mobx'

class LoadingStore {
    isLoadingItems: boolean = false

    constructor() {
        makeObservable(this, {
            isLoadingItems: observable,
            setLoadingItems: action,
        })
    }

    setLoadingItems = (load: boolean) => this.isLoadingItems = load
}

export const loadingStore = new LoadingStore()
