import styles from './RadioTabWithText.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'

import CkeckedIcon from '../../../icons/button/radio_checked.svg'
import unCheckedIcon from '../../../icons/button/radio_un-ckecked.svg'
import LockIcon from '../../../icons/lock-icon.svg'
import { VipBlockButton } from 'components/ui/VipBlockButton'

type RadioTabWithTextProps = {
  text: string
  isActive: boolean
  onClick?: () => void
  fontSize?: number
  cssContainerStyles?: string
  isBlocked?: boolean
}

const RadioTabWithText = ({
  text,
  isActive,
  onClick,
  fontSize = 14,
  cssContainerStyles,
  isBlocked
}: RadioTabWithTextProps) => {
  const handleClick = () => {
    if (onClick) {
      if (!isBlocked) onClick()
    }
  }

  return (
    <VipBlockButton isChildrenInside isBlocked={isBlocked}>
      <DivFlexRow
        style={`${styles.container} ${isActive && styles.active} ${cssContainerStyles} ${isBlocked && styles.blocked}`}
        onClick={handleClick}
      >
        {!isBlocked && <img src={isActive ? CkeckedIcon : unCheckedIcon} alt="checkbox" className={styles.checkIcon} />}
        {isBlocked && <img src={LockIcon} alt="lock" className={styles.checkIcon} />}
        <p className={styles.text} style={{ fontSize: fontSize }}>
          {text}
        </p>
      </DivFlexRow>
    </VipBlockButton>
  )
}

export { RadioTabWithText }
