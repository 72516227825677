export const informationArray = [
  {
    name: 'Сток:',
    text: '200 км/ч'
  },
  {
    name: 'ФТ:',
    text: '322 км/ч'
  },
  {
    name: 'Багажник, слот:',
    text: '6 шт'
  },
  {
    name: 'Багажник, кг:',
    text: '10 кг'
  },
  {
    name: 'Места',
    text: '2 шт'
  }
]
