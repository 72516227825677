import api from 'api/axios/axiosInstance'
import axios from 'axios'
import { MessageType } from 'components/ui/errors/AlertError'
import { AdvertisementCatalogSearch, advertisementCreateStore } from 'store/advertisement/advertisementCreateStore'
import errorAlerts from 'store/errorAlertsStore'

export type AdvertisementCreateForm = {
  type: 'buy' | 'sale'
  serverId: string
  catalogId: string
  count: number
  price: number
  gamePhoneId: string | null
  itemImageId: string
  isLoadImages: boolean
  isPublication: boolean
  customImages: Array<{ name: string; base64: string }>
}

const advertisementCatalogSearch = async (data: { searchText?: string; systemCatalogId?: string }) => {
  const { setCatalogSearch } = advertisementCreateStore
  try {
    const queryParams = new URLSearchParams()
    if (data?.searchText) queryParams.append('searchText', data.searchText)
    if (data?.systemCatalogId) queryParams.append('systemCatalogId', data.systemCatalogId)

    const response: AdvertisementCatalogSearch[] = await api.get(
      `/api/advertisement/catalog/search?${queryParams.toString()}`
    )
    setCatalogSearch(response)
    return true
  } catch (error) {
    return false
  }
}

const advertisementCreate = async (advertisement: AdvertisementCreateForm) => {
  try {
    await api.post('/api/advertisement/create', advertisement)
    errorAlerts.addError({ errorType: MessageType.Success, errorText: 'Объявление создано' })
    return true
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorAlerts.addError({
          errorType: MessageType.Error,
          errorText: error.response.data.message
        })
      }
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    }
    return false
  }
}

export { advertisementCatalogSearch, advertisementCreate }
