import styles from './Referral.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { Text32White } from 'components/styledComponents/text/textStyles'
import { useState } from 'react'
import { ReferralInput } from 'components/ui/Refferal/ReferralInput'
import { ReferralList } from 'components/ui/Refferal/ReferralList'
import { ReferralStatistic } from 'components/ui/Refferal/ReferralStatistic'
import { ReferralGetMoneyModal } from 'components/ui/modals/ReferralGetMoneyModal'

import UsersIcon from '../../icons/referral/users.svg'

const Referral = () => {
  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <PageWrapper>
      <DivFlexColumn style={styles.referral}>
        <DivFlexRow style={styles.header}>
          <img src={UsersIcon} alt="users" className={styles.headerIcon} />
          <Text32White style={styles.headerText}>Реферральная система</Text32White>
        </DivFlexRow>

        <ReferralInput />

        <ReferralStatistic handleOpenModal={setOpen.bind(this, true)} />

        <ReferralList />
      </DivFlexColumn>

      {isOpen && <ReferralGetMoneyModal isOpen={isOpen} onClose={setOpen.bind(this, false)} />}
    </PageWrapper>
  )
}

export default Referral
