import styles from './AdvertisementProductCard.module.css'

import { getCardImage } from 'api/catalog/catalog.method'
import { DivFlexRow, DivFlexColumn } from 'components/styledComponents/div/div'
import { ShopBarCardInfo } from 'components/ui/ShopBar/ShopBarCard/ShopBarCardInfo'
import { ShopBarCardProperty } from 'components/ui/ShopBar/ShopBarCard/ShopBarCardProperty'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { useState, useEffect } from 'react'
import { Text16White, Text12Green, Text14White } from 'components/styledComponents/text/textStyles'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { getUserGamePhone } from 'api/userSettings/userGamePhones'
import { userStatusToText } from 'utils/userStatusToText'
import { ProductBigImage } from 'components/ui/image/ProductBigImage'

import CheckIcon from '../../../../components/icons/check_green.svg'
import CallIcon from '../../../../components/icons/button/green_phone-call.svg'
import ChatIcon from '../../../../components/icons/button/chat-unread-rounded.svg'
import Avatar from '../../../../components/icons/facke/avatar.svg'
import { ButtonTexts } from '../../../../constants/buttonTexts'
import { getChatIdByAdvertisementId } from '../../../../api/chat/method'
import { IGetResAdvertisementListItem } from '../../../../api/advetrisement/shopbar/advertisement.type'
import errorAlerts from '../../../../store/errorAlertsStore'
import { MessageType } from '../../errors/AlertError'
import AuthModal from '../../modals/AuthModal/AuthModal'
import { userInfoStore } from '../../../../store/userInfoStore'

type AdvertisementProductCardProps = {
  card: IGetResAdvertisementListItem
  onOpen: (cardData: IGetResAdvertisementListItem, baseImage: string) => void
}

const AdvertisementProductCard = ({ card, onOpen }: AdvertisementProductCardProps) => {
  const { id, name, price, propertyCard, sourceCategory, user, gamePhoneId, images, currency } = card

  const [imageUrl, setImageUrlUrl] = useState<string>('')
  const [isActivePhone, setActivePhone] = useState<boolean>(false)
  const [isAuthModal, setAuthModal] = useState<boolean>(false)
  const { isAuth } = userInfoStore

  useEffect(() => {
    ;(async () => {
      const getImage = await getCardImage(images[0].id)
      setImageUrlUrl(getImage)
    })()
  }, [])

  const handleCopyPhone = async () => {
    if (!isAuth) {
      setAuthModal(true)
      return
    }

    if (gamePhoneId) {
      const userPhone = await getUserGamePhone(gamePhoneId)
      if (userPhone) {
        navigator.clipboard
          .writeText(userPhone.number)
          .then(() => {
            console.log('Телефон скопирован в буфер обмена: ', userPhone)
            setActivePhone(true)

            setTimeout(() => {
              setActivePhone(false)
            }, 2000)
          })
          .catch((err) => {
            console.error('Ошибка при копировании текста: ', err)
            setActivePhone(false)
          })
      }
    }
  }

  const handleWriteToChat = async () => {
    if (!isAuth) {
      setAuthModal(true)
      return
    }

    const result = await getChatIdByAdvertisementId(id)
    if (result.data) {
      window.open('/chat/' + result.data.chatId, '_blank')
    }
    if (result.error) {
      errorAlerts.addError({
        errorType: MessageType.Error,
        errorText: result.error.message
      })
    }
  }

  return (
    <DivFlexRow style={styles.card}>
      <ProductBigImage width={228} height={228} image={imageUrl} />

      <DivFlexColumn style={styles.textBlock}>
        <DivFlexRow style={styles.informationBlock}>
          <ShopBarCardInfo
            name={name}
            sourceCategory={sourceCategory}
            price={String(price)}
            onClick={onOpen.bind(this, card, imageUrl)}
            currency={currency}
          />

          <ShopBarCardProperty property={propertyCard} />
        </DivFlexRow>

        <HorizontalDivider cssStyle={styles.divider} />

        <DivFlexRow style={styles.buttonsContainer}>
          <DivFlexRow style={styles.traderInfoBlock}>
            <img src={Avatar} alt="avatar" className={styles.avatar} />
            <DivFlexColumn style={styles.traderTextBlock}>
              <Text16White>{user.nickname}</Text16White>
              <Text12Green style={`${styles.userStatusText} ${styles[user.activeStatus]}`}>
                {userStatusToText(user.activeStatus)}
              </Text12Green>
            </DivFlexColumn>
          </DivFlexRow>

          <DivFlexRow style={styles.infoButtonsBox}>
            {gamePhoneId && (
              <>
                {!isActivePhone ? (
                  <OvalButton
                    text={ButtonTexts.ToCall}
                    icon={CallIcon}
                    isSwapContent
                    cssContainerStyle={styles.call}
                    onClick={handleCopyPhone}
                  />
                ) : (
                  <DivFlexRow style={styles.copyPhoneButton}>
                    <Text14White>Телефон скопирован</Text14White>
                    <img className={styles.copyIcon} alt="copy" src={CheckIcon} />
                  </DivFlexRow>
                )}
              </>
            )}

            <OvalButton
              text={ButtonTexts.WriteTheChat}
              icon={ChatIcon}
              isSwapContent
              cssContainerStyle={styles.chat}
              onClick={handleWriteToChat}
            />
          </DivFlexRow>
        </DivFlexRow>
      </DivFlexColumn>
      <AuthModal isOpen={isAuthModal} onClose={() => setAuthModal(false)} />
    </DivFlexRow>
  )
}

export { AdvertisementProductCard }
