import styles from './ChatCardUserBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Green, Text14Grey, Text16White } from 'components/styledComponents/text/textStyles'

import { ChatPageAvatar } from '../ChatPageAvatar'
import { TUserStatus } from '../../../../common/types'
import { userStatusToText } from '../../../../utils/userStatusToText'

type ChatCardUserBlockProps = {
  avatar?: string
  nickname: string
  status: TUserStatus
}

const ChatCardUserBlock = ({ avatar, nickname, status }: ChatCardUserBlockProps) => {
  const statusText = userStatusToText(status)

  return (
    <DivFlexRow style={styles.container}>
      <DivFlexColumn style={styles.textBlock}>
        <Text16White style={styles.nickname}>{nickname}</Text16White>
        {status === 'online' ? (
          <Text14Green style={styles.status}>{statusText}</Text14Green>
        ) : status === 'game' ? (
          <Text14Green style={styles.status}>{statusText}</Text14Green>
        ) : (
          <Text14Grey style={styles.statusOffline}>{statusText}</Text14Grey>
        )}
      </DivFlexColumn>
      <ChatPageAvatar avatar={avatar} />
    </DivFlexRow>
  )
}

export { ChatCardUserBlock }
