import styles from './AdvertisementMyFilterBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Grey } from 'components/styledComponents/text/textStyles'
import Button from 'components/ui/buttons/Button/Button'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { DropdownServersBase } from 'components/ui/dropdowns/DropdownServersBase'
import { DropdownStatusesBase } from 'components/ui/dropdowns/DropdownStatusesBase'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import { colors } from 'styled/colors'

const statuses: string[] = ['Активное', 'Просрочено', 'Скрыто', 'На модерации', 'Отклонено', 'Заблокировано']
const typesFilter: string[] = ['Покупка', 'Продажа']

type AdvertisementMyFilterBlockProps = {
  count: number
  handleChooseTypeFilter: (name: string) => void
  handleChooseServerFilter: (serverId: string) => void
  handleChooseStatusFilter: (status: string) => void
  handleClearFilters: () => void
  isClearFilterButton: boolean
}

const AdvertisementMyFilterBlock = observer(
  ({
    count,
    handleChooseTypeFilter,
    handleChooseServerFilter,
    handleChooseStatusFilter,
    handleClearFilters,
    isClearFilterButton
  }: AdvertisementMyFilterBlockProps) => {
    const { servers } = userPlatformConfig.platformConfigs

    const [key, setKey] = useState<number>(Math.random())

    const handleClear = () => {
      handleClearFilters()
      setKey(Math.random())
    }

    return (
      <DivFlexColumn key={key} style={styles.container}>
        <Button
          text="Сбросить фильтры"
          isTransparent
          textColor={colors.grey.scarlet}
          hoverText={colors.white}
          cssButtonStyles={`${styles.cancelFiltersButton} ${!isClearFilterButton && styles.hidden}`}
          onClick={handleClear}
        />

        <DivFlexRow style={styles.filtersBlock}>
          <Text14Grey style={styles.countText}>У Вас {count} объявлений</Text14Grey>

          <HorizontalDivider style={{ width: 1, height: 22, background: '#7E88BB' }} />

          <Text14Grey>Фильтровать по</Text14Grey>

          <DropdownStatusesBase
            baseText="Типу"
            items={typesFilter}
            cssStyles={styles.typeDropdown}
            cssItemsSection={styles.typeSection}
            onClickItem={handleChooseTypeFilter}
          />

          <DropdownStatusesBase
            baseText="Статусу"
            items={statuses}
            cssStyles={styles.dropdown}
            onClickItem={handleChooseStatusFilter}
          />

          <DropdownServersBase
            baseText="Серверу"
            items={servers}
            cssStyles={styles.dropdown}
            onClickItem={handleChooseServerFilter}
          />
        </DivFlexRow>
      </DivFlexColumn>
    )
  }
)

export { AdvertisementMyFilterBlock }
