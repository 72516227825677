import styles from './VipDisableMessage.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text12White } from 'components/styledComponents/text/textStyles'
import { ImgBase } from 'components/styledComponents/img/img'

import Icon from '../../icons/userStatus/pro_badge.svg'

type VipDisableMessageProps = {
  text?: string
  cssStyles?: string
  cssIconStyle?: string
}

const VipDisableMessage = ({ text, cssStyles, cssIconStyle }: VipDisableMessageProps) => {
  const message = text || 'Доступно в подписке'

  return (
    <DivFlexRow style={`${styles.container} ${cssStyles}`}>
      <ImgBase img={Icon} style={`${cssIconStyle}`} />
      <Text12White>{message}</Text12White>
    </DivFlexRow>
  )
}

export { VipDisableMessage }
