import { DivFlexRow } from 'components/styledComponents/div/div'
import styles from './ProfileSettingTransactionCard.module.css'
import Icon from './diamond.svg'
import { Text14Grey, Text14White } from 'components/styledComponents/text/textStyles'
import { ProfileSettingsTransactionStatus } from '../ProfileSettingsTransactionStatus'
import { userSettingTransactionType } from 'api/userSettings/userTransactions'
import { formatDate } from 'utils/dateFormated'

type ProfileSettingTransactionCardProps = {
  transactionData: userSettingTransactionType
  onClick: (transactionData: userSettingTransactionType) => void
}

const ProfileSettingTransactionCard = ({ transactionData, onClick }: ProfileSettingTransactionCardProps) => {
  const { name, status, amount, createdAt } = transactionData

  return (
    <DivFlexRow style={styles.card} onClick={onClick.bind(this, transactionData)}>
      <img src={Icon} alt="diamond" className={styles.icon} />
      <Text14White style={styles.name}>{name}</Text14White>
      <Text14White style={styles.price}>{amount} ₽</Text14White>
      <Text14Grey style={styles.date}>{formatDate(createdAt)}</Text14Grey>
      <ProfileSettingsTransactionStatus status={status} />
    </DivFlexRow>
  )
}

export { ProfileSettingTransactionCard }
