import styles from './HomeAbsProductFilterContainer.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { DarkGreyContainer } from '../../../components/DarkGreyContainer'
import { Text16Grey, Text16White, Text24White } from 'components/styledComponents/text/textStyles'
import { ImgBase } from 'components/styledComponents/img/img'

import NoCheck from '../../../icons/checkbox_1.svg'
import Checked from '../../../icons/checkbox_2.svg'
import Arrow from '../../../../../icons/arrow_bottom_purple.svg'
import Scroll from '../../../icons/scroll.svg'

const HomeAbsProductFilterContainer = () => {
  return (
    <DivFlexColumn style={styles.container}>
      <DarkGreyContainer style={styles.checkContainer}>
        <Text24White style={styles.headerText}>Выберите тип сделки</Text24White>

        <DivFlexRow style={`${styles.checkbox} ${styles.disable}`}>
          <ImgBase img={Checked} alt="check" />
          <Text16White>Продать</Text16White>
        </DivFlexRow>

        <DivFlexRow style={`${styles.checkbox} ${styles.active}`}>
          <ImgBase img={NoCheck} alt="check" />
          <Text16White>Купить</Text16White>
        </DivFlexRow>
      </DarkGreyContainer>

      <DivFlexRow style={styles.itemFilter}>
        <Text16White>Кепка Козырёк Urban</Text16White>
        <ImgBase img={Arrow} alt="arrow" style={styles.itemArrow} />
      </DivFlexRow>

      <DivFlexRow style={styles.scrollContainer}>
        <DivFlexColumn>
          <Text16Grey style={styles.item}>Кепка Gucci^Adidas</Text16Grey>
          <Text16Grey style={`${styles.item} ${styles.active}`}>Кепка Козырёк Urban</Text16Grey>
          <Text16Grey style={styles.item}>Кепка Diamond (LW)</Text16Grey>
          <Text16Grey style={styles.item}>Кепка Chrome Hearts</Text16Grey>
        </DivFlexColumn>
        <ImgBase img={Scroll} alt="scroll" style={styles.sctollImg} />
      </DivFlexRow>
    </DivFlexColumn>
  )
}

export { HomeAbsProductFilterContainer }
