import styles from './ShopBarSecondLevelButton.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text16Grey } from 'components/styledComponents/text/textStyles'
import { ImgBase } from 'components/styledComponents/img/img'
import { ShopBarThirdLevelButton } from '../ShopBarThirdLevelButton'
import { IPlatformCategory } from 'store/platformConfigStore/types'

import ArrowRight from '../../../../icons/arrow_right_grey.svg'
import ArrowBottom from '../../../../icons/arrow_bottom_purple.svg'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

type ShopBarSecondLevelButtonProps = {
  category: IPlatformCategory
  isActive: boolean
  setCategoryData: (categoryData: IPlatformCategory) => void
  onClick: () => void
}

const ShopBarSecondLevelButton = observer(
  ({ category, isActive, setCategoryData, onClick }: ShopBarSecondLevelButtonProps) => {
    const isShowArrow = category.categories.length > 0
    const [activeCategory, setActiveCategory] = useState<IPlatformCategory | null>(null)

    const handlerClick = (category: IPlatformCategory) => {
      if (activeCategory && activeCategory.id === category.id) {
        setActiveCategory(null)
      } else {
        setActiveCategory(category)
        setCategoryData(category)
      }
    }

    return (
      <DivFlexColumn style={styles.container}>
        <DivFlexRow style={styles.button} onClick={onClick}>
          <Text16Grey style={`${styles.text} ${isActive && styles.active}`}>{category.name}</Text16Grey>

          {isShowArrow && <ImgBase img={isActive ? ArrowBottom : ArrowRight} style={styles.arrow} />}
        </DivFlexRow>

        {category.categories && isActive && (
          <>
            {category.categories.map((category) => {
              return (
                <ShopBarThirdLevelButton
                  key={category.id}
                  category={category}
                  isActive={activeCategory?.id === category.id}
                  onClick={handlerClick.bind(this, category)}
                />
              )
            })}
          </>
        )}
      </DivFlexColumn>
    )
  }
)

export { ShopBarSecondLevelButton }
