import styles from './ScrollCheckboxesWithSearch.module.css'

import { CheckBoxList } from 'components/ui/lists/CheckBoxList'
import { DivFlexRow } from 'components/styledComponents/div/div'

import SearchIcon from '../../../icons/search-icon.svg'
import { useState } from 'react'
import { InputWidhtCross } from 'components/ui/inputs/InputWidhtCross'
import { VipBlockButton } from 'components/ui/VipBlockButton'
import { FilterCheckBoxType } from 'store/platformConfigStore/types'

type ScrollCheckboxesWithSearchProps = {
  headerText: string
  fontSize?: number
  list: FilterCheckBoxType[]
  isBlocked?: boolean
  activeCheckBoxes: string[]
  onChange: (value: string[]) => void
}

const ScrollCheckboxesWithSearch = ({
  headerText,
  fontSize = 14,
  list,
  isBlocked = false,
  activeCheckBoxes,
  onChange
}: ScrollCheckboxesWithSearchProps) => {
  const [isInput, setInput] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const [filteredList, setFilteredList] = useState<FilterCheckBoxType[]>(list)

  const handleClickSearch = () => {
    if (!isBlocked) setInput(true)
  }

  const handleInputChange = (value: string) => {
    setInputValue(value)
    const filtered = list.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
    setFilteredList(filtered)
  }

  return (
    <VipBlockButton isChildrenInside buttonText="В подписке" isBlocked={isBlocked}>
      <div className={`${styles.container} ${isBlocked && styles.blocked}`}>
        {!isInput ? (
          <DivFlexRow style={styles.headerContainer}>
            <p className={`${styles.headerText} ${isBlocked && styles.blocked}`} style={{ fontSize: fontSize }}>
              {headerText}
            </p>
            <img className={styles.searchIcon} src={SearchIcon} alt="search" onClick={handleClickSearch} />
          </DivFlexRow>
        ) : (
          <InputWidhtCross value={inputValue} onChange={handleInputChange} onClick={setInput.bind(this, false)} />
        )}

        <div className={styles.list}>
          <CheckBoxList
            list={filteredList}
            cssListStyles={styles.listStyles}
            isBlockedIcon={isBlocked}
            onChange={onChange}
            activeCheckBoxes={activeCheckBoxes}
          />
        </div>
      </div>
    </VipBlockButton>
  )
}

export { ScrollCheckboxesWithSearch }
