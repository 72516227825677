import { userSettingTransactionStatus } from 'api/userSettings/userTransactions'
import { ProfileSettingTransactionText } from './types'

export const getTransactionsStatusText = (status: string): string => {
  if (status === userSettingTransactionStatus.Canceled) {
    return ProfileSettingTransactionText.Canceled
  }
  if (status === userSettingTransactionStatus.Pending) {
    return ProfileSettingTransactionText.Pending
  } else return ProfileSettingTransactionText.Succeeded
}

export const getTransactionsStatusColor = (status: string) => {
  if (status === userSettingTransactionStatus.Canceled) {
    return { color: '#F85A5A', bgColor: 'rgba(248, 90, 90, 0.10)' }
  }
  if (status === userSettingTransactionStatus.Pending) {
    return { color: '#F8D55A', bgColor: 'rgba(248, 213, 90, 0.10)' }
  } else return { color: '#8EF84F', bgColor: 'rgba(142, 248, 79, 0.10)' }
}
