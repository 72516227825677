import { useState } from 'react'
import AcceptRulesBlock from '../../AcceptRulesBlock/AcceptRulesBlock'
import AuthDiscordButton from '../../buttons/AuthDiscordButton/AuthDiscordButton'
import AuthSubmitButton from '../../buttons/AuthSubmitButton/AuthSubmitButton'
import EmailFormInput from '../../inputs/EmailFormInput/EmailFormInput'
import { authDiscordOauth2Url, userRegistration } from '../../../../api/auth/auth'
import { StateErrorType } from '../types'
import EmailCodeForm from '../EmailCodeForm/EmailCodeForm'
import useNicknameValidation from 'utils/forms/nicknameValidationHook'
import NicknameFormInput from 'components/ui/inputs/NicknameFormInput/NicknameFormInput'
import useEmailValidation from 'utils/forms/emailValidationHook'
import DiscordForm from '../DiscordForm/DiscordForm'
import styles from './RegistrationForm.module.css'
import FormTextBackButton from 'components/ui/buttons/FormTextBackButton/FormTextBackButton'
import { BrowserRouters } from '../../../../PageRouters'
import errorAlertsStore from '../../../../store/errorAlertsStore'
import { MessageType } from '../../errors/AlertError'

const emailBottomText = 'Используется для входа в систему'
const nicknameBottomText = 'Отображается в профиле, латинские буквы, цифры, символы'
const backButtonText = '< Вернуться к окну регистрации'
const discrodSubmitText = 'Продолжить регистрацию'

type RegistrationFormType = {
  onClose?: () => void
}

const RegistrationForm = ({ onClose }: RegistrationFormType) => {
  const [isOpenCodePage, setOpenCodePage] = useState<boolean>(false)
  const [isDiscordForm, setDiscordForm] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [nickname, setNickname] = useState<string>('')
  const [checkbox, setCheckbox] = useState<boolean>(true)
  const [codeId, setCodeId] = useState<string>('')
  const [isSubmit, setSubmit] = useState<boolean>(false)

  const validationCheckbox = () => {
    setCheckbox((prevCheckbox) => !prevCheckbox)
  }

  const { isValid: isNicknameValid } = useNicknameValidation(nickname)
  const { isValid: isEmailValid } = useEmailValidation(email)

  const [nicknameError, setNicknameError] = useState<StateErrorType>({
    text: '',
    isError: false
  })

  const [emailError, setEmailError] = useState<StateErrorType>({
    text: '',
    isError: false
  })

  const handleDiscordSubmit = async () => {
    setSubmit(true)
    const result = await authDiscordOauth2Url()
    if (result.data) {
      window.location.href = result.data.redirectUrl
    }

    if (result.error) {
      errorAlertsStore.addError({ errorType: MessageType.Error, errorText: result.error.message })
    }
    setSubmit(false)
  }

  const handlerSubmit = async (e: any) => {
    e.preventDefault()
    if (
      isNicknameValid &&
      isEmailValid &&
      checkbox &&
      nickname &&
      email &&
      !nicknameError.isError &&
      !emailError.isError
    ) {
      setSubmit(true)
      const result = await userRegistration(email, nickname)

      if (result.data) {
        setOpenCodePage(true)
        setCodeId(result.data.confirmationCodeId)
        setNicknameError({ text: '', isError: false })
        setEmailError({ text: '', isError: false })
      }

      if (result.error) {
        console.log(result.error)
        if (result.error.code === 9) {
          setEmailError({ text: result.error.message, isError: true })
        } else if (result.error && result.error.code === 8) {
          setNicknameError({ text: result.error.message, isError: true })
        } else {
          errorAlertsStore.addError({
            errorType: MessageType.Error,
            errorText: result.error.message
          })
        }
      }

      setSubmit(false)
    }
  }

  // форма с дискордом
  if (isDiscordForm) {
    return (
      <div className={styles.form}>
        <DiscordForm submitButtonText={discrodSubmitText} />
        <FormTextBackButton text={backButtonText} onClick={setDiscordForm.bind(this, false)} />
      </div>
    )
  }

  // форма с email кодом
  if (isOpenCodePage) {
    return (
      <div className={styles.form}>
        <EmailCodeForm confirmationCodeId={codeId} onCloseModal={onClose} />
        <FormTextBackButton text={backButtonText} onClick={setOpenCodePage.bind(this, false)} />
      </div>
    )
  }

  // форма регистрации
  return (
    <form className={styles.form} onSubmit={(e: any) => handlerSubmit(e)}>
      <EmailFormInput
        value={email}
        onChange={setEmail}
        placeHolder="Ваш e-mail"
        bottomText={emailBottomText}
        backendError={{
          backendText: emailError.text,
          isBackandError: emailError.isError
        }}
        clearError={setEmailError.bind(this, { text: '', isError: false })}
      />

      <NicknameFormInput
        value={nickname}
        onChange={setNickname}
        placeHolder="Ваш никнейм"
        bottomText={nicknameBottomText}
        backendError={{
          backendText: nicknameError.text,
          isBackandError: nicknameError.isError
        }}
        clearError={setNicknameError.bind(this, { text: '', isError: false })}
      />

      <AcceptRulesBlock
        text="Зарегистрироваться"
        rules="правилами использования сервиса"
        anyStyles={{ marginTop: '24px' }}
        isCheked={validationCheckbox}
        link={BrowserRouters.Agreement}
      />

      <AuthSubmitButton
        text="Зарегистрироваться на RP Market"
        loaderText="Регистрация"
        anyStyles={{ marginTop: '24px' }}
        isSubmit={isSubmit}
        onSubmit={(e: any) => handlerSubmit(e)}
      />

      <AuthDiscordButton
        text="Регистрация с помощью Discord"
        anyStyles={{ margin: '24px auto 0' }}
        submit={handleDiscordSubmit}
      />
    </form>
  )
}

export default RegistrationForm
