import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text16White } from 'components/styledComponents/text/textStyles'
import styles from './ShopBarChartBlock.module.css'

import Frame from './frame.png'

type ShopBarChartBlockProps = {
  headerText: string
  cssContainer?: string
}

const ShopBarChartBlock = ({ headerText, cssContainer }: ShopBarChartBlockProps) => {
  return (
    <DivFlexColumn style={`${styles.container} ${cssContainer}`}>
      <DivFlexRow style={styles.headerContainer}>
        <Text16White>{headerText}</Text16White>

        <DivFlexRow style={styles.headerButtons}></DivFlexRow>
      </DivFlexRow>

      <ImgBase img={Frame} />
    </DivFlexColumn>
  )
}

export { ShopBarChartBlock }
