import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import styles from './ShopBarContanctBlock.module.css'
import { Text16White, Text14Green, Text16Grey, Text14White } from 'components/styledComponents/text/textStyles'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'

import Avatar from '../../../../components/icons/facke/avatar.svg'
import CallIcon from '../../../../components/icons/button/green_phone-call.svg'
import ChatIcon from '../../../../components/icons/button/chat-unread-rounded.svg'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { AdvertisementPropertyUserDto } from 'store/advertisement/advertisementsShopBar/types'
import { userStatusToText } from 'utils/userStatusToText'
import { useState } from 'react'
import { getUserGamePhone } from 'api/userSettings/userGamePhones'
import CheckIcon from '../../../../components/icons/check_green.svg'
import { getChatIdByAdvertisementId } from '../../../../api/chat/method'
import errorAlerts from '../../../../store/errorAlertsStore'
import { MessageType } from '../../errors/AlertError'
import { userInfoStore } from '../../../../store/userInfoStore'
import AuthModal from '../../modals/AuthModal/AuthModal'

type ShopBarContanctBlockProps = {
  isDisable: boolean
  user?: AdvertisementPropertyUserDto
  gamePhoneId?: string | null
  advertisementId?: string | null
}

const ShopBarContanctBlock = ({ isDisable, user, gamePhoneId, advertisementId }: ShopBarContanctBlockProps) => {
  const [isActivePhone, setActivePhone] = useState<boolean>(false)
  const [isAuthModal, setAuthModal] = useState<boolean>(false)
  const { isAuth } = userInfoStore

  const handleOpenChat = async () => {
    if (isDisable || !advertisementId) {
      return
    }

    if (!isAuth) {
      setAuthModal(true)
      return
    }

    const result = await getChatIdByAdvertisementId(advertisementId)
    if (result.data) {
      window.open('/chat/' + result.data.chatId, '_blank')
    }
    if (result.error) {
      errorAlerts.addError({
        errorType: MessageType.Error,
        errorText: result.error.message
      })
    }
  }

  const handleCopyPhone = async () => {
    if (gamePhoneId) {
      const userPhone = await getUserGamePhone(gamePhoneId)
      if (userPhone) {
        navigator.clipboard
          .writeText(userPhone.number)
          .then(() => {
            console.log('Телефон скопирован в буфер обмена: ', userPhone)
            setActivePhone(true)

            setTimeout(() => {
              setActivePhone(false)
            }, 2000)
          })
          .catch((err) => {
            console.error('Ошибка при копировании текста: ', err)
            setActivePhone(false)
          })
      }
    }
  }

  return (
    <DivFlexColumn style={`${styles.tradeBox} ${isDisable && styles.containerDisable}`}>
      <DivFlexRow style={styles.dividerBlock}>
        <Text16Grey style={`${isDisable && styles.textDisable}`}>Продавец</Text16Grey>
        <HorizontalDivider style={{ flex: '1', height: '1.5px' }} />
      </DivFlexRow>

      {user && (
        <DivFlexRow style={styles.traderInfoBlock}>
          <img src={Avatar} alt="avatar" className={`${styles.avatar} ${isDisable && styles.iconDisable}`} />
          <DivFlexColumn style={styles.traderTextBlock}>
            <Text16White style={`${isDisable && styles.textDisable}`}>{user.nickname}</Text16White>
            <Text14Green style={`${styles.userStatusText} ${styles[user.activeStatus]}`}>
              {userStatusToText(user.activeStatus)}
            </Text14Green>
          </DivFlexColumn>
        </DivFlexRow>
      )}

      <DivFlexRow style={styles.infoButtonsBox}>
        <OvalButton
          text="Написать в чат"
          icon={ChatIcon}
          isSwapContent
          cssContainerStyle={`${styles.button} ${styles.call} ${isDisable && styles.bgDisable}`}
          textStyle={isDisable ? { color: '#7E88BB', fontSize: '16px' } : { fontSize: '16px' }}
          iconStyle={
            isDisable
              ? {
                  filter: 'sepia(100%) hue-rotate(184deg) brightness(80%)',
                  width: '20px',
                  height: '20px'
                }
              : { width: '20px', height: '20px' }
          }
          onClick={handleOpenChat}
        />

        {gamePhoneId && (
          <>
            {!isActivePhone ? (
              <OvalButton
                text="Позвонить"
                icon={CallIcon}
                isSwapContent
                cssContainerStyle={`${styles.button} ${styles.chat} ${isDisable && styles.bgDisable}`}
                textStyle={isDisable ? { color: '#7E88BB', fontSize: '16px' } : { fontSize: '16px' }}
                iconStyle={
                  isDisable
                    ? {
                        filter: 'sepia(100%) hue-rotate(184deg) brightness(80%)',
                        width: '20px',
                        height: '20px'
                      }
                    : { width: '20px', height: '20px' }
                }
                onClick={handleCopyPhone}
              />
            ) : (
              <DivFlexRow style={styles.copyPhoneButton}>
                <Text14White>Телефон скопирован</Text14White>
                <img className={styles.copyIcon} alt="copy" src={CheckIcon} />
              </DivFlexRow>
            )}
          </>
        )}
      </DivFlexRow>
      <AuthModal isOpen={isAuthModal} onClose={setAuthModal.bind(this, false)} />
    </DivFlexColumn>
  )
}

export { ShopBarContanctBlock }
