import styles from './AddGamePhoneModal.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Red, Text16Grey, Text32White } from 'components/styledComponents/text/textStyles'
import { GamePhoneInput } from 'components/ui/inputs/GamePhoneInput'
import { DropdownServersArrow } from 'components/ui/dropdowns/DropdownServersArrow'
import { observer } from 'mobx-react-lite'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import { useState } from 'react'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { useGamePhoneValidation } from 'utils/forms/gamePhoneValidationHook'
import { addNewUserPhone, getUserGamePhones } from 'api/userSettings/userGamePhones'
import Loader from 'components/ui/loaders/Loader/Loader'

import CloseIcon from '../../../icons/cross_purple.svg'
import ObligatoryIcon from '../../../icons/obligatory_red.svg'
import Tick from '../../../icons/tick_green.svg'
import { ModalWrapper } from 'components/ui/wrappers/ModalWrapper'
import { IPlatformServer } from 'store/platformConfigStore/types'

type AddGamePhoneModalProps = {
  isOpen: boolean
  onClose: () => void
  isHiddenServerBlock?: boolean
  server?: IPlatformServer | null
}

const AddGamePhoneModal = observer(
  ({ isOpen, onClose, isHiddenServerBlock = false, server: serverProps }: AddGamePhoneModalProps) => {
    const { servers } = userPlatformConfig.platformConfigs

    const [isSubmit, setSubmit] = useState<boolean>(false)

    const [phone, setPhone] = useState<string>('')
    const [server, setServer] = useState<{ id: string; name: string }>(serverProps ? serverProps : { id: '', name: '' })

    const { isValid, errorText } = useGamePhoneValidation(phone)
    const isServerValid = server.id !== ''
    const isInutValid = phone.length === 9 && isValid

    const handleSubmit = async () => {
      if (isServerValid && isInutValid) {
        const newPhone = {
          number: phone.replace(/[^0-9]/g, ''),
          serverId: server.id
        }

        setSubmit(true)
        const result = await addNewUserPhone(newPhone)
        if (result) {
          await getUserGamePhones()
          onClose()
        }
        setSubmit(false)
      }
    }

    return (
      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <DivFlexColumn style={`${styles.modal} ${isHiddenServerBlock && styles.small}`}>
          <DivFlexRow style={styles.header}>
            <Text32White style={styles.headerText}>Привязка номера</Text32White>
            <img src={CloseIcon} alt="close" className={styles.headerCloseIcon} onClick={onClose} />
          </DivFlexRow>

          <DivFlexRow style={styles.inputHeader}>
            <Text16Grey>Номер телефона</Text16Grey>
            <img src={ObligatoryIcon} alt="obligatory" className={styles.validRedIcon} />
            {isInutValid && <img src={Tick} alt="success" className={styles.tick} />}
          </DivFlexRow>

          <GamePhoneInput cssContainerStyles={styles.phoneInput} value={phone} setValue={setPhone} />
          {!isInutValid && <Text14Red>{errorText}</Text14Red>}

          {!isHiddenServerBlock && (
            <>
              <DivFlexRow style={styles.inputHeader}>
                <Text16Grey>Выберите сервер</Text16Grey>
                <img src={ObligatoryIcon} alt="obligatory " className={styles.validRedIcon} />
                {isServerValid && <img src={Tick} alt="success" className={styles.tick} />}
              </DivFlexRow>

              <DropdownServersArrow serversArray={servers} onChange={setServer} />
            </>
          )}

          <OvalButton
            text="Создать"
            cssContainerStyle={`${styles.button} ${!isServerValid && styles.disable} ${!isInutValid && styles.disable}`}
            onClick={handleSubmit}
          />

          {isSubmit && (
            <div className={styles.disableBlock}>
              <Loader />
            </div>
          )}
        </DivFlexColumn>
      </ModalWrapper>
    )
  }
)

export { AddGamePhoneModal }
