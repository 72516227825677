import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import api from '../axios/axiosInstance'
import { LoadingStatusType, loadingStatusStore } from 'store/loadingStatusStore'
import axios from 'axios'
import { getUrlS3 } from '../../common/utils'
import {IGetResCatalogList, IGetReqCatalogList, IGetResCatalogListItem} from './catalog.type'

const getCatalogSearch = async (options: IGetReqCatalogList): Promise<IGetResCatalogList> => {
  const { name, categoryId, paginationSkip, serverId, paginationTake, sort, filter } = options

  const queryParams = new URLSearchParams()

  if (categoryId) queryParams.append('filter[systemCategoryId]', categoryId)
  if (name) queryParams.append('filter[systemCatalogName]', name)
  queryParams.append('filter[systemServerId]', serverId)

  queryParams.append('pagination[take]', String(paginationTake))
  queryParams.append('pagination[skip]', String(paginationSkip ?? 0))

    queryParams.append('sort[field]', String(sort.field))
    queryParams.append('sort[order]', String(sort.order))

  if (filter) {
    for (const keyFilter in filter) {
      queryParams.append(`filter[${keyFilter}]`, String(filter[keyFilter]))
    }
  }

  const url = `/api/catalog/list?${queryParams.toString()}`
  return api.get(url)
}

const getCardImage = async (imageId: string): Promise<string> => {
  const { setLoading } = loadingStatusStore

  try {
    setLoading(LoadingStatusType.Loading)

    const url = getUrlS3(imageId)
    const response = await axios.get(url, { responseType: 'arraybuffer' })

    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const imageUrl = URL.createObjectURL(blob)

    setLoading(LoadingStatusType.Stop)
    return imageUrl
  } catch (error) {
    setLoading(LoadingStatusType.Error)
    return ''
  }
}

const getCatalogItem = async (catalogId: string) => {
  const { setLoading } = loadingStatusStore
  const { currentServer, saveCatalogItem } = userPlatformConfig

  try {
    setLoading(LoadingStatusType.Loading)

    const response: IGetResCatalogListItem = await api.get(`/api/catalog/${currentServer.id}/${catalogId}/item`)
    saveCatalogItem(response)

    setLoading(LoadingStatusType.Stop)
  } catch (error) {
    setLoading(LoadingStatusType.Error)
    return ''
  }
}

export { getCardImage, getCatalogSearch, getCatalogItem }
