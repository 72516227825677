import styles from './DropdownServersArrow.module.css'

import { useState } from 'react'
import { DropdownWrapper } from 'components/ui/wrappers/DropdownWrapper'
import { DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text14Grey } from 'components/styledComponents/text/textStyles'

import Arrow from '../../../icons/arrow_right_purple.svg'
import { toJS } from 'mobx'

type DropdownServersArrowProps = {
  headerText?: string
  serversArray: { id: string; name: string }[]
  onChange?: (data: any) => void
  fontSize?: number
  cssContainerStyles?: string
}

const DropdownServersArrow = ({
  headerText = 'Не выбрано',
  serversArray,
  onChange,
  fontSize = 16,
  cssContainerStyles
}: DropdownServersArrowProps) => {
  const [text, setText] = useState<string>(headerText)
  const [isOpen, setOpen] = useState<boolean>(false)

  const firstItem = headerText

  const handleChooseItem = (server: { id: string; name: string }) => {
    onChange && onChange(server)
    setText(server.name)
  }

  return (
    <DropdownWrapper onClose={setOpen.bind(this, false)}>
      <DivFlexRow
        style={`${styles.button} ${cssContainerStyles} ${isOpen && styles.active}`}
        onClick={setOpen.bind(this, !isOpen)}
      >
        <p className={styles.text} style={{ fontSize: fontSize }}>
          {text}
        </p>

        <ImgBase img={Arrow} style={`${styles.arrow} ${isOpen && styles.active}`} />

        {isOpen && (
          <div className={styles.dropContainer}>
            <ul className={styles.itemsSection}>
              {
                <li className={styles.item} onClick={setText.bind(this, firstItem)}>
                  <Text14Grey style={`${styles.itemText} ${text === firstItem && styles.active}`}>
                    {firstItem}
                  </Text14Grey>
                </li>
              }
              {serversArray.map((server) => {
                const { name, id } = server
                return (
                  <li key={id} className={styles.item} onClick={handleChooseItem.bind(this, server)}>
                    <Text14Grey style={`${styles.itemText} ${text === name && styles.active}`}>{name}</Text14Grey>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </DivFlexRow>
    </DropdownWrapper>
  )
}

export { DropdownServersArrow }
