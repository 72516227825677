import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import styles from './AdvertisementCreatePhoneBlock.module.css'
import { Text16Grey } from 'components/styledComponents/text/textStyles'

import StarIcon from '../../../icons/obligatory_red.svg'
import CheckIcon from '../../../icons/check_green.svg'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'

import AddIcon from '../../../icons/profileSettings/phone_add.svg'
import { DropdownWithPhones } from 'components/ui/dropdowns/DropdownWithPhones'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { getUserGamePhones } from 'api/userSettings/userGamePhones'
import { UserGamePhone, userGamePhonesStore } from 'store/profileSettingsStore/gamePhonesStore'
import { AdvertisementCreateForm } from 'api/advetrisement/create/advetrisementCreate'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'
import { AdvertisementCreateAlertBlock } from '../AdvertisementCreateAlertBlock'
import { IPlatformServer } from 'store/platformConfigStore/types'
import { AddGamePhoneModal } from 'components/ui/modals/AddGamePhoneModal'

const alertText = 'У вас нет привязанного телефона, для этого сервера'

type AdvertisementCreatePhoneBlockProps = {
  submitForm: AdvertisementCreateForm
  setSubmitForm: React.Dispatch<React.SetStateAction<AdvertisementCreateForm>>
}

const AdvertisementCreatePhoneBlock = observer(({ submitForm, setSubmitForm }: AdvertisementCreatePhoneBlockProps) => {
  const { userPhones } = userGamePhonesStore
  const { servers } = userPlatformConfig.platformConfigs

  const [serverPhones, setServerPhones] = useState<UserGamePhone[]>([])
  const [server, setServer] = useState<IPlatformServer | null>(null)
  const [isAddPhoneOpen, setAddPhoneOpen] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      await getUserGamePhones()
    })()
  }, [])

  useEffect(() => {
    // устанавливает выбранный сервер
    servers.some((item) => item.id === submitForm.serverId && setServer({ ...item }))
  }, [servers, submitForm.serverId])

  useEffect(() => {
    // собирает телефоны пользователя по выбранному серверу
    setServerPhones([...userPhones.filter((phone) => phone.serverName === server?.name)])
  }, [userPhones, server?.name])

  useEffect(() => {
    if (submitForm.gamePhoneId === null && serverPhones[0]?.id) {
      setSubmitForm((prev) => ({
        ...prev,
        gamePhoneId: serverPhones[0]?.id
      }))
    }
  }, [serverPhones, setSubmitForm, submitForm])

  const handleShoosePhone = (phoneId: string) => {
    setSubmitForm({ ...submitForm, gamePhoneId: phoneId })
  }

  return (
    <>
      {!!!serverPhones.length && <AdvertisementCreateAlertBlock alertText={alertText} />}

      <DivFlexColumn style={styles.container}>
        <DivFlexRow style={styles.header}>
          <Text16Grey>Выберите игровой номер</Text16Grey>
          <img className={styles.star} src={StarIcon} alt="star" />
          {!!serverPhones.length && (
            <span className={styles.arrowContainer}>
              <img className={styles.arrow} src={CheckIcon} alt="check" />
            </span>
          )}
        </DivFlexRow>

        <DivFlexRow style={styles.body}>
          {!!serverPhones.length && <DropdownWithPhones items={serverPhones} onChoose={handleShoosePhone} />}

          <OvalButton
            text="Создать новый номер"
            icon={AddIcon}
            containerStyle={{ height: 48 }}
            onClick={setAddPhoneOpen.bind(this, true)}
          />
        </DivFlexRow>
      </DivFlexColumn>

      {isAddPhoneOpen && (
        <AddGamePhoneModal
          isOpen={isAddPhoneOpen}
          onClose={setAddPhoneOpen.bind(this, false)}
          isHiddenServerBlock
          server={server}
        />
      )}
    </>
  )
})

export { AdvertisementCreatePhoneBlock }
