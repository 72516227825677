import { Text16White } from 'components/styledComponents/text/textStyles'
import styles from './PurpleButtonWithIcon.module.css'
import { ImgBase } from 'components/styledComponents/img/img'

type PurpleButtonWithIconProps = {
  text: string
  cssStyle?: string
  img: string
  textStyle?: string
}

const PurpleButtonWithIcon = ({ text, cssStyle, img, textStyle }: PurpleButtonWithIconProps) => {
  return (
    <button className={`${styles.button} ${cssStyle}`}>
      <Text16White style={textStyle}>{text}</Text16White>
      <ImgBase img={img} />
    </button>
  )
}

export { PurpleButtonWithIcon }
