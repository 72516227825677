import styles from './ReferralInput.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'

import ChainIcon from '../../../icons/referral/chain.svg'
import RefreshIcon from '../../../icons/refresh_purple.svg'
import { useState } from 'react'
import { createReferralPromoCode, getReferralPromoCodeList } from 'api/referral/referral'
import { generateRandomString } from 'utils/generateString'

const ReferralInput = () => {
  const protocol = window.location.protocol
  const host = window.location.hostname
  const port = window.location.port

  const promoCodeLink = port ? `${protocol}//${host}:${port}/?ref=` : `${protocol}//${host}/?ref=`

  const [input, setInput] = useState<string>('')

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9]/g, '').slice(0, 20)
    setInput(sanitizedValue)
  }

  const handleGeneratePromoCode = () => {
    const generatedString = generateRandomString(6)
    setInput(generatedString)
  }

  const handleSubmit = async () => {
    if (input.length > 2) {
      await createReferralPromoCode(input)
      await getReferralPromoCodeList()
      setInput('')
    }
  }

  return (
    <DivFlexRow style={styles.container}>
      <DivFlexRow style={styles.inputContainer}>
        <OvalButton
          text={promoCodeLink}
          icon={ChainIcon}
          cssContainerStyle={styles.linkButton}
          textStyle={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '175px'
          }}
          iconSize={24}
        />

        <input
          value={input}
          onChange={onChangeInput}
          className={styles.input}
          placeholder="Введите промокод (лат. буквы, цифры, от 3 до 20)"
        />

        <OvalButton
          text="Генерировать"
          icon={RefreshIcon}
          cssContainerStyle={styles.generateButton}
          onClick={handleGeneratePromoCode}
        />
      </DivFlexRow>

      <OvalButton
        text="Создать ссылку"
        cssContainerStyle={`${styles.createLinkButton} ${input.length < 3 && styles.disable}`}
        onClick={handleSubmit}
      />
    </DivFlexRow>
  )
}

export { ReferralInput }
