import styles from './AdvertisementMyLimitsBlock.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text18White } from 'components/styledComponents/text/textStyles'
import { AdvertisementMyLimitCard } from '../AdvertisementMyLimitCard'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'

import ArrowUpIcon from '../../../icons/AdvertisementMy/arrow-up-circle_purple.svg'
import ArrowDownIcon from '../../../icons/AdvertisementMy/arrow-down-circle_purple.svg'
import HourglassIcon from '../../../icons/AdvertisementMy/hourglass_purple.svg'
import {
  MyAdvertisementsDashboardLimit,
  MyAdvertisementsDashboardStatistic
} from 'store/advertisement/advertisementMyStore/types'
import { nameDay } from '../../../../utils/dateFormated'

type AdvertisementMyLimitsBlockProps = {
  limit: MyAdvertisementsDashboardLimit
  statistic: MyAdvertisementsDashboardStatistic
}

const AdvertisementMyLimitsBlock = observer(({ limit, statistic }: AdvertisementMyLimitsBlockProps) => {
  const { isVip } = userInfoStore

  const { maxCountBuy, maxCountSale, maxCountBuyVip, maxCountSaleVip, maxCountHourShow, maxCountHourShowVip } = limit

  const { countBuy, countSale } = statistic
  const maxCountDaysShow = maxCountHourShow / 24
  const maxCountDaysShowVip = maxCountHourShowVip / 24

  const maxCountDays = isVip ? maxCountDaysShowVip : maxCountDaysShow
  const textCountDaysShow = isVip ? nameDay(maxCountDaysShowVip) : nameDay(maxCountDaysShow)

  return (
    <DivFlexColumn style={styles.container}>
      <Text18White style={styles.headerText}>Доступные лимиты</Text18White>

      <DivFlexRow style={styles.cardsBlock}>
        <AdvertisementMyLimitCard
          headerText="Активных объявлений на продажу"
          firstCount={`${countSale}`}
          secondCount={`${isVip ? maxCountSaleVip : maxCountSale}`}
          cardIcon={ArrowUpIcon}
          isDisable={!isVip}
          vipText={`${maxCountSaleVip} объявлений на продажу в подписке`}
        />

        <AdvertisementMyLimitCard
          headerText="Активных объявлений на покупку"
          firstCount={`${countBuy}`}
          secondCount={`${isVip ? maxCountBuyVip : maxCountBuy}`}
          cardIcon={ArrowDownIcon}
          isDisable={!isVip}
          vipText={`${maxCountBuyVip} объявлений на покупку в подписке`}
        />

        <AdvertisementMyLimitCard
          headerText="Время размещения объявления"
          firstCount={`${maxCountDays} ${textCountDaysShow}`}
          cardIcon={HourglassIcon}
          isDisable={!isVip}
          vipText={`${maxCountDaysShowVip} ${textCountDaysShow} для размещения в подписке`}
        />
      </DivFlexRow>
    </DivFlexColumn>
  )
})

export { AdvertisementMyLimitsBlock }
