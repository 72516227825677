import styles from './HomeFilterContainer.module.css'

import { DarkPurpleContainer } from 'components/pages/Home/components/DarkPurpleContainer'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import VerticalDivider from 'components/ui/dividers/VerticalDivider/VerticalDivider'
import { Text14Grey, Text14White, Text16White, Text24White } from 'components/styledComponents/text/textStyles'

import Search from '../../../../../icons/search-icon_purple.svg'
import Scroll from '../../../icons/scroll.svg'
import { CheckBoxWithText } from 'components/ui/checkboxes/CheckBoxWithText'
import { TumblerPurpleWithText } from 'components/ui/buttons/TumblerPurpleWithText'

const HomeFilterContainer = () => {
  return (
    <DarkPurpleContainer style={styles.filterBlock}>
      <DivFlexRow style={styles.input}>
        <ImgBase img={Search} alt="search" />
        <VerticalDivider cssStyle={styles.inputDivider} />
        <Text16White>Кепка Козырёк Urban</Text16White>
      </DivFlexRow>

      <Text24White style={styles.filtersHeader}>Фильтр</Text24White>

      <DivFlexRow style={styles.checkContainer}>
        <DivFlexColumn>
          <Text14White style={styles.checkHeaderText}>Откуда предмет</Text14White>

          <DivFlexColumn style={styles.filtersContainer}>
            <CheckBoxWithText text="Ивент" isActive={true} />
            <CheckBoxWithText text="Магазин" isActive={false} />
            <CheckBoxWithText text="Эксклюзив" isActive={false} />
            <CheckBoxWithText text="Контейнер" isActive={false} />
            <CheckBoxWithText text="Кейс" isActive={false} />
          </DivFlexColumn>
        </DivFlexColumn>

        <ImgBase img={Scroll} alt="scroll" />
      </DivFlexRow>

      <Text14Grey style={styles.additionallyText}>Дополнительно</Text14Grey>

      <DivFlexColumn style={styles.tumblersContainer}>
        <TumblerPurpleWithText isActive text="Несколько цветов" />
        <TumblerPurpleWithText isActive={false} text="Специальные" cssContainerStyles={styles.specialTumbler} />
      </DivFlexColumn>
    </DarkPurpleContainer>
  )
}

export { HomeFilterContainer }
