import styles from './HomeCatalogNormalCarCard.module.css'

import { Text14Grey, Text16Green, Text18White, Text24Green } from 'components/styledComponents/text/textStyles'

import CarImg from '../../../../../icons/stock/base_white_car_photo.png'

const carName = 'Obey NSY 2020'
const carSub = 'Автомобили , Спортивные'
const price = '5.200.000'
const priceSymbol = '$'

const HomeCatalogNormalCarCard = () => {
  return (
    <div className={styles.container}>
      <img src={CarImg} alt="icon" className={styles.photo}></img>

      <Text18White style={styles.carNameText}>{carName}</Text18White>
      <Text14Grey style={styles.carSubText}>{carSub}</Text14Grey>

      <div className={styles.priceContainer}>
        <Text24Green>{price}</Text24Green>
        <Text16Green>{priceSymbol}</Text16Green>
      </div>
    </div>
  )
}

export { HomeCatalogNormalCarCard }
