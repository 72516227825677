import { MessageType } from 'components/ui/errors/AlertError'
import { FaqType, faqListStore } from 'store/faqListStore'
import errorAlerts from 'store/errorAlertsStore'
import api from 'api/axios/axiosInstance'
import axios from 'axios'

type PromiseType = {
  sections: FaqType[]
}

const getFaqList = async (filter?: string | undefined): Promise<boolean> => {
  try {
    const queryParams = filter ? `filter[text]=${encodeURIComponent(filter)}` : ''
    const url = `/api/faq/list?${queryParams}`

    const response: PromiseType = await api.get(url)

    faqListStore.saveFaqList(response.sections)

    return true
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorAlerts.addError({
          errorType: MessageType.Error,
          errorText: error.response.data.message
        })
      }
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    }
    return false
  }
}

export { getFaqList }
