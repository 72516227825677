import styles from './PurpleNeonButton.module.css'

type PurpleNeonButtonProps = {
  text: string
  cssStyle?: string
  fontSize?: number
  onClick?: () => void
}

const PurpleNeonButton = ({ text, cssStyle, fontSize, onClick }: PurpleNeonButtonProps) => {
  return (
    <button className={`${styles.button} ${cssStyle}`} onClick={onClick}>
      <p style={{ fontSize: fontSize }}>{text}</p>
    </button>
  )
}

export { PurpleNeonButton }
