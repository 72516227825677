import styles from './ChatPageBackButton.module.css'

import LeftArrowIcon from '../../../icons/arrow_left_white_big.svg'
import { BrowserRouters } from '../../../../PageRouters'
import { useNavigate } from 'react-router-dom'

const ChatPageBackButton = () => {
  const navigate = useNavigate()

  const onBackPage = () => {
    navigate(BrowserRouters.Chats)
  }

  return (
    <div className={styles.backButton} onClick={onBackPage}>
      <img className={styles.backButtonIcon} src={LeftArrowIcon} alt="back" />
    </div>
  )
}

export { ChatPageBackButton }
