import { action, makeObservable, observable } from 'mobx'
import { IGetResCatalogList } from '../../api/catalog/catalog.type'
import { ELocalStorage } from '../../common/enum/localStorage'
import { PlatformConfigFilter } from '../platformConfigStore/types'
import {
  TSearchParamsJson,
  TSearchParamsJsonTypesValue,
  TSearchParamsJsonValueTypeRange
} from '../../utils/searchParams'
import { isJson } from '../../utils/is'
import { PlatformConfigSort } from '../../api/platform/platform.type'
import { defaultItemFilterCatalog, defaultItemSort, defaultItemsSortOrderBy } from '../../common/defaultValue'

const PAGINATION_TAKE = 20
const MAX_COUNT_SEARCH_WORD = 20

class CatalogStore {
  constructor() {
    makeObservable(this, {
      sortListOrderBy: observable,
      sortListItem: observable,
      sortSelectedItem: observable,
      sortSelectedOrderBy: observable,
      isFiltersOpen: observable,
      catalogItems: observable,
      textBySearch: observable,
      categoryIdBySearch: observable,
      paginationSkip: observable,
      valueFilters: observable,
      setCatalogList: action,
      setInputTextBySearch: action,
      setCategoryIdBySearch: action,
      setPaginationSkip: action,
      setFiltersList: action,
      setFilterValue: action,
      removeFiltersValue: action,
      setFilterOpen: action,
      setSortItem: action,
      setSortItemOrderBy: action,
      setSortsList: action
    })

    const searchWords = localStorage.getItem(ELocalStorage.searchWords)
    if (searchWords && isJson(searchWords)) {
      this.searchWords = JSON.parse(searchWords)
    }
  }

  catalogItems: IGetResCatalogList = {
    items: [],
    pagination: {
      currentPage: 0,
      take: 0,
      totalCount: 0
    }
  }

  textBySearch: string = ''
  categoryIdBySearch?: string

  searchWords: string[] = []

  paginationTake: number = PAGINATION_TAKE
  paginationSkip: number = 0

  filters: PlatformConfigFilter[][] = defaultItemFilterCatalog
  valueFilters: TSearchParamsJson = {}
  isFiltersOpen: boolean = false

  sortListItem: PlatformConfigSort[] = defaultItemSort
  sortSelectedItem: PlatformConfigSort = defaultItemSort[0]

  sortListOrderBy: PlatformConfigSort[] = defaultItemsSortOrderBy
  sortSelectedOrderBy: PlatformConfigSort = defaultItemsSortOrderBy[0]

  // Установить список объявлений
  setCatalogList = (data: IGetResCatalogList) => (this.catalogItems = data)

  // Установка списка полей сортировки
  setSortsList = (items: PlatformConfigSort[]) => {
    if (items.length > 0) {
      this.sortListItem = items
      this.sortSelectedItem = items[0]
    } else {
      this.sortListItem = defaultItemSort
      this.sortSelectedItem = defaultItemSort[0]
    }
  }

  // Установка выбранного поля сортировки
  setSortItem = (value: PlatformConfigSort) => (this.sortSelectedItem = value)

  // Установка выбранного типа сортировки (asc/desc)
  setSortItemOrderBy = (value: PlatformConfigSort) => (this.sortSelectedOrderBy = value)

  // Установить список фильтров
  setFiltersList = (filters: PlatformConfigFilter[][]) => (this.filters = filters)

  // Установить значения фильтра
  setFilterValue = (key: string, value: TSearchParamsJsonTypesValue) => {
    this.valueFilters[key] ??= value

    switch (value.type) {
      case 'range': {
        const exValueFilters = this.valueFilters[key] as TSearchParamsJsonValueTypeRange
        if (exValueFilters.data.max !== value.data.max) {
          exValueFilters.data.max = value.data.max
        }
        if (exValueFilters.data.min !== value.data.min) {
          exValueFilters.data.min = value.data.min
        }
        break
      }
      case 'variant': {
        this.valueFilters[key] = value
      }
    }
  }

  // Обнулить значения фильтров
  removeFiltersValue = () => (this.valueFilters = {})

  // Открыть окно фильтров
  setFilterOpen = (type: boolean) => (this.isFiltersOpen = type)

  // event на ввод текста в инпут глобального поиска
  setInputTextBySearch = (text: string) => (this.textBySearch = text)

  saveTextBySearchToStore = () => {
    this.searchWords.unshift(this.textBySearch)
    this.searchWords = this.searchWords.slice(0, MAX_COUNT_SEARCH_WORD)
    localStorage.setItem(ELocalStorage.searchWords, JSON.stringify(this.searchWords))
  }

  // Установить id категории для поиска
  setCategoryIdBySearch = (categoryId: string | undefined) => (this.categoryIdBySearch = categoryId)

  // Установить пагинацию
  setPaginationSkip = (skip: number) => (this.paginationSkip = skip)
}

export const catalogStore = new CatalogStore()
