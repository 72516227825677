import api, { ResultHandlerAxiosResponse } from '../axios/axiosInstance'
import { IGetLinkedAccountList, IPatchLinkedAccountEditSetting } from './types'

export const getLinkedAccountList = async (): Promise<ResultHandlerAxiosResponse<IGetLinkedAccountList>> => {
  const url = `/api/linked-account/list`
  return api.getVersion2(url)
}

export const postLinkedAccountTestMessage = async (
  messenger: 'discord' | 'telegram'
): Promise<ResultHandlerAxiosResponse<IGetLinkedAccountList>> => {
  const url = `/api/linked-account/${messenger}/send-test-message`
  return api.postVersion2(url, {})
}

export const postLinkedAccountGenerateUrl = async (
  messenger: 'discord' | 'telegram'
): Promise<ResultHandlerAxiosResponse<{ url: string }>> => {
  const url = `/api/linked-account/${messenger}/link-url`
  return api.postVersion2(url, {})
}

export const patchLinkedAccount = async (
  messenger: 'discord' | 'telegram',
  data: { isSendMessage: boolean; isSendAdvertisement: boolean }
): Promise<ResultHandlerAxiosResponse<IPatchLinkedAccountEditSetting>> => {
  const url = `/api/linked-account/${messenger}/edit-setting`
  return api.pathVersion2(url, data)
}

export const deleteLinkedAccount = async (
  messenger: 'discord' | 'telegram'
): Promise<ResultHandlerAxiosResponse<{ url: string }>> => {
  const url = `/api/linked-account/${messenger}/delete`
  return api.deleteVersion2(url, {})
}

export const pathLinkedAccountBindDiscord = async (code: string): Promise<ResultHandlerAxiosResponse<{}>> => {
  const url = `/api/linked-account/discord/handler`
  return api.postVersion2(url, {
    code
  })
}

export const pathLinkedAccountBindTelegram = async (token: string): Promise<ResultHandlerAxiosResponse<{}>> => {
  const url = `/api/linked-account/telegram/handler`
  return api.postVersion2(url, {
    token
  })
}
