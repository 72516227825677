import { action, makeAutoObservable, observable } from 'mobx'
import { TypeAdvertisement } from '../../../common/types'
import { PlatformConfigSort } from '../../../api/platform/platform.type'
import { defaultItemFilterAdvertisement, defaultItemSort, defaultItemsSortOrderBy } from '../../../common/defaultValue'
import { PlatformConfigFilter } from '../../platformConfigStore/types'
import { ELocalStorage } from '../../../common/enum/localStorage'
import { isJson } from '../../../utils/is'
import {
  TSearchParamsJson,
  TSearchParamsJsonTypesValue,
  TSearchParamsJsonValueTypeRange
} from '../../../utils/searchParams'
import { IGetResAdvertisementList } from '../../../api/advetrisement/shopbar/advertisement.type'

const PAGINATION_TAKE = 20
const MAX_COUNT_SEARCH_WORD = 20

class AdvertisementsShopBarStore {
  constructor() {
    makeAutoObservable(this, {
      dealerStatus: observable,
      sortListOrderBy: observable,
      type: observable,
      sortListItem: observable,
      sortSelectedItem: observable,
      sortSelectedOrderBy: observable,
      isFiltersOpen: observable,
      advertisementItems: observable,
      textBySearch: observable,
      categoryIdBySearch: observable,
      paginationSkip: observable,
      valueFilters: observable,
      setTextBySearch: action,
      setCategoryIdBySearch: action,
      setPaginationSkip: action,
      setFiltersList: action,
      setFilterValue: action,
      removeFiltersValue: action,
      setFilterOpen: action,
      setSortItem: action,
      setSortItemOrderBy: action,
      setSortsList: action,
      onChangeInputTextBySearch: action,
      setAdvertisementList: action,
      setDealerStatus: action,
      setAdvertisementType: action
    })

    const searchWords = localStorage.getItem(ELocalStorage.searchWords)
    if (searchWords && isJson(searchWords)) {
      this.searchWords = JSON.parse(searchWords)
    }
  }

  advertisementItems: IGetResAdvertisementList = {
    pagination: {
      totalCount: 0,
      currentPage: 0,
      take: 0
    },
    items: []
  }

  dealerStatus: '' | 'online' | 'game' = ''
  type: TypeAdvertisement = 'sale'
  textBySearch: string = ''
  categoryIdBySearch?: string

  searchWords: string[] = []

  paginationTake: number = PAGINATION_TAKE
  paginationSkip: number = 0

  filters: PlatformConfigFilter[][] = defaultItemFilterAdvertisement
  valueFilters: TSearchParamsJson = {}
  isFiltersOpen: boolean = false

  sortListOrderBy: PlatformConfigSort[] = defaultItemsSortOrderBy
  sortSelectedOrderBy: PlatformConfigSort = defaultItemsSortOrderBy[0]

  sortListItem: PlatformConfigSort[] = defaultItemSort
  sortSelectedItem: PlatformConfigSort = defaultItemSort[0]

  setDealerStatus = (status: '' | 'online' | 'game') => (this.dealerStatus = status)

  // Установить тип объявлений
  setAdvertisementType = (type: TypeAdvertisement) => (this.type = type)

  // Установить список объявлений
  setAdvertisementList = (data: IGetResAdvertisementList) => (this.advertisementItems = data)

  // Установка списка полей сортировки
  setSortsList = (items: PlatformConfigSort[]) => {
    if (items.length > 0) {
      this.sortListItem = items
      this.sortSelectedItem = items[0]
    } else {
      this.sortListItem = defaultItemSort
      this.sortSelectedItem = defaultItemSort[0]
    }
  }

  // Установка выбранного поля сортировки
  setSortItem = (value: PlatformConfigSort) => (this.sortSelectedItem = value)

  // Установка выбранного типа сортировки (asc/desc)
  setSortItemOrderBy = (value: PlatformConfigSort) => (this.sortSelectedOrderBy = value)

  // Установить список фильтров
  setFiltersList = (filters: PlatformConfigFilter[][]) => (this.filters = filters)

  // Установить значения фильтра
  setFilterValue = (key: string, value: TSearchParamsJsonTypesValue) => {
    this.valueFilters[key] ??= value

    switch (value.type) {
      case 'range': {
        const exValueFilters = this.valueFilters[key] as TSearchParamsJsonValueTypeRange
        if (exValueFilters.data.max !== value.data.max) {
          exValueFilters.data.max = value.data.max
        }
        if (exValueFilters.data.min !== value.data.min) {
          exValueFilters.data.min = value.data.min
        }
        break
      }
      case 'variant': {
        this.valueFilters[key] = value
        break
      }
      case 'value': {
        this.valueFilters[key] = value
      }
    }
  }

  // Обнулить значения фильтров
  removeFiltersValue = () => (this.valueFilters = {})

  // Открыть окно фильтров
  setFilterOpen = (type: boolean) => (this.isFiltersOpen = type)

  onChangeInputTextBySearch = (text: string) => (this.textBySearch = text)

  // Установить текст для глобального поиска
  setTextBySearch = (text: string): void => {
    this.textBySearch = text

    if (text.length > 0) {
      this.searchWords.unshift(text)
      this.searchWords = this.searchWords.slice(0, MAX_COUNT_SEARCH_WORD)
      localStorage.setItem(ELocalStorage.searchWords, JSON.stringify(this.searchWords))
    }
  }

  // Установить id категории для поиска
  setCategoryIdBySearch = (categoryId: string | undefined) => (this.categoryIdBySearch = categoryId)

  // Установить пагинацию
  setPaginationSkip = (skip: number) => (this.paginationSkip = skip)
}

const advertisementsShopBarStore = new AdvertisementsShopBarStore()

export { advertisementsShopBarStore }
