export const colors: ColorPalette = {
  white: '#FFFFFF',
  grey: {
    main: '#2A3152',
    scarlet: '#7E88BB',
    dark: '#1A1D3C'
  },
  green: {
    main: '#8EF84F',
    scarlet: '#25333D',
    dark: '#293423',
    veryDark: '#60AE32',
    light: '#CFFB58'
  },
  purple: {
    main: '#7B4CFF',
    scarlet: '#262357',
    dark: '#262357',
    veryDark: '#141631'
  },
  azure: {
    main: '#75F2EB',
    scarlet: '#324a61',
    dark: '#23324c'
  },
  blue: '#504CFF',
  yellowColor: '#F8D55A',
  red: '#F85A5A'
}

type ColorPalette = {
  white: string
  grey: {
    main: string
    scarlet: string
    dark: string
  }
  green: {
    main: string
    scarlet: string
    dark: string
    veryDark: string
    light: string
  }
  purple: {
    main: string
    scarlet: string
    dark: string
    veryDark: string
  }
  azure: {
    main: string
    scarlet: string
    dark: string
  }
  blue: string
  yellowColor: string
  red: string
}
