import PageWrapper from 'components/ui/wrappers/PageWrapper/PageWrapper'
import { useLayoutEffect, useState } from 'react'
import AuthModal from 'components/ui/modals/AuthModal/AuthModal'
import { useSearchParams } from 'react-router-dom'
import DiscordError from '../DiscordError/DiscordError'
import { LoaderPage } from 'components/ui/loaders/LoaderPage'
import { pathLinkedAccountBindTelegram } from '../../../api/linked-account/linked-account'

const titleText = 'Привязка аккаунта'

const TelegramCallbackBind = () => {
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')

  const [errorText, errorTextSet] = useState<string>('Неизвестная ошибка. Пожалуйста, обратитесь к администратору!')
  const [isAuthModal, setAuthModal] = useState<boolean>(false)

  const [isLoading, setLoading] = useState<boolean>(true)

  useLayoutEffect(() => {
    ;(async () => {
      if (!token) {
        errorTextSet('Ошибка. Обязательный параметр "token" отсутствует!')
        setLoading(false)
        return
      }

      const result = await pathLinkedAccountBindTelegram(token)
      if (result.error) {
        errorTextSet(result.error.message)
      } else {
          errorTextSet('Вы успешно привязали аккаунт!')
      }

      setLoading(false)
    })()
  }, [token])

  return (
    <PageWrapper>
      {!isLoading && (
        <DiscordError
          onFormClick={setAuthModal.bind(this, true)}
          onDiscordClick={setAuthModal.bind(this, true)}
          headerText={titleText}
          subText={errorText}
        />
      )}

      {isAuthModal && <AuthModal isOpen={isAuthModal} onClose={setAuthModal.bind(this, false)} />}

      {isLoading && <LoaderPage containerStyle={{ background: 'transparent' }} />}
    </PageWrapper>
  )
}

export { TelegramCallbackBind }
