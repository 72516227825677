import styles from './UserStatusLockButton.module.css'

import LockIcon from '../../../../icons/lock-icon.svg'
import ProBage from '../../../../icons/userStatus/pro_badge.svg'
import { useState } from 'react'
import { SubscriptionPaymentModalsContainer } from 'components/ui/modals/SubscriptionPaymentModalsContainer'

const UserStatusLockButton = () => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleClick = (e: any) => {
    setOpen(true)
    e.stopPropagation()
  }

  return (
    <>
      <div className={styles.lockButton} onClick={handleClick}>
        <img className={styles.lockButtonIcon} src={LockIcon} alt="lock"></img>
        <p className={styles.lockButtonText}>Онлайн в игре</p>
        <img className={styles.lockButtonBage} src={ProBage} alt="pro bage"></img>
      </div>

      {isOpen && <SubscriptionPaymentModalsContainer onClose={setOpen.bind(this, false)} />}
    </>
  )
}

export { UserStatusLockButton }
