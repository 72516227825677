import styles from './App.module.css'

import AlertErrorWrapper from 'components/ui/errors/AlertErrorWrapper/AlertErrorWrapper'
import errorAlerts from 'store/errorAlertsStore'
import AlertError from 'components/ui/errors/AlertError/AlertError'
import { observer } from 'mobx-react-lite'
import Footer from 'components/ui/Footer/Footer'
import PageRouters from 'PageRouters/PageRouters'
import { useLayoutEffect, useState } from 'react'
import { FooterDisable } from 'components/ui/FooterDisable'
import { LoaderPage } from 'components/ui/loaders/LoaderPage'
import { userLoading } from 'api/userLoading'
import Header from 'components/ui/headers/Header/Header'
import { useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { FooterMobile } from 'mobileComponents/ui/FooterMobile'
import { HeaderMobile } from 'mobileComponents/ui/HeaderMobile'
import { FooterDisableMobile } from 'components/ui/FooterDisableMobile'

const App = observer(() => {
  const location = useLocation()
  const [isLoading, setLoading] = useState<boolean>(true)

  useLayoutEffect(() => {
    userLoading().then(() => {
      setLoading(false)
    })
  }, [])

  if (isLoading) {
    return <LoaderPage />
  }

  const getHeaderType = () => {
    if (!isMobile) {
      if (location.pathname !== '/' && location.pathname !== '/503') {
        return <Header />
      }
    } else {
      if (isMobile && location.pathname !== '/503') {
        return <HeaderMobile />
      } else return <></>
    }
  }

  const getFooterType = () => {
    const chatPathRegex = /^\/chat\/.+$/

    if (!isMobile) {
      if (location.pathname === '/503') {
        return <FooterDisable />
      }
      if (chatPathRegex.test(location.pathname)) {
        return null
      } else return <Footer />
    }
    if (isMobile) {
      if (location.pathname === '/503') {
        return <FooterDisableMobile />
      } else return <FooterMobile />
    }
  }

  const showHeader = getHeaderType()
  const showFooter = getFooterType()

  return (
    <div className={`${styles.app} ${location.pathname === '/' && styles.home}`}>
      {showHeader}

      <PageRouters />

      {showFooter}

      <AlertErrorWrapper>
        {errorAlerts.array.map((error, index) => (
          <AlertError key={index} error={error} />
        ))}
      </AlertErrorWrapper>
    </div>
  )
})

export default App
