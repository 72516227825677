import { DivFlexColumn } from 'components/styledComponents/div/div'
import styles from './HomeActivitySectionText.module.css'
import { Text18White, Text24White, Text48Grey } from 'components/styledComponents/text/textStyles'
import { Ellipse } from 'components/ui/Ellipse'

const HomeActivitySectionText = () => {
  return (
    <DivFlexColumn style={styles.textContainer}>
      <Text24White style={styles.headerNumber}>06</Text24White>
      <Text48Grey style={styles.headerText}>Активность</Text48Grey>
      <ul className={styles.itemsList}>
        <li className={styles.item}>
          <Ellipse />
          <Text18White>Игрок имеет три статуса: онлайн, офлайн, в игре</Text18White>
        </li>
        <li className={styles.item}>
          <Ellipse />
          <Text18White>На основе статуса вы можете оперативно провести сделку</Text18White>
        </li>
      </ul>
    </DivFlexColumn>
  )
}

export { HomeActivitySectionText }
