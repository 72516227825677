import styles from './DinamicModal.module.css'

import { ModalWrapper } from 'components/ui/wrappers/ModalWrapper'
import { ReactNode } from 'react'

type DinamicModalProps = {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  width?: number
  marginTop?: number
  isPortal?: boolean
}

const DinamicModal = ({ isOpen, onClose, children, width = 640, marginTop = 48, isPortal }: DinamicModalProps) => {
  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} isPortal={isPortal} marginTop={marginTop}>
      <div className={styles.modal} style={{ width: width }}>
        {children}
      </div>
    </ModalWrapper>
  )
}

export { DinamicModal }
