import styles from './HomeAdsSection.module.css'

import { HomeAbsProuctStatusCard } from './HomeAbsProuctStatusCard'
import { HomeAbsProductFilterContainer } from './HomeAbsProductFilterContainer'
import { BackFilter } from '../../components/BackFilter'
import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text18White, Text24White, Text48Grey } from 'components/styledComponents/text/textStyles'
import { Ellipse } from '../../../../ui/Ellipse'
import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'

import Back from './back.svg'

const HomeAdsSection = () => {
  return (
    <section className={styles.container}>
      <BackFilter backContainer={Back} style={{ top: '225px' }} />

      <HomeAbsProuctStatusCard />

      <HomeAbsProductFilterContainer />

      <DivFlexColumn style={styles.textContainer}>
        <Text24White style={styles.headerNumber}>02</Text24White>
        <Text48Grey style={styles.headerText}>Объявления</Text48Grey>
        <ul className={styles.itemsList}>
          <li className={styles.item}>
            <Ellipse />
            <Text18White>Все объявления разбиты по категориям</Text18White>
          </li>
          <li className={styles.item}>
            <Ellipse />
            <Text18White>Можно разместить предмет не только на продажу, но и заявку на покупку</Text18White>
          </li>
        </ul>
      </DivFlexColumn>

      <PurpleNeonButton text="Создать объявление" cssStyle={styles.button} />
    </section>
  )
}

export { HomeAdsSection }
