import { Text16White } from 'components/styledComponents/text/textStyles'
import styles from './ProfileSettingsBarButton.module.css'

type ProfileSettingsBarButtonProps = {
  onClick: () => void
  isActive: boolean
  text: string
  src: string
}

const ProfileSettingsBarButton = ({ onClick, isActive, text, src }: ProfileSettingsBarButtonProps) => {
  return (
    <button className={`${styles.button} ${isActive && styles.active}`} onClick={onClick}>
      <img src={src} alt={text} className={`${styles.icon} ${isActive && styles.active}`} />
      <Text16White>{text}</Text16White>
    </button>
  )
}

export { ProfileSettingsBarButton }
