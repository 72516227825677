import { DivFlexRow } from 'components/styledComponents/div/div'
import styles from './SaleCheckbox.module.css'

import CkeckedIcon from '../../../icons/button/radio_checked.svg'
import unCheckedIcon from '../../../icons/button/radio_un-ckecked.svg'
import { Text16White } from 'components/styledComponents/text/textStyles'

type SaleCheckboxProps = {
  text: string
  percent: number
  isActive: boolean
  onClick: () => void
}

const SaleCheckbox = ({ text, percent, isActive, onClick }: SaleCheckboxProps) => {
  return (
    <DivFlexRow style={`${styles.container} ${isActive && styles.active}`} onClick={onClick}>
      <img src={isActive ? CkeckedIcon : unCheckedIcon} alt="checkbox" className={styles.checkIcon} />
      <Text16White style={styles.text}>{text}</Text16White>
      {!!percent && <p className={styles.percent}>{`-${percent}%`}</p>}
    </DivFlexRow>
  )
}

export { SaleCheckbox }
