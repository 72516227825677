import CheckButton from '../buttons/CheckButton/CheckButton'
import styles from './AcceptRulesBlock.module.css'
import { CSSProperties } from 'react'

type AcceptRulesBlockProps = {
  anyStyles?: CSSProperties
  isCheked: () => void
  text: string
  rules: string
  link?: string
  cssCommonText?: string
  cssTextContainer?: string
  cssCheckButtonStyle?: string
}

const AcceptRulesBlock = ({
  anyStyles,
  isCheked,
  text,
  rules,
  link,
  cssCommonText,
  cssTextContainer,
  cssCheckButtonStyle,
}: AcceptRulesBlockProps) => {
  return (
    <div className={styles.container} style={{ ...anyStyles }}>
      <CheckButton isCheked={isCheked} style={cssCheckButtonStyle} />
      <div className={`${styles.textContainer} ${cssTextContainer}`}>
        <p className={`${styles.commonText} ${cssCommonText}`}>Нажимая "{text}" я соглашаюсь</p>
        <div className={styles.underlinedText}>
            <span className={styles.text}><a className={'link'} target={'_blank'} href={link}>{`с ${rules}`}</a></span>
        </div>
      </div>
    </div>
  )
}

export default AcceptRulesBlock
