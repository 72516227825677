import { action, makeAutoObservable, observable } from 'mobx'

export type AdvertisementCatalogSearch = {
  id: string
  name: string
  price: number
  catalogCurrency: string
  advertisementCurrency: string
  sourceCategory: string
  maxCountSale: number
  isLoadImages: number
  images: AdvertisementCatalogSearchImages[]
}

type AdvertisementCatalogSearchImages = {
  id: string
  name: string
}

class AdvertisementCreateStore {
  catalogSearch: AdvertisementCatalogSearch[] = []

  constructor() {
    makeAutoObservable(this, {
      catalogSearch: observable,
      setCatalogSearch: action.bound
    })
  }

  setCatalogSearch(result: AdvertisementCatalogSearch[]) {
    this.catalogSearch = result
  }
}

const advertisementCreateStore = new AdvertisementCreateStore()

export { advertisementCreateStore }
