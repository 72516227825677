import styles from './HomePriceChartTextContainer.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text18White, Text24White, Text48Grey } from 'components/styledComponents/text/textStyles'
import { Ellipse } from 'components/ui/Ellipse'

const HomePriceChartTextContainer = () => {
  return (
    <DivFlexColumn style={styles.textContainer}>
      <Text24White style={styles.headerNumber}>05</Text24White>
      <Text48Grey style={styles.headerText}>График цен</Text48Grey>
      <ul className={styles.itemsList}>
        <li className={styles.item}>
          <Ellipse />
          <Text18White>
            По каждому предмету можно посмотреть среднюю стоимость продажи в любой отрезок времени
          </Text18White>
        </li>
      </ul>
    </DivFlexColumn>
  )
}

export { HomePriceChartTextContainer }
