import styles from './HomePriceChartProductCard.module.css'

import { DarkGreyContainer } from 'components/pages/Home/components/DarkGreyContainer'
import { ImgBase } from 'components/styledComponents/img/img'
import { DivFlexRow } from 'components/styledComponents/div/div'
import { Text14Green, Text16Green, Text18White, Text24Green } from 'components/styledComponents/text/textStyles'

import CardIcon from './dinka.png'
import LoaderIcon from './loader-icon.svg'

const itemName = 'Dinka Civil 2019'
const itemPrice = '900.000'

const HomePriceChartProductCard = () => {
  return (
    <DarkGreyContainer style={styles.container}>
      <ImgBase img={CardIcon} style={styles.photo} />

      <DivFlexRow style={styles.loadingContainer}>
        <Text14Green>Сбор данных</Text14Green>
        <ImgBase img={LoaderIcon} />
      </DivFlexRow>

      <Text18White style={styles.itemName}>{itemName}</Text18White>

      <DivFlexRow style={styles.itemPrice}>
        <Text24Green>{itemPrice}</Text24Green>
        <Text16Green>$</Text16Green>
      </DivFlexRow>
    </DarkGreyContainer>
  )
}

export { HomePriceChartProductCard }
