import styles from './Header.module.css'

import { ButtonTexts } from 'constants/buttonTexts'
import { BrowserRouters, NavigateLinks } from 'PageRouters'
import { userInfoStore } from 'store/userInfoStore'
import { observer } from 'mobx-react-lite'
import HeaderLogo from 'components/ui/HeaderLogo/HeaderLogo'
import UserHeaderButton from 'components/ui/UserHeaderButton/UserHeaderButton'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import VerticalDivider from 'components/ui/dividers/VerticalDivider/VerticalDivider'
import { useState } from 'react'
import AuthModal from 'components/ui/modals/AuthModal/AuthModal'

import Megaphone from '../../../icons/button/megaphone.svg'
import Envelop from '../../../icons/button/envelop.svg'
import HomeIcon from '../../../icons/button/home.svg'
import Category from '../../../icons/button/category.svg'
import VoiceSms from '../../../icons/button/voice_sms.svg'
import AddDocument from '../../../icons/button/add_document.svg'
import LogIn from '../../../icons/button/log-out_white.svg'

const Header = observer(() => {
  const { isAuth, userData: { countChatNotRead } } = userInfoStore

  const [isAuthModal, setAuthModal] = useState<boolean>(false)

  return (
    <header className={styles.header}>
      <div className={`${isAuth ? styles.container : styles.notAuthContainer}`}>
        <HeaderLogo route={BrowserRouters.Home} />

        {isAuth && (
          <div className={styles.buttonsBlock}>
            <UserHeaderButton />
            <OvalButton
              text={ButtonTexts.AdvertisementMy}
              icon={Megaphone}
              router={BrowserRouters.AdvertisementMy}
            />
            <OvalButton
              text={ButtonTexts.Chat}
              icon={Envelop}
              router={BrowserRouters.Chats}
              textMarker={countChatNotRead > 0 ? String(countChatNotRead) : ''}
            />
          </div>
        )}

        {isAuth && <VerticalDivider style={{ height: '22px' }} />}

        <div className={`${isAuth ? styles.buttonsBlock : styles.notAuthButtonsBlock}`}>
          <OvalButton icon={HomeIcon} containerStyle={{ background: '#2A3152' }} router={BrowserRouters.Home} />
          <OvalButton
            text={ButtonTexts.Catalog}
            icon={Category}
            router={NavigateLinks.Catalog}
            containerStyle={{ background: '#303423' }}
          />
          <OvalButton
            text={ButtonTexts.Advertisement}
            icon={VoiceSms}
            router={NavigateLinks.Advertisement}
            containerStyle={{ background: '#2F4847' }}
          />
        </div>

        {isAuth && <VerticalDivider style={{ height: '22px' }} />}

        {isAuth ? (
          <OvalButton
            text={ButtonTexts.AdvertisementCreate}
            icon={AddDocument}
            router={BrowserRouters.AdvertisementCreate}
            containerStyle={{
              height: '50px',
              border: '4px solid #262357',
              background: '#7B4CFF'
            }}
          />
        ) : (
          <OvalButton
            text={ButtonTexts.LogIn}
            icon={LogIn}
            containerStyle={{
              height: '54px',
              width: '111px',
              border: '4px solid rgb(38, 35, 87)',
              background: 'rgb(123, 76, 255)',
              margin: isAuth ? 'auto auto auto 59px' : 'auto 35px auto auto'
            }}
            onClick={setAuthModal.bind(this, true)}
          />
        )}
      </div>

      <AuthModal isOpen={isAuthModal} onClose={setAuthModal.bind(this, false)} />
    </header>
  )
})

export default Header
