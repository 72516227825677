import api from 'api/axios/axiosInstance'
import axios from 'axios'
import { MessageType } from 'components/ui/errors/AlertError'
import errorAlerts from 'store/errorAlertsStore'

export type ReferralTransactionDataType = {
  id: string
  name: string
  description: string
  reasonCanceled: null
  status: ReferralTransactionStatus
  amount: number
  createdAt: string
}

export enum ReferralTransactionStatus {
  Pending = 'pending',
  Succeeded = 'succeeded',
  Canceled = 'canceled'
}

const paymentSubscriptionPay = async (subscriptionId: string, code?: string) => {
  const promoCode = code ? code : ''
  try {
    const response: { transactionId: string; confirmationUrl: string, subscriptionAdded: boolean } = await api.post(
      '/api/payment/subscription/pay',
      { subscriptionId, promoCode }
    )
    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorAlerts.addError({
          errorType: MessageType.Error,
          errorText: error.response.data.message
        })
      }
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    }
    return false
  }
}

const getPaymentReferralTransactionData = async (transactionId: string) => {
  try {
    const response: ReferralTransactionDataType = await api.get(`/api/payment/transactions/${transactionId}/item`)
    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorAlerts.addError({
          errorType: MessageType.Error,
          errorText: error.response.data.message
        })
      }
    } else {
      errorAlerts.addError({ errorType: MessageType.Error, errorText: '' })
    }
  }
}

export { paymentSubscriptionPay, getPaymentReferralTransactionData }
