import { Text16White, Text14Grey } from 'components/styledComponents/text/textStyles'
import styles from './ShopBarProductDescription.module.css'

type ShopBarProductDescriptionProps = {
  description: string
  gap?: number
  mt: number
}

const ShopBarProductDescription = ({ description, gap = 12, mt }: ShopBarProductDescriptionProps) => {
  return (
    <div
      className={styles.description}
      style={{
        gap: gap,
        marginTop: mt
      }}
    >
      <Text16White>Подробнее о предмете</Text16White>
      <Text14Grey>{description}</Text14Grey>
    </div>
  )
}

export { ShopBarProductDescription }
