import { TUserStatus } from '../common/types'

export const userStatusToText = (status: TUserStatus): string => {
  switch (status) {
    case 'game':
      return 'Онлайн в игре'
    case 'online':
      return 'Онлайн на сайте'
    case 'offline':
      return 'Оффлайн'
  }
  return 'Не в сети'
}
