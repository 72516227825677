export const FooterLinks = {
  telegram: process.env.REACT_APP_TELEGRAM_CHANNEL ?? '',
  vk: process.env.REACT_APP_VK_CHANNEL ?? '',
  discord: process.env.REACT_APP_DISCORD_CHANNEL ?? ''
}

export enum FooterButtonsText {
  Help = 'Нужна помощь? Напишите в техподдержку',
  HelpSmall = 'Нужна помощь?',
  Copyright = 'Copyright © RP.MARKET. 2024'
}
