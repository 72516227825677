import styles from './CopyLinkButton.module.css'

import { useState } from 'react'

import CopyIcon from '../../../../components/icons/copy-purple_icon.svg'
import CheckIcon from '../../../../components/icons/check_green.svg'

type CopyLinkButtonProps = {
  text: string
  fontSize?: number
  isDisable?: boolean
  cssContainerStyles?: string
  iconSize?: number
  link: string
}

const CopyLinkButton = ({
  text,
  fontSize,
  isDisable,
  cssContainerStyles,
  iconSize,
  link,
}: CopyLinkButtonProps) => {
  const [isActive, setActive] = useState<boolean>(false)
  const activeText = 'Ссылка скопирована'

  const handleCopyLink = () => {
    if (!isDisable) {
      const cardLink = `${location.protocol}//${window.location.host}/${link}`

      navigator.clipboard
        .writeText(cardLink)
        .then(() => {
          console.log('Текст скопирован в буфер обмена: ', cardLink)
          setActive(true)
        })
        .catch((err) => {
          console.error('Ошибка при копировании текста: ', err)
          setActive(false)
        })
    }
  }

  const reloadComponent = () => {
    if (isActive) {
      setTimeout(() => {
        setActive(false)
      }, 2000)
    }
  }

  return (
    <div
      className={`
      ${styles.button}
      ${isActive && styles.active}
      ${isDisable && styles.disable}
      ${cssContainerStyles}
      `}
      onClick={handleCopyLink}
      onMouseLeave={reloadComponent}
    >
      <p style={{ fontSize: fontSize }} className={`${isDisable && styles.disable}`}>
        {isActive ? activeText : text}
      </p>
      <img
        src={isActive ? CheckIcon : CopyIcon}
        alt="copy"
        className={`${isDisable && styles.imageDisable}`}
        style={{ width: iconSize, height: iconSize }}
      />
    </div>
  )
}

export { CopyLinkButton }
