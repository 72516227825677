import styles from './HomeFiltersSection.module.css'

import { BackFilter } from '../../components/BackFilter'
import { HomeFilterContainer } from './HomeFilterContainer'
import { HomeFiltersShopItemsContainer } from './HomeFiltersShopItemsContainer'
import { HomeFiltersPriceFilterContainer } from './HomeFiltersPriceFilterContainer'
import { HomeFiltersItemTypeContainer } from './HomeFiltersItemTypeContainer'
import { HomeFiltersMainTextContainer } from './HomeFiltersMainTextContainer'
import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'

import Back from './icons/filters-back.svg'

const HomeFiltersSection = () => {
  return (
    <section className={styles.container}>
      <BackFilter backContainer={Back} cssStyle={styles.back} />

      <div style={{ zIndex: 2, position: 'relative', pointerEvents: 'none' }}>
        <HomeFilterContainer />

        <HomeFiltersShopItemsContainer />

        <HomeFiltersPriceFilterContainer />

        <HomeFiltersItemTypeContainer />

        <HomeFiltersMainTextContainer />
      </div>

      <PurpleNeonButton text="Регистрация" cssStyle={styles.button} />
    </section>
  )
}

export { HomeFiltersSection }
