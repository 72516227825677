import styles from './HomeWelcomeSectionMobile.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { HomeWelcomeSectionTextMobile } from './HomeWelcomeSectionTextMobile'
import { HomeWelcomeSectionCardMobile } from './HomeWelcomeSectionCardMobile'
import { BackFilter } from 'components/pages/Home/components/BackFilter'

import Back from './back.svg'

const HomeWelcomeSectionMobile = () => {
  return (
    <DivFlexColumn style={styles.section}>
      <DivFlexColumn style={styles.content}>
        <HomeWelcomeSectionTextMobile />
        <HomeWelcomeSectionCardMobile />
      </DivFlexColumn>

      <BackFilter backContainer={Back} cssStyle={styles.back} />
    </DivFlexColumn>
  )
}

export { HomeWelcomeSectionMobile }
