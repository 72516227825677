import styles from './DiscordError.module.css'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import Icon from '../../icons/social/discord_404_purple.svg'
import Discord from '../../icons/social/discord_blue.svg'

type DiscordErrorProps = {
  onFormClick: () => void
  onDiscordClick: () => void
  headerText: string
  subText: string
  formText?: string
  discordText?: string
}

const DiscordError = ({
  onFormClick,
  onDiscordClick,
  headerText,
  subText,
  formText,
  discordText
}: DiscordErrorProps) => {
  return (
    <div className={styles.container}>
      <img src={Icon} alt="error"></img>
      <p className={styles.errorText}>{headerText}</p>
      <p className={styles.text}>{subText}</p>
      <div className={styles.buttonsContainer}>
        {formText && (
          <OvalButton text={formText} onClick={onFormClick} containerStyle={buttonAuthStyle} fontSize={16} />
        )}

        {discordText && (
          <OvalButton
            text={discordText}
            icon={Discord}
            onClick={onDiscordClick}
            isSwapContent
            containerStyle={buttonDiscordStyle}
            fontSize={16}
          />
        )}
      </div>
    </div>
  )
}

export default DiscordError

const buttonAuthStyle = {
  padding: '0 24px',
  backgroundColor: '#7B4CFF',
  borderRadius: '12px',
  height: '56px'
}

const buttonDiscordStyle = {
  padding: '0 24px',
  backgroundColor: '#2A3152',
  borderRadius: '12px',
  height: '56px'
}
