import { useEffect, useState } from 'react'

const useMoneyInputValidationHook = (value: string): { isValid: boolean; errorText: string } => {
  const [isValid, setIsValid] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string>('')

  useEffect(() => {
    if (value !== '') {
      const numericValue = value.replace(/\D/g, '')

      if (value !== numericValue) {
        setIsValid(false)
        setErrorText('Введите только цифры')
      } else if (value.length < 2) {
        setIsValid(false)
        setErrorText('Минимальная сумма должна быть двухзначной')
      } else {
        setIsValid(true)
        setErrorText('')
      }
    }
  }, [value])

  return { isValid, errorText }
}

export default useMoneyInputValidationHook
