import styles from './AdvertisementCreateData.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import VerticalDivider from 'components/ui/dividers/VerticalDivider/VerticalDivider'

import { AdvertisementCreateDataMain } from '../AdvertisementCreateDataMain'
import { AdvertisementCreateDataAddPhotoBlock } from '../AdvertisementCreateDataAddPhotoBlock'
import { AdvertisementCatalogSearch } from 'store/advertisement/advertisementCreateStore'
import { AdvertisementCreateForm } from 'api/advetrisement/create/advetrisementCreate'
import { RefObject } from 'react'

type AdvertisementCreateDataProps = {
  choseItem: AdvertisementCatalogSearch
  submitForm: AdvertisementCreateForm
  handleOnLoadImage: (name: string, base64: string) => void
  setSubmitForm: React.Dispatch<React.SetStateAction<AdvertisementCreateForm>>
  isError: boolean
  scrollToPrice: RefObject<HTMLDivElement>
  scrollToPhoto: RefObject<HTMLDivElement>
}

const AdvertisementCreateData = ({
  choseItem,
  submitForm,
  setSubmitForm,
  isError,
  handleOnLoadImage,
  scrollToPrice,
  scrollToPhoto
}: AdvertisementCreateDataProps) => {
  return (
    <DivFlexColumn style={styles.container}>
      <DivFlexColumn style={styles.body}>
        <AdvertisementCreateDataMain
          choseItemName={choseItem.name}
          choseItemSub={choseItem.sourceCategory}
          choseItemImages={choseItem.images}
          choseItemCatalogPrice={choseItem.price}
          choseItemCatalogCurrency={choseItem.catalogCurrency}
          choseItemAdvertisementCurrency={choseItem.advertisementCurrency}
          isCounter={choseItem.maxCountSale > 1}
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
          isError={!isError}
          scrollToPrice={scrollToPrice}
        />

        {choseItem.isLoadImages && (
          <>
            <VerticalDivider cssStyle={styles.bodyDivider} />
            <AdvertisementCreateDataAddPhotoBlock scrollToPhoto={scrollToPhoto} handleOnLoadImage={handleOnLoadImage} />
            <VerticalDivider cssStyle={styles.bodyDivider} />
          </>
        )}
      </DivFlexColumn>
    </DivFlexColumn>
  )
}

export { AdvertisementCreateData }
