import styles from './VerticalDivider.module.css'
import { CSSProperties } from 'react'

type VerticalDividerProps = {
  style?: CSSProperties
  cssStyle?: string
}

const VerticalDivider = ({ style, cssStyle }: VerticalDividerProps) => {
  return <div className={`${styles.divider} ${cssStyle}`} style={{ ...style }}></div>
}

export default VerticalDivider
