import styles from './HomeSubscriptionSection.module.css'

import { buttonsArray } from './buttonsArray'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'
import { Text48Purple, Text48White } from 'components/styledComponents/text/textStyles'

import Arrow from '../../../../icons/long_bootm_arrow.svg'

const headerText = 'RP MARKET - первая целевая площадка для торговли на серверах GTA5RP.COM'
const subText = 'В 10+ категориях - более 12к+ позиций'

const HomeSubscriptionSection = () => {
  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTextContainer}>
          <Text48White style={styles.headerText}>{headerText}</Text48White>
          <Text48Purple style={styles.headerSub}>{subText}</Text48Purple>
        </div>
        <img className={styles.arrow} src={Arrow} alt="icon" />
      </div>

      <div className={styles.filtersContainer}>
        {buttonsArray.map((button, index) => {
          const { text, icon } = button
          return (
            <OvalButton
              key={index}
              text={text}
              icon={icon}
              containerStyle={buttonStyles.containerStyle}
              iconStyle={buttonStyles.iconStyle}
              textStyle={buttonStyles.textStyle}
            />
          )
        })}
      </div>
    </section>
  )
}

export { HomeSubscriptionSection }

const buttonStyles = {
  containerStyle: {
    height: '53px',
    borderRadius: '10px',
    padding: '16px 32px',
    gap: '16px',
    cursor: 'default'
  },
  iconStyle: {
    width: '24px',
    height: '24px'
  },
  textStyle: {
    letterSpacing: '1px'
  }
}
