import styles from './UserAvatar.module.css'

import AvatarIcon from '../../icons/facke/avatar.svg'
import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'
import { CSSProperties } from 'react'

type UserAvatarProps = {
  containerStyle?: CSSProperties
}

const UserAvatar = observer(({ containerStyle }: UserAvatarProps) => {
  const { isVip, userAvatar } = userInfoStore
  const { activeStatus } = userInfoStore.userData

  const statusClassName = `${styles.status} ${styles[activeStatus]}`

  return (
    <div className={styles.container} style={{ ...containerStyle }}>
      <img
        className={`${styles.avatar} ${isVip && styles.active}`}
        src={userAvatar ? userAvatar : AvatarIcon}
        alt="avatar"
      ></img>
      <div className={statusClassName}></div>
    </div>
  )
})

export { UserAvatar }
