import styles from './SubscriptionPaymentModalButtons.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { SubscriptionsInfoType } from 'store/subscriptionStore/subscriptionStore'
import { SaleCheckbox } from 'components/ui/checkboxes/SaleCheckbox'

type SubscriptionPaymentModalButtonsProps = {
  activeSub: SubscriptionsInfoType
  subButtons: SubscriptionsInfoType[]
  handleClickButton: (buttonData: SubscriptionsInfoType) => void
}

const SubscriptionPaymentModalButtons = ({
  activeSub,
  subButtons,
  handleClickButton
}: SubscriptionPaymentModalButtonsProps) => {
  return (
    <DivFlexRow style={styles.checkboxBlock}>
      {!!subButtons.length &&
        subButtons.map((item) => {
          const count = item.name.replace(/\D/g, '')
          const itemText = `${count} мес`

          return (
            <SaleCheckbox
              key={item.id}
              text={itemText}
              percent={item.discount}
              isActive={activeSub.id === item.id}
              onClick={handleClickButton.bind(this, item)}
            />
          )
        })}
    </DivFlexRow>
  )
}

export { SubscriptionPaymentModalButtons }
