import React, { useState } from 'react'
import styles from './CheckButton.module.css'

type CheckboxProps = {
  style?: string
  isCheked: () => void
}

const CheckButton = ({ style, isCheked }: CheckboxProps) => {
  const [checked, setChecked] = useState(true)

  const handleChange = () => {
    isCheked()
    setChecked(!checked)
  }

  return (
    <label className={`${styles.checkbox} ${style}`}>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <span className={styles.checkmark}></span>
    </label>
  )
}

export default CheckButton
