import styles from './HomePriceChartSection.module.css'

import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'
import { BackFilter } from '../../components/BackFilter'
import Back from './back-filter.svg'
import { HomePriceChartTextContainer } from './HomePriceChartTextContainer'
import { HomePriceChartChangesContainer } from './HomePriceChartChangesContainer'
import { HomePriceChartProductCard } from './HomePriceChartProductCard'

const HomePriceChartSection = () => {
  return (
    <section className={styles.section}>
      <BackFilter backContainer={Back} cssStyle={styles.back} />

      <div style={{ zIndex: 2, position: 'relative', pointerEvents: 'none' }}>
        <HomePriceChartTextContainer />

        <HomePriceChartChangesContainer />

        <HomePriceChartProductCard />
      </div>

      <PurpleNeonButton text="Начать торговать" cssStyle={styles.button} />
    </section>
  )
}

export { HomePriceChartSection }
