import styles from './HomeActivityUsersCard.module.css'

import { Text16White } from 'components/styledComponents/text/textStyles'
import HorizontalDivider from 'components/ui/dividers/GorizontalDivider/HorizontalDivider'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase, ImgRounded } from 'components/styledComponents/img/img'
import { DarkGreyContainer } from 'components/pages/Home/components/DarkGreyContainer'

import AvatarOne from './icons/avatar1.png'
import AvatarTwo from './icons/avatar2.png'
import AvatarThree from './icons/avatar3.png'
import ChatIcon from './icons/chat-icon.svg'
import CallIcon from './icons/call-icon.svg'
import { TradeStatus } from 'components/pages/Home/components/TradeStatus/TradeStatus'

const HomeActivityUsersCard = () => {
  return (
    <DarkGreyContainer style={styles.container}>
      <Text16White style={styles.header}>Продавцы</Text16White>

      <HorizontalDivider cssStyle={styles.divider} />

      <DivFlexColumn style={styles.usersList}>
        <DivFlexRow style={styles.userContainer}>
          <ImgRounded img={AvatarOne} style={styles.userAvatar} />

          <DivFlexColumn style={styles.userStatusContainer}>
            <Text16White>Vladislav G.</Text16White>
            <TradeStatus text="На сайте" />
          </DivFlexColumn>

          <DivFlexRow style={styles.userButtonsContainer}>
            <ImgBase img={CallIcon} style={styles.icon} />
            <ImgBase img={ChatIcon} style={styles.icon} />
          </DivFlexRow>
        </DivFlexRow>

        <DivFlexRow style={styles.userContainer}>
          <ImgRounded img={AvatarTwo} style={styles.userAvatar} />

          <DivFlexColumn style={styles.userStatusContainer}>
            <Text16White>Sergey B.</Text16White>
            <TradeStatus text="В игре" />
          </DivFlexColumn>

          <DivFlexRow style={styles.userButtonsContainer}>
            <ImgBase img={CallIcon} style={styles.icon} />
            <ImgBase img={ChatIcon} style={styles.icon} />
          </DivFlexRow>
        </DivFlexRow>

        <DivFlexRow style={styles.userContainer}>
          <ImgRounded img={AvatarThree} style={styles.userAvatar} />

          <DivFlexColumn style={styles.userStatusContainer}>
            <Text16White>Natasha A.</Text16White>
            <TradeStatus text="Оффлайн" isDisable />
          </DivFlexColumn>

          <DivFlexRow style={styles.userButtonsContainer}>
            <ImgBase img={CallIcon} style={styles.icon} />
            <ImgBase img={ChatIcon} style={styles.icon} />
          </DivFlexRow>
        </DivFlexRow>
      </DivFlexColumn>
    </DarkGreyContainer>
  )
}

export { HomeActivityUsersCard }
