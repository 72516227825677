import styles from './ChatCardMessage.module.css'

import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { Text12GreyRoboto, Text14Grey, Text16WhiteRoboto } from 'components/styledComponents/text/textStyles'
import { formatTimeDate } from '../../../../utils/dateFormated'

type ChatCardMessageProps = {
  isHover: boolean
  isNotReadMessage: boolean
  messageDate: string
  messageText: string
}

const ChatCardMessage = ({ isHover, isNotReadMessage, messageDate, messageText }: ChatCardMessageProps) => {
  if (!messageText) {
    return (
      <DivFlexColumn style={`${styles.messageBlock} ${isHover && styles.hover}`}>
        <Text14Grey>Нет сообщений</Text14Grey>
      </DivFlexColumn>
    )
  }

  return (
    <DivFlexColumn style={`${styles.messageBlock} ${isHover && styles.hover}`}>
      <Text12GreyRoboto style={styles.messageDate}>{formatTimeDate(messageDate)}</Text12GreyRoboto>

      <DivFlexRow style={styles.message}>
        {isNotReadMessage && <div className={styles.circle}></div>}
        <Text16WhiteRoboto style={styles.messageText}>{messageText}</Text16WhiteRoboto>
      </DivFlexRow>
    </DivFlexColumn>
  )
}

export { ChatCardMessage }
