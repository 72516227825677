import OvalButton from '../OvalButton/OvalButton'
import styles from './CardInCatalogButton.module.css'

import ArrowIcon from './arrow.svg'
import {NavigateLinks} from "../../../../PageRouters";

type CardInCatalogButtonProps = {
  id?: string
}

const CardInCatalogButton = ({ id }: CardInCatalogButtonProps) => {
  const handleClick = () => {
    const cardLink = `${NavigateLinks.CatalogCard}/${id}`
    window.open(`${window.location.origin}/${cardLink}`)
  }

  return (
    <OvalButton
      text="Предмет в каталоге"
      icon={ArrowIcon}
      cssContainerStyle={styles.button}
      fontSize={14}
      iconStyle={{ width: '20px', height: '20px' }}
      isSwapContent
      onClick={handleClick}
    />
  )
}

export { CardInCatalogButton }
