import styles from './ChatCard.module.css'

import { DivFlexRow, DivFlexColumn } from 'components/styledComponents/div/div'
import { Text14White } from 'components/styledComponents/text/textStyles'
import { ChatCardUserBlock } from '../ChatCardUserBlock'
import { ChatCardMessage } from '../ChatCardMessage'
import { useState } from 'react'
import { ChatCardProductData } from '../ChatCardProductData'
import { useLocation, useNavigate } from 'react-router-dom'
import { TUserStatus, TypeAdvertisement } from '../../../../common/types'
import { getUrlS3 } from '../../../../common/utils'
import { IChatListChatResponse } from '../../../../api/chat/type.response'
import { chatClientStore } from '../../../../store/chat/chat'

type ChatCardProps = {
  chat: IChatListChatResponse
}

const ChatCard = ({ chat }: ChatCardProps) => {
  const {
    advertisementImageId,
    advertisementServerName,
    advertisementName,
    advertisementSourceCategory,
    advertisementPrice,
    chatInterlocutor,
    chatLastMessage,
    chatId,
    chatIsNotRead
  } = chat

  const interlocutorAvatarUrl = chatInterlocutor?.avatarId ? getUrlS3(chatInterlocutor.avatarId) : undefined

  const navigate = useNavigate()
  const location = useLocation()

  const [isHover, setHover] = useState<boolean>(false)

  const getChatPage = () => {
    navigate(`${location.pathname}/${chatId}`)
  }

  return (
    <div
      className={styles.container}
      onMouseEnter={setHover.bind(this, true)}
      onMouseLeave={setHover.bind(this, false)}
      onClick={getChatPage}>
      <img className={styles.avatar} src={getUrlS3(advertisementImageId)} alt="avatar" />

      <DivFlexColumn style={styles.body}>
        <DivFlexRow style={styles.headerBlock}>
          <ChatCardProductData
            tradeType={'sale'}
            serverName={advertisementServerName}
            itemName={advertisementName}
            itemSourceCategory={advertisementSourceCategory}
            itemPrice={advertisementPrice}
          />

          <ChatCardUserBlock
            avatar={interlocutorAvatarUrl}
            nickname={chatInterlocutor.nickname}
            status={chatInterlocutor.activeStatus}
          />
        </DivFlexRow>

        <ChatCardMessage
          isNotReadMessage={chatIsNotRead}
          messageDate={chatLastMessage.createdAt}
          messageText={chatLastMessage.text}
          isHover={isHover}
        />

        {chatIsNotRead && <Text14White style={styles.newMessage}>Новые сообщения</Text14White>}
      </DivFlexColumn>
    </div>
  )
}

export { ChatCard }
