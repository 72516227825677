import styles from './VipBlockButton.module.css'

import { observer } from 'mobx-react-lite'
import { userInfoStore } from 'store/userInfoStore'
import { DivFlexRow } from 'components/styledComponents/div/div'
import { useState } from 'react'

import VipIcon from '../../icons/userStatus/pro_badge.svg'
import LockIcon from '../../icons/lock-icon.svg'
import { SubscriptionPaymentModalsContainer } from '../modals/SubscriptionPaymentModalsContainer'

type VipBlockButtonProps = {
  children: React.ReactNode
  isChildrenInside?: boolean
  childrenText?: string
  cssContainerStyles?: string
  buttonText?: string
  isBlocked?: boolean
}

const VipBlockButton = observer(
  ({
    children,
    isChildrenInside = false,
    childrenText = '',
    cssContainerStyles,
    buttonText,
    isBlocked = false
  }: VipBlockButtonProps) => {
    const { isVip } = userInfoStore

    const [isOpen, setOpen] = useState<boolean>(false)

    const buttonBuyText = buttonText ?? 'Доступно в подписке'
    const isActive = isBlocked ?? isVip

    const handleOpenModal = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if (!isOpen) {
        setOpen(true)
      }
    }

    if (isActive) {
      return (
        <div className={`${styles.container} ${cssContainerStyles}`} onClick={handleOpenModal}>
          <DivFlexRow style={`${styles.badgeBlock}`}>
            <img src={VipIcon} alt="vip" className={`${styles.icon}`} />
            <p className={`${styles.text}`}>{buttonBuyText}</p>
          </DivFlexRow>

          {!isChildrenInside && (
            <DivFlexRow style={`${styles.childrenBlock}`}>
              <img src={LockIcon} alt="vip" className={`${styles.lock}`} />
              <p className={`${styles.childrenText}`}>{childrenText}</p>
            </DivFlexRow>
          )}

          {isChildrenInside && children}

          {isOpen && <SubscriptionPaymentModalsContainer onClose={setOpen.bind(this, false)} />}
        </div>
      )
    } else {
      return <>{children}</>
    }
  }
)

export { VipBlockButton }
