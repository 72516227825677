import styles from './HomeFiltersMainTextContainer.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text18White, Text24White, Text48Grey } from 'components/styledComponents/text/textStyles'
import { Ellipse } from 'components/ui/Ellipse'

const HomeFiltersMainTextContainer = () => {
  return (
    <DivFlexColumn style={styles.textContainer}>
      <Text24White style={styles.headerNumber}>03</Text24White>
      <Text48Grey style={styles.headerText}>Фильтры</Text48Grey>
      <ul className={styles.itemsList}>
        <li className={styles.item}>
          <Ellipse />
          <Text18White>Множество фильтров по предметам на странице каталога и объявлений</Text18White>
        </li>
        <li className={styles.item}>
          <Ellipse />
          <Text18White>В категории “Одежда” существует фильтр по цвету</Text18White>
        </li>
      </ul>
    </DivFlexColumn>
  )
}

export { HomeFiltersMainTextContainer }
