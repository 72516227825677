import { DivFlexRow } from 'components/styledComponents/div/div'
import styles from './DinamicModalHeader.module.css'
import CrossIocn from '../../../../icons/cross_purple.svg'

type DinamicModalHeaderProps = {
  icon?: string
  text?: string
  fontSize?: number
  onClose: () => void
}

const DinamicModalHeader = ({ icon, text = '', fontSize = 32, onClose }: DinamicModalHeaderProps) => {
  return (
    <DivFlexRow style={styles.header}>
      {icon && <img className={styles.icon} src={icon} alt="modal icon" />}

      <p className={styles.text} style={{ fontSize: fontSize }}>
        {text}
      </p>

      <img className={styles.crossIcon} src={CrossIocn} alt="cross icon" onClick={onClose} />
    </DivFlexRow>
  )
}

export { DinamicModalHeader }
