import styles from './ShopBarHeaderDropdown.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import { useState } from 'react'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text14Grey, Text14White } from 'components/styledComponents/text/textStyles'
import { DropdownWrapper } from 'components/ui/wrappers/DropdownWrapper'
import { IPlatformCategory, IPlatformServer } from 'store/platformConfigStore/types'

import Frame from './icons/frame.svg'
import ArrowPurple from '../../../../components/icons/arrow_bottom_purple.svg'
import { observer } from 'mobx-react-lite'
import { userPlatformConfig } from 'store/platformConfigStore/platformConfigStore'

type ShopBarHeaderDropdownProps = {
  serversArray: IPlatformCategory[]
  cssContainerStyles?: string
  isScarlet?: boolean
  onChooseServer?: (serverId: string) => void
  isNotSaveServer?: boolean
}

const ShopBarHeaderDropdown = observer(
  ({
    serversArray,
    cssContainerStyles,
    isScarlet = false,
    onChooseServer,
    isNotSaveServer = false
  }: ShopBarHeaderDropdownProps) => {
    const { saveUserCurrentServer, currentServer } = userPlatformConfig

    const [text, setText] = useState<string>(currentServer.name)
    const [isOpen, setOpen] = useState<boolean>(false)

    const handleChangeServer = (server: IPlatformServer) => {
      setText(server.name)

      if (!isNotSaveServer) {
        saveUserCurrentServer(server)
        localStorage.setItem('server', JSON.stringify(server))
      }

      if (onChooseServer) {
        onChooseServer(server.id)
      }
    }

    return (
      <DropdownWrapper onClose={setOpen.bind(this, false)}>
        <DivFlexRow
          style={`${styles.button} ${isOpen && styles.active} ${isScarlet && styles.scarlet} ${cssContainerStyles}`}
          onClick={setOpen.bind(this, !isOpen)}>
          <ImgBase img={Frame} style={styles.frame} />

          <Text14White style={styles.text}>{text}</Text14White>

          <ImgBase img={ArrowPurple} style={styles.arrow} />

          {isOpen && (
            <div className={`${styles.dropContainer} ${isScarlet && styles.scarlet}`}>
              <ul className={styles.itemsSection}>
                {serversArray.map((server) => {
                  const { name, id } = server
                  return (
                    <li key={id} className={styles.item} onClick={handleChangeServer.bind(this, server)}>
                      <Text14Grey style={`${styles.itemText} ${text === name && styles.active}`}>{name}</Text14Grey>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </DivFlexRow>
      </DropdownWrapper>
    )
  }
)

export { ShopBarHeaderDropdown }
