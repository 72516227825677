import styles from './HeaderLogo.module.css'

import Logo from '../../icons/rp_market_logo_dark.svg'
import { Link } from 'react-router-dom'
import { CSSProperties } from 'react'

type HeaderLogoProps = {
  route?: string
  anyStyles?: CSSProperties
  cssStyles?: string
}

const HeaderLogo = ({ route, anyStyles, cssStyles }: HeaderLogoProps) => {
  if (!route) {
    return <img className={`${styles.logo} ${cssStyles}`} src={Logo} alt="logo" style={{ ...anyStyles }} />
  }

  return (
    <Link to={route}>
      <img className={`${styles.logo} ${cssStyles}`} src={Logo} alt="logo" style={{ ...anyStyles }} />
    </Link>
  )
}

export default HeaderLogo
