import styles from './ChatPageDialogueBlock.module.css'

import { DivFlexColumn } from 'components/styledComponents/div/div'
import { Text12Grey } from 'components/styledComponents/text/textStyles'
import { ChatPageUserMessage } from '../ChatPageUserMessage'
import { useEffect, useRef } from 'react'
import { IGetChatInfoInterlocutorResponse, IGetChatInfoMessagesResponse } from '../../../../api/chat/type.response'
import { observer } from 'mobx-react-lite'

type ChatPageDialogueBlockProps = {
  messages: IGetChatInfoMessagesResponse[]
  interlocutor: IGetChatInfoInterlocutorResponse
}

const ChatPageDialogueBlock = observer(({ messages, interlocutor }: ChatPageDialogueBlockProps) => {
  // для определения размеров окна чата
  const anyDivsHeight = 555
  const chatHeight = window.innerHeight - anyDivsHeight

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Устанавливаем скролл в нижнюю позицию после первого рендеринга
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [messages.length])

  return (
    <DivFlexColumn style={styles.container}>
      <Text12Grey style={styles.headerDate}>Сегодня</Text12Grey>

      <div ref={containerRef} className={styles.scroll} style={{ height: chatHeight }}>
        <DivFlexColumn style={styles.chat}>
          {messages.map((msg) => {
            return <ChatPageUserMessage key={String(msg.id)} message={msg} interlocutor={interlocutor} />
            /*if ('alertType' in msg) {
                        } else {
                            return <ChatPageUserMessage key={msg.id} msg={msg} />
                        }*/
          })}
        </DivFlexColumn>
      </div>
    </DivFlexColumn>
  )
})

export { ChatPageDialogueBlock }
