import { SearchPagination } from 'store/platformConfigStore/types'

export type MyAdvertisementsStoreType = {
  pagination: SearchPagination
  advertisements: MyAdvertisementsResponseDto[]
}

export type MyAdvertisementsResponseDto = {
  id: string
  type: 'sale' | 'buy'
  name: string
  price: number
  count: number
  sourceCategory: string
  server: string
  status: MyAdvertisementsStatus
  imageId: string
  expirationAt: string | null
}

export enum MyAdvertisementsStatus {
  Active = 'active',
  Hidden = 'hidden',
  Deleted = 'deleted',
  Moderation = 'moderation',
  Cancel = 'cancel',
  Banned = 'banned',
  Expired = 'expired'
}

export type MyAdvertisementDashboardResponseDto = {
  limit: MyAdvertisementsDashboardLimit
  statistic: MyAdvertisementsDashboardStatistic
}

export type MyAdvertisementsDashboardLimit = {
  maxCountBuy: number
  maxCountSale: number
  maxCountBuyVip: number
  maxCountSaleVip: number
  maxCountHourShow: number
  maxCountHourShowVip: number
}

export type MyAdvertisementsDashboardStatistic = {
  countBuy: number
  countSale: number
}
