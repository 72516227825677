import styles from './HomeActivitySection.module.css'

import { PurpleNeonButton } from 'components/ui/buttons/PurpleNeonButton'
import { BackFilter } from '../../components/BackFilter'
import { HomeActivitySectionText } from './HomeActivitySectionText'
import { HomeActivitySectionProductCard } from './HomeActivitySectionProductCard'
import { HomeActivityUsersCard } from './HomeActivityUsersCard'

import Back from './back-filter.svg'

const HomeActivitySection = () => {
  return (
    <section className={styles.section}>
      <BackFilter backContainer={Back} cssStyle={styles.back} />

      <div style={{ zIndex: 2, position: 'relative', pointerEvents: 'none' }}>
        <HomeActivitySectionText />

        <HomeActivitySectionProductCard />

        <HomeActivityUsersCard />
      </div>

      <PurpleNeonButton text="Создать аккаунт" cssStyle={styles.button} />
    </section>
  )
}

export { HomeActivitySection }
