import styles from './AdvertisementCreateDataAddPhotoBlock.module.css'

import { DivFlexRow, DivFlexColumn } from 'components/styledComponents/div/div'
import { Text16Grey, Text14White, Text14Grey } from 'components/styledComponents/text/textStyles'
import { ShopBarGallery } from 'components/ui/modals/ShopBarModal/ShopBarGallery'
import { useState, RefObject, useRef } from 'react'

import ObligatoryIcon from '../../../icons/obligatory_red.svg'
import AddPhotoIcon from '../../../icons/AdvertisementCreate/cloud.svg'

type Image = {
  id: string
  name: string
  image?: string
}

const galleryInputText = 'Для отображения дополнительного тюнинга, расцветок, винилов и других особенностей предмета'
const photoFormat = '.png, .jpg, .gif'

type AdvertisementCreateDataAddPhotoBlockProps = {
  scrollToPhoto: RefObject<HTMLDivElement>
  handleOnLoadImage: (name: string, buffer: string) => void
}

const AdvertisementCreateDataAddPhotoBlock = ({
  scrollToPhoto,
  handleOnLoadImage
}: AdvertisementCreateDataAddPhotoBlockProps) => {
  const [loadedImages, setLoadedImages] = useState<Image[]>([])
  const [activeLoadedImages, setActiveLoadedImages] = useState<Image>({
    id: '1',
    name: '',
    image: ''
  })

  const fileInputRef: RefObject<HTMLInputElement> = useRef(null)

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const toBase64 = (file: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(String(reader.result))
      reader.onerror = reject
    })

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files) {
      // Преобразовываем список файлов в массив объектов Image
      const newImages: Image[] = []
      for (const file of Array.from(files)) {
        newImages.push({
          id: `${Date.now()}`,
          name: file.name,
          image: URL.createObjectURL(file) // Создаем URL изображения для превью
        })

        const imageToBase64 = await toBase64(file)
        handleOnLoadImage(file.name, imageToBase64)
      }

      // Обновляем состояние loadedImages, добавляя новые изображения
      setLoadedImages((prevImages) => [...prevImages, ...newImages])
    }
  }

  return (
    <DivFlexRow style={styles.addPhotoBlock}>
      <DivFlexColumn style={styles.addPhotoGallery}>
        <DivFlexRow style={styles.addPhotoGalleryHeader}>
          <span ref={scrollToPhoto} />
          <Text16Grey>Загрузите изображения</Text16Grey>
          <img className={styles.addPhotoObligatory} src={ObligatoryIcon} alt="obligatory" />
        </DivFlexRow>

        <Text14White style={styles.galleryInputText}>{galleryInputText}</Text14White>

        {!!loadedImages.length && (
          <ShopBarGallery
            headerText="Выбран"
            loadedImages={loadedImages}
            activeImage={activeLoadedImages}
            setActiveImage={setActiveLoadedImages}
            cssContainer={styles.addGallery}
            cssDividerStyles={styles.addGalleryDivider}
            cssHeaderColor={styles.addGalleryTextColor}
          />
        )}
      </DivFlexColumn>

      <DivFlexColumn style={styles.addPhotoButton}>
        <input
          ref={fileInputRef}
          id="fileInput"
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          max-size="5000000"
          accept={photoFormat}
          multiple
        />
        <div className={styles.addButtonIconContainer}>
          <img className={styles.addButtonIcon} src={AddPhotoIcon} alt="product" />
        </div>

        <DivFlexRow style={styles.addPhotoTextBlock}>
          <DivFlexColumn style={styles.addPhotoUnderlineText} onClick={handleFileInputClick}>
            <Text14White style={styles.addPhotoWhiteText}>Нажмите для загрузки</Text14White>
            <span className={styles.undrline} />
          </DivFlexColumn>
          <Text14Grey>или перетащите изображения в окно</Text14Grey>
        </DivFlexRow>
        <Text14Grey style={styles.addPhotoFormatText}>PNG, JPG или GIF (макс. 5 мб)</Text14Grey>
      </DivFlexColumn>
    </DivFlexRow>
  )
}

export { AdvertisementCreateDataAddPhotoBlock }
