import { RangeDynamic } from '../RangeDinamic'
import styles from './RangeDinamicWithHeader.module.css'

type RangeDynamicWithHeaderProps = {
  onChange: (inputMin: string, inputMax: string) => void
  placeholderMin?: string | number
  placeholderMax?: string | number
  inputMin?: string
  inputMax?: string
  headerText: string
  fontSize?: number
}

const RangeDynamicWithHeader = ({
  headerText,
  onChange,
  placeholderMin,
  placeholderMax,
  inputMin,
  inputMax,
  fontSize = 14
}: RangeDynamicWithHeaderProps) => {
  return (
    <div>
      <p className={styles.headerText} style={{ fontSize: fontSize }}>
        {headerText}
      </p>
      <RangeDynamic
        inputMin={inputMin}
        inputMax={inputMax}
        placeholderMin={placeholderMin}
        placeholderMax={placeholderMax}
        onChange={onChange}
      />
    </div>
  )
}

export { RangeDynamicWithHeader }
