import styles from './HomeSubscriptionSectionMobile.module.css'

import { buttonsArray } from 'components/pages/Home/sections/HomeSubscriptionSection/buttonsArray'
import { DivFlexColumn, DivFlexRow } from 'components/styledComponents/div/div'
import { ImgBase } from 'components/styledComponents/img/img'
import { Text31White } from 'components/styledComponents/text/textStyles'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'

import Arrow from './arrow.svg'

const headerText = 'RP MARKET - первая целевая площадка для торговли на серверах GTA5RP.COM'
const cubText = 'В 10+ категориях - более 12к+ позиций'

const HomeSubscriptionSectionMobile = () => {
  return (
    <DivFlexColumn style={styles.section}>
      <DivFlexRow>
        <DivFlexColumn>
          <Text31White style={styles.header}>{headerText}</Text31White>
          <Text31White style={styles.subscription}>{cubText}</Text31White>
        </DivFlexColumn>
        <ImgBase img={Arrow} />
      </DivFlexRow>

      <div className={styles.filtersContainer}>
        {buttonsArray.map((button, index) => {
          const { text, icon } = button
          return (
            <OvalButton
              key={index}
              text={text}
              icon={icon}
              containerStyle={buttonStyles.containerStyle}
              iconStyle={buttonStyles.iconStyle}
              textStyle={buttonStyles.textStyle}
            />
          )
        })}
      </div>
    </DivFlexColumn>
  )
}

export { HomeSubscriptionSectionMobile }

const buttonStyles = {
  containerStyle: {
    height: '48px',
    borderRadius: '10px',
    padding: '12px 16px',
    gap: '9px',
    cursor: 'default',
    flex: 'auto'
  },
  iconStyle: {
    width: '20px',
    height: '20px'
  },
  textStyle: {
    fontSize: '14px'
  }
}
