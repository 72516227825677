import styles from './ArrowBottomButton.module.css'

const ArrowBottomButton = () => {
  return (
    <button className={styles.container}>
      <div className={styles.arrow}></div>
    </button>
  )
}

export default ArrowBottomButton
