import styles from './ChatPageInputBlock.module.css'

import { DivFlexRow } from 'components/styledComponents/div/div'
import OvalButton from 'components/ui/buttons/OvalButton/OvalButton'

import Icon from '../../../icons/send_icon.svg'
import React, { useState } from 'react'

type ChatPageInputBlockProps = {
  onSendMessage: (text: string) => void
}

const ChatPageInputBlock = ({ onSendMessage }: ChatPageInputBlockProps) => {
  const [inputText, setInputText] = useState<string>('')

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
  }

  const onClickSendMessage = () => {
    if (inputText.length > 0) {
      onSendMessage(inputText)
    }
    setInputText('')
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onClickSendMessage()
    }
  }

  return (
    <DivFlexRow style={styles.container}>
      <input
        value={inputText}
        className={styles.input}
        onChange={onChangeInput}
        onKeyDown={handleKeyDown}
        placeholder="Ваше сообщение..."
      />
      <OvalButton icon={Icon} cssContainerStyle={styles.button} onClick={onClickSendMessage} />
    </DivFlexRow>
  )
}

export { ChatPageInputBlock }
