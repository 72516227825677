import styles from './CheckBoxList.module.css'

import { useCallback, useEffect, useRef, useState } from 'react'
import { DivFlexColumn } from 'components/styledComponents/div/div'
import { CheckBoxWithText } from 'components/ui/checkboxes/CheckBoxWithText'
import { TumblerPurpleWithText } from 'components/ui/buttons/TumblerPurpleWithText'
import { VipBlockButton } from 'components/ui/VipBlockButton'
import { FilterCheckBoxType } from 'store/platformConfigStore/types'

type CheckBoxListProps = {
  onChange: (value: string[]) => void
  headerText?: string
  fontSize?: number
  list: FilterCheckBoxType[]
  activeCheckBoxes: string[]
  cssListStyles?: string
  isColumn?: boolean
  isTumbler?: boolean
  isBlocked?: boolean
  isBlockedIcon?: boolean
}

const CheckBoxList = ({
  onChange,
  headerText,
  fontSize,
  list,
  activeCheckBoxes,
  cssListStyles,
  isColumn,
  isTumbler,
  isBlocked = false,
  isBlockedIcon = false
}: CheckBoxListProps) => {
  const handleCheckActiveItem = (item: string) => {
    if (!activeCheckBoxes.includes(item)) {
      onChange([...activeCheckBoxes, item])
    } else if (activeCheckBoxes.includes(item)) {
      const newArray = activeCheckBoxes.filter((element) => element !== item)
      onChange(newArray)
    }
  }

  const [maxCheckBoxWidth, setMaxCheckBoxWidth] = useState<number>(0) // Состояние для максимальной ширины

  const gridContainerRef = useRef<HTMLDivElement | null>(null)

  const measureCheckBoxWidth = useCallback(
    (text: string) => {
      // Измеряем ширину элемента CheckBoxWithText
      let blockWidth
      const checkboxSize = 20
      const tumblerSize = 36
      const gap = 14

      const tempElement = document.createElement('div')
      tempElement.style.position = 'absolute'
      tempElement.style.visibility = 'hidden'
      tempElement.textContent = text
      document.body.appendChild(tempElement)
      const width = tempElement.offsetWidth
      document.body.removeChild(tempElement)

      if (isTumbler) {
        blockWidth = tumblerSize + gap + width
        return blockWidth
      } else {
        blockWidth = checkboxSize + gap + width
        return blockWidth
      }
    },
    [isTumbler]
  )

  useEffect(() => {
    // Найдем максимальную ширину элемента CheckBoxWithText
    let maxWidth = 0
    list.forEach((item) => {
      const itemWidth = measureCheckBoxWidth(item.name)
      if (itemWidth > maxWidth) {
        maxWidth = itemWidth
      }
    })
    setMaxCheckBoxWidth(maxWidth)
  }, [list, measureCheckBoxWidth, isTumbler])

  const updateGrid = useCallback(() => {
    // Определяем количество столбцов и их ширину
    const container = gridContainerRef.current
    const gridGap = 24
    if (container) {
      const containerWidth = container.offsetWidth
      const newColumns = Math.floor(containerWidth / (maxCheckBoxWidth + gridGap))
      container.style.gridTemplateColumns = `repeat(${newColumns}, minmax(${maxCheckBoxWidth}px, min-content))`
    }
  }, [maxCheckBoxWidth, gridContainerRef])

  useEffect(() => {
    // Обновляем столбцы при изменении максимальной ширины
    updateGrid()
  }, [maxCheckBoxWidth, updateGrid])

  return (
    <VipBlockButton isChildrenInside buttonText="В подписке" isBlocked={isBlocked}>
      <DivFlexColumn style={`${styles.container} ${isBlocked && styles.blocked}`}>
        {headerText && (
          <p className={styles.headerText} style={{ fontSize: fontSize }}>
            {headerText}
          </p>
        )}

        <div ref={gridContainerRef} className={`${styles.list} ${isColumn && styles.column} ${cssListStyles}`}>
          {!!list.length &&
            !isTumbler &&
            list.map((item, index) => {
              return (
                <CheckBoxWithText
                  key={index}
                  text={item.name}
                  isActive={activeCheckBoxes.includes(item.key)}
                  onClick={handleCheckActiveItem.bind(this, item.key)}
                  isBlocked={isBlockedIcon || isBlocked}
                />
              )
            })}
          {!!list.length &&
            isTumbler &&
            list.map((item, index) => {
              return (
                <TumblerPurpleWithText
                  key={index}
                  text={item.name}
                  isActive={activeCheckBoxes.includes(item.name)}
                  onClick={handleCheckActiveItem.bind(this, item.name)}
                  isRight
                />
              )
            })}
        </div>
      </DivFlexColumn>
    </VipBlockButton>
  )
}

export { CheckBoxList }
